import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { ClientUser, ClientUserSearchModel } from "../model/client-user";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { ApiRequestModelFactory } from '@apps/common/model/api.request.model';

@Injectable()
export class RestaurantUserService {
	constructor(private apiService: ApiService) {}

	getUsers(request: ClientUserSearchModel): Observable<IApiPagingResponseModel<ClientUser[]>> {
		// const params = new HttpParams({ fromObject: request as any });
		// return this.apiService.get(environment.prefix_api_url + "ClientUser/Users", params);
		return this.apiService.post(
			environment.prefix_api_url + "clientuser/list",
			request
		);
	}

	getUser(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "ClientUser/User", params);
	}

	createUser(context: ClientUser) {
		return this.apiService.post(environment.prefix_api_url + "ClientUser/Create", context);
	}

	updateUser(context: ClientUser) {
		return this.apiService.post(environment.prefix_api_url + "ClientUser/Update", context);
	}

	deleteUser(context: any) {
		return this.apiService.post(environment.prefix_api_url + "ClientUser/Delete", context);
	}

	unLockUser(context: any) {
		return this.apiService.post(environment.prefix_api_url + "ClientUser/Unlock", context);
	}

	lockUser(context: any) {
		return this.apiService.post(environment.prefix_api_url + "ClientUser/Lock", context);
	}

	getRoles() {
		const request: any = ApiRequestModelFactory.createPagingMaxSize();
		return this.apiService.post(environment.prefix_api_url + "clientrole/selection", request);


		// please change to use api: clientrole/selection | POST | Accept params:
		// {
		//		restaurantIds: [ restaurantId1, restaurantId2,...]
		// }

	}
}
