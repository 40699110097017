import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { CustomerLevelModule } from "../customer-level";
import { CampaignsModule } from "../campaigns";
import { GiftModule } from "../gift";
import { RewardPointModule } from "../reward-point";
import { GeneralConfigModule } from "../general-config";
import { CustomerServiceSettingsModule } from "../settings/settings.module";

const routes: Routes = [
	{
		path: "",
		redirectTo: AppRoutesNames.GENERAL,
	},
	{
		path: AppRoutesNames.CUSTOMER_LEVEL,
		loadChildren: () => CustomerLevelModule
	},
	{
		path: AppRoutesNames.CAMPAIGNS,
		loadChildren: () => CampaignsModule
	},
	{
		path: AppRoutesNames.GIFT,
		loadChildren: () => GiftModule
	},
	{
		path: AppRoutesNames.REWARDPOINT,
		loadChildren: () => RewardPointModule
	},
	{
		path: AppRoutesNames.GENERAL,
		loadChildren: () => GeneralConfigModule
	},
	{
		path: AppRoutesNames.SETTINGS,
		loadChildren: () => CustomerServiceSettingsModule
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomerConfigurationRoutingModule {}
