import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloCurrencyComponent } from "./currency.component";
import { EmloCurrencyListComponent } from "./components/list/currency.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloCurrencyDetailComponent } from "./components/detail/currency.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloCurrencyComponent,
		children: [
			{
				path: "",
				component: EmloCurrencyListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloCurrencyListComponent,
			},
			{ path: "add", component: EmloCurrencyDetailComponent },
			{ path: "edit", component: EmloCurrencyDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloCurrencyRoutingModule {}
