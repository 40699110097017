export enum Event {
	onPagination = "onPagination",
	onOrder = "onOrder",
	onGlobalSearch = "onGlobalSearch",
	onSearch = "onSearch",
	onClick = "onClick",
	onDoubleClick = "onDoubleClick",
	onCheckboxSelect = "onCheckboxSelect",
	onRadioSelect = "onRadioSelect",
	onCheckboxToggle = "onCheckboxToggle",
	onSelectAll = "onSelectAll",
	onInfiniteScrollEnd = "onInfiniteScrollEnd",
	onColumnResizeMouseDown = "onColumnResizeMouseDown",
	onColumnResizeMouseUp = "onColumnResizeMouseUp",
	onRowCollapsedShow = "onRowCollapsedShow",
	onRowCollapsedHide = "onRowCollapsedHide",
	onRowContextMenu = "onRowContextMenu"
}
