import { Component, OnInit } from "@angular/core";
import { CampaignsService } from "../../services/campaigns.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { Columns, Config, DefaultConfig } from "@apps/shared/table";
import { Campaigns } from "../../model/campaigns";

@Component({
	selector: "app-campaigns-detail",
	templateUrl: "detail.html",
})
export class CampaignsDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	public configuration: Config;
	public columns: Columns[] = [];
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	campaignsDetailFormFields: FormlyFieldConfig[] = [];
	campaignsDetail: Campaigns = new Campaigns();
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private campaignsService: CampaignsService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const campaignsId = params.get("id");
			if (campaignsId) {
				this.isEdit = true;
				this.getCampaignsById(campaignsId);
			}
			this.createForm();
			this.configCampaignsTable();
		});
	}

	private createForm() {
		this.campaignsDetailFormFields = [
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "name",
						defaultValue: null,
						className: "col-12",
						templateOptions: {
							label: "Name",
							placeholder: "Name",
						},
					},
					{
						key: "validFrom",
						type: "emlo-datetime",
						className: "col-6",
						templateOptions: {
							required: false,
							type: "text",
							label: "Valid From",
							placeholder: "dd/mm/yyyy",
						},
					},
					{
						key: "validTo",
						type: "emlo-datetime",
						className: "col-6",
						templateOptions: {
							required: false,
							type: "text",
							label: "Valid To",
							placeholder: "dd/mm/yyyy",
						},
					},
					{
						key: "description",
						type: "textarea",
						className: "col-12",
						templateOptions: {
							label: "Description",
							rows: 10,
						},
					},
					{
						type: "input",
						key: "image",
						defaultValue: null,
						className: "col-12",
						templateOptions: {
							type: "file",
							label: "Image",
							placeholder: "Image",
						},
					},
				],
			},
			IdControl,
		];
	}

	private configCampaignsTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = false;
		this.columns = [
			{ key: "date", title: "Date", orderEnabled: false },
			{ key: "bean", title: "Bean", orderEnabled: false },
		];
	}

	private getCampaignsById(campaignId: string): void {
		this.campaignsService
			.getCampaignsById(campaignId)
			.subscribe((campaigns: Campaigns) => {
				if (campaigns) {
					this.campaignsDetail = campaigns;
				}
			});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		this.isEdit ? this.updateCampaigns(data) : this.createCampaigns(data);
	}

	private createCampaigns(body: Campaigns): void {
		this.campaignsService.createCampaigns(body).subscribe(() => {
			this.backToCampaignsList();
		});
	}

	private updateCampaigns(body: Campaigns): void {
		this.campaignsService.updateCampaigns(body).subscribe(() => {
			this.backToCampaignsList();
		});
	}

	cancel() {
		this.backToCampaignsList();
	}

	private backToCampaignsList(): void {
		this.router.navigate(["/customer-service/configuration/campaigns"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}
}
