export const AppRoutesNames = {
	DASHBOARD: "",
	LOGIN: "login",
	COMING_SOON: "coming-soon",
	LIST: "list",
	ADMIN_DASH_BOARD: "admin",
	PRORUDCT_MANAGEMENT: "product-management",
	CATEGORY: "category",
	ITEMS: "items",
	MENU_GROUP: "menu-group",
	MODIFIER_GROUP: "modifier-group",
	MODIFIER: "modifier",
	COUPON: "coupon",
	TAX: "tax",
	ACCOUNT_MANAGEMENT: "account-management",
	USER: "user",
	USER_TYPE: "user-type",
	RESTAURANT_USER: "restaurant-user",
	RESTAURANT_USER_TYPE: "restaurant-user-type",
	CURRENCY: "currency",
	CONFIGURATION: "configuration",
	CLIENT: "client",
	REPORT: "report",
	CASH_REPORT: "cash-report",
	SALE_SUMMARY_REPORT: "sale-summary-report",
	DETAIL_SALE_REPORT: "detail-sale-report",
	MENU_ITEM_SALE_ANALYSIS_REPORT: "menu-item-sales-analysis",
	TOTAL_SALE_REPORT: "total-sale-report",
	ADMIN_SETTINGS: "admin-settings",
	LEDGER_GROUPS: "ledger-groups",
	LEDGER_ACCOUNTS: "ledger-accounts",
	PERIOD_LOCK: "period-lock",
	EMPLOYEE: "employee",
	EMPLOYEE_CATEGORY: "employee-category",
	EMPLOYEE_EXPENSES: "employee-expenses",
	CUSTOMER_SERVICE: "customer-service",
	CUSTOMER: "customer",
	ORDER: "order",
	CUSTOMER_LEVEL: "customer-level",
	OVERVIEW: "overview",
	CAMPAIGNS: "campaigns",
	GIFT: "gift",
	REWARDPOINT: "reward-point",
	GENERAL: "general",
	SETTINGS: "settings",
};
