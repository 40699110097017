import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloItemComponent } from "./item.component";
import { EmloItemListComponent } from "./components/list/item.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloItemDetailComponent } from "./components/detail/item.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloItemComponent,
		children: [
			{
				path: "",
				component: EmloItemListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloItemListComponent,
			},
			{ path: "add", component: EmloItemDetailComponent },
			{ path: "edit", component: EmloItemDetailComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class EmloItemRoutingModule {}
