import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmloPaginatePipe } from "./pipes/paginate.pipe";
import { EmloPaginationService } from "./services/pagination.service";
import { EmloPaginationComponent } from "./components/pagination.component";
import { EmloPaginationDirective } from "./directives/pagination.directive";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	imports: [CommonModule, MatIconModule],
	declarations: [
		EmloPaginatePipe,
		EmloPaginationComponent,
		EmloPaginationDirective
	],
	providers: [EmloPaginationService],
	exports: [
		EmloPaginatePipe,
		EmloPaginationComponent,
		EmloPaginationDirective
	]
})
export class EmloPaginationModule {}
