import { IPermissionModel } from "@apps/modules/emlo-admin/shared/model/permission.model";
import { UserRoleType } from "@apps/shared/enums/user.role-type";

export interface UserType {
	id: string;
	roleTypeId: number;
	concurrencyStamp: string;
	name: string;
	roleType: UserRoleType;
	permissions: IPermissionModel[];
}

export class UserTypeFactory {
	public static create(): UserType {
		return {
			id: null,
			concurrencyStamp: null,
			roleTypeId: null,
			name: null,
			roleType: UserRoleType.ADMINISTRATOR,
			permissions: null
		};
	}
}
