import { Component, OnInit } from "@angular/core";
import { CustomerServiceSettingService } from "../services/settings.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { CustomerServiceSetting } from "../model/settings";

@Component({
	selector: "customer-service-settings-detail",
	templateUrl: "detail.html",
	styleUrls: ["./styles.scss"],
})
export class CustomerServiceSettingsDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	tabIndex: number = 0;
	IconName = IconName;
	months: { value: number; name: string }[] = [];
	dates: number[] = [];

	customerServiceSettings: CustomerServiceSetting[] = [];
	constructor(
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private customerServiceSettingService: CustomerServiceSettingService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.months = Array.from({ length: 12 }, (item, i) => {
			return {
				value: i + 1,
				name: new Date(0, i).toLocaleString("vi-VN", { month: "long" }),
			};
		});
		this.dates = Array.from({ length: 31 }, (item, i) => {
			return i + 1;
		});

		this.activatedRoute.queryParamMap.subscribe(() => {
			this.getCustomerServiceSettings();
		});
	}

	private getCustomerServiceSettings(): void {
		this.customerServiceSettingService
			.getSettings()
			.subscribe((data: CustomerServiceSetting[]) => {
				if (data) {
					this.customerServiceSettings = data;
				}
			});
	}
	private updateGeneralConfig(body: CustomerServiceSetting[]): void {
		this.customerServiceSettingService
			.updateSettings(body)
			.subscribe(() => {});
	}

	onAddNewRow() {
		const newRow: CustomerServiceSetting = new CustomerServiceSetting();
		newRow.date = 1;
		newRow.month = 1;
		newRow.isChecked = true;
		this.customerServiceSettings.push(newRow);
	}

	onRemoveRow(index: number) {
		this.customerServiceSettings.splice(index, 1);
	}

	onSubmit() {
		this.updateGeneralConfig(this.customerServiceSettings);
	}
}
