/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:13:33
 * @modify date 2020-02-11 00:13:33
 * @desc [HTTP requests headers with configurable]
 */
import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import {
	CredentialsService,
	Credentials
} from "../authentication/credentials.service";

@Injectable({
	providedIn: "root"
})
export class HttpTokenInterceptor implements HttpInterceptor {
	constructor(private credentialsService: CredentialsService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const headersConfig = {
			"Content-Type": "application/json",
			"Accept": "application/json",
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Headers": "Content-Type"
		};

		const credentials: Credentials = this.credentialsService.credentials;

		if (credentials) {
			headersConfig[
				"Authorization"
			] = `Bearer ${credentials.access_token}`;
		}

		const request = req.clone({
			setHeaders: headersConfig,
			withCredentials: true
		});
		return next.handle(request);
	}
}
