import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { ModifierGroup, ModifierGroupSearchModel } from "../model/modifier-group";
import { environment } from "@env/environment";
import { IModifierSearchRequestModel } from '../../modifier/model/modifier-search-request.model';
import { Observable } from 'rxjs';
import { IApiPagingResponseModel } from '@apps/common/model/api.reponse.model';
import { IModifierGroupSearchRequestModel } from '../model/modifier-search-request.model';

@Injectable()
export class ModifierGroupService {
	constructor(private apiService: ApiService) {}

	getModifierGroups(request: ModifierGroupSearchModel): Observable<IApiPagingResponseModel<ModifierGroup[]>> {
		return this.apiService.post(environment.prefix_api_url+ "modifierGroup/list", request);
	}

	// getModifierGroupSelections(request: IModifierGroupSearchRequestModel): Observable<IApiPagingResponseModel<ModifierGroup[]>> {
	// 	return this.apiService.post(environment.prefix_api_url+ "modifierGroup/SelectionList", request);
	// }

	getModifierGroupSelections(restaurantId: string) {
		var request = {
			restaurantIds: restaurantId ? [ restaurantId ] : []
		} ;
		return this.apiService.post(environment.prefix_api_url + "modifiergroup/selectionlist", request);
		// const request = new HttpParams({fromObject: {restaurantId: restaurantId}})
		// return this.apiService.get(environment.prefix_api_url + "modifiergroup/selectionlist", request);
	}

	getModifierGroup(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url+ "modifierGroup/getbyid", params);
	}

	createModifierGroup(context: ModifierGroup) {
		return this.apiService.post(environment.prefix_api_url+ "modifierGroup/create", context);
	}

	updateModifierGroup(context: ModifierGroup) {
		return this.apiService.post(environment.prefix_api_url+ "modifierGroup/update", context);
	}

	deleteModifierGroup(context: any) {
		return this.apiService.post(environment.prefix_api_url+ "modifierGroup/delete", context);
	}
}
