import { IMenuGroupSearchRequestModel, MenuGroupSearchRequestModelFactory } from "./../../menu-group/model/menu-group-search-request.model";
import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";
import { LogicalCondition } from "@apps/shared/filter-builder/services/logic-condition";
import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { DefaultCondition } from "@apps/shared/filter-builder/services/default-condition";
import { FilterType } from "@apps/shared/filter-builder/model/condition-type.enum";

@Injectable()
export class ItemMenuGroupService {
	constructor(private apiService: ApiService) {}

	getVisiblegroup(restaurantId: string | string[]) {
		// let menuGroupRequest: any = MenuGroupSearchRequestModelFactory.createWithRestaurantId(restaurantId);
		// menuGroupRequest = {...menuGroupRequest, ...new LogicalCondition(ConditionOperator.AND, [
		// 	new DefaultCondition(
		// 		"isVisible",
		// 		"1",
		// 		ConditionOperator.EQUAL,
		// 		null,
		// 		null,
		// 		FilterType.BOOLEAN
		// 	)
		// ]).printPretty()};
		// return this.apiService.post(environment.prefix_api_url + "group/list", menuGroupRequest);
		let menuGroupRequest = {
			restaurantIds: restaurantId ? [ restaurantId ] : null
		};
		return this.apiService.post(environment.prefix_api_url + "group/groupSelection", menuGroupRequest);
	}

	/***
	 * SAMPLE GET MENU GROUP FOR PAGINATION
	 **/
	public getSampleMenuGroup(restaurantId: string, selectedId: string, searchTern: string, pageIndex: number)
	{
		return this.apiService.post("/aapi/v1/group/GroupSelection", 
		{ "searchText": searchTern, "selectedId": selectedId, "restaurantId": restaurantId, "pageNum": pageIndex, "pageSize": 10 });
	}
}
