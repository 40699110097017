import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { Config, PaginationRange } from "../..";
import { EmloPaginationDirective } from "@apps/shared/pagination/directives/pagination.directive";

@Component({
	selector: "pagination",
	templateUrl: "./pagination.html",
	styleUrls: ["./pagination.scss"]
})
export class EmloPaginationTableComponent implements OnChanges {
	@ViewChild("paginationDirective", { static: true })
	paginationDirective: EmloPaginationDirective;
	@ViewChild("paginationRange") paginationRange;
	@Input() pagination: any;
	@Input() config: Config;
	@Input() id: any;
	@Output() readonly updateRange: EventEmitter<
		PaginationRange
	> = new EventEmitter();
	public ranges: number[] = [10, 15, 20, 30, 50, 100];
	public selectedLimit: number;
	public showRange = false;
	public screenReaderPaginationLabel = "Pagination";
	public screenReaderPageLabel = "";
	public screenReaderCurrentLabel = "";
	public previousLabel = "Prev";
	public nextLabel = "Next";
	public directionLinks = true;

	@HostListener("document:click", ["$event.target"])
	public onClick(targetElement: any): void {
		if (
			this.paginationRange &&
			!this.paginationRange.nativeElement.contains(targetElement)
		) {
			this.showRange = false;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { config } = changes;
		if (config && config.currentValue) {
			this.selectedLimit = this.config.rows;
		}
	}

	onPageChange(page: number): void {
		this.updateRange.emit({
			page,
			limit: this.selectedLimit
		});
	}

	changeLimit(limit: number, callFromAPI: boolean): void {
		if (!callFromAPI) {
			this.showRange = !this.showRange;
		}
		this.selectedLimit = limit;
		this.updateRange.emit({
			page: 1,
			limit
		});
	}
}
