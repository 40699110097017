<div class="box border-sub-1">
	<emlo-box-header [title]="'Customers Level - ' + (isEdit ? 'Edit' : 'Add')"
		[headerActionsTemplate]="headerActionsTemplate">
	</emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content item-content" *ngIf="customerLevel">
			<formly-form [model]="customerLevel" [fields]="customerFormFields" [form]="form"></formly-form>
			<div class="row" *ngIf="customerLevel.gifts && customerLevel.gifts.length > 0">
				<div class="col-lg-12 mb-2 font-weight-bold">Attached gifts</div>
				<div class="col-lg-12">
					<div class="row">
						<table class="table table-striped">
							<tbody>
								<tr class="col-lg-12" *ngFor="let item of customerLevel.gifts; let index = index">
									<td class="align-middle">{{item.name}}</td>
									<td class="text-right align-middle">
										<button type="button" class="btn btn-danger btn-sm"
											(click)="onRemoveCustomerGift(item, index)">Remove</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>
				Cancel
			</button>

			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
