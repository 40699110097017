import { CommonConstant } from "@apps/common/constants/common.constant";

export interface ModifierGroup {
	id: string;
	isEnabled: boolean;
	isExclusived: boolean;
	name: string;
	nameCn: string;
	nameEn: string;
	nameVi: string;
	parentMenuModifierGroupId: string;
	parentMenuModifierGroupName: string;
	parentMenuModifierGroupNameEn: string;
	parentMenuModifierGroupNameCn: string;
	parentMenuModifierGroupNameVi: string;
	isRequired: boolean;
	parentMenuModifierGroup: ModifierGroup;
	restaurantIds: string | string[];
	restaurants: ModifierGroupRestaurant[];
	concurrencyStamp: string;
}

export interface ModifierGroupRestaurant {
	concurrencyStamp: string;
	id: string;
	parentMenuModifierGroupId: string;
	name: string;
}

export class ModifierGroupSearchModel {
	searchText: string = null;
	isVisible: boolean = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export class ModifierGroupFactory {
	public static create(): ModifierGroup {
		return {
			id: null,
			isEnabled: false,
			isExclusived: false,
			name: null,
			nameCn: null,
			nameEn: null,
			nameVi: null,
			parentMenuModifierGroupId: null,
			parentMenuModifierGroupName: null,
			parentMenuModifierGroupNameEn: null,
			parentMenuModifierGroupNameCn: null,
			parentMenuModifierGroupNameVi: null,
			isRequired: false,
			parentMenuModifierGroup: null,
			concurrencyStamp: null,
			restaurantIds: [],
			restaurants: [],
		};
	}
}
