/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:12:27
 * @modify date 2020-02-11 00:12:27
 * @desc [Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.]
 */
import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@env/environment";

@Injectable({
	providedIn: "root"
})
export class ApiPrefixInterceptor implements HttpInterceptor {
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (!/^(http|https):/i.test(request.url)) {
			request = request.clone({
				url: environment.api_url + request.url
			});
		}
		return next.handle(request);
	}
}
