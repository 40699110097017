import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloUserTypeComponent } from "./usertypes.component";
import { EmloUserTypeListComponent } from "./components/list/usertype.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloUserTypeDetailComponent } from "./components/detail/usertype.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloUserTypeComponent,
		children: [
			{
				path: "",
				component: EmloUserTypeListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloUserTypeListComponent,
			},
			{ path: "add", component: EmloUserTypeDetailComponent },
			{ path: "edit", component: EmloUserTypeDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloUserTypeRoutingModule {}
