import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";

import { ComingSoonRoutingModule } from "./coming-soon-routing.module";
import { ComingSoonComponent } from "./components/coming-soon.component";
import { SharedModule } from "@apps/shared";
import { EmloButtonModule } from "@apps/shared/button";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		MatIconModule,
		SharedModule,
		EmloButtonModule,
		ComingSoonRoutingModule
	],
	declarations: [ComingSoonComponent]
})
export class ComingSoonModule {}
