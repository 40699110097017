import { CommonConstant } from "@apps/common/constants/common.constant";

export interface Coupon {
	id: string;
	name: string;
	type: string;
	typeName: string;
	value: number;
	expiryDate: any;
	isDisabled: boolean;
	isNeverExpire: string;
	restaurantId: string | string[];
	restaurants: CouponRestaurant[];
}

export interface CouponRestaurant {
	concurrencyStamp: string;
	expiryDate: any;
	id: string;
	isDisabled: boolean;
	isNeverExpire: boolean;
	name: string;
	type: number;
}

export class CouponSearchModel {
	searchText: string = null;
	isEnabled: boolean = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
export class CouponFactory {
	public static create(): Coupon {
		return {
			id: null,
			name: null,
			type: null,
			typeName: null,
			value: null,
			expiryDate: null,
			isDisabled: false,
			isNeverExpire: null,
			restaurantId: null,
			restaurants: [],
		};
	}
}
