/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-13 00:00:34
 * @modify date 2020-02-13 00:00:34
 * @desc [Service for add svg icons custom]
 */
import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@env/environment";
import { IconName } from "@apps/common/constants/icon";

@Injectable({
	providedIn: "root"
})
export class IconService {
	private readonly PATH_SVG = "assets/svg/";
	constructor(
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer
	) {}

	init() {
		this.iconRegistry.addSvgIcon(
			IconName.icon_dashboard,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "dashboard.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_category,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "category.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_menu_item,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "menu-item.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_menu_group,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "menu-group.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_modifier_group,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "modifier-group.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_modifier,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "modifier.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_coupon,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "coupon.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_tax,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "tax.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_currency,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "currency.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_users,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "users.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_user_group,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "user-group.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_report,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "report.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_configuration,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "configuration.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_user,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "user.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_lock,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "lock.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_eye,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "eye.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_eye_hide,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "eye-hide.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_acc,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "profile_person.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_settings,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "settings.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_notification,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "notification.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_signout,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "signout.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_close,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "close.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_menu,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "menu.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_home,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "home.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_double_arrow_left,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "double-arrow-left.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_double_arrow_right,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "double-arrow-right.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_double_down_arrow,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "double-down-arrow.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_double_up_arrow,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "double-up-arrow.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_pinned,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "pinned.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_more,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "more.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_admin_settings,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "admin-settings.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_electronics,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "electronics.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_warning,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "warning.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_edit,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "edit.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_trash,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "trash-bin.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_employee,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "employee.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_finance,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "finance.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_money_coin,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "money-coin.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_arrow_up,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "arrow-up.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_arrow_down,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "arrow-down.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_add,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "add.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_add_nocircle,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "add-nocircle.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_checkmark,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "checkmark.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_search,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "search.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_filter,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "filter.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_calendar,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "calendar.svg"
			)
		);
		this.iconRegistry.addSvgIcon(
			IconName.icon_download_black_24dp,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				environment.base_url + this.PATH_SVG + "download_black_24dp.svg"
			)
		);
	}
}
