import { Component, OnInit } from "@angular/core";
import { GeneralConfigService } from "../../services/general-config.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { GeneralConfig, ExcludeDate } from "../../model/general-config";
import { finalize } from "rxjs/operators";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { MatDialog } from "@angular/material/dialog";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";

@Component({
	selector: "app-general-config-detail",
	templateUrl: "detail.html",
})
export class GeneralConfigDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	isLoading: boolean = false;
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	generalConfigDetailFormFields: FormlyFieldConfig[] = [];
	generalConfigDetail: GeneralConfig = new GeneralConfig();
	months: { value: number; name: string }[] = [];
	days: number[] = [];
	constructor(
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private generalConfigService: GeneralConfigService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.activatedRoute.queryParamMap.subscribe(() => {
			this.getGeneralConfig();
			this.createForm();
		});
		this.months = Array.from({ length: 12 }, (item, i) => {
			return {
				value: i + 1,
				name: new Date(0, i).toLocaleString("vi-VN", { month: "long" }),
			};
		});
		this.days = Array.from({ length: 31 }, (item, i) => {
			return i + 1;
		});
	}

	private createForm() {
		this.generalConfigDetailFormFields = [
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "amountPerPoint",
						defaultValue: null,
						className: "col-12",
						templateOptions: {
							label: "Amount per point",
							placeholder: "Amount per point",
						},
					},
					{
						type: "emlo-input",
						key: "concurrencyStamp",
						templateOptions: {
							type: "hidden",
						},
					},
				],
			},
		];
	}

	private getGeneralConfig(): void {
		this.isLoading = true;
		this.generalConfigService
			.getGeneralConfig()
			.pipe(
				finalize(() => {
					setTimeout(() => {
						this.isLoading = false;
					}, 200);
				})
			)
			.subscribe((data: GeneralConfig) => {
				if (data) {
					this.generalConfigDetail = data;
				}
			});
	}

	submit() {
		const data = this.form.value;
		data.excludeDayMonths = this.formatDataBeforeSubmit(
			this.generalConfigDetail.excludeDayMonths
		);
		this.updateGeneralConfig(data);
	}

	private formatDataBeforeSubmit(excludeDayMonths: ExcludeDate[] = []) {
		excludeDayMonths?.forEach((x) => {
			x.day = Number(x.day);
			x.month = Number(x.month);
		});
		return excludeDayMonths;
	}

	private updateGeneralConfig(body: GeneralConfig): void {
		this.isLoading = true;
		this.generalConfigService
			.updateGeneralConfig(body)
			.pipe(
				finalize(() => {
					setTimeout(() => {
						this.isLoading = false;
					}, 200);
				})
			)
			.subscribe((latestConfig: GeneralConfig) => {
				// this.backToGernalList();
				this.generalConfigDetail = latestConfig;
			});
	}

	cancel() {
		// this.backToGernalList();
	}

	private backToGernalList(): void {
		// this.router.navigate(["/customer-service/configuration/general"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	onAddNewRow() {
		const newRow: ExcludeDate = new ExcludeDate();
		newRow.day = 1;
		newRow.month = 1;
		newRow.isLunar = true;
		if (!this.generalConfigDetail.excludeDayMonths) {
			this.generalConfigDetail.excludeDayMonths = [];
		}
		this.generalConfigDetail.excludeDayMonths.push(newRow);
	}

	onRemoveRow(index: number) {
		this.generalConfigDetail.excludeDayMonths.splice(index, 1);
	}

	onInitGeneralConfig() {
		const confirmModel: ConfirmModel = {
			id: null,
			message:
				"This progress will remove all currently configuration, it will impact to customer. Are you sure to continue?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.generalConfigService
						.initGeneralConfig()
						.subscribe((latestConfig: GeneralConfig) => {
							this.generalConfigDetail = latestConfig;
						});
				}
			});
	}
}
