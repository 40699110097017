import { Injectable } from "@angular/core";
import { Config, STYLE, THEME } from "../model/config";
import { CommonConstant } from '@apps/common/constants/common.constant';

export const DefaultConfig: Config = {
	searchEnabled: false,
	headerEnabled: true,
	orderEnabled: true,
	orderEventOnly: false,
	paginationEnabled: true,
	exportEnabled: false,
	clickEvent: true,
	selectRow: false,
	selectCol: false,
	selectCell: false,
	rows: CommonConstant.NUMBER_RECORD_PER_PAGE,
	additionalActions: false,
	serverPagination: false,
	isLoading: false,
	detailsTemplate: false,
	groupRows: false,
	paginationRangeEnabled: true,
	collapseAllRows: false,
	checkboxes: false,
	radio: false,
	resizeColumn: false,
	fixedColumnWidth: false,
	horizontalScroll: false,
	draggable: false,
	logger: false,
	showDetailsArrow: false,
	showContextMenu: false,
	persistState: false,
	paginationMaxSize: 5,
	threeWaySort: false,
	tableLayout: {
		style: STYLE.NORMAL,
		theme: THEME.LIGHT,
		borderless: false,
		hover: true,
		striped: true,
		bordered: true
	}
};

@Injectable()
export class DefaultConfigService {
	public static config: Config = DefaultConfig;
}
