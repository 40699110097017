import { Subject } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { MenuGroupService } from "../../services/menu-group.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import {
	MenuGroup,
	MenuGroupFactory,
	MenuGroupSearchModel,
} from "../../model/menu-group";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
	IdControl,
	NameEnControl,
	NameViControl,
	NameCnControl,
	ButtonTextColorControl,
	VisibleAndSortOrderControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { CategoryService } from "../../../category/services/category.service";
import { CategorySearchModel } from "../../../category/model/category";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
@Component({
	selector: "app-menu-group-detail",
	templateUrl: "detail.html",
})
export class EmloMenuGroupDetailComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	form = new FormGroup({});
	menuGroupFormFields: FormlyFieldConfig[] = [];

	category$: Subject<any[]> = new Subject();
	categoryGroup$: Subject<any[]> = new Subject();
	menuGroup = MenuGroupFactory.create();
	tabIndex: number = 0;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private menuGroupService: MenuGroupService,
		private restaurantService: RestaurantService,
		private categoryService: CategoryService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.isEdit = this.menuGroup && this.menuGroup.id !== null;
		this.createForm();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const menuGroupId = params.get("id");
			this.isEdit = menuGroupId && menuGroupId !== null;
			this.restaurantService
				.getRestaurants()
				.subscribe((restaurants: IRestaurantItemModel[]) => {
					if (restaurants && restaurants.length > 0) {
						this.restaurants = restaurants;
						this.restaurants.map(x => {
							x.isVisible = false;
							x.sortOrder = null;
							x.menuCategoryId = null;
							x.parentGroupId = null;
						})
					}
					if (this.isEdit) {
						this.initEditMenuGroup(menuGroupId);
					}
				});
		});
	}

	ngOnDestroy() {}

	private createForm() {
		this.menuGroupFormFields = [
			// {
			// 	key: "restaurantIds",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		multiple: true,
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		options: this.adminShareDataService.restaurants,
			// 		change: (field, $event) => {
			// 			// field.form.controls["categoryId"].setValue(null);
			// 			// field.form.controls["parentGroupId"].setValue(null);
			// 			// this.restaurant$.next(field.model?.restaurantId);
			// 		}
			// 	},
			// 	defaultValue: [this.adminShareDataService.restaurantActive?.id]
			// },
			IdControl,
			NameEnControl,
			NameViControl,
			NameCnControl,
			{
				type: "emlo-input",
				key: "concurrencyStamp",
				templateOptions: {
					type: "hidden",
				},
			},
			// {
			// 	fieldGroupClassName: "row",
			// 	fieldGroup: [
			// 		{
			// 			type: "emlo-select",
			// 			key: "categoryId",
			// 			defaultValue: null,
			// 			className: "col-6",
			// 			templateOptions: {
			// 				label: "Category",
			// 				required: true,
			// 				options: this.category$
			// 			}
			// 		},
			// 		{
			// 			type: "emlo-select",
			// 			key: "parentGroupId",
			// 			defaultValue: null,
			// 			className: "col-6",
			// 			templateOptions: {
			// 				label: "Parent Group",
			// 				options: this.categoryGroup$
			// 			}
			// 		}
			// 	]
			// },
			{
				type: 'emlo-input',
				key: 'concurrencyStamp',
				templateOptions: {
					type: 'hidden',
				},
			},
			VisibleAndSortOrderControl,
			ButtonTextColorControl,
		];
	}

	private initEditMenuGroup(menuGroupId: string): void {
		this.menuGroupService
			.getMenuGroup(menuGroupId)
			.subscribe((menuGroup: MenuGroup) => {
				this.menuGroup = menuGroup;
				this.mapDataMenuGroupRestaurant();
			});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		// if (assignRestaurants && assignRestaurants.length > 0) {
		// 	assignRestaurants.map((a) => {
		// 		delete a.parentMenuGroups;
		// 		delete a.categories;
		// 	});
		// }
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateMenuGroup(data) : this.createMenuGroup(data);
	}

	private createMenuGroup(body: MenuGroup): void {
		this.menuGroupService.createMenuGroup(body).subscribe(() => {
			this.backToMenuGroups(true);
		});
	}

	private updateMenuGroup(body: MenuGroup): void {
		this.menuGroupService.updateMenuGroup(body).subscribe(() => {
			this.backToMenuGroups(true);
		});
	}

	cancel() {
		this.backToMenuGroups();
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {
		if (!restaurant.sortOrder) {
			restaurant.sortOrder = this.menuGroup.sortOrder;
		}
		if (!restaurant.categories) {
			this.getCategoryByRestaurantId(restaurant);
		}
		if (!restaurant.parentMenuGroups) {
			this.getParentGroupsByRestaurantId(restaurant);
		}
	}

	private mapDataMenuGroupRestaurant(): void {
		this.restaurants.map((res) => {
			const itemRestaurant = this.menuGroup.restaurants.find(
				(itemRes) => itemRes.id === res.id
			);
			res.assigned = false;
			if (itemRestaurant) {
				res.assigned = true;
				res.isVisible = itemRestaurant.isVisible;
				res.sortOrder = itemRestaurant.sortOrder;
				res.parentGroupId = itemRestaurant.parentGroupId;
				res.menuCategoryId = itemRestaurant.menuCategoryId;
				res.concurrencyStamp = itemRestaurant.concurrencyStamp || null;
				this.getCategoryByRestaurantId(res);
				this.getParentGroupsByRestaurantId(res);
			}
		});
	}

	private getCategoryByRestaurantId(itemRestaurant: IRestaurantItemModel) {
		const request: CategorySearchModel = new CategorySearchModel();
		request.pageSize = 100000;
		request.restaurantId = itemRestaurant.id;
		this.categoryService.getCategories(request).subscribe((data) => {
			if (data) {
				itemRestaurant.categories = data.data;
			}
		});
	}

	private getParentGroupsByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		const request: MenuGroupSearchModel = new MenuGroupSearchModel();
		request.pageSize = 100000;
		request.restaurantId = itemRestaurant.id;
		this.menuGroupService.getMenuGroups(request).subscribe((data) => {
			if (data) {
				itemRestaurant.parentMenuGroups = data.data;
			}
		});
	}

	private backToMenuGroups(isReload: boolean = false): void {
		this.router.navigate(["/product-management/menu-group"]);
	}
}
