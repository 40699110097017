import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatDialogRef
} from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { GeneralConfigRoutingModule } from "./general-config.routes";
import { GeneralConfigComponent } from "./general-config.component";
import { GeneralConfigListComponent } from "./components/list/general-config.list.component";
import { GeneralConfigDetailComponent } from "./components/detail/general-config.detail.component";
import { GeneralConfigService } from "./services/general-config.service";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { EmloFormModule } from "@apps/shared/form";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
	declarations: [
		GeneralConfigComponent,
		GeneralConfigListComponent,
		GeneralConfigDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatTabsModule,
		MatRadioModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		FormlyBootstrapModule,
		EmloFormModule,
		GeneralConfigRoutingModule,
	],
	providers: [
		GeneralConfigService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [GeneralConfigDetailComponent]
})
export class GeneralConfigModule {}
