<div class="box border-sub-1">
	<emlo-box-header [title]="'Settings'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content item-content">
		<table class="table" *ngIf="customerServiceSettings">
			<thead>
				<tr>
					<th class="px-0">Date</th>
					<th class="px-2">Month</th>
					<th class="px-2">Check</th>
					<th class="px-0 col-remove"></th>
				</tr>
			</thead>
			<tbody>
				<ng-template ngFor let-index="index" let-item [ngForOf]="customerServiceSettings">
					<tr>
						<td class="align-middle px-0">
							<select class="form-control" [(ngModel)]="item.date">
								<option [value]="date" *ngFor="let date of dates">{{date}}</option>
							</select>
						</td>
						<td class="align-middle px-2">
							<select class="form-control" [(ngModel)]="item.month">
								<option [value]="month.value" *ngFor="let month of months">{{month.name}}</option>
							</select>
						</td>
						<td class="align-middle px-2">
							<input [(ngModel)]="item.isChecked" type="checkbox" />
						</td>
						<td class="align-middle px-0 col-remove text-right">
							<button class="btn btn-danger" (click)="onRemoveRow(index)">Remove</button>
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
	<div class="box-footer" *ngIf="customerServiceSettings.length > 0">
		<emlo-button type="button" [className]="'btn btn-primary'" [textButton]="'Submit'" translate
			(submit)="onSubmit()"></emlo-button>
	</div>
	<ng-template #headerActionsTemplate>
		<button class="btn btn-primary" (click)="onAddNewRow()">Add</button>
	</ng-template>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
</div>
