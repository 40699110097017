import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { CampaignsSearchModel, Campaigns } from "../../model/campaigns";
import { CampaignsService } from "../../services/campaigns.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-campaigns-list",
	templateUrl: "./list.html",
})
export class CampaignsListComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public campaigns: Campaigns[] = [];
	public pagination = PaginationModelFactory.create();
	criteria: CampaignsSearchModel = new CampaignsSearchModel();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private campaignsService: CampaignsService,
		private credentialsService: CredentialsService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.configCampaignsTable();
		this.getCampaigns();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria = { ...this.criteria, ...$event.value };
			this.getCampaigns();
		}
	}

	private getCampaigns() {
		this.campaignsService
			.getCampaigns()
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if (response) {
					this.campaigns = response.data;
				}
			});
	}

	private configCampaignsTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "name", title: "Name", orderEnabled: false },
			{ key: "description", title: "Description", orderEnabled: false },
			{ key: "validFrom", title: "Valid From", orderEnabled: false },
			{ key: "validTo", title: "Valid To", orderEnabled: false },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteCampaigns(campaigns: Campaigns): void {
		const data = { id: campaigns.id };
		this.campaignsService
			.deleteCampaigns(JSON.stringify(data))
			.subscribe(() => {
				this.getCampaigns();
			});
	}

	delete(campaigns: Campaigns): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteCampaigns(campaigns);
				}
			});
	}

	openCampaignsDetail(campaigns: Campaigns): void {
		if (!campaigns) {
			this.router.navigate(["/customer-service/configuration/campaigns/add"]);
		} else {
			this.router.navigate(["/customer-service/configuration/campaigns/edit"], {
				queryParams: { id: campaigns.id },
			});
		}
	}

	onFilter() {
		this.getCampaigns();
	}

	ngOnDestroy() {}
}
