import {
	Component,
	Inject,
	ChangeDetectionStrategy
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WarningModel } from "./warning";
import { IconName } from "@apps/common/constants/icon";

@Component({
	selector: "emlo-warning",
	styleUrls: ["./style.scss"],
	templateUrl: "warning.html"
})
export class EmloWarningComponent {
	IconName = IconName;
	confirmTextButton: string = "OK";
	constructor(
		public dialogRef: MatDialogRef<EmloWarningComponent>,
		@Inject(MAT_DIALOG_DATA) public warningModel: WarningModel
	) {
		if (warningModel && warningModel.textOk)
			this.confirmTextButton = warningModel.textOk;
	}

	confirm() {
		this.dialogRef.close(true);
	}
}
