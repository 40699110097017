import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import {
	Customer,
	CustomerSearchModel,
	CustomerClaimPointHistory,
	CustomerTransactionSearchModel,
	CustomerGiftSearchModel,
	AddPointCustomer,
	CustomerGift,
	AddGiftCustomer,
	UpdateGiftCustomer,
	SendGiftCustomerLevel,
	SendGiftCustomerLevelRequest,
} from "../model/customer";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable } from "rxjs";
@Injectable()
export class CustomerService {
	constructor(private apiService: ApiService) {}

	getCustomers(
		request: CustomerSearchModel
	): Observable<IApiPagingResponseModel<Customer[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "customer/list",
			request
		);
	}

	removeGiftFromCustomer(_dataRequest: { id: string | number; customerId: string; notes: any; }) : Observable<any> {
		return this.apiService.post(
			environment.prefix_api_url + "customer/removeGiftFromCustomer",
			_dataRequest
		);
	}

	getCustomerById(id: string) {
		const params = new HttpParams().set("code", id);
		return this.apiService.get(
			environment.prefix_api_url + "customer/getbycode",
			params
		);
	}

	createCustomer(context: Customer) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/create",
			context
		);
	}

	updateCustomer(context: Customer) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/update",
			context
		);
	}

	deleteCustomer(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/delete",
			context
		);
	}

	unLockCustomer(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/unlock",
			context
		);
	}

	lockCustomer(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/lock",
			context
		);
	}

	getCustomerPointHistory(request: CustomerTransactionSearchModel, customerCode: string): Observable<IApiPagingResponseModel<CustomerClaimPointHistory[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "customer/listPointHistory?code=" + customerCode,
			request
		);
	}

	getCustomerGifts(request: CustomerGiftSearchModel, customerCode: string): Observable<IApiPagingResponseModel<CustomerGift[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "customer/listCustomerGifts?code=" + customerCode,
			request
		);
	}

	updatePoint(context: AddPointCustomer) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/addPointsToCustomer",
			context
		);
	}

	addGift(context: AddGiftCustomer) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/addGiftToCustomer",
			context
		);
	}

	updateGift(context: UpdateGiftCustomer) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/editGiftToCustomer",
			context
		);
	}

	sendGift(context: SendGiftCustomerLevel) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/sendGiftToLevel",
			context
		);
	}

	sendGiftToLevel(request: SendGiftCustomerLevelRequest) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/sendGiftToLevel",
			request
		);
	}
}
