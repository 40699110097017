import { BaseDataReport } from "../../shared/model/report.base.mode";

export class CashSummary {
	paymentType: string;
	paymentTypeDisplay: string;
	numberOfChecks: number;
	totalAmount: number;
}

export class DetailSaleSummaryReport extends BaseDataReport {
	reportKey: string;
	generatedAt: string;
	state: string;
	ping: ReportPing;
	data: FullDataDetailSaleSummary;
	summary: TotalDetailSaleSummary;
}

export class FullDataDetailSaleSummary {
	totalItems: number;
	totalPages: number;
	pageSize: number;
	pageNum: number;
	data: DetailSaleSummary[];
}
export class ReportPing {
	action: string;
	controller: string;
	method: string;
	routeData: ReportRouteData;
}

export class DetailSaleSummary {
	ticketId: number;
	date: string;
	check: number;
	kitchen: number;
	bbq: number;
	dimsum: number;
	bar: number;
	pickle: number;
	cigarette: number;
	serviceFee: number;
	totalBeforeTax: number;
	discount: number;
	tax: number;
	roundAdjusted: number;
	receiptTotal: number;
	paymentType: string;
	paymentTypeDisplay: string;
}

export class TotalDetailSaleSummary {
	totalKitchen: number;
	totalBBQ: number;
	totalDimsum: number;
	totalBar: number;
	totalPickle: number;
	totalCigarette: number;
	totalServiceFee: number;
	totalBeforeTax: number;
	totalDiscount: number;
	totalTax: number;
	totalRoundAdjusted: number;
	totalReceiptTotal: number;
}

export class ReportRouteData {
	endDate: string;
	endTime: string;
	pageNum: number;
	pageSize: number;
	regenerate: false;
	startDate: string;
	startTime: string;
}
