export enum OrderDirectionEnum {
	ASC = "asc",
	DESC = "desc"
}
export class IOrderModel {
	direction: OrderDirectionEnum | string;
	columnName: string;
}

export class OrderFactory {
	static printPretty(orders: IOrderModel[]): string[] {
		return orders.map(x => {
			return x.columnName + "-" +  x.direction;
		});
	}
}
