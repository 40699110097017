import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloFilterBuilderModule } from "@apps/shared/filter-builder/filter-builder.module";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ColorPickerModule } from "ngx-color-picker";

import { EmloFormModule } from "./../../../../shared/form/form.module";
import { EmloCategoryComponent } from "./category.component";
import { EmloCategoryRoutingModule } from "./category.routes";
import { EmloCategoryDetailComponent } from "./components/detail/category.detail.component";
import { EmloCategoryListComponent } from "./components/list/category.list.component";
import { CategoryService } from "./services/category.service";

@NgModule({
	declarations: [
		EmloCategoryComponent,
		EmloCategoryListComponent,
		EmloCategoryDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ColorPickerModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatTabsModule,
		MatRadioModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		EmloFilterBuilderModule,
		EmloFormModule,
		FormlyBootstrapModule,
		EmloCategoryRoutingModule
	],
	providers: [
		CategoryService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloCategoryDetailComponent]
})
export class EmloCategoryModule {}
