import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IconName } from "@apps/common/constants/icon";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Subject } from "rxjs";
import { GiftSearchModel } from "../../../gift/model/gift";
import { GiftService } from "../../../gift/services/gift.service";
import { AddGiftCustomer, Customer } from "../../model/customer";
import { CustomerService } from "../../services/customer.service";

@Component({
	selector: "app-customer-add-gift",
	templateUrl: "add-gift.html",
})
export class AddGiftCustomerComponent implements OnInit {
	IconName = IconName;
	addGiftFormFields: FormlyFieldConfig[] = [];
	addGift: AddGiftCustomer = new AddGiftCustomer();
	form = new FormGroup({});
	gift$: Subject<any[]> = new Subject();
	constructor(
		public dialogRef: MatDialogRef<AddGiftCustomerComponent>,
		@Inject(MAT_DIALOG_DATA) public customer: Customer,
		private customerService: CustomerService,
		private giftService: GiftService,
	) {
		this.addGift.customerId = customer.customerCode;
	}

	ngOnInit(): void {
		this.getGifts();
		this.createForm();
	}

	private createForm() {
		this.addGiftFormFields = [
			{
				key: "giftId",
				type: "emlo-select",
				templateOptions: {
					label: "Select Gift",
					placeholder: "Select Gift",
					bindLabel: "name",
					bindValue: "id",
					options: this.gift$,
				},
			},
			{
				type: "textarea",
				key: "notes",
				defaultValue: null,
				templateOptions: {
					label: "Notes",
					placeholder: "Notes",
					rows: 4,
					maxLength: 250
				},
			},
			{
				type: "emlo-input",
				key: "customerCode",
				templateOptions: {
					type: "hidden",
				},
			},
		];
	}

	private getGifts(): void {
		const criteria: GiftSearchModel = new GiftSearchModel();
		criteria.pageSize = 10000;
		this.giftService.getGifts(criteria).subscribe((data) => {
			if (data) {
				this.gift$.next(data.data);
			}
		});
	}

	close() {
		this.dialogRef.close();
	}

	addGiftToCustomer() {
		this.customerService.addGift(this.addGift).subscribe(() => {
			this.dialogRef.close(true);
		});
	}
}
