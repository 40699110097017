/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-07 10:43:58
 * @modify date 2020-02-07 10:43:58
 * @desc [I18n Service]
 */
import { Injectable } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { Logger } from "./logger.service";

const log = new Logger("I18nService");
const languageKey = "language";

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
	return s;
}

@Injectable({
	providedIn: "root"
})
export class I18nService {
	defaultLanguage!: string;
	supportedLanguages!: string[];

	private langChangeSubscription!: Subscription;

	constructor(private translateService: TranslateService) {}

	/**
	 * Initializes i18n for the application.
	 * Loads language from local storage if present, or sets default language.
	 * @param defaultLanguage The default language to use.
	 * @param supportedLanguages The list of supported languages.
	 */
	init(defaultLanguage: string, supportedLanguages: string[]) {
		this.defaultLanguage = defaultLanguage;
		this.supportedLanguages = supportedLanguages;
		this.language = "";

		// Warning: this subscription will always be alive for the app's lifetime
		this.langChangeSubscription = this.translateService.onLangChange.subscribe(
			(event: LangChangeEvent) => {
				localStorage.setItem(languageKey, event.lang);
			}
		);
	}

	/**
	 * Cleans up language change subscription.
	 */
	destroy() {
		if (this.langChangeSubscription) {
			this.langChangeSubscription.unsubscribe();
		}
	}

	/**
	 * Sets the current language.
	 * Note: The current language is saved to the local storage.
	 * If no parameter is specified, the language is loaded from local storage (if present).
	 * @param language The IETF language code to set.
	 */
	set language(language: string) {
		language =
			language ||
			localStorage.getItem(languageKey) ||
			this.translateService.getBrowserCultureLang();
		let isSupportedLanguage = this.supportedLanguages.includes(language);

		// If no exact match is found, search without the region
		if (language && !isSupportedLanguage) {
			language = language.split("-")[0];
			language =
				this.supportedLanguages.find(supportedLanguage =>
					supportedLanguage.startsWith(language)
				) || "";
			isSupportedLanguage = Boolean(language);
		}

		// Fallback if language is not supported
		if (!isSupportedLanguage) {
			language = this.defaultLanguage;
		}

		log.debug(`Language set to ${language}`);
		this.translateService.use(language);
	}

	/**
	 * Gets the current language.
	 * @return The current language code.
	 */
	get language(): string {
		return this.translateService.currentLang;
	}
}
