import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
	selector: "formly-field-input",
	template: `
		<div class="form-group">
			<input
				class="form-control"
				[(colorPicker)]="_innerValue"
				[style.background]="_innerValue"
				[value]="_innerValue"
				[formControl]="formControl"
				[cpPosition]="'top'"
				(colorPickerChange)="selectColor(_innerValue)"
			/>
		</div>
	`
})
export class EmloColorPickerComponent extends FieldType implements OnInit {
	_innerValue;
	ngOnInit(): void {
		this._innerValue = this.formControl.value
	}
	selectColor(color: string) {
		this.formControl.patchValue(color);
	}
}
