/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-10 22:38:08
 * @modify date 2020-02-10 22:38:08
 * @desc [Provides helper methods to create routes]
 */
import { Routes, Route } from "@angular/router";

import { AuthenticationGuard } from "@apps/core";
import { ShellComponent } from "./components/shell.component";

export class Shell {
	/**
	 * Creates routes using the shell component and authentication.
	 * @param routes The routes to add.
	 * @return The new route using shell as the base.
	 */
	static childRoutes(routes: Routes): Route {
		return {
			path: "",
			component: ShellComponent,
			children: routes,
			canActivate: [AuthenticationGuard],
			// Reuse ShellComponent instance when navigating between child views
			data: { reuse: true }
		};
	}
}
