import { Component, OnInit } from "@angular/core";
import { ModifierGroupService } from "../../services/modifier-group.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import {
	ModifierGroup,
	ModifierGroupFactory,
	ModifierGroupSearchModel,
} from "../../model/modifier-group";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
	IdControl,
	NameEnControl,
	NameViControl,
	NameCnControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
@Component({
	selector: "app-modifier-group-detail",
	templateUrl: "detail.html",
})
export class EmloModifierGroupDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form = new FormGroup({});
	tabIndex: number = 0;
	modifierGroupFormFields: FormlyFieldConfig[] = [];

	modifierGroup: ModifierGroup = ModifierGroupFactory.create();
	constructor(
		private credentialsService: CredentialsService,
		private modifierGroupService: ModifierGroupService,
		private restaurantService: RestaurantService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.createForm();

		this.activatedRoute.queryParamMap.subscribe((params) => {
			const modifierGroupId = params.get("id");
			this.isEdit = modifierGroupId && modifierGroupId !== null;
			this.restaurantService
				.getRestaurants()
				.subscribe((restaurants: IRestaurantItemModel[]) => {
					if (restaurants && restaurants.length > 0) {
						this.restaurants = restaurants;
						this.restaurants.map((x) => {
							x.concurrencyStamp = null;
							x.parentMenuModifierGroupId = null;
						});
					}
					if (this.isEdit) {
						this.initEditMenuGroup(modifierGroupId);
					}
				});
		});
	}

	private createForm() {
		this.modifierGroupFormFields = [
			// {
			// 	key: "restaurantIds",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		multiple: true,
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		options: this.adminShareDataService.restaurants,
			// 		change: (field, $event) => {
			// 			// field.form.controls["parentMenuModifierGroupId"].setValue(null);
			// 			// this.restaurant$.next(field.model?.restaurantId);
			// 		},
			// 	},
			// 	defaultValue: [this.adminShareDataService.restaurantActive?.id],
			// },
			IdControl,
			NameEnControl,
			NameViControl,
			NameCnControl,
			{
				type: "emlo-input",
				key: "concurrencyStamp",
				templateOptions: {
					type: "hidden",
				},
			},
			// {
			// 	key: "parentMenuModifierGroupId",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Parent Group",
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		options: this.modifierGroup$.asObservable(),
			// 	},
			// },
		];
	}

	private initEditMenuGroup(modifierGroupId: string): void {
		this.modifierGroupService
			.getModifierGroup(modifierGroupId)
			.subscribe((modifierGroup: ModifierGroup) => {
				this.modifierGroup = modifierGroup;
				this.modifierGroup.parentMenuModifierGroupId =
					modifierGroup.parentMenuModifierGroup &&
					modifierGroup.parentMenuModifierGroup.id
						? modifierGroup.parentMenuModifierGroup.id
						: null;

				this.mapDataMenuGroupRestaurant();
			});
	}

	private mapDataMenuGroupRestaurant(): void {
		if (this.modifierGroup && this.modifierGroup.restaurants) {
			this.restaurants.map((res) => {
				const itemRestaurant = this.modifierGroup.restaurants.find(
					(itemRes) => itemRes.id === res.id
				);
				res.assigned = false;
				if (itemRestaurant) {
					res.assigned = true;
					res.parentMenuModifierGroupId =
						itemRestaurant.parentMenuModifierGroupId;
					res.concurrencyStamp =
						itemRestaurant.concurrencyStamp || null;
					this.getModifierGroupByRestaurantId(res);
				}
			});
		}
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit
			? this.updateModifierGroup(data)
			: this.createModifierGroup(data);
	}

	private createModifierGroup(body: ModifierGroup): void {
		this.modifierGroupService.createModifierGroup(body).subscribe(() => {
			this.backToModifierGroups(true);
		});
	}

	private updateModifierGroup(body: ModifierGroup): void {
		this.modifierGroupService.updateModifierGroup(body).subscribe(() => {
			this.backToModifierGroups(true);
		});
	}

	cancel() {
		this.backToModifierGroups();
	}

	private backToModifierGroups(isReload: boolean = false): void {
		this.router.navigate(["/product-management/modifier-group"]);
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {
		if (!restaurant.modifierGroups) {
			this.getModifierGroupByRestaurantId(restaurant);
		}
	}

	private getModifierGroupByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		const request: ModifierGroupSearchModel = new ModifierGroupSearchModel();
		request.pageSize = 100000;
		request.restaurantId = itemRestaurant.id;
		this.modifierGroupService
			.getModifierGroups(request)
			.subscribe((data) => {
				if (data) {
					itemRestaurant.modifierGroups = data.data;
				}
			});
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}
}
