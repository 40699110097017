import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { environment } from "@env/environment";
import { ApiRequestModelFactory } from "@apps/common/model/api.request.model";

@Injectable()
export class TaxService {
	constructor(private apiService: ApiService) {}

	getTaxs(restaurantId: string) {
		// const paging: any = ApiRequestModelFactory.createPagingMaxSize();
		// paging.restaurantId = restaurantId;
		// const httpPrams = new HttpParams({ fromObject: paging });
		// return this.apiService.get(
		// 	environment.prefix_api_url + "tax/list",
		// 	httpPrams
		// );
		let taxRequest = {
			restaurantIds: restaurantId ? [ restaurantId ] : null
		};
		return this.apiService.post(environment.prefix_api_url + "tax/taxSelection", taxRequest);
	}
}
