import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Gift, GiftSearchModel, GiftType } from "../model/gift";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable, of } from "rxjs";

@Injectable()
export class GiftService {
	constructor(private apiService: ApiService, private http: HttpClient) {}

	getGiftById(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "giftdef/getbyid",
			params
		);
	}

	createGift(context: Gift) {
		return this.apiService.post(
			environment.prefix_api_url + "giftdef/create",
			context
		);
	}

	updateGift(context: Gift) {
		return this.apiService.post(
			environment.prefix_api_url + "giftdef/update",
			context
		);
	}

	deleteGift(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "giftdef/delete",
			context
		);
	}

	getGifts(
		criteria: GiftSearchModel
	): Observable<IApiPagingResponseModel<Gift[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "giftdef/list",
			criteria
		);
	}

	getGiftTypes(): Observable<GiftType[]> {
		return this.apiService.get(
			environment.prefix_api_url + "giftdef/listgifttype"
		);
	}

	getItemsGift(searchingName: string): Observable<GiftType[]> {
		const params = new HttpParams().set("searchingName", searchingName);
		return this.apiService.get(
			environment.prefix_api_url + "giftdef/getitemsforgift",
			params
		);
	}

	getImage(fileName: string): Observable<Blob> {
		const headers = new HttpHeaders();
		headers.set("Accept", "image/jpeg");

		return this.http.get(
			`${environment.prefix_api_url}giftdef/get-image?fileName=${fileName}`,
			{
				headers: headers,
				responseType: "blob",
			}
		);
	}
}
