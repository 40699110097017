/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-12 10:32:26
 * @modify date 2020-02-12 10:32:26
 */
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { ButtonState } from "./button";

@Injectable()
export class EmloButtonService {
	private loaderSubject = new Subject<ButtonState>();

	loaderState = this.loaderSubject.asObservable();

	constructor() {}

	show() {
		this.loaderSubject.next({ isProcessing: true } as ButtonState);
	}

	hide() {
		this.loaderSubject.next({ isProcessing: false } as ButtonState);
	}
}
