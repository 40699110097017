<div class="box border-sub-1">
	<emlo-box-header [title]="'Detail Sales Report'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content mh-50" *ngIf="canAccess; else contactAdmin">
		<div class="w-50 form-group">
			<label translate>Restaurant</label>
			<ng-select [items]="restaurants" bindLabel="name" autofocus bindValue="id" placeholder="Select Restaurant"  [(ngModel)]="restaurantId">
			</ng-select>
		</div>

		<div class="d-lg-flex d-md-inline justify-content-start align-items-start">
			<div class="pr-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="startDate" (ngModelChange)="changeDateReport()"
							[matDatepicker]="start" placeholder="{{ 'Start Date' | translate }}" [max]="endDate">
						<mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
						<mat-datepicker #start></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="startTime" [format]="24" [controlOnly]="true"
							[disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="pl-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="endDate" (ngModelChange)="changeDateReport()" [matDatepicker]="end"
							placeholder="{{ 'End Date' | translate }}" [min]="startDate">
						<mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
						<mat-datepicker #end></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="endTime" [format]="24" [controlOnly]="true"
							[disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="d-flex align-items-start">
				<emlo-button class="mx-1 b-small b-full" [textButton]="'View Report'" [color]="'primary'"
					[textProcessing]="'View Report'" (click)="viewReport()" [disabled]="inprogress">
				</emlo-button>

				<emlo-button class="mx-1 b-small b-full" [textButton]="'Export Excel'" [color]="'primary'"
					[textProcessing]="'Export Excel'" (click)="exportReport()" [disabled]="inprogress">
				</emlo-button>
			</div>
		</div>
		<app-loader [isLoading]="inprogress"></app-loader>

		<div class="content-report" *ngIf="!inprogress">
			<div class="alert alert-warning" role="alert" *ngIf="detailSaleSummaryReport">
				{{ 'This is top result, to view full report, please Export to Excel' | translate}}
			</div>
			<div *ngIf="detailSaleSummaryReport" class="alert alert-info" role="alert">
				<div *ngIf="detailSaleSummaryReport.generatedAt">{{ 'Report Generated On' | translate}}:
					{{ detailSaleSummaryReport.generatedAt }}</div>
				<div *ngIf="detailSaleSummaryReport.from">{{ 'Report Period' | translate}}:
					{{ detailSaleSummaryReport.from }} to
					{{ detailSaleSummaryReport.to }}</div>
			</div>
			<div class="detailSaleReports" *ngIf="detailSaleReports; else noSaleReport">
				<div class="table-responsive">
					<table class="table table-bordered table-striped">
						<thead>
							<tr>
								<th>{{ 'Date' | translate }}</th>
								<th class="text-right">{{ 'Check#' | translate }}</th>
								<th class="text-right">{{ 'Kitchen' | translate }}</th>
								<th class="text-right">{{ 'BBQ' | translate }}</th>
								<th class="text-right">{{ 'Dimsum' | translate }}</th>
								<th class="text-right">{{ 'Bar' | translate }}</th>
								<th class="text-right">{{ 'Pickle' | translate }}</th>
								<th class="text-right">{{ 'Cigarette' | translate }}</th>
								<th class="text-right">{{ 'Service Fee' | translate }} (5%)</th>
								<th class="text-right">{{ 'Total Before Tax' | translate }}</th>
								<th class="text-right">{{ 'Discount' | translate }}</th>
								<th class="text-right">{{ 'Tax' | translate }} (10%)</th>
								<th class="text-right">{{ 'Round Adjusted' | translate }}</th>
								<th class="text-right">{{ 'Receipt Total' | translate }}</th>
								<th>{{ 'Payment Type' | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of detailSaleReports">
								<td>{{ item.date }}</td>
								<td class="text-right">{{ item.ticketId | number }}</td>
								<td class="text-right">{{ item.kitchen | number }}</td>
								<td class="text-right">{{ item.bbq | number }}</td>
								<td class="text-right">{{ item.dimsum | number }}</td>
								<td class="text-right">{{ item.bar | number }}</td>
								<td class="text-right">{{ item.pickle | number }}</td>
								<td class="text-right">{{ item.cigarette | number }}</td>
								<td class="text-right">{{ item.serviceFee | number }}</td>
								<td class="text-right">{{ item.totalBeforeTax | number }}</td>
								<td class="text-right">{{ item.discount | number }}</td>
								<td class="text-right">{{ item.tax | number }}</td>
								<td class="text-right">{{ item.roundAdjusted | number }}</td>
								<td class="text-right">{{ item.receiptTotal | number }}</td>
								<td>{{ item.paymentTypeDisplay }}</td>
							</tr>
							<tr *ngIf="summary">
								<td colspan="2" class="text-right">{{ 'Total' | translate }}</td>
								<td class="text-right">{{ summary.totalKitchen | number }}</td>
								<td class="text-right">{{ summary.totalBBQ | number }}</td>
								<td class="text-right">{{ summary.totalDimsum | number }}</td>
								<td class="text-right">{{ summary.totalBar | number }}</td>
								<td class="text-right">{{ summary.totalPickle | number }}</td>
								<td class="text-right">{{ summary.totalCigarette | number }}</td>
								<td class="text-right">{{ summary.totalServiceFee | number }}
								</td>
								<td class="text-right">{{ summary.totalBeforeTax | number }}</td>
								<td class="text-right">{{ summary.totalDiscount | number }}</td>
								<td class="text-right">{{ summary.totalTax | number }}</td>
								<td class="text-right">{{ summary.totalRoundAdjusted | number }}
								</td>
								<td class="text-right">{{ summary.totalReceiptTotal | number }}
								</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<ng-template #noSaleReport>
				<div class="py-3" *ngIf="detailSaleSummaryReport">{{ 'No data' | translate }}</div>
			</ng-template>
		</div>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
</div>
