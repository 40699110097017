<div class="table-responsive">
	<table [id]="id" [ngClass]="(tableClass === null || tableClass === '') ? 'table' : tableClass"
		[class.table-sm]="config.tableLayout.style === 'small'"
		[class.table-normal]="config.tableLayout.style === 'normal'"
		[class.table-lg]="config.tableLayout.style === 'large'"
		[class.table-borderless]="config.tableLayout.borderless"
		[class.table-bordered]="config.tableLayout.bordered" [class.table-dark]="config.tableLayout.theme === 'dark'"
		[class.table-hover]="config.tableLayout.hover" [class.table-striped]="config.tableLayout.striped"
		[class.horizontal-scroll]="config.horizontalScroll && !config.isLoading">
		<thead table-thead [config]="config" [sortKey]="sortKey" [sortState]="sortState"
			[selectAllTemplate]="selectAllTemplate" [filtersTemplate]="filtersTemplate"
			[additionalActionsTemplate]="additionalActionsTemplate" [columns]="columns" (selectAll)="onSelectAll()"
			(filter)="onSearch($event)" (order)="orderBy($event)" (event)="emitEvent($event.event, $event.value)">
		</thead>
		<tbody *ngIf="data && !config.isLoading && !config.rowReorder">
			<ng-container *ngIf="rowTemplate">
				<ul class="table-row-context-menu"
					[ngStyle]="{'position': 'absolute', 'top': rowContextMenuPosition.top, 'left': rowContextMenuPosition.left }"
					*ngIf="rowContextMenuPosition.top">
					<ng-container [ngTemplateOutlet]="rowContextMenu"
						[ngTemplateOutletContext]="{ $implicit: rowContextMenuPosition.value}">
					</ng-container>
				</ul>
				<ng-container>
					<ng-container *ngFor="let row of data | sort:sortBy | search:term:filteredCountSubject | global:globalSearchTerm:filteredCountSubject | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
              let rowIndex = index">
						<tr (click)="onClick($event, row, '', null, rowIndex)" #contextMenu
							(contextmenu)="onRowContextMenu($event, row, '', null, rowIndex)"
							(dblclick)="onDoubleClick($event, row, '', null, rowIndex)"
							[class.table-row--selected]="rowIndex === selectedRow && !config.selectCell">
							<ng-container [ngTemplateOutlet]="rowTemplate"
								[ngTemplateOutletContext]="{ $implicit: row, index: rowIndex }">
							</ng-container>
							<td *ngIf="config.detailsTemplate">
								<span class="emlo-table-icon" *ngIf="arrowDefinition" (click)="collapseRow(rowIndex)">
									<mat-icon svgIcon="isRowCollapsed(rowIndex) ? 'icon-double-down-arrow' : 'icon-double-arrow-right'" class="mat-icon-sssm"></mat-icon>
								</span>

							</td>
						</tr>
						<tr
							*ngIf="(config.detailsTemplate && selectedDetailsTemplateRowId.has(rowIndex)) || config.collapseAllRows">
							<td [attr.colspan]="columns.length + 1">
								<ng-container [ngTemplateOutlet]="detailsTemplate"
									[ngTemplateOutletContext]="{ $implicit: row, index: rowIndex  }">
								</ng-container>
							</td>
						</tr>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!rowTemplate && !config.groupRows">
				<ul class="table-row-context-menu"
					[ngStyle]="{'position': 'absolute', 'top': rowContextMenuPosition.top, 'left': rowContextMenuPosition.left }"
					*ngIf="rowContextMenuPosition.top">
					<ng-container [ngTemplateOutlet]="rowContextMenu"
						[ngTemplateOutletContext]="{ $implicit: rowContextMenuPosition.value}">
					</ng-container>
				</ul>
				<ng-container>
					<ng-container *ngFor="let row of data | sort:sortBy | search:term:filteredCountSubject | global:globalSearchTerm:filteredCountSubject | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
                  let rowIndex = index">
						<tr [class.table-row--selected]="rowIndex === selectedRow && !config.selectCell">
							<td *ngIf="config.checkboxes">
								<label class="emlo-table-form-checkbox">
									<input type="checkbox" id="checkbox-{{rowIndex}}"
										[checked]="isSelected || selectedCheckboxes.has(rowIndex)"
										(change)="onCheckboxSelect($event, row, rowIndex)">
									<em class="emlo-table-form-icon"></em>
								</label>
							</td>
							<td *ngIf="config.radio">
								<label>
									<input type="radio" id="radio-{{rowIndex}}" name="radio"
										(change)="onRadioSelect($event, row, rowIndex)">
								</label>
							</td>
							<ng-container *ngFor="let column of columns; let colIndex = index">
								<td (click)="onClick($event, row, column.key, colIndex, rowIndex)" #contextMenu
									(contextmenu)="onRowContextMenu($event, row, column.key, colIndex, rowIndex)"
									(dblclick)="onDoubleClick($event, row, column.key, colIndex, rowIndex)"
									[class.pinned-left]="column.pinned"
									[ngClass]="column.cssClass ? column.cssClass.name : ''"
									[style.left]="styleService.pinnedWidth(column.pinned, colIndex)"
									[class.table-col--selected]="colIndex === selectedCol && !config.selectCell"
									[class.table-cell--selected]="colIndex === selectedCol && rowIndex === selectedRow && !config.selectCol && !config.selectRow">
									<div *ngIf="!column.cellTemplate">{{ row | render:column.key }}</div>
									<ng-container *ngIf="column.cellTemplate" [ngTemplateOutlet]="column.cellTemplate"
										[ngTemplateOutletContext]="{ $implicit: row, rowIndex: rowIndex }">
									</ng-container>
								</td>
							</ng-container>
							<td *ngIf="config.additionalActions || config.detailsTemplate">
								<span class="emlo-table-icon" *ngIf="arrowDefinition" (click)="collapseRow(rowIndex)">
									<mat-icon svgIcon="isRowCollapsed(rowIndex) ? 'icon-double-down-arrow' : 'icon-double-arrow-right'" class="mat-icon-sssm"></mat-icon>
								</span>
							</td>
						</tr>
						<tr
							*ngIf="(config.detailsTemplate && selectedDetailsTemplateRowId.has(rowIndex)) || config.collapseAllRows">
							<td *ngIf="config.checkboxes || config.radio"></td>
							<td [attr.colspan]="columns.length + 1">
								<ng-container [ngTemplateOutlet]="detailsTemplate"
									[ngTemplateOutletContext]="{ $implicit: row, index: rowIndex }">
								</ng-container>
							</td>
						</tr>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!rowTemplate && config.groupRows">
				<ng-container *ngFor="let group of grouped | sort:sortBy:config | search:term:filteredCountSubject:config | global:globalSearchTerm:filteredCountSubject | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
        let rowIndex = index">
					<tr>
						<ng-container *ngIf="!groupRowsHeaderTemplate">
							<td [attr.colspan]="columns.length">
								<div>{{group[0][groupRowsBy]}} ({{group.length}})</div>
							</td>
						</ng-container>
						<ng-container *ngIf="groupRowsHeaderTemplate" [ngTemplateOutlet]="groupRowsHeaderTemplate"
							[ngTemplateOutletContext]="{
              total: group.length,
              key: groupRowsBy,
              value: group[0] ? group[0][groupRowsBy] : '',
              group: group,
              index: rowIndex
            }">
						</ng-container>
						<td>
							<span class="emlo-table-icon" *ngIf="arrowDefinition" (click)="collapseRow(rowIndex)">
								<mat-icon svgIcon="isRowCollapsed(rowIndex) ? 'icon-double-down-arrow' : 'icon-double-arrow-right'" class="mat-icon-sssm"></mat-icon>
							</span>
						</td>
					</tr>
					<ng-container *ngIf="selectedDetailsTemplateRowId.has(rowIndex)">
						<tr *ngFor="let row of group">
							<td *ngFor="let column of columns">
								{{ row | render:column.key }}
							</td>
							<td></td>
						</tr>
					</ng-container>
				</ng-container>
			</ng-container>
		</tbody>
		<tbody *ngIf="data && !config.isLoading && config.rowReorder" class="emlo-table-draggable-row-area">
			<ng-container *ngIf="!rowTemplate && !config.groupRows">
				<ng-container *ngFor="let row of data | sort:sortBy | search:term:filteredCountSubject | global:globalSearchTerm:filteredCountSubject | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
                  let rowIndex = index">
					<tr class="emlo-table-draggable-row">
						<td *ngIf="config.checkboxes">
							<label class="emlo-table-form-checkbox">
								<input type="checkbox" id="checkbox-draggable-{{rowIndex}}"
									[checked]="isSelected || selectedCheckboxes.has(rowIndex)"
									(change)="onCheckboxSelect($event, row, rowIndex)">
								<em class="emlo-table-form-icon"></em>
							</label>
						</td>
						<td *ngIf="config.radio">
							<label>
								<input type="radio" id="radio-draggable-{{rowIndex}}" name="radio"
									(change)="onRadioSelect($event, row, rowIndex)">
							</label>
						</td>
						<ng-container *ngFor="let column of columns; let colIndex = index">
							<td (click)="onClick($event, row, column.key, colIndex, rowIndex)"
								(dblclick)="onDoubleClick($event, row, column.key, colIndex, rowIndex)"
								[class.table-col--selected]="colIndex === selectedCol && !config.selectCell"
								[class.table-cell--selected]="colIndex === selectedCol && rowIndex === selectedRow && !config.selectCol && !config.selectRow">
								<div>{{ row | render:column.key }}</div>
							</td>
						</ng-container>
					</tr>
				</ng-container>
			</ng-container>
		</tbody>
		<tbody *ngIf="filterCount === 0">
			<tr class="body-empty">
				<ng-container *ngIf="noResultsTemplate" [ngTemplateOutlet]="noResultsTemplate">
				</ng-container>
				<td [attr.colspan]="columns && columns.length + 1" *ngIf="!noResultsTemplate">
					<div class="table-no-results">
						No results
					</div>
				</td>
			</tr>
		</tbody>
		<tbody *ngIf="config.isLoading">
			<tr class="body-loading">
				<ng-container *ngIf="loadingTemplate" [ngTemplateOutlet]="loadingTemplate">
				</ng-container>
				<td [attr.colspan]="columns && columns.length + 1" *ngIf="!loadingTemplate">
					<div [style.height.px]="loadingHeight" class="table-loader-wrapper">
						<div class="table-loader">Loading...</div>
					</div>
				</td>
			</tr>
		</tbody>
		<tfoot *ngIf="summaryTemplate">
			<tr>
				<ng-container [ngTemplateOutlet]="summaryTemplate"
					[ngTemplateOutletContext]="{ total: data.length, limit: limit, page: page  }">
				</ng-container>
			</tr>
		</tfoot>
	</table>
	<pagination [attr.id]="'pagination' + id" [id]="id" #paginationComponent [config]="config" [pagination]="_internalPagination"
		(updateRange)="onPagination($event)">
	</pagination>
</div>
