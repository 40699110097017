import { IItemRequestSearchModel } from "./../../model/item-request-search.model";
import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	TemplateRef,
	ViewChild,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Subscription, Subject } from "rxjs";
import { EventName } from "@apps/common/constants/event";
import { MatDialog } from "@angular/material/dialog";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { ShareDataService } from "@apps/core/services/data.service";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { Item, ItemFactory, ItemSearchModel } from "../../model/item";
import { ItemService } from "../../services/item.service";
import { ReportGroupService } from "../../services/report-group.service";
import { ItemModifierGroupService } from "../../services/modifier-group.service";
import { IModifierGroupModel } from "@apps/modules/emlo-admin/shared/model/modifier-group.model";
import {
	IApiPagingResponseModel,
	IPagingnationModel,
	PaginationModelFactory,
} from "@apps/common/model/api.reponse.model";
import { switchMap, catchError, debounceTime } from "rxjs/operators";
import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { FilterType } from "@apps/shared/filter-builder/model/condition-type.enum";
import { BaseFilterComponent } from "../../../base-filter-component";
import { Router } from "@angular/router";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { ItemMenuGroupService } from "../../services/menu-group.service";
import { MenuGroupSelection } from "../../../menu-group/model/menu-group";
import { CredentialsService } from "@apps/core";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
@Component({
	selector: "app-productmanagement-item-list",
	templateUrl: "./list.html",
})
export class EmloItemListComponent
	extends BaseFilterComponent<IItemRequestSearchModel>
	implements OnInit, OnDestroy {
	IconName = IconName;
	private switchRestaurant$: Subscription;
	@ViewChild("isVisibleTpl", { static: true }) isVisibleTpl: TemplateRef<any>;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public items: Item[] = [];
	public pagination: IPagingnationModel = PaginationModelFactory.create();
	private readonly All = "All";
	private readonly Visible = "Visible";
	private readonly InVisible = "Invisible";
	isEditOrder: boolean = false;
	criteria: ItemSearchModel = new ItemSearchModel();
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];
	restaurants: IRestaurantItemModel[] = [];
	menuGroups: MenuGroupSelection[] = [];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private itemService: ItemService,
		private reportGroupService: ReportGroupService,
		private modifierGroupService: ItemModifierGroupService,
		private shareDataService: ShareDataService,
		private adminShareDataService: AdminShareDataService,
		private restaurantService: RestaurantService,
		private menuGroupService: ItemMenuGroupService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.getMenuItems();
		// this.initItemDataSource();
		this.initFilterMetaData();
		this.configColumnForItemTable();
		// this.switchRestaurant$ = this.shareDataService
		// 	.getObservable(EventName.SWITCH_RESTAURANT)
		// 	.subscribe(() => {
		// 		// this.request.restaurantId = this.adminShareDataService?.restaurantActive?.id;
		// 		this.getModifierGroupSelections();
		// 		// if (this.request.restaurantId) {
		// 		// 	this.dataSource$.next(this.request);
		// 		// }
		// 		this.dataSource$.next(this.request);
		// 	});
		// this.getVisiblegroup();

		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
	}

	private getMenuItems() {
		this.itemService
			.getItems(this.criteria)
			.pipe(debounceTime(300))
			.subscribe((data) => {
				this.items = [];
				if (data) {
					this.items = data.data;
					this.pagination = data;
					// this.cdr.detectChanges();
				}
			});
	}

	onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getMenuItems();
			// this.dataSource$.next({ ...this.request, ...$event.value });
		}
	}

	protected initFilterMetaData() {
		super.initFilterMetaData();
		return [
			...this.filterMeta,
			{
				name: "Price",
				key: "price",
				type: FilterType.NUMBER,
				supportedOperator: [
					ConditionOperator.BETWEEN,
					ConditionOperator.EQUAL,
					ConditionOperator.GREAT_THAN,
					ConditionOperator.LESS_THAN,
					ConditionOperator.GREATER_OR_EQUAL,
					ConditionOperator.LESS_OR_EQUAL,
				],
			},
		];
	}

	// private initItemDataSource(): void {
	// 	this.dataSource$
	// 		.pipe(
	// 			switchMap(request => {
	// 				this.isLoading = true;
	// 				this.cdr.detectChanges();
	// 				return this.itemService.getItems(request);
	// 			})
	// 		)
	// 		.pipe(
	// 			catchError(x => {
	// 				this.isLoading = false;
	// 				this.cdr.detectChanges();
	// 				this.initItemDataSource();
	// 				return x;
	// 			})
	// 		)
	// 		.subscribe((items: IApiPagingResponseModel<Item[]>) => {
	// 			this.isLoading = false;
	// 			if (items) {
	// 				this.items = items.data;
	// 				this.pagination = items;
	// 			}
	// 			this.cdr.detectChanges();
	// 		});
	// }

	// private getTaxs(): void {
	// 	if (this.adminShareDataService.taxs) return;
	// 	this.taxService.getTaxs(null).subscribe((taxs: ITaxModel[]) => {
	// 		if (taxs && taxs.length > 0) {
	// 			this.adminShareDataService.taxs = taxs;
	// 		}
	// 	});
	// }

	// private getPrinters(): void {
	// 	if (this.adminShareDataService.printerGroups) return;
	// 	this.printerService
	// 		.getAvaiablePrinters()
	// 		.subscribe((printers: IPrinterGroupModel[]) => {
	// 			if (printers && printers.length > 0) {
	// 				this.adminShareDataService.printerGroups = printers;
	// 			}
	// 		});
	// }

	// private getMenuGroups() {
	// 	if (this.adminShareDataService.menuGroups) return;
	// 	// this.menuGroupService
	// 	// 	.getVisiblegroup()
	// 	// 	.subscribe((menuGroups: IMenuGroupModel[]) => {
	// 	// 		if (menuGroups && menuGroups.length > 0) {
	// 	// 			this.adminShareDataService.menuGroups = menuGroups;
	// 	// 		}
	// 	// 	});
	// }

	// private getVisiblegroup() {
	// 	if (this.adminShareDataService.reportGroups) return;
	// 	this.reportGroupService
	// 		.getVisiblegroup()
	// 		.subscribe((reportGroups: string[]) => {
	// 			if (reportGroups && reportGroups.length > 0) {
	// 				this.adminShareDataService.reportGroups = reportGroups;
	// 			}
	// 		});
	// }

	// private getModifierGroupSelections() {
	// 	this.modifierGroupService
	// 		.getModifierGroupSelections(this.adminShareDataService.restaurantActive?.id)
	// 		.subscribe((modifierGroups: IModifierGroupModel[]) => {
	// 			if (modifierGroups && modifierGroups.length > 0) {
	// 				this.adminShareDataService.modifierGroups = modifierGroups;
	// 			}
	// 		});
	// }

	private configColumnForItemTable(): void {
		this.configuration = { ...DefaultConfig, serverPagination: true };
		this.columns = [
			{ key: "nameEn", title: "Name EN" },
			{ key: "nameVi", title: "Name VI" },
			{ key: "nameCn", title: "Name CN" },
			{ key: "canDiscount", title: "Discount" },
			{ key: "priceDisplay", title: "Price" },
			{ key: "reportingGroup", title: "Report" },
			{
				key: "isVisible",
				title: "Visible",
				cellTemplate: this.isVisibleTpl,
				width: "50px",
				cssClass: { name: "text-center", includeHeader: true },
				orderEnabled: false,
			},
			{ key: "restaurantNames", title: "Selling at" },
			// { key: "sortOrder", title: "Sort Order", width: "120px" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	toggleItem(event, item: Item) {
		event.preventDefault();
		if (!item.isVisible) {
			this.enableItem(item);
		} else {
			const confirmModel: ConfirmModel = {
				id: null,
				message: "Are you sure want to disable this menu item?",
			};
			this.dialog
				.open(EmloConfirmComponent, {
					data: confirmModel,
				})
				.afterClosed()
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.disableItem(item);
					}
				});
		}
	}

	private enableItem(item: Item): void {
		this.itemService.enableItem(item).subscribe(() => {
			item.isVisible = true;
		});
	}

	private disableItem(item: Item): void {
		this.itemService.disableItem(item).subscribe(() => {
			item.isVisible = false;
		});
	}

	private deleteItem(item: Item): void {
		this.itemService.deleteItem(item).subscribe(() => {
			this.dataSource$.next(this.request);
		});
	}

	delete(item: any): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteItem(item);
				}
			});
	}

	openItemDetail(item: Item): void {
		if (!item) {
			item = ItemFactory.create();
			item.restaurantId = this.request.restaurantId;
			this.router.navigate(["/product-management/items/add"]);
		} else {
			this.router.navigate(["/product-management/items/edit"], {
				queryParams: { id: item.id },
			});
		}
		// this.dialog
		// 	.open(EmloItemDetailComponent, {
		// 		width: "90%",
		// 		maxWidth: "700px",
		// 		data: item
		// 	})
		// 	.afterClosed()
		// 	.subscribe(isSaved => {
		// 		if (isSaved) {
		// 			this.dataSource$.next(this.request);
		// 		}
		// 	});
	}

	onFilter() {
		this.getMenuItems();
	}

	onFilterRestaurant() {
		this.criteria.menuGroupId = null;
		this.getMenuGroupByRestaurantId(this.criteria.restaurantId);
		this.getMenuItems();
	}

	private getMenuGroupByRestaurantId(restaurantId: string | string[]) {
		this.menuGroupService
			.getVisiblegroup(restaurantId)
			.subscribe((data) => {
				if (data) {
					this.menuGroups = data;
				}
			});
	}

	ngOnDestroy() {
		if (this.switchRestaurant$) {
			this.switchRestaurant$.unsubscribe();
		}
	}
}
