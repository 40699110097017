import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloFilterBuilderModule } from "@apps/shared/filter-builder/filter-builder.module";
import { EmloFormModule } from "@apps/shared/form";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ColorPickerModule } from "ngx-color-picker";
import { TaxService } from "../item/services/tax.service";
import { ModifierGroupService } from "../modifier-group/services/modifier-group.service";

import { EmloModifierDetailComponent } from "./components/detail/modifier.detail.component";
import { EmloModifierListComponent } from "./components/list/modifier.list.component";
import { EmloModifierComponent } from "./modifier.component";
import { EmloModifierRoutingModule } from "./modifier.routes";
import { ModifierService } from "./services/modifier.service";

@NgModule({
	declarations: [
		EmloModifierComponent,
		EmloModifierListComponent,
		EmloModifierDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ColorPickerModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatRadioModule,
		MatTabsModule,
		MatIconModule,
		MatSlideToggleModule,
		MatDialogModule,
		MatCheckboxModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		EmloFilterBuilderModule,
		EmloFormModule,
		FormlyBootstrapModule,
		EmloModifierRoutingModule
	],
	providers: [
		ModifierService,
		ModifierGroupService,
		TaxService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloModifierDetailComponent]
})
export class EmloModifierModule {}
