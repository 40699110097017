import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { Coupon, CouponFactory, CouponSearchModel } from "../../model/coupon";
import { CouponService } from "../../services/coupon.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { IDiscountSearchRequestModel } from "../../model/discount-search-request.model";
import { ApiRequestModelFactory } from "@apps/common/model/api.request.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-coupon-list",
	templateUrl: "./list.html",
})
export class EmloCouponListComponent extends AdminBaseComponent implements OnInit {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("disableTpl", { static: true }) disableTpl: TemplateRef<any>;
	@ViewChild("expireTpl", { static: true }) expireTpl: TemplateRef<any>;
	public columns: Columns[] = [];
	public coupons: Coupon[] = [];
	public configuration: Config;
	public pagination = PaginationModelFactory.create();
	request: IDiscountSearchRequestModel = ApiRequestModelFactory.create();

	criteria: CouponSearchModel = new CouponSearchModel();
	restaurants: IRestaurantItemModel[] = [];
	private readonly All = "All";
	private readonly Enable = "Enable";
	private readonly Disable = "Disable";
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Enable,
			value: true,
		},
		{
			name: this.Disable,
			value: false,
		},
	];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private couponService: CouponService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getCoupons();
		this.configColumnForCouponTable();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getCoupons();
		}
	}

	private getCoupons() {
		this.couponService
			.getCoupons(this.criteria)
			.pipe(debounceTime(300))
			.subscribe((response) => {
				if (response) {
					this.coupons = response.data;
					this.pagination = response;
				}
			});
	}

	private configColumnForCouponTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "name", title: "Name" },
			{ key: "value", title: "Discount Value" },
			{ key: "expiryDate", title: "Expiry Date" },
			{
				key: "isDisabled",
				title: "Disabled",
				cellTemplate: this.disableTpl,
				width: "120px",
				cssClass: { name: "text-center", includeHeader: true },
			},
			{
				key: "isNeverExpire",
				title: "Never Expire",
				cellTemplate: this.expireTpl,
				width: "120px",
				cssClass: { name: "text-center", includeHeader: true },
			},
			{ key: "restaurantNames", title: "Selling at" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteCoupon(coupon: Coupon): void {
		const data = { id: coupon.id };
		this.couponService.deleteCoupon(JSON.stringify(data)).subscribe(() => {
			this.getCoupons();
		});
	}

	delete(coupon: Coupon): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteCoupon(coupon);
				}
			});
	}

	openCouponDetail(coupon: Coupon): void {
		if (!coupon) {
			coupon = CouponFactory.create();
			this.router.navigate(["/customer-service/coupon/add"]);
		} else {
			this.router.navigate(["/customer-service/coupon/edit"], {
				queryParams: { id: coupon.id },
			});
		}
	}

	onFilter() {
		this.getCoupons();
	}
}
