<div class="p-3">
	<h3>Add Point for <strong>{{ customer.fullName }}</strong></h3>
	<formly-form
		[model]="addPoint"
		[fields]="addPointFormFields"
		[form]="form"
	></formly-form>
	<div class="text-right">
		<button type="button" class="btn btn-danger" (click)="close()" translate>
			Close
		</button>
		<button type="button" class="btn btn-primary ml-2" (click)="updatePoint()" translate>
			Add Point
		</button>
	</div>
</div>
