/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:11:03
 * @modify date 2020-02-11 00:11:03
 */
import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { I18nService, IconService } from "@apps/core";
import { environment } from "@env/environment";
import { StaticInjectorService } from "./core/services/static-injector.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
	title = "emlo-admin";

	constructor(
		private i18nService: I18nService,
		private iconService: IconService,
		private injector: Injector,
		private swUpdate: SwUpdate
	) {}

	ngOnInit(): void {
		// Setup translations
		this.checkingAndUpdateNewVersion();
		this.i18nService.init(
			environment.defaultLanguage,
			environment.supportedLanguages
		);
		// Init svg icons custom
		this.iconService.init();

		this.initializeCoreService();
	}

	private initializeCoreService() {
		// Init injector from app root.
		StaticInjectorService.injector = this.injector;
	}

	/**
   * Checking & confirm reload with user if have new app version.
   */
	private checkingAndUpdateNewVersion() {
		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(() => {
				console.log("New version available. Reloading..");
			//CoreToolbox.messageService.showInfoConfirm('New version available. Load New Version?', 'Reload', () => {
				window.location.reload();
			//});
			});
			this.swUpdate
			.checkForUpdate()
			.then((version) => {
				console.log(
				`Updated new version: ${version} ${new Date().getTime()}`
				);
			})
			.catch((error) => {
				console.error(`Failed in updating app version ${error}`);
			});
		}
	}

	ngOnDestroy(): void {
		this.i18nService.destroy();
	}
}
