import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { ClientDetail, RestaurantDetail } from "../model/client";
import { environment } from "@env/environment";

@Injectable()
export class ClientService {
	constructor(private apiService: ApiService) {}

	getClients() {
		return this.apiService.get(environment.prefix_api_url + "restaurant/list");
	}

	getClient(id: string) {
		const params = new HttpParams().set("id", id + "");
		return this.apiService.get(
			environment.prefix_api_url + "restaurant/getbyid",
			params
		);
	}

	getGrantTypes() {
		return this.apiService.get(
			environment.prefix_api_url + "client/listgranttype"
		);
	}

	createClient(context: RestaurantDetail) {
		return this.apiService.post(
			environment.prefix_api_url + "restaurant/create",
			context
		);
	}

	updateClient(context: RestaurantDetail) {
		return this.apiService.post(
			environment.prefix_api_url + "restaurant/update",
			context
		);
	}

	deleteClient(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "restaurant/delete",
			context
		);
	}

	requestRawSecret(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "client/requestrawsecret",
			context
		);
	}
}
