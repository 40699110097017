<div class="box border-sub-1">
	<emlo-box-header [title]="'Reward Points - ' + (isEdit ? 'Edit' : 'Add')"
		[headerActionsTemplate]="headerActionsTemplate">
	</emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content item-content" *ngIf="rewardPointDetail">
			<formly-form [model]="rewardPointDetail" [fields]="rewardPointDetailFormFields" [form]="form"></formly-form>
			<div class="form-group" *ngIf="rewardPointDetail">
				<!--<label for="">Select a Menu Item</label>
				<ng-select
					[items]="items$ | async"
					bindLabel="name"
					[minTermLength]="minLengthTerm"
					[loading]="itemsLoading"
					appendTo="body"
					placeholder="Search a item"
					typeToSearchText="Please enter {{minLengthTerm}} or more characters"
					[typeahead]="itemsInput$"
					(change)="onChangeMenuItem($event)"
					[(ngModel)]="menuItem.name" [ngModelOptions]="{standalone: true}">
				</ng-select>-->
			</div>
			<div class="row">
				<div class="col-sm-6">
					<label>Begin Date</label>
					<div class="input-group">
						<input class="form-control" placeholder="yyyy-mm-dd" name="dp1" [(ngModel)]="date.beginValidDate"
							ngbDatepicker #d1="ngbDatepicker" [maxDate]="endValidDate" [ngModelOptions]="{standalone: true}">
						<div class="input-group-append">
							<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
								<mat-icon [svgIcon]="IconName.icon_calendar"></mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<label>End Date</label>
					<div class="input-group">
						<input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="date.endValidDate"
							ngbDatepicker #d2="ngbDatepicker" [minDate]="beginValidDate" [ngModelOptions]="{standalone: true}">
						<div class="input-group-append">
							<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
								<mat-icon [svgIcon]="IconName.icon_calendar"></mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>
				Cancel
			</button>

			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
