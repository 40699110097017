import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class CashReportService {
	constructor(private apiService: ApiService) {}

	getSaleReportSummaryReport(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "salereport/salesummary",
			context
		);
	}

	generateSaleReportSummaryReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "salereport/summaryreportfile",
			context
		);
	}
}
