<div class="box border-sub-1">
	<emlo-box-header [title]="title" [headerActionsTemplate]="headerActionsTemplate">
	</emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content">
			<div class="box-content item-content" *ngIf="user">
                <formly-form [model]="user" [fields]="userFormFields" [form]="form"></formly-form>
            </div>
		</div>
		<div class="box-footer">

			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>

			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
