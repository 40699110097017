import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { Modifier, ModifierSearchModel } from "../model/modifier";
import { environment } from "@env/environment";
import { IModifierSearchRequestModel } from "../model/modifier-search-request.model";
import { Observable } from 'rxjs';
import { IApiPagingResponseModel } from '@apps/common/model/api.reponse.model';
import { ApiRequestModelFactory } from '@apps/common/model/api.request.model';

@Injectable()
export class ModifierService {
	constructor(private apiService: ApiService) {}

	getModifiers(request: ModifierSearchModel): Observable<IApiPagingResponseModel<Modifier[]>> {
		return this.apiService.post(environment.prefix_api_url+ "modifier/list", request);
	}

	getModifier(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url+ "modifier/getbyid", params);
	}

	createModifier(context: Modifier) {
		return this.apiService.post(environment.prefix_api_url+ "modifier/create", context);
	}

	updateModifier(context: Modifier) {
		return this.apiService.post(environment.prefix_api_url+ "modifier/update", context);
	}

	deleteModifier(context: any) {
		return this.apiService.post(environment.prefix_api_url+ "modifier/delete", context);
	}

	// Get taxs

	getTaxs(restaurantId: string) {
		const paging: any = ApiRequestModelFactory.createPagingMaxSize();
		paging.restaurantId = restaurantId;
		const httpPrams = new HttpParams({ fromObject: paging });
		return this.apiService.get(
			environment.prefix_api_url + "tax/list",
			httpPrams
		);
	}

	getModifierGroupSelection(restaurantId: string) {
		const request = new HttpParams({fromObject: {restaurantId: restaurantId}})
		return this.apiService.get(environment.prefix_api_url + "modifiergroup/selectionlist", request);
	}
}
