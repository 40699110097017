import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { EmloMenuItemSalesAnalysisReportRoutingModule } from "./menu-item-sales-analysis.routes";
import { MenuItemSalesAnalysisService } from "./services/menu-item-sales-analysis.service";
import { CommonModule, DatePipe } from "@angular/common";
import { EmloMenuItemSalesAnalysisReportComponent } from "./components/menu-item-sales-analysist.component";
import {
	MatNativeDateModule,
	DateAdapter,
	MAT_DATE_LOCALE,
	MAT_DATE_FORMATS
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgSelectModule } from "@ng-select/ng-select";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { EmloLoadingModule } from "@apps/shared/loading";
export const MY_FORMATS = {
	parse: {
		dateInput: "DD/MM/YYYY"
	},
	display: {
		dateInput: "DD/MM/YYYY",
		monthYearLabel: "YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "YYYY"
	}
};
@NgModule({
	declarations: [EmloMenuItemSalesAnalysisReportComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		EmloTableModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatRadioModule,
		NgxMaterialTimepickerModule,
		NgSelectModule,
		EmloButtonModule,
		EmloLoadingModule,
		EmloMenuItemSalesAnalysisReportRoutingModule
	],
	providers: [
		DatePipe,
		MenuItemSalesAnalysisService,
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},

		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	],
	entryComponents: []
})
export class EmloMenuItemSalesAnalysisReportModule {}
