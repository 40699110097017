/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:10:34
 * @modify date 2020-02-11 00:10:34
 */
import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "app-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit {
	@Input() isLoading = false;
	@Input() message: string | undefined;

	constructor() {}

	ngOnInit() {}
}
