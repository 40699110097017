import { CommonConstant } from "@apps/common/constants/common.constant";
import { InputValueType } from "@apps/common/enums/input-value-type";
import { LoyaltyGiftType } from "@apps/common/enums/loyalty-gift-type";

export class Gift {
	id?: string | number = null;
	concurrencyStamp?: string | number = null;
	name?: string | number = null;
	giftType?: LoyaltyGiftType = null;
	giftTypeName: string = null;
	menuItemId: string = null;
	menuItemName: string = null;
	amount: number = null;
	amountPercent: number = null;
	numberOfValidDays: number = null;
	isPermanent: boolean = false;
	isDOBGift: boolean = false;
	valueType: InputValueType = InputValueType.Fixed;
	isExchangeable: boolean = false;
	pointToExchange: number = null;
	validFrom: string = null;
	validTo: string = null;
	termConditions: string = null;
	minExpense: number = null;
	avatar: string = null;
}
export class GiftType {
	id?: string | number = null;
	name?: string | number = null;
}

export class GiftSearchModel {
	searchText?: string = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
