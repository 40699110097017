import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { User, UserFactory, UserRestaurant } from "../../model/user";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { IconName } from "@apps/common/constants/icon";
import { UserLanguage } from "@apps/modules/emlo-admin/shared/model/language.model";
import { UserSettingService } from "@apps/modules/emlo-admin/shared/services/user.feature.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { Subject } from "rxjs";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";

@Component({
	selector: "app-user-detail",
	templateUrl: "detail.html",
})
export class EmloUserDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	userFormFields: FormlyFieldConfig[] = [];
	userLanguages: UserLanguage[] = [];
	public user: User = UserFactory.create();
	roles$: Subject<any[]> = new Subject();
	public userRestaurants: UserRestaurant[] = [];
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private userService: UserService,
		private restaurantService: RestaurantService,
		private userSettingService: UserSettingService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;
		this.user = UserFactory.create();
		this.userLanguages = this.userSettingService.getListLanguageSupport();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const userId = params.get("id");
			this.isEdit =
				userId && userId !== "00000000-0000-0000-0000-000000000000";
			this.getRestaurants(userId);
			this.createForm();
		});
	}

	private getRestaurants(userId: string) {
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: any) => {
				if (restaurants && restaurants.length > 0) {
					this.userRestaurants = [...restaurants];
					this.userRestaurants.map((x) => (x.assigned = false));
					if (this.isEdit) {
						this.initEditUser(userId);
					}
					this.getUserRole();
				}
			});
	}

	private getUserRole(): void {
		this.userService.getUserRole().subscribe((roles) => {
			if (roles) {
				this.roles$.next(roles);

			}
		});
	}

	private createForm() {
		this.userFormFields = [
			IdControl,
			{
				type: "input",
				key: "userName",
				defaultValue: null,
				templateOptions: {
					label: "User name",
					placeholder: "User name",
					disabled: this.isEdit,
				},
			},
			{
				type: "input",
				key: "firstName",
				defaultValue: null,
				templateOptions: {
					label: "First name",
					placeholder: "First name",
				},
			},
			{
				type: "input",
				key: "lastName",
				defaultValue: null,
				templateOptions: {
					label: "Last name",
					placeholder: "Last name",
				},
			},
			{
				key: "secretKey",
				hideExpression: this.isEdit,
				validators: {
					fieldMatch: {
						expression: (control) => {
							const value = control.value;
							return (
								value.confirmSecretKey === value.secretKey ||
								// avoid displaying the message error when values are empty
								!value.confirmSecretKey ||
								!value.secretKey
							);
						},
						message: "Secret Key Not Matching",
						errorPath: "confirmSecretKey",
					},
				},
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						key: "secretKey",
						type: "input",
						className: "col-6",
						templateOptions: {
							type: "password",
							label: "Password",
							placeholder:
								"Those passwords didn't match. Try again. (At least 4 characters)",
							minLength: 4,
						},
					},
					{
						key: "confirmSecretKey",
						type: "input",
						className: "col-6",
						templateOptions: {
							type: "password",
							label: "Confirm Password",
							placeholder: "Please re-enter your Secret Key",
						},
					},
				],
			},
			{
				key: "roleId",
				type: "emlo-select",
				templateOptions: {
					label: "User role",
					bindLabel: "name",
					bindValue: "id",
					options: this.roles$,
				},
			},
			{
				className: "section-label",
				template: "<div>User settings</div>",
			},
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "emlo-select",
						key: "defaultLanguage",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Default language",
							bindLabel: "name",
							bindValue: "code",
							options: this.userLanguages,
						},
					},
					{
						type: "input",
						key: "autoLogoffInSecond",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Auto loggoff time",
							type: "number",
						},
					},
				],
			},
		];
	}

	private initEditUser(userId: string): void {
		this.userService.getUser(userId).subscribe((user: User) => {
			if (user) {
				this.user = user;
				this.mapDataItemRestaurant();
			}
		});
	}

	private mapDataItemRestaurant(): void {
		this.userRestaurants.map((res) => {
			const itemRestaurant = this.user.restaurants.find(
				(itemRes) => itemRes.id === res.id
			);
			res.assigned = false;
			if (itemRestaurant) {
				res.assigned = true;
				res.concurrencyStamp = itemRestaurant.concurrencyStamp;
				// res.firstName = itemRestaurant.firstName;
				// res.lastName = itemRestaurant.lastName;
				// res.defaultLanguage = itemRestaurant.defaultLanguage;
				// res.autoLogoffInSecond = itemRestaurant.autoLogoffInSecond;
				// res.roleId = itemRestaurant.roleId;
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		if (data.secretKey) {
			data.secretKey = data.secretKey.secretKey;
		}
		const assignRestaurants = this.userRestaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateUser(data) : this.createUser(data);
	}

	private createUser(body: User): void {
		this.userService.createUser(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateUser(body: User): void {
		this.userService.updateUser(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	cancel() {
		this.resetFormAndClose();
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.form.reset();
		this.router.navigate(["/account-management/user"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: UserRestaurant) {
		// if (!restaurant.roleId) {
		// 	restaurant.roleId = this.user.roleId;
		// }
		// if (!restaurant.defaultLanguage) {
		// 	restaurant.defaultLanguage = this.user.defaultLanguage;
		// }
		// if (!restaurant.autoLogoffInSecond) {
		// 	restaurant.autoLogoffInSecond = this.user.autoLogoffInSecond;
		// }
	}
}
