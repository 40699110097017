import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloWarningComponent } from "../warning/warning.component";
import { WarningModel } from "../warning/warning";
const credentialsKey = "credentials";
@Injectable()
export class ErrorDialogService {
	public isDialogOpen: boolean = false;
	constructor(private dialog: MatDialog, private router: Router) { }
	openDialog(httpResponse: HttpErrorResponse) {
		if (
			httpResponse &&
			httpResponse.status !== undefined &&
			httpResponse.status !== null
		) {
			if (httpResponse.status.toString().indexOf("401") > -1) {
				const hasToken = localStorage.getItem(credentialsKey);
				if (this.isDialogOpen || !hasToken) {
					return false;
				}
				this.isDialogOpen = true;
				const confirmModel: WarningModel = {
					id: null,
					message:
						"Your session has expired. Please re-login to renew your session."
				};
				if (window.location.href.indexOf("/login") == -1)
					window.location.href = "/login";
				//this.dialog
				//	.open(EmloWarningComponent, {
				//		data: confirmModel
				//	})
				//	.afterClosed()
				//	.subscribe((isOk: boolean) => {
				//		if (isOk) {
				//			this.isDialogOpen = false;
				//			this.dialog.closeAll();
				//			this.router.navigate([AppRoutesNames.LOGIN]);
				//		}
				//	});
			}
		}
	}
}
