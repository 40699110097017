import { CommonConstant } from "@apps/common/constants/common.constant";

export class Customer {
	id?: string | number = null;
	code?: string = null;
	password?: string = null;
	title?: string = null;
	email?: string = null;
	dob?: string | Date = null;
	lastLoginDate?: string | Date = null;
	isActive?: boolean = null;
	gender?: string | number = null;
	phoneNumber?: string = null;
	address?: string = null;
	lastLoggedInAt?: string = null;
	company?: string = null;
	registeredAt?: string = null;
	levelId?: string | number = null;
	currentPoints?: number = null;
	onHoldPoints?: number = null;
	currentOnHoldPointReason?: string = null;
	fullName?: string = null;
	levelName?: string = null;
	totalOrder?: number = null;
	loyaltyCode: string = null;
	customerCode: string = null;
}

export class CustomerSearchModel {
	searchText?: string = null;
	isActive?: boolean = null;
	levelId?: string | number = null;
	email?: string = null;
	phoneNumber?: string = null;
	loyaltyCodes?: string = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
	birthdayMonth?: number = null;
}

export class CustomerTransactionSearchModel {
	searchText?: string = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
export class CustomerClaimPointHistory {
	id?: string | number = null;
	claimedDate: string = null;
	campaignId: string = null;
	claimedPoints?: number = null;
	giftId: string = null;
	purchaseAmount: number = null;
	restaurantName: string = null;
	restaurantTicketId: number = null;
	voucherId: string = null;
	ticketType: string = null;
}

export class CustomerGiftSearchModel {
	searchText?: string = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export class CustomerGift {
	id?: string | number = null;
	giftName?: string = null;
	isClaimed?: boolean = null;
	customerGiftId?: string | number = null;
	claimedByTicketId?: number = null;
	claimedAtRestaurantId?: string = null;
	claimedOnDate?: string | Date = null;
	notes?: string = null;
}

export class AddPointCustomer {
	customerId: string = null;
	points: number = null;
	notes: string = null;
	claimedDate: string = null;
	restaurantId: string = null;
	restaurantTicketId: string = null;
	purchaseAmount: number = null;
	ticketType: string = null;
}

export class AddGiftCustomer {
	customerId: string = null;
	giftId: number = null;
	notes: string = null;
}

export class UpdateGiftCustomer {
	id?: string | number = null;
	giftName?: string = null;
	is_claimed?: boolean = null;
	customerGiftId?: string | number = null;
	notes: string = null;
	ticketId?: number = null;
	restaurantId?: string = null;
	claimedDate?: string | Date = null;
}
export class SendGiftCustomerLevel {
	levelId: string = null;
	giftId: number = null;
}

export class SendGiftCustomerLevelRequest {
	levelId: string = null;
	giftId: number = null;
}
