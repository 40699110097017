import {
	ChangeDetectorRef,
	Component,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { concat, merge, Observable, of, Subject } from "rxjs";
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	finalize,
	map,
	switchMap,
	tap,
} from "rxjs/operators";

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => NgSelectPaginationComponent),
	multi: true,
};

@Component({
	selector: "ng-select-pagination",
	templateUrl: "./ng-select-pagination.component.html",
	styleUrls: ["./ng-select-pagination.component.scss"],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class NgSelectPaginationComponent
	implements OnInit, OnChanges, ControlValueAccessor
{
	@Input() dataSource: (
		searchTerm: string,
		index: number
	) => Observable<any[]>;
	@Input() isPagination: boolean = true;
	@Input() multiple: boolean = false;
	@Input() disabled = false;
	@Input() placeholder = "";
	@Input() bindValue = "id";
	@Input() bindLabel = "name";
	@Input() readonly = false;
	@Input() clearable = false;
	@Input() loading = false;
	@Input() className = "";
	// @Input() restaurantId = null;
	// @Input() selectedId = null

	internalValue: any[] = [];
	internalValue$: Subject<any[]> = new Subject<any[]>();
	pageIndex: number = 0;

	public tags$: Observable<any>;
	public tagsInput$: Subject<string>;
	public tagsChanger$: Subject<any>;
	public tagsLoadMore$: Subject<number>;
	public selectedTagIds: any;
	public selectedTags: any;
	public tagsOpen = true;
	public searchTerm = null;

	_internalNgSelectValue;
	hasLoadData: boolean = false;

	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	constructor(private crd: ChangeDetectorRef) {}

	get value(): any {
		return this._internalNgSelectValue;
	}

	set value(v: any) {
		if (v !== this._internalNgSelectValue) {
			this._internalNgSelectValue = v;
			this.onChangeCallback(v);
		}
	}
	writeValue(value: any) {
		if (value !== this._internalNgSelectValue) {
			this._internalNgSelectValue = value;
		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	onChangeInternalValue() {
		this.value = this._internalNgSelectValue;
		this.onChangeCallback(this.value);
	}

	ngOnInit(): void {
		this.initData();
	}

	ngOnChanges(data: SimpleChanges) {
		if (data["dataSource"]?.currentValue && !this.disabled) {
			this.tagsLoadMore$.next(++this.pageIndex);
		}
		if (data["disabled"] && this.hasLoadData && !this.disabled) {
			this.tagsLoadMore$.next(++this.pageIndex);
		}
	}

	onChangeValue($event) {
		this.value = this._internalNgSelectValue;
		this.onChangeCallback(this.value);
	}

	onScroll($event) {}

	onScrollToEnd() {
		console.log("On load more....");
		this.loadMore();
	}

	onSearchFn() {}

	private initData() {
		this.tagsInput$ = new Subject<string>();
		this.tagsLoadMore$ = new Subject<number>();
		this.tags$ = merge(
			of([]),
			this.tagsLoadMore$.pipe(
				tap(() => {
					this.loading = true;
				}),
				switchMap((q) => {
					this.pageIndex = q;
					return this.serilizeDataSource(
						this.searchTerm,
						this.pageIndex
					);
				})
			),
			this.tagsInput$.pipe(
				debounceTime(200),
				distinctUntilChanged(),
				tap(() => {
					this.loading = true;
				}),
				switchMap((q) => {
					this.searchTerm = q;
					this.pageIndex = 1;
					return this.serilizeDataSource(
						this.searchTerm,
						this.pageIndex
					);
				})
			)
		);
		setTimeout(() => {

		}, 100);
	}

	private serilizeDataSource(
		searchTerm: string,
		pageIndex: number
	): Observable<any[]> {
		return this.dataSource != null
			? this.dataSource(searchTerm, pageIndex)
					.pipe(
						map((data) => {
							this.hasLoadData = true;
							if (this.pageIndex === 1) {
								this.internalValue = data;
								return data;
							} else {
								return [...this.internalValue, ...data];
							}
						})
					)
					.pipe(
						catchError((err) => {
							this.loading = false;
							console.error(err);
							return of([]);
						}),
						tap(() => (this.loading = false))
					)
			: of([]);
	}

	public filterSelectedTags(_q: string, item: any) {
		return !this?.selectedTagIds?.includes(item.id);
	}

	private loadMore() {
		this.tagsLoadMore$.next(++this.pageIndex);
		// if (this.dataSource) {
		//   this.loading = true;
		//   this.dataSource(++this.pageIndex).pipe(finalize(() => {
		//     this.loading = false;
		//   })).subscribe(x => {
		//     if (x && x.length > 0) {
		//       this.internalValue = [...this.internalValue, ...x];
		//       this.internalValue$.next(this.internalValue);
		//       console.log(this.internalValue);
		//       this.crd.detectChanges();
		//     }
		//   });
		// }
	}
}
