import { Component, OnInit } from "@angular/core";
import { UserTypeService } from "../../services/usertype.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { UserType, UserTypeFactory } from "../../model/usertype";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { UserRoleTypeResponse } from "../../model/user-role-type-response.model";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-usertype-detail",
	templateUrl: "detail.html",
})
export class EmloUserTypeDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form: FormGroup;
	public usertype: UserType = UserTypeFactory.create();
	public roles: UserRoleTypeResponse[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private credentialsService: CredentialsService,
		private userTypeService: UserTypeService,
		private formBuilder: FormBuilder
	) {
		super();
		this.createForm();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;
		this.getUserRoleTypes();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const roleId = params.get("id");
			this.isEdit = roleId && roleId !== null;
			if (this.isEdit) {
				this.initEditUserType(roleId);
			}
		});
	}

	private getUserRoleTypes(): void {
		this.userTypeService.getUserRoleTypes().subscribe((roleTypes) => {
			if (roleTypes && roleTypes.length > 0) {
				this.roles = roleTypes;
			}
		});
	}

	private createForm(): void {
		this.form = this.formBuilder.group({
			id: [null, Validators.nullValidator],
			concurrencyStamp: [null, Validators.nullValidator],
			name: [null, Validators.required],
			roleTypeId: [null, Validators.required],
		});
	}

	private initEditUserType(roleId: string): void {
		this.userTypeService
			.getUserType(roleId)
			.subscribe((usertype: UserType) => {
				if (usertype) {
					this.usertype = usertype;
					this.form.setValue({
						id: this.usertype.id,
						name: this.usertype.name,
						roleTypeId: this.usertype.roleTypeId || null,
						concurrencyStamp:
							this.usertype.concurrencyStamp || null,
					});
				}
			});
	}

	submit() {
		const data = this.prepareDataBeforeSubmit();
		this.isEdit ? this.updateUserType(data) : this.createUserType(data);
	}

	private prepareDataBeforeSubmit() {
		const usetType = {
			id: this.form.value.id,
			name: this.form.value.name,
			concurrencyStamp: this.form.value.concurrencyStamp,
			roleTypeId: this.form.value.roleTypeId,
			// permissions: this.filterPermissionCode(),
		};
		return usetType;
	}

	private filterPermissionCode(): string[] {
		return this.usertype.permissions
			.filter((userType) => userType.isActive)
			.map((userType) => userType.code);
	}

	private createUserType(body: any): void {
		this.userTypeService.createUserType(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateUserType(body: any): void {
		this.userTypeService.updateUserType(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	// onSwitchRoleType(role: UserRoleTypeResponse) {
	// 	this.userTypeService
	// 		.getPermissionsByRoleType(role.id)
	// 		.subscribe((permissions) => {
	// 			if (permissions) {
	// 				this.usertype.permissions = permissions
	// 			}
	// 		});
	// }

	cancel() {
		this.resetFormAndClose();
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.router.navigate(["/account-management/user-type"]);
	}
}
