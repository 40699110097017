import { Injectable } from "@angular/core";
import {
	NgbDateAdapter,
	NgbDateStruct,
	NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { CommonConstant } from "@apps/common/constants/common.constant";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = "/";

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = moment(
				value,
				CommonConstant.DEFUALT_SERVER_FORMAT
			).toDate();
			return {
				day: date.getDate(),
				month: date.getMonth() + 1,
				year: date.getFullYear(),
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		return date
			? date.day +
					this.DELIMITER +
					date.month +
					this.DELIMITER +
					date.year
			: null;
	}
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = "/";

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = moment(
				value,
				CommonConstant.DEFUALT_SERVER_FORMAT
			).toDate();
			return {
				day: date.getDate(),
				month: date.getMonth() + 1,
				year: date.getFullYear(),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		return date
			? date.day +
					this.DELIMITER +
					date.month +
					this.DELIMITER +
					date.year
			: "";
	}
}
