/**
 * @desc Abstract storage service
 */

export abstract class StorageService {
	/**
	 * Get value by key.
	 */
	public abstract getItem(key: string): string;

	/**
	 * Set value by key
	 */
	public abstract setItem(key: string, value: string): void;

	/**
	 * Set value by key - async fn
	 */
	public abstract setItemAsync(key: string, value: string): Promise<void>;

	/**
	 * Remove item
	 */
	public abstract removeItem(key: string): void;

	/**
	 * Get value by key - async fn
	 */
	public abstract getItemAsync(key: string): Promise<string>;

	/**
	 * Initialize storage service
	 */
	public abstract initialize(): Promise<boolean>;
}
