import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GiftComponent } from "./gift.component";
import { GiftListComponent } from "./components/list/gift.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { GiftDetailComponent } from "./components/detail/gift.detail.component";

const routes: Routes = [
	{
		path: "",
		component: GiftComponent,
		children: [
			{
				path: "",
				component: GiftListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: GiftListComponent,
			},
			{ path: "add", component: GiftDetailComponent },
			{ path: "edit", component: GiftDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GiftRoutingModule {}
