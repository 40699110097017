import {
	Component,
	OnInit
} from "@angular/core";
import { ClientService } from "../../services/client.service";
import {
	MatDialog
} from "@angular/material/dialog";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import {
	ClientDetail,
	RestaurantDetail,
	ClientItemList,
	ClientDataRequest,
	RestaurantDetailFactory,
} from "../../model/client";
import { FormGroup, FormArray } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { IconName } from "@apps/common/constants/icon";
import { EmloDisplaySecretComponent } from "../display-secret/display-secret.component";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
export interface TabType {
	label: string;
	model: ClientDetail | RestaurantDetail;
	fields: FormlyFieldConfig[];
}
@Component({
	selector: "app-client-detail",
	styleUrls: ["./style.scss"],
	templateUrl: "detail.html"
})
export class EmloClientDetailComponent extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;

	tabs: TabType[] = [];
	fields: FormlyFieldConfig[] = [];
	// form = new FormArray(this.tabs.map(() => new FormGroup({})));
	options = this.tabs.map(() => <FormlyFormOptions>{});
	restaurantDetail: RestaurantDetail = RestaurantDetailFactory.create();

	form = new FormGroup({});

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private clientService: ClientService,
		private adminShareDataService: AdminShareDataService,
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;
		this.createForm();

		this.activatedRoute.queryParamMap.subscribe((params) => {
			const restaurantId = params.get("id");
			this.isEdit = restaurantId && restaurantId !== null;
			if (this.isEdit) {
				this.initEditClientDetail(restaurantId);
			}
		});
		// this.isEdit =
		// 	this.clientItemList &&
		// 	this.clientItemList.clientDetail &&
		// 	this.clientItemList.clientDetail.id !== null;
		// if (this.isEdit) {
		// 	this.initEditClientDetail();
		// }
	}

	private createForm(): void {
		this.fields = [
			{
				type: "emlo-input",
				key: "concurrencyStamp",
				templateOptions: {
					type: "hidden"
				}
			},
			{
				type: "input",
				key: "id",
				defaultValue: null,
				templateOptions: {
					label: "Id",
					readonly: true,
					placeholder: "Id"
				}
			},
			{
				type: "input",
				key: "name",
				defaultValue: null,
				templateOptions: {
					label: "Restaurant name",
					placeholder: "Restaurant name"
				}
			},
			{
				type: "input",
				key: "addressLine1",
				defaultValue: null,
				templateOptions: {
					label: "Address line 1",
					placeholder: "Address line 1"
				}
			},
			{
				type: "input",
				key: "addressLine2",
				defaultValue: null,
				templateOptions: {
					label: "Address line 2",
					placeholder: "Address line 2"
				}
			},
			{
				type: "input",
				key: "addressLine3",
				defaultValue: null,
				templateOptions: {
					label: "Address line 3",
					placeholder: "Address line 3"
				}
			},
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "zipCode",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "ZIP code"
						}
					},
					{
						type: "input",
						key: "telephone",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Phone"
						}
					},
					{
						type: "input",
						key: "capacity",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Capacity",
							type: "number"
						}
					},
					{
						type: "input",
						key: "currencyName",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Currency name"
						}
					},
					{
						type: "input",
						key: "tables",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Tables",
							type: "number"
						}
					},
					{
						type: "input",
						key: "currencySymbol",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Currency symbol"
						}
					},
				]
			},
			{
				type: "input",
				key: "serviceChargePercentage",
				defaultValue: null,
				templateOptions: {
					label: "Service charge",
					type: "number"
				}
			},
			{
				type: "textarea",
				key: "ticketFooter",
				defaultValue: null,
				templateOptions: {
					label: "Ticket footer message",
					type: "textarea"
				}
			}
		];
		// this.tabs = [
		// 	{
		// 		label: "Api Client",
		// 		model: this.clientItemList.clientDetail,
		// 		fields: [
		// 			{
		// 				type: "input",
		// 				key: "id",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Id",
		// 					readonly: true,
		// 					placeholder: "Id"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "clientId",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Client Id",
		// 					placeholder: "Client Id"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "clientName",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Client Name",
		// 					placeholder: "Client Name"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "localUrl",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Local Url",
		// 					placeholder: "Local Url"
		// 				}
		// 			},
		// 			{
		// 				type: "emlo-select",
		// 				key: "grantType",
		// 				templateOptions: {
		// 					label: "Grant type",
		// 					bindLabel: "description",
		// 					bindValue: "name",
		// 					options: this.adminShareDataService.grantTypes
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "clientScope",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Client scope",
		// 					placeholder: "Client scope"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "clientSecret",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Client secret",
		// 					placeholder: "Client secret",
		// 					addonRight: {
		// 						class: 'fa fa-heart',
		// 						onClick: () => this.displaySecret()
		// 					},
		// 				}
		// 			}
		// 		]
		// 	},
		// 	{
		// 		label: "Restaurant",
		// 		model: this.clientItemList.restaurantDetail,
		// 		fields: [
		// 			{
		// 				type: "input",
		// 				key: "id",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Id",
		// 					readonly: true,
		// 					placeholder: "Id"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "name",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Restaurant name",
		// 					placeholder: "Restaurant name"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "addressLine1",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Address line 1",
		// 					placeholder: "Address line 1"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "addressLine2",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Address line 2",
		// 					placeholder: "Address line 2"
		// 				}
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "addressLine3",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Address line 3",
		// 					placeholder: "Address line 3"
		// 				}
		// 			},
		// 			{
		// 				fieldGroupClassName: "row",
		// 				fieldGroup: [
		// 					{
		// 						type: "input",
		// 						key: "zipCode",
		// 						defaultValue: null,
		// 						className: "col-6",
		// 						templateOptions: {
		// 							label: "ZIP code"
		// 						}
		// 					},
		// 					{
		// 						type: "input",
		// 						key: "telephone",
		// 						defaultValue: null,
		// 						className: "col-6",
		// 						templateOptions: {
		// 							label: "Phone"
		// 						}
		// 					},
		// 					{
		// 						type: "input",
		// 						key: "capacity",
		// 						defaultValue: null,
		// 						className: "col-6",
		// 						templateOptions: {
		// 							label: "Capacity"
		// 						}
		// 					},
		// 					{
		// 						type: "input",
		// 						key: "currencyName",
		// 						defaultValue: null,
		// 						className: "col-6",
		// 						templateOptions: {
		// 							label: "Currency name"
		// 						}
		// 					},
		// 					{
		// 						type: "input",
		// 						key: "tables",
		// 						defaultValue: null,
		// 						className: "col-6",
		// 						templateOptions: {
		// 							label: "Tables"
		// 						}
		// 					},
		// 					{
		// 						type: "input",
		// 						key: "currencySymbol",
		// 						defaultValue: null,
		// 						className: "col-6",
		// 						templateOptions: {
		// 							label: "Currency symbol"
		// 						}
		// 					}
		// 				]
		// 			},
		// 			{
		// 				type: "input",
		// 				key: "serviceChargePercentage",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Service charge",
		// 					type: "number"
		// 				}
		// 			},
		// 			{
		// 				type: "textarea",
		// 				key: "ticketFooter",
		// 				defaultValue: null,
		// 				templateOptions: {
		// 					label: "Ticket footer message",
		// 					type: "textarea"
		// 				}
		// 			}
		// 		]
		// 	}
		// ];
	}

	private displaySecret(): void {
		// if (this.clientItemList && this.clientItemList.clientDetail) {
		// 	this.dialog
		// 		.open(EmloDisplaySecretComponent, {
		// 			data: null
		// 		})
		// 		.afterClosed()
		// 		.subscribe(confirmPassword => {
		// 			if (confirmPassword) {
		// 				this.requestRawSecret(confirmPassword);
		// 			}
		// 		});
		// }
	}

	private requestRawSecret(confirmPassword: string): void {
		// const request = {
		// 	clientId: this.clientItemList.clientDetail.id,
		// 	confirmPassword
		// };
		// this.clientService.requestRawSecret(request).subscribe(rs => {
		// 	if (rs) {
		// 		this.clientItemList.clientDetail.clientSecret = rs.message;
		// 	}
		// });
	}

	private initEditClientDetail(restaurantId: string): void {
		this.clientService
			.getClient(restaurantId)
			.subscribe((restaurantDetail: RestaurantDetail) => {
				if (restaurantDetail) {
					this.restaurantDetail = restaurantDetail;
				}
			});
	}

	submit() {
		// const data = this.prepareDateBeforeSave();
		const data = this.form.value;
		if (data) {
			this.isEdit ? this.updateClient(data) : this.createClient(data);
		}
	}

	private prepareDateBeforeSave() {
		// if (this.restaurantDetail && this.restaurantDetail.clientDetail) {
		// 	const clientDetail = this.restaurantDetail.clientDetail;
		// 	const restaurant = this.restaurantDetail.restaurantDetail;
		// 	const clientDataRequest: ClientDataRequest = {
		// 		clientDetailId: clientDetail.id,
		// 		clientDetailClientId: null, //clientDetail.clientId,
		// 		clientDetailClientName: null, //clientDetail.clientName,
		// 		clientDetailClientScope: clientDetail.clientScope,
		// 		clientDetailClientSecret: clientDetail.clientSecret,
		// 		clientDetailGrantType: null, //clientDetail.grantType,
		// 		clientDetailLocalUrl: null, //clientDetail.localUrl,
		// 		enabledClientSecret: true, // TODO
		// 		restaurantDetailAddressLine1: restaurant.addressLine1,
		// 		restaurantDetailAddressLine2: restaurant.addressLine2,
		// 		restaurantDetailAddressLine3: restaurant.addressLine3,
		// 		restaurantDetailCapacity: restaurant.capacity,
		// 		restaurantDetailCurrencyName: restaurant.currencyName,
		// 		restaurantDetailCurrencySymbol: restaurant.currencySymbol,
		// 		restaurantDetailId: restaurant.id,
		// 		restaurantDetailName: restaurant.name,
		// 		restaurantDetailServiceChargePercentage:
		// 			restaurant.serviceChargePercentage,
		// 		restaurantDetailTables: restaurant.tables,
		// 		restaurantDetailTelephone: restaurant.telephone,
		// 		restaurantDetailTicketFooter: restaurant.ticketFooter,
		// 		restaurantDetailZipCode: restaurant.zipCode
		// 	};
		// 	return clientDataRequest;
		// }
		return null;
	}

	private createClient(body: any): void {
		this.clientService.createClient(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateClient(body: any): void {
		this.clientService.updateClient(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	cancel() {
		this.resetFormAndClose();
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.router.navigate(['/configuration/client'])
	}
}
