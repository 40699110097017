import { FieldType } from "@ngx-formly/core";
import { Component } from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { NgbDateAdapter, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter, CustomDateParserFormatter } from "./datetime-format.service";


@Component({
	selector: "emlo-datetime",
	template: `
	<div class="input-group mb-3">
	<input type="text" ngbDatepicker  [formControl]="formControl"
	[formlyAttributes]="field" class="form-control" #d="ngbDatepicker">
	<div class="input-group-append">
		<button [disabled]="to.readonly" class="btn btn-outline-secondary" type="button" (click)="d.toggle()">
			<mat-icon [svgIcon]="IconName.icon_calendar"></mat-icon>
		</button>
	</div>
	</div>
	`,
	providers: [
		{provide: NgbDateAdapter, useClass: CustomAdapter},
		{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
	  ]
})
export class EmloDatetimeComponent extends FieldType {
	IconName = IconName;
	startDate: Date = new Date();
}
