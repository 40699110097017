/**
 * @create date 2020-04-01 13:50:13
 * @modify date 2020-04-01 13:50:13
 * @desc Common request model for api
 */

import { CommonConstant } from "../constants/common.constant";

// tslint:disable-next-line: no-empty-interface
export interface IApiRequestModel {}

export interface IApiTenancyRequestModel {
	restaurantId: string | string[];
}

export interface IApiPagingRequestModel extends IApiRequestModel {
	pageSize: number;
	pageNum: number;
	sortBy?: [];
	sortDirection?: string;
}

export class ApiRequestModelFactory {
	static create<T extends IApiPagingRequestModel>(): T {
		return {
			pageSize: CommonConstant.NUMBER_RECORD_PER_PAGE,
			pageNum: 1
		} as T;
	}

	static createPagingMaxSize<T extends IApiPagingRequestModel>(): T {
		return {
			pageSize: 10000,
			pageNum: 1
		} as T;
	}
}

export interface IApiFilterRequestModel {
	statement: string,
	filter: any[]
}
