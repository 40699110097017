import { IPermissionModel } from "@apps/modules/emlo-admin/shared/model/permission.model";

export interface User {
	id: string;
	phoneNo: string;
	userName: string;
	firstName: string;
	lastName: string;
	secretKey: string;
	confirmSecretKey: string;
	roleName: string;
	roleId: string | number;
	userType: number;
	isLockedOut: boolean;
	defaultLanguage: string;
	autoLogoffInSecond: number;
	restaurantIds: string[];
	isCloud: boolean;
	restaurants?: UserRestaurant[];
}
export class UserRestaurant {
	id: string;
	name: string;
	concurrencyStamp: string;
	assigned?: boolean;
	// phoneNo: string;
	// firstName: string;
	// lastName: string;
	// roleName: string;
	// roleId: string | number;
	// userType: number;
	// isLockedOut: boolean;
	// autoLogoffInSecond: number;
	// restaurantIds: string[];
	// isCloud: boolean;
	// defaultLanguage: string;
	// userRoles: any[];
}

export class ClientUserRestaurant {
	id: string;
	name: string;
	concurrencyStamp: string;
	assigned?: boolean;
	phoneNo: string;
	firstName: string;
	lastName: string;
	roleName: string;
	roleId: string | number;
	userType: number;
	costPerHour: number;
	ssn: string | number;
	isLockedOut: boolean;
	autoLogoffInSecond: number;
	restaurantIds: string[];
	isCloud: boolean;
	defaultLanguage: string;
	userRoles: any[];
	permissions: IPermissionModel[];
}

export class UserFactory {
	public static create(): User {
		return {
			id: null,
			phoneNo: null,
			userName: null,
			firstName: null,
			lastName: null,
			secretKey: null,
			confirmSecretKey: null,
			roleName: null,
			roleId: null,
			userType: null,
			isLockedOut: false,
			defaultLanguage: null,
			autoLogoffInSecond: null,
			restaurantIds: null,
			isCloud: false
		};
	}
}
