import { ICategoryRequestSearchModel } from "./../../model/category-request-search.model";
import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Event, DefaultConfig, Config } from "@apps/shared/table";
import {
	Category,
	CategoryFactory,
	CategorySearchModel,
} from "../../model/category";
import { CategoryService } from "../../services/category.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { Subscription } from "rxjs";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { BaseFilterComponent } from "../../../base-filter-component";
import { Router } from "@angular/router";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-productmanagement-category-list",
	templateUrl: "./list.html",
})
export class EmloCategoryListComponent
	extends BaseFilterComponent<ICategoryRequestSearchModel>
	implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("isVisibleTpl", { static: true }) isVisibleTpl: TemplateRef<any>;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public categories: Category[] = [];
	public pagination = PaginationModelFactory.create();
	isEditOrder: boolean = false;

	private readonly All = "All";
	private readonly Visible = "Visible";
	private readonly InVisible = "Invisible";

	criteria: CategorySearchModel = new CategorySearchModel();
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];
	restaurants: IRestaurantItemModel[] = [];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private categoryService: CategoryService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		// this.initDataSource();
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getCategories();
		this.initFilterMetaData();
		this.configColumnForCategoryTable();
		// this.switchRestaurant$ = this.shareDataService
		// 	.getObservable(EventName.SWITCH_RESTAURANT)
		// 	.pipe(untilDestroyed(this))
		// 	.subscribe(() => {
		// 		// this.request.restaurantId = this.adminShareDataService?.restaurantActive?.id;
		// 		// if (this.request.restaurantId) {

		// 		// }
		// 		this.dataSource$.next(this.request);
		// 	});
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getCategories();
			// this.request = { ...this.request, ...$event.value };
			// this.dataSource$.next(this.request);
		}
	}

	// private initDataSource(): void {
	// 	this.dataSource$
	// 		.pipe(untilDestroyed(this))
	// 		.pipe(debounceTime(200))
	// 		.pipe(
	// 			catchError(() => {
	// 				this.initDataSource();
	// 				return empty();
	// 			})
	// 		)
	// 		.pipe(switchMap(obj => this.categoryService.getCategories(obj)))
	// 		.subscribe((response: IApiPagingResponseModel<Category[]>) => {
	// 			if(response) {
	// 				this.categories = response.data;
	// 				this.pagination = response;
	// 				this.cdr.detectChanges();
	// 			}
	// 		});
	// }

	private getCategories() {
		this.categoryService.getCategories(this.criteria).subscribe((data) => {
			this.categories = [];
			if (data) {
				this.categories = data.data;
				this.pagination = data;
			}
		});
	}

	private configColumnForCategoryTable(): void {
		this.configuration = { ...DefaultConfig, serverPagination: true };
		this.columns = [
			{ key: "nameEn", title: "Name EN" },
			{ key: "nameVi", title: "Name VI" },
			{ key: "nameCn", title: "Name CN" },
			{
				key: "isVisible",
				title: "Visible",
				cellTemplate: this.isVisibleTpl,
				width: "50px",
				cssClass: { name: "text-center", includeHeader: true },
				orderEnabled: false,
			},
			{ key: "sortOrder", title: "Sort Order", width: "120px" },
			{ key: "restaurantNames", title: "Selling at"},
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	toggleCategory(event, category: Category) {
		event.preventDefault();
		if (!category.isVisible) {
			this.enableCategory(category);
		} else {
			const confirmModel: ConfirmModel = {
				id: null,
				message: "Are you sure want to disable this category?",
			};
			this.dialog
				.open(EmloConfirmComponent, {
					data: confirmModel,
				})
				.afterClosed()
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.disableCategory(category);
					}
				});
		}
	}

	private enableCategory(category: Category): void {
		const data = { id: category.id };
		this.categoryService
			.enableCategory(JSON.stringify(data))
			.subscribe(() => {
				category.isVisible = true;
			});
	}

	private disableCategory(category: Category): void {
		const data = { id: category.id };
		this.categoryService
			.disableCategory(JSON.stringify(data))
			.subscribe(() => {
				category.isVisible = false;
			});
	}

	private deleteCategory(category: Category): void {
		const data = { id: category.id };
		this.categoryService
			.deleteCategory(JSON.stringify(data))
			.subscribe(() => {
				this.dataSource$.next(this.request);
			});
	}

	delete(category: Category): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteCategory(category);
				}
			});
	}

	openCategoryDetail(category: Category): void {
		if (!category) {
			category = CategoryFactory.create();
			category.restaurantIds = this.request.restaurantId;
			this.router.navigate(["/product-management/category/add"]);
		} else {
			this.router.navigate(["/product-management/category/edit"], {
				queryParams: { id: category.id },
			});
		}

		// if (!category) {
		// 	category = CategoryFactory.create();
		// 	category.restaurantIds = this.request.restaurantId;
		// }
		// this.dialog
		// 	.open(EmloCategoryDetailComponent, {
		// 		data: category
		// 	})
		// 	.afterClosed()
		// 	.subscribe(isSaved => {
		// 		if (isSaved) {
		// 			this.dataSource$.next(this.request);
		// 		}
		// 	});
	}

	onFilter() {
		this.getCategories();
	}

	ngOnDestroy() {}
}
