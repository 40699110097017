import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { Currency, CurrencySearchModel } from "../model/currency";
import { environment } from "@env/environment";

@Injectable()
export class CurrencyService {
	constructor(private apiService: ApiService) {}

	getCurrencys(request: CurrencySearchModel) {
		return this.apiService.post(environment.prefix_api_url + "currency/list", request);
	}

	createCurrency(context: Currency) {
		return this.apiService.post(environment.prefix_api_url + "currency/create", context);
	}
}
