import { CommonConstant } from "@apps/common/constants/common.constant";
import { ClientUserRestaurant } from "../../user/model/user";

export interface ClientUser {
	id: string;
	phoneNo: string;
	userName: string;
	firstName: string;
	lastName: string;
	secretKey: string;
	confirmSecretKey: string;
	roleName: string;
	roleId: string | number;
	userType: number;
	isLockedOut: boolean;
	defaultLanguage: string;
	autoLogoffInSecond: number;
	restaurantId: string | string[];
	isCloud: boolean;
	costPerHour: number;
	ssn: number;
	pin: string;
	restaurants: ClientUserRestaurant[]
}

export class ClientFactory {
	public static create(): ClientUser {
		return {
			id: null,
			phoneNo: null,
			userName: null,
			firstName: null,
			lastName: null,
			secretKey: null,
			confirmSecretKey: null,
			roleName: null,
			roleId: null,
			userType: null,
			isLockedOut: false,
			defaultLanguage: null,
			autoLogoffInSecond: null,
			restaurantId: null,
			isCloud: false,
			costPerHour: null,
			ssn: null,
			pin: null,
			restaurants: []
		};
	}
}

export class ClientUserSearchModel {
	searchText: string = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export class ClientRole {
	id: string = null;
	name: string = null;
	restaurantId: string = null;
}
