import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomerLevelComponent } from "./customer-level.component";
import { CustomerLevelListComponent } from "./components/list/customer-level.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { CustomerLevelDetailComponent } from "./components/detail/customer-level.detail.component";

const routes: Routes = [
	{
		path: "",
		component: CustomerLevelComponent,
		children: [
			{
				path: "",
				component: CustomerLevelListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: CustomerLevelListComponent,
			},
			{ path: "add", component: CustomerLevelDetailComponent },
			{ path: "edit", component: CustomerLevelDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomerLevelRoutingModule {}
