<div class="box border-sub-1">
	<emlo-box-header [title]="'Configuration Campaigns'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-2">
			<div class="flex-grow-1">
				<div class="d-flex flex-row align-items-center">
					<div class="keyword">
						<input type="text" class="form-control" name="keyword" [(ngModel)]="criteria.searchText"
							(keyup.enter)="onFilter()" placeholder="{{ 'Search...' | translate }}" autocomplete="off">
					</div>
				</div>
			</div>
		</div>

		<emlo-table [configuration]="configuration" [data]="campaigns" [columns]="columns" [pagination]="pagination"
			(event)="onTableEvent($event)">
		</emlo-table>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openCampaignsDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="openCampaignsDetail(null)">Add
			New</button>
	</ng-template>
</div>
