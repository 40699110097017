/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:13:10
 * @modify date 2020-02-11 00:13:10
 * @desc [Adds a default error handler to all requests.]
 */
import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			retry(0),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					// Refresh token
				}
				return throwError(error);
			})
		);
	}
}
