<div class="condition-section">
    <ng-container  *ngFor="let condition of formControl.value; let i = index">
        <div class="d-flex mb-2">
            <div class="input-group condition">
                <select class="form-control input-sm custom-select condition-column" [(ngModel)]="condition.key" (ngModelChange)="onChangeColumn(condition.key, i)" required>
                    <option *ngFor="let column of columnSupported" value="{{column.value}}">{{column.label}}</option>
                </select>
                <select class="form-control custom-select condition-operator"  [(ngModel)]="condition.operator" required>
                    <option *ngFor="let operator of condition.supportedOperator" value={{operator}}>{{operator}}</option>
                </select>
                <filter-value [type]="condition.type" [(ngModel)]="condition.value" class="condition-value"></filter-value>
                <filter-value [type]="condition.type" [(ngModel)]="condition.value2" class="condition-value" *ngIf="condition.operator == 'Between'"></filter-value>
            </div>
            <div class="d-flex vertical-center trash-container" (click)="onRemoveCondition(i)" >
                <mat-icon [svgIcon]="IconName.icon_close"></mat-icon>
            </div>
        </div>
    </ng-container>
    <div>
        <button class="d-flex btn btn-sm new-row" (click)="onAddCondition()">
            <mat-icon [svgIcon]="IconName.icon_add_nocircle"></mat-icon>
            <span>Add Filter</span>
        </button>
    </div>
</div>

