<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Edit Restaurant' : 'Add Restaurant'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>

	<form [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content">
			<formly-form [model]="restaurantDetail" [fields]="fields" [form]="form"></formly-form>
		</div>

		<!-- <mat-tab-group>
			<mat-tab *ngFor="let tab of tabs;let index = index;"
			[label]="tab.label">
				<div class="box-content client-content">
					<formly-form
						[form]="form.at(index)"
						[model]="tab.model"
						[fields]="tab.fields"
						[options]="options[index]">
					</formly-form>
				</div>
			</mat-tab>
		</mat-tab-group> -->
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>
			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'" translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
