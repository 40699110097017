import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import {
	CustomerLevel,
	CustomerLevelSearchModel,
	CustomerSearchModel,
} from "../../model/customer-level";
import { CustomerLevelService } from "../../services/customer-level.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-customer-level-list",
	templateUrl: "./list.html",
})
export class CustomerLevelListComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("isStatusTpl", { static: true }) isStatusTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public customerLevels: CustomerLevel[] = [];
	public pagination = PaginationModelFactory.create();
	private readonly All = "All";
	private readonly Visible = "Active";
	private readonly InVisible = "Inactive";
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];
	criteria: CustomerLevelSearchModel = new CustomerLevelSearchModel();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private customerService: CustomerLevelService,
		private credentialsService: CredentialsService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.configCustomerLevelTable();
		this.getCustomerLevels();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria = { ...this.criteria, ...$event.value };
			this.getCustomerLevels();
		}
	}

	private getCustomerLevels() {
		this.customerService
			.getCustomerLevels(this.criteria)
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if (response) {
					this.customerLevels = response.data;
				}
			});
	}

	private configCustomerLevelTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "levelCodeName", title: "Code", orderEnabled: false },
			{ key: "name", title: "Level", orderEnabled: false },
			{ key: "minPoints", title: "Min Points", orderEnabled: false },
			{
				key: "defaultDiscountPercentage",
				title: "Default discount rates (%)",
				orderEnabled: false,
			},
			{
				key: "discountPercentageOnDOB",
				title: "Discount rates on DOB (%)",
				orderEnabled: false,
			},
			{
				key: "discountPercentageOnCustomerNumber",
				title: "Discount rates on Customer Number (%)",
				orderEnabled: false,
			},
			{
				key: "minDayFromDOBForGift",
				title: "Day range from/to DOB (day)",
				orderEnabled: false,
			},
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	// toggleLockOut(event, customer: CustomerLevel) {
	// 	event.preventDefault();
	// 	if (customer.isActive) {
	// 		this.unLockCustomerLevel(customer);
	// 	} else {
	// 		const confirmModel: ConfirmModel = {
	// 			id: null,
	// 			message: "Are you sure want to lock this customer level?",
	// 		};
	// 		this.dialog
	// 			.open(EmloConfirmComponent, {
	// 				data: confirmModel,
	// 			})
	// 			.afterClosed()
	// 			.subscribe((isConfirmed: boolean) => {
	// 				if (isConfirmed) {
	// 					this.lockCustomerLevel(customer);
	// 				}
	// 			});
	// 	}
	// }

	// private unLockCustomerLevel(customer: CustomerLevel): void {
	// 	const data = { id: customer.id };
	// 	this.customerService.unLockCustomerLevel(data).subscribe(() => {
	// 		this.getCustomerLevel();
	// 	});
	// }

	// private lockCustomerLevel(customer: CustomerLevel): void {
	// 	const data = { id: customer.id };
	// 	this.customerService
	// 		.lockCustomerLevel(JSON.stringify(data))
	// 		.subscribe(() => {
	// 			this.getCustomerLevel();
	// 		});
	// }

	private deleteCustomer(customer: CustomerLevel): void {
		const data = { id: customer.id };
		this.customerService
			.deleteCustomerLevel(JSON.stringify(data))
			.subscribe(() => {
				this.getCustomerLevels();
			});
	}

	delete(customer: CustomerLevel): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteCustomer(customer);
				}
			});
	}

	openCustomerLevelDetail(customer: CustomerLevel): void {
		if (!customer) {
			this.router.navigate([
				"/customer-service/configuration/customer-level/add",
			]);
		} else {
			this.router.navigate(
				["/customer-service/configuration/customer-level/edit"],
				{
					queryParams: { id: customer.id },
				}
			);
		}
	}

	onFilter() {
		this.getCustomerLevels();
	}

	ngOnDestroy() {}
}
