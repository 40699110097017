/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:11:16
 * @modify date 2020-02-11 00:11:16
 */
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import {
	HttpClientModule,
	HttpClient,
	HTTP_INTERCEPTORS
} from "@angular/common/http";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from "@angular/core";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "@env/environment";
import { CoreModule, ErrorHandlerInterceptor } from "@apps/core";
import { SharedModule } from "@apps/shared";
import { ShellModule } from "@apps/modules/emlo-admin/shell";
import { EmloAdminModule } from "./modules/emlo-admin";
import { LoginModule } from "@apps/modules/emlo-admin/login/login.module";
import { ErrorDialogModule } from "./shared/errordialog";
import { GlobalErrorHandler } from "./core/global-error-handler";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(
		http.disableApiPrefix(),
		"/translations/",
		".json"
	);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		NoopAnimationsModule,
		MatSnackBarModule,
		FormsModule,
		ServiceWorkerModule.register("./ngsw-worker.js", {
			enabled: environment.production
		}),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		CoreModule,
		ShellModule,
		SharedModule,
		ErrorDialogModule,
		LoginModule,
		EmloAdminModule,
		AppRoutingModule // must be imported as the last module as it contains the fallback route
	],
	providers: [
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
