import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatDialogRef
} from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { GiftRoutingModule } from "./gift.routes";
import { GiftComponent } from "./gift.component";
import { GiftListComponent } from "./components/list/gift.list.component";
import { GiftDetailComponent } from "./components/detail/gift.detail.component";
import { GiftService } from "./services/gift.service";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { EmloFormModule } from "@apps/shared/form";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
	declarations: [
		GiftComponent,
		GiftListComponent,
		GiftDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatTabsModule,
		MatRadioModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		NgbDatepickerModule,
		FormlyBootstrapModule,
		EmloFormModule,
		GiftRoutingModule,
	],
	providers: [
		GiftService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [GiftDetailComponent]
})
export class GiftModule {}
