import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared";
import { EmloProductManagementRoutingModule } from "./account-management-routing.module";
import { EmloAccountManagementComponent } from "./account-management.component";
import { EmloFormModule } from "@apps/shared/form";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		EmloProductManagementRoutingModule,
		EmloFormModule
	],
	declarations: [EmloAccountManagementComponent]
})
export class EmloAccountManagementModule {}
