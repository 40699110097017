import { EmloButtonModule } from "./../button/button.module";
import { MatIconModule } from "@angular/material/icon";
import { NgModule } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { FilterFieldComponent } from "../filter-builder/components/defination/filter-control/filter-field.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FilterContainerComponent } from "./components/defination/filter-container/filter-container.component";
import { FilterValueComponent } from "./components/defination/filter-value/filter-value.component";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatIconModule,
		MatRadioModule,
		EmloButtonModule,
		FormlyModule.forRoot({
			types: [{ name: "filter-control", component: FilterFieldComponent }]
		})
	],
	declarations: [FilterContainerComponent, FilterFieldComponent, FilterValueComponent],
	entryComponents: [FilterFieldComponent, FilterValueComponent],
	exports: [FilterContainerComponent]
})
export class EmloFilterBuilderModule {}
