<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Customer Information' : 'Customer Information'"
		[headerActionsTemplate]="headerActionsTemplate">
	</emlo-box-header>
	<form class="form-w-480" [formGroup]="form" *ngIf="canAccess; else contactAdmin">

		<mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="swittchTab($event)">
			<mat-tab label="{{ 'Customer Info' | translate }}">
				<div class="box-content item-content" *ngIf="customer">
					<formly-form [model]="customer" [fields]="customerFormFields" [form]="form"></formly-form>
				</div>
			</mat-tab>
			<mat-tab label="{{ 'Points History' | translate }}">
				<div class="box-content item-content" *ngIf="customer && tabIndex === 1">
					<app-customer-point-history [customer]="customer" [tabIndex]="tabIndex"></app-customer-point-history>
				</div>
			</mat-tab>
			<mat-tab label="{{ 'Gifts' | translate }}">
				<div class="box-content item-content" *ngIf="customer && tabIndex === 2">
					<app-customer-gift [customer]="customer" [tabIndex]="tabIndex"></app-customer-gift>
				</div>
			</mat-tab>
			<!-- <mat-tab label="{{ 'Coupon / Discount' | translate }}">
				<div class="box-content item-content" *ngIf="customer">
					Coming Soon
				</div>
			</mat-tab> -->
		</mat-tab-group>
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>
				Close
			</button>

			<!-- Temporary disable <emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button> -->
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<a *ngIf="canAccess && tabIndex === 1" class="btn btn-primary btn-sm text-white ml-2" (click)="addPoint()">Add Point</a>
		<a *ngIf="canAccess && tabIndex === 2" class="btn btn-primary btn-sm text-white" (click)="addGift()">Add Gift</a>
	</ng-template>
</div>
