/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-12 00:03:15
 * @modify date 2020-02-12 00:03:15
 * @desc [Style For Box Header]
 */
import { Component, Input, ChangeDetectionStrategy, TemplateRef } from "@angular/core";

@Component({
	selector: "emlo-box-header",
	templateUrl: "boxheader.component.html",
})
export class EmloBoxHeaderComponent {
	@Input() title: string = null;
	@Input() headerActionsTemplate: TemplateRef<void>;
}
