import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloUserModule } from "./user";
import { EmloUserTypeModule } from "./usertypes";
import { EmloRestaurantUserModule } from "./client-user";
import { EmloRestaurantUserTypeModule } from "./client-usertypes";

const routes: Routes = [
	{
		path: "",
		redirectTo: AppRoutesNames.USER
	},
	{
		path: AppRoutesNames.USER,
		loadChildren: () => EmloUserModule
	},
	{
		path: AppRoutesNames.USER_TYPE,
		loadChildren: () => EmloUserTypeModule
	},
	{
		path: AppRoutesNames.RESTAURANT_USER,
		loadChildren: () => EmloRestaurantUserModule
	},
	{
		path: AppRoutesNames.RESTAURANT_USER_TYPE,
		loadChildren: () => EmloRestaurantUserTypeModule
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloProductManagementRoutingModule {}
