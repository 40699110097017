import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { Category, CategorySearchModel } from "../model/category";
import { environment } from "@env/environment";
import { ICategoryRequestSearchModel } from "../model/category-request-search.model";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";

@Injectable()
export class CategoryService {
	constructor(private apiService: ApiService) {}

	getCategories(obj: CategorySearchModel): Observable<IApiPagingResponseModel<Category[]>> {
		return this.apiService.post(environment.prefix_api_url + "category/list", obj);
	}

	getCategory(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "category/getbyid", params);
	}

	createCategory(context: Category) {
		return this.apiService.post(environment.prefix_api_url + "category/create", context);
	}

	updateCategory(context: Category) {
		return this.apiService.post(environment.prefix_api_url + "category/update", context);
	}

	deleteCategory(context: any) {
		return this.apiService.post(environment.prefix_api_url + "category/delete", context);
	}

	enableCategory(context: any) {
		return this.apiService.post(environment.prefix_api_url + "category/enable", context);
	}

	disableCategory(context: any) {
		return this.apiService.post(environment.prefix_api_url + "category/disable", context);
	}
}
