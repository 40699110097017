import { FormlyFieldConfig } from "@ngx-formly/core";

export const IdControl = {
	type: "emlo-input",
	key: "id",
	templateOptions: {
		type: "hidden"
	}
};

export const NameControl = {
	type: "emlo-input",
	key: "name",
	templateOptions: {
		label: "Name",
		placeholder: "Name",
	}
};

export const ItemIdControl: FormlyFieldConfig = {
	type: "emlo-input",
	key: "itemId",
	focus: true,
	templateOptions: {
		label: "Item ID",
		placeholder: "Item ID",
	}
};

export const NameEnControl: FormlyFieldConfig = {
	type: "emlo-input",
	key: "nameEn",
	focus: true,
	templateOptions: {
		label: "Name EN",
		placeholder: "Name EN",
	}
};

export const NameViControl = {
	type: "emlo-input",
	key: "nameVi",
	templateOptions: {
		label: "Name VI",
		placeholder: "Name VI",
	}
};

export const NameCnControl = {
	type: "emlo-input",
	key: "nameCn",
	templateOptions: {
		label: "Name CN",
		placeholder: "Name CN",
	}
};

export const SortOrderrControl = {
	type: "emlo-input",
	key: "sortOrder",
	defaultValue: null,
	templateOptions: {
		label: "Sort Order",
		placeholder: "Sort Order",
		type: "number"
	}
};

export const VisibleControl = {
	type: "emlo-checkbox",
	key: "isVisible",
	defaultValue: true,
	templateOptions: {
		label: "Visible"
	}
};

export const ButtonTextColorControl = {
	fieldGroupClassName: "row",
	fieldGroup: [
		{
			key: "btnColor",
			type: "emlo-colorpicker",
			className: "col-6",
			defaultValue: null,
			templateOptions: {
				label: "Button Color"
			}
		},
		{
			key: "textColor",
			type: "emlo-colorpicker",
			className: "col-6",
			defaultValue: null,
			templateOptions: {
				label: "Text Color"
			}
		}
	]
};

export const VisibleAndSortOrderControl = {
	fieldGroupClassName: "row",
	fieldGroup: [
		{
			type: "emlo-checkbox",
			key: "isVisible",
			defaultValue: true,
			className: "col-6 pt-4",
			templateOptions: {
				label: "Visible"
			}
		},
		{
			type: "input",
			key: "sortOrder",
			defaultValue: null,
			className: "col-6",
			templateOptions: {
				label: "Sort Order",
				placeholder: "Sort Order",
				type: "number"
			}
		}
	]
};

export const CanDiscountDiscountRateControl = {
	fieldGroupClassName: "row",
	fieldGroup: [
		{
			type: "emlo-checkbox",
			key: "canDiscount",
			defaultValue: true,
			className: "col-6 pt-4",
			templateOptions: {
				label: "Can Discount"
			}
		},
		{
			type: "emlo-input",
			key: "discountRate",
			defaultValue: null,
			className: "col-6",
			templateOptions: {
				label: "Discount Rate",
				placeholder: "Discount Rate",
				type: "number"
			}
		}
	]
};

export const BuyPriceSalePriceControl = {
	fieldGroupClassName: "row",
	fieldGroup: [
		{
			type: "input",
			key: "buyPrice",
			defaultValue: null,
			className: "col-6",
			templateOptions: {
				label: "Buy Price",
				placeholder: "Buy Price",
				type: "number"
			}
		},
		{
			type: "input",
			key: "price",
			defaultValue: null,
			className: "col-6",
			templateOptions: {
				label: "Sale Price",
				placeholder: "Sale Price",
				type: "number"
			}
		}
	]
};
