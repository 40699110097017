import { BaseDataReport } from "../../shared/model/report.base.mode";

export class TotalSaleReport {
	paymentType: string;
	paymentTypeDisplay: string;
	numberOfChecks: number;
	totalAmount: number;
}

export class TotalSaleDataReport extends BaseDataReport {
	paymentTypeSummary: TotalSaleReport[];
	totalChecks: number;
	total: number;
}
