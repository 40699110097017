import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { Customer, CustomerSearchModel } from "../../model/customer";
import { CustomerService } from "../../services/customer.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { debounceTime, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { CustomerLevel, CustomerLevelSearchModel } from "../../../customer-level/model/customer-level";
import { CustomerLevelService } from "../../../customer-level/services/customer-level.service";
import { SendGiftCustomerComponent } from "../send-gift/send-gift.component";

@Component({
	selector: "app-customer-list",
	templateUrl: "./list.html",
})
export class CustomerListComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	isLoading: boolean = false;
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("isStatusTpl", { static: true }) isStatusTpl: TemplateRef<any>;
	@ViewChild("createdDateTpl", { static: true }) createdDateTpl: TemplateRef<any>;
	@ViewChild("dobTpl", { static: true }) dobTpl: TemplateRef<any>;
	@ViewChild("lastLoginDateTpl", { static: true }) lastLoginDateTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public customers: Customer[] = [];
	public customerLevels: CustomerLevel[] = [];
	public months = [
		{ text: "Tháng 1", id: 1 },
		{ text: "Tháng 2", id: 2 },
		{ text: "Tháng 3", id: 3 },
		{ text: "Tháng 4", id: 4 },
		{ text: "Tháng 5", id: 5 },
		{ text: "Tháng 6", id: 6 },
		{ text: "Tháng 7", id: 7 },
		{ text: "Tháng 8", id: 8 },
		{ text: "Tháng 9", id: 9 },
		{ text: "Tháng 10", id: 10 },
		{ text: "Tháng 11", id: 11 },
		{ text: "Tháng 12", id: 12 },
	];
	public pagination = PaginationModelFactory.create();
	private readonly All = "All";
	private readonly Visible = "Active";
	private readonly InVisible = "Inactive";
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];
	criteria: CustomerSearchModel = new CustomerSearchModel();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private customerService: CustomerService,
		private customerLevelService: CustomerLevelService,
		private credentialsService: CredentialsService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.configCustomerTable();
		this.getCustomerLevel();
		this.getCustomers();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria = { ...this.criteria, ...$event.value };
			this.getCustomers();
		}
	}

	private getCustomerLevel() {
		const criteria: CustomerLevelSearchModel = new CustomerLevelSearchModel();
		criteria.pageSize = 10;
		this.customerLevelService
			.getCustomerLevels(criteria)
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if (response) {
					this.customerLevels = response.data;
				}
			});
	}

	private getCustomers() {
		this.isLoading = true;
		this.customerService
			.getCustomers(this.criteria)
			.pipe(
				finalize(() => {
					this.isLoading = false;
				}),
			)
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if (response) {
					this.customers = response.data;
					this.pagination = response;
				}
			});
	}

	private configCustomerTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "loyaltyCode", title: "Code", orderEnabled: false },
			{ key: "fullName", title: "Customer Name", orderEnabled: false },
			{ key: "email", title: "Email", orderEnabled: false },
			{ key: "mobileNumber", title: "Phone", orderEnabled: false },
			{ key: "dob", title: "Date of birth", orderEnabled: false, cellTemplate: this.dobTpl },
			{ key: "createdDate", title: "Joined Date", orderEnabled: false, cellTemplate: this.createdDateTpl, },
			{ key: "profile.currentLevelName", title: "Level", orderEnabled: false },
			{ key: "profile.currentPoints", title: "Points", orderEnabled: false },
			{
				key: "isActive",
				title: "Is Active",
				cellTemplate: this.isStatusTpl,
				width: "100px",
				cssClass: { name: "text-center", includeHeader: true },
				orderEnabled: false,
			},
			{ key: "lastLoginDate", title: "Last Logged In (on web)", orderEnabled: false, cellTemplate: this.lastLoginDateTpl },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	toggleLockOut(event, customer: Customer) {
		event.preventDefault();
		return;
		// if (customer.isActive) {
		// 	this.unLockCustomer(customer);
		// } else {
		// 	const confirmModel: ConfirmModel = {
		// 		id: null,
		// 		message: "Are you sure want to lock this customer?",
		// 	};
		// 	this.dialog
		// 		.open(EmloConfirmComponent, {
		// 			data: confirmModel,
		// 		})
		// 		.afterClosed()
		// 		.subscribe((isConfirmed: boolean) => {
		// 			if (isConfirmed) {
		// 				this.lockCustomer(customer);
		// 			}
		// 		});
		// }
	}

	gotoAdminDimtutac() {
		this.router.navigateByUrl("https://demo-admin.dimtutac.com");
	}

	private unLockCustomer(customer: Customer): void {
		const data = { id: customer.id };
		this.customerService.unLockCustomer(data).subscribe(() => {
			this.getCustomers();
		});
	}

	private lockCustomer(customer: Customer): void {
		const data = { id: customer.id };
		this.customerService
			.lockCustomer(JSON.stringify(data))
			.subscribe(() => {
				this.getCustomers();
			});
	}

	private deleteCustomer(customer: Customer): void {
		const data = { id: customer.id };
		this.customerService
			.deleteCustomer(JSON.stringify(data))
			.subscribe(() => {
				this.getCustomers();
			});
	}

	delete(customer: Customer): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteCustomer(customer);
				}
			});
	}

	openCustomerDetail(customer: Customer): void {
		if (!customer) {
			this.router.navigate(["/customer-service/customer/add"]);
		} else {
			this.router.navigate(["/customer-service/customer/edit"], {
				queryParams: { code: customer.customerCode },
			});
		}
	}

	onFilter() {
		this.getCustomers();
	}

	sendGift() {
		this.dialog
			.open(SendGiftCustomerComponent, {
				minWidth: 360,
			})
			.afterClosed()
			.subscribe(() => {});
	}

	ngOnDestroy() {}
}
