import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class RestaurantService {
	constructor(private apiService: ApiService) {}

	getRestaurants() {
		return this.apiService.get(environment.prefix_api_url + "restaurant/listSelectionByUser");
	}
}
