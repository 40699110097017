import { untilDestroyed } from "@apps/core";
import { IconName } from "./../../../../../common/constants/icon";
import { Component, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { IFilterMetadataItemModel } from "@apps/shared/filter-builder/model/filter.metadata.model";
import { DefaultCondition } from "@apps/shared/filter-builder/services/default-condition";

@Component({
	templateUrl: "filter-field.component.html",
	styleUrls: ["filter-field.component.scss"]
})
export class FilterFieldComponent extends FieldType implements OnChanges, OnDestroy {
	conditionMeta: IFilterMetadataItemModel[] = [];
	columnSupported: any[] = [];
	operatorSupported: ConditionOperator[] = [];
	IconName = IconName;
	// tslint:disable-next-line: use-lifecycle-interface
	ngOnInit() {
		this.conditionMeta = this.field.templateOptions["conditionMeta"];
		this.columnSupported = this.conditionMeta.map(x => {
			return {
				label: x.name,
				value: x.key
			};
		});
		this.field.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(obj => {
			this.setMedataData();
		});
	}

	ngOnChanges(change: SimpleChanges) {
		if (this.field.templateOptions["conditionMeta"]) {
			this.conditionMeta = this.field.templateOptions["conditionMeta"];
		}
	}

	onRemoveCondition(index) {
		this.formControl.value.splice(index, 1);
		this.formControl.setValue(this.formControl.value);
	}

	onAddCondition() {
		const value = this.field.formControl.value;
		if (!value) {
			this.formControl.setValue([]);
		}
		this.formControl.setValue([...this.field.formControl.value, this.findRemainField()]);
	}

	onChangeColumn(key, i) {
		this.setMedataDataForCondition(key, i);
	}

	ngOnDestroy(): void {
	}

	private findRemainField() {
		const temp = this.conditionMeta.filter(
			x => this.formControl.value.map(key => key.key).indexOf(x.key) === -1
		);
		if (temp.length > 0) {
			const condition = new DefaultCondition(
				temp[0].key,
				null,
				temp[0].supportedOperator[0],
				temp[0].name
			);
			condition.supportedOperator = temp[0].supportedOperator;
			condition.type = temp[0].type;
			return condition;
		}
		return new DefaultCondition(null, null, null);
	}

	private setMedataData() {
		if(!this.formControl.value || this.formControl.value.length  === 0) {
			return;
		}

		this.formControl.value.forEach((condition: DefaultCondition, index) => {
			if(condition.key == null) {
				Object.assign(condition, this.findRemainField());
			}
			this.setMedataDataForCondition(condition.key, index);
		})
	}

	private setMedataDataForCondition(key, i) {
		const temp = this.conditionMeta.filter(x => x.key === key);
		if (temp.length > 0) {
			this.formControl.value[i].supportedOperator = temp[0].supportedOperator;
			this.formControl.value[i].type = temp[0].type;
			if(this.formControl.value[i].supportedOperator.length > 0) {
				this.formControl.value[i].operator = this.formControl.value[i].supportedOperator[0];
			}
		}
	}
}
