<div class="box border-sub-1">
	<emlo-box-header [title]="'General Config'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content item-content" *ngIf="generalConfigDetail">
			<app-loader [isLoading]="isLoading"></app-loader>
			<formly-form [model]="generalConfigDetail" [fields]="generalConfigDetailFormFields" [form]="form">
			</formly-form>
			<div class="mb-3">
				<button type="button" class="btn btn-primary" (click)="onAddNewRow()">Add date that cannot apply member discounts</button>
			</div>
			<table class="table" *ngIf="generalConfigDetail.excludeDayMonths">
				<thead>
					<tr>
						<th class="px-0">Day</th>
						<th class="px-2">Month</th>
						<th class="px-2">Is Lunar</th>
						<th class="px-2">Notes</th>
						<th class="px-0 col-remove"></th>
					</tr>
				</thead>
				<tbody>
					<ng-template ngFor let-index="index" let-item [ngForOf]="generalConfigDetail.excludeDayMonths">
						<tr>
							<td class="align-middle px-0">
								<select class="form-control" [(ngModel)]="item.day"
									[ngModelOptions]="{standalone: true}">
									<option [value]="day" *ngFor="let day of days">{{day}}</option>
								</select>
							</td>
							<td class="align-middle px-2">
								<select class="form-control" [(ngModel)]="item.month"
									[ngModelOptions]="{standalone: true}">
									<option [value]="month.value" *ngFor="let month of months">{{month.name}}</option>
								</select>
							</td>
							<td class="align-middle px-2">
								<input [(ngModel)]="item.isLunar" type="checkbox"
									[ngModelOptions]="{standalone: true}" />
							</td>
							<td class="align-left px-2">
								<input class="form-control" [(ngModel)]="item.notes" type="input"
									[ngModelOptions]="{standalone: true}" />
							</td>
							<td class="align-middle px-0 col-remove text-right">
								<button class="btn btn-danger btn-sm" (click)="onRemoveRow(index)">Remove</button>
							</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>
		<div class="box-footer">
			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button class="btn btn-primary" (click)="onInitGeneralConfig()">Init/Re-Init Config</button>
	</ng-template>
</div>
