<ng-select #ngselect
      [placeholder]="placeholder || 'Select'"
      [bindValue]="bindValue || 'id'"
      [bindLabel]="bindLabel || 'name'"
      [readonly]="readonly"
      [clearable]="clearable"
      (change)="onChangeValue($event)"
      [loading]="loading"
      (scroll)="onScroll($event)"
      (scrollToEnd)="onScrollToEnd()"
      [(ngModel)]="_internalNgSelectValue"
      class="{{ className }}"
      [searchFn]="filterSelectedTags"
      [typeahead]="tagsInput$"
      [items]="tags$ | async"
	  [disabled]="disabled"
    >
    </ng-select>
