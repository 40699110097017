import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloDetailSaleReportComponent } from "./components/detail-sale-report.component";

const routes: Routes = [
	{
		path: "",
		component: EmloDetailSaleReportComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloDetailSaleReportRoutingModule {}
