<div class="box border-sub-1">
	<emlo-box-header [title]="'Currency'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-3">
			<div class="flex-grow-1">
				<div class="d-flex flex-row align-items-center">
					<div class="search-type">
						<ng-select class="p-0" name="restaurantId" [items]="restaurants"
							[(ngModel)]="criteria.restaurantId" (change)="onFilter()" bindLabel="name" bindValue="id"
							[placeholder]="'All restaurants'" appendTo="body">
						</ng-select>
					</div>
					<!-- attempt to comment out due to seems unneccessary -->
					<!-- <div class="keyword ml-2">
						<input type="text" class="form-control" name="keyword" [(ngModel)]="criteria.searchText"
							(keyup.enter)="onFilter()" placeholder="{{ 'Search...' | translate }}" autocomplete="off">
					</div> -->
				</div>
			</div>
		</div>
		<emlo-table
			[configuration]="configuration"
			[data]="currencys"
			[columns]="columns">
		</emlo-table>


	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #statusTpl let-row>
		<span [ngClass]="{'text-success': row.status === 'Open', 'text-danger': row.status !== 'Open'}">{{ row.status }}</span>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="addCurrency(null)">Add New</button>
	</ng-template>
</div>
