import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { OrderModule } from "./order";
import { CustomerConfigurationModule } from "./configuration";
import { EmloCouponModule } from "./coupon";
import { CustomerModule } from "./customer";
import { CustomerOverviewModule } from "./overview";

const routes: Routes = [
	{
		path: "",
		redirectTo: AppRoutesNames.CUSTOMER
	},
	{
		path: AppRoutesNames.CUSTOMER,
		loadChildren: () => CustomerModule
	},
	{
		path: AppRoutesNames.ORDER,
		loadChildren: () => OrderModule
	},
	{
		path: AppRoutesNames.COUPON,
		loadChildren: () => EmloCouponModule
	},
	{
		path: AppRoutesNames.CONFIGURATION,
		loadChildren: () => CustomerConfigurationModule
	},
	{
		path: AppRoutesNames.OVERVIEW,
		loadChildren: () => CustomerOverviewModule
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomerServiceRoutingModule {}
