import { Component, OnInit } from "@angular/core";
import { TaxService } from "../../services/tax.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { Tax, TaxFactory } from "../../model/tax";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
	IdControl,
	NameControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-tax-detail",
	templateUrl: "detail.html",
})
export class EmloTaxDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form = new FormGroup({});
	taxFormFields: FormlyFieldConfig[] = [];
	tax: Tax = TaxFactory.create();
	tabIndex: number = 0;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private credentialsService: CredentialsService,
		private taxService: TaxService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.createForm();
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					this.restaurants.map((x) => {
						x.isDefault = false;
						x.concurrencyStamp = null;
					});
				}
			});
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const taxId = params.get("id");
			this.isEdit = taxId && taxId !== null;
			if (this.isEdit) {
				this.initEditTax(taxId);
			}
		});
	}

	private createForm() {
		this.taxFormFields = [
			IdControl,
			NameControl,
			{
				type: "input",
				key: "rate",
				defaultValue: null,
				templateOptions: {
					label: "Rate",
					placeholder: "Rate",
					type: "number",
				},
			},
			{
				type: "emlo-checkbox",
				key: "isDefault",
				defaultValue: true,
				templateOptions: {
					label: "Default",
				},
			},
		];
	}

	private initEditTax(taxId: string): void {
		this.taxService.getTax(taxId).subscribe((tax: Tax) => {
			if (tax) {
				this.tax = tax;
				this.mapDataCouponRestaurant();
			}
		});
	}

	private mapDataCouponRestaurant(): void {
		this.restaurants.map((res) => {
			res.assigned = false;
			const taxRestaurant = this.tax.restaurants?.find(
				(itemRes) => itemRes.id === res.id
			);
			if (taxRestaurant) {
				res.assigned = true;
				res.concurrencyStamp = taxRestaurant.concurrencyStamp || null;
				res.isDefault = taxRestaurant.isDefault;
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateTax(data) : this.createTax(data);
	}

	private createTax(body: Tax): void {
		this.taxService.createTax(body).subscribe(() => {
			this.backToTaxList(true);
		});
	}

	private updateTax(body: Tax): void {
		this.taxService.updateTax(body).subscribe(() => {
			this.backToTaxList(true);
		});
	}

	cancel() {
		this.backToTaxList();
	}

	switchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {
		restaurant.isDefault = false;
	}

	private backToTaxList(isReload: boolean = false): void {
		this.router.navigate(["/product-management/tax"]);
	}
}
