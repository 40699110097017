import {
	Component,
	Inject,
	OnInit,
	ChangeDetectionStrategy
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
	selector: "app-errordialog",
	styleUrls: ["./style.scss"],
	templateUrl: "errordialog.html"
})
export class CoreErrorDialogComponent implements OnInit {
	messages: string[] = [];
	constructor(
		public dialogRef: MatDialogRef<CoreErrorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: HttpErrorResponse
	) {
		const msg =
			"Your session has expired. Please re-login to renew your session.";
		this.messages.push(msg);
	}

	ngOnInit(): void {}

	enterOk() {
		this.dialogRef.close(true);
	}
}
