import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GeneralConfigComponent } from "./general-config.component";
import { GeneralConfigListComponent } from "./components/list/general-config.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { GeneralConfigDetailComponent } from "./components/detail/general-config.detail.component";

const routes: Routes = [
	{
		path: "",
		component: GeneralConfigComponent,
		children: [
			{
				path: "",
				component: GeneralConfigDetailComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: GeneralConfigListComponent,
			},
			{ path: "add", component: GeneralConfigDetailComponent },
			{ path: "edit", component: GeneralConfigDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GeneralConfigRoutingModule {}
