import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import {
	Customer,
	CustomerGift,
	CustomerGiftSearchModel,
} from "../../model/customer";
import { CustomerService } from "../../services/customer.service";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { debounceTime } from "rxjs/operators";
import { UpdateGiftCustomerComponent } from "../update-gift/update-gift.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
	selector: "app-customer-gift",
	templateUrl: "./gift.component.html",
})
export class GiftCustomerComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy, OnChanges
{
	@ViewChild("validFromTpl", { static: true }) validFromTpl: TemplateRef<any>;
	@ViewChild("validToTpl", { static: true }) validToTpl: TemplateRef<any>;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("isDOBGiftTpl", { static: true }) isDOBGiftTpl: TemplateRef<any>;
	@ViewChild("isByLevelUpTpl", { static: true })
	isByLevelUpTpl: TemplateRef<any>;
	@ViewChild("isPermanentTpl", { static: true })
	isPermanentTpl: TemplateRef<any>;
	@ViewChild("isClaimedTpl", { static: true }) isClaimedTpl: TemplateRef<any>;
	@Input() customer: Customer = new Customer();
	@Input() tabIndex: number = 0;
	public configuration: Config;
	isLoading: boolean = false;
	IconName = IconName;
	criteriaGift: CustomerGiftSearchModel = new CustomerGiftSearchModel();
	customerGifts: CustomerGift[] = [];
	public pagination = PaginationModelFactory.create();
	public columns: Columns[] = [];

	constructor(
		private customerService: CustomerService,
		private dialog: MatDialog) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.getCustomerGifts();
	}

	ngOnInit(): void {
		this.configCustomerGiftTable();
	}

	private configCustomerGiftTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "giftName", title: "Name", orderEnabled: false },
			{
				key: "isPermanent",
				title: "Is permanent",
				cssClass: {
					name: "text-nowrap text-center",
					includeHeader: true,
				},
				orderEnabled: false,
				cellTemplate: this.isPermanentTpl,
			},
			{
				key: "isDOBGift",
				title: "Is DOB Gift",
				cssClass: {
					name: "text-nowrap text-center",
					includeHeader: true,
				},
				orderEnabled: false,
				cellTemplate: this.isDOBGiftTpl,
			},
			{
				key: "isByLevelUp",
				title: "Added by Level Up",
				cssClass: {
					name: "text-nowrap text-center",
					includeHeader: true,
				},
				orderEnabled: false,
				cellTemplate: this.isByLevelUpTpl,
			},
			{
				key: "isClaimed",
				title: "Is claimed",
				cssClass: {
					name: "text-nowrap text-center",
					includeHeader: true,
				},
				orderEnabled: false,
				cellTemplate: this.isClaimedTpl,
			},
			{
				key: "validFrom",
				title: "Valid from",
				cssClass: { name: "text-nowrap", includeHeader: true },
				orderEnabled: false,
				cellTemplate: this.validFromTpl,
			},
			{
				key: "validTo",
				title: "Valid to",
				cssClass: { name: "text-nowrap", includeHeader: true },
				orderEnabled: false,
				cellTemplate: this.validToTpl,
			},
			{ key: "notes", title: "Notes", orderEnabled: false },
			{
				key: "action",
				title: "",
				orderEnabled: false,
				cellTemplate: this.actionTpl,
			},
			// { key: "type", title: "Gift Type", orderEnabled: false },
			// { key: "restaurantName", title: "At branch", orderEnabled: false },
			// { key: "date", title: "Expiry Date", orderEnabled: false },
		];
	}

	onFilter() {
		this.getCustomerGifts();
	}

	updateGiftFromCustomer(customerGift: CustomerGift) {
		this.dialog
			.open(UpdateGiftCustomerComponent, {
				data: customerGift,
				minWidth: 360,
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.getCustomerGifts();
				}
			});
	}

	removeGiftFromCustomer(customerGift: CustomerGift) {
		const _dataRequest = {
			id: customerGift.customerGiftId,
			customerId: this.customer.loyaltyCode,
			notes: null,
		};
		this.customerService
			.removeGiftFromCustomer(_dataRequest)
			.subscribe((response) => {
				this.getCustomerGifts();
			});
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteriaGift = {
				...this.criteriaGift,
				...$event.value,
			};
			this.getCustomerGifts();
		}
	}

	private getCustomerGifts() {
		this.customerService
			.getCustomerGifts(this.criteriaGift, this.customer.customerCode)
			.pipe(debounceTime(500))
			.subscribe((response) => {
				if (response) {
					this.customerGifts = response.data;
					console.log(this.customerGifts);
					this.pagination = response;
				}
			});
	}

	ngOnDestroy(): void {}
}
