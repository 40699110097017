<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Edit Category' : 'Add Category'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content">
			<mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="swittchTab($event)">
				<mat-tab label="{{ 'General' | translate }}">
					<div class="box-content item-content" *ngIf="category">
						<formly-form [model]="category" [fields]="categoryFormFields" [form]="form"></formly-form>
					</div>
				</mat-tab>
				<mat-tab label="{{ 'Assign Restaurant ' | translate }}">
					<div class="box-content item-content">
						<fieldset *ngFor="let restaurant of restaurants; let index = index;"
							[ngClass]="{'un-assign': !restaurant.assigned}">
							<legend>
								<input type="checkbox" [(ngModel)]="restaurant.assigned"
									[ngModelOptions]="{standalone: true}" class="form-check-input"
									[id]="'restaurant_name_' + index" (ngModelChange)="toggleAssignRestaurant(restaurant)">
								<label [for]="'restaurant_name_' + index">{{ restaurant.name}}</label>
							</legend>
							<input type="hidden" [(ngModel)]="restaurant.concurrencyStamp"
							[ngModelOptions]="{standalone: true}" class="form-control">
							<div class="form-group form-check">
								<input type="checkbox" [(ngModel)]="restaurant.isVisible"
									[ngModelOptions]="{standalone: true}" [disabled]="!restaurant.assigned"
									class="form-check-input" [id]="'visible_' + index">
								<label class="form-check-label" [for]="'visible_' + index">
									{{ 'Visible' | translate }}
								</label>
							</div>
						</fieldset>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>

			<button *ngIf="tabIndex === 0" type="button" class="btn btn-primary mr-2" (click)="tabIndex = 1"
				translate>Assign Restaurant</button>

			<emlo-button *ngIf="tabIndex === 1" type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'" translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
