import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { CommonComponent } from "@apps/core/common/common-component";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, Event } from "@apps/shared/table";
import {
	ModifierGroup,
	ModifierGroupFactory,
	ModifierGroupSearchModel,
} from "../../model/modifier-group";
import { ModifierGroupService } from "../../services/modifier-group.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import {
	PaginationModelFactory,
	IApiPagingResponseModel,
} from "@apps/common/model/api.reponse.model";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";

@Component({
	selector: "app-modifier-group-list",
	templateUrl: "./list.html",
})
export class EmloModifierGroupListComponent extends AdminBaseComponent implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public columns: Columns[] = [];
	public modifierGroups: ModifierGroup[] = [];
	public configuration: Config;
	public pagination = PaginationModelFactory.create();
	criteria: ModifierGroupSearchModel = new ModifierGroupSearchModel();
	private readonly All = "All";
	private readonly Visible = "Visible";
	private readonly InVisible = "Invisible";
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];
	restaurants: IRestaurantItemModel[] = [];

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private credentialsService: CredentialsService,
		private modifierGroupService: ModifierGroupService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.configColumnForModifierGroupable();
		this.getModifierGroups();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getModifierGroups();
		}
	}

	private getModifierGroups() {
		this.modifierGroupService
			.getModifierGroups(this.criteria)
			.subscribe(
				(modifierGroups: IApiPagingResponseModel<ModifierGroup[]>) => {
					if (modifierGroups) {
						this.modifierGroups = modifierGroups.data;
						this.pagination = modifierGroups;
					}
				}
			);
	}

	// private getModifierGroupSelections() {
	// 	this.modifierGroupService
	// 		.getModifierGroupSelections(this.adminShareDataService.restaurantActive?.id)
	// 		.subscribe((modifierGroups: IModifierGroupModel[]) => {
	// 			if (modifierGroups && modifierGroups.length > 0) {
	// 				this.adminShareDataService.modifierGroups = modifierGroups;
	// 			}
	// 		});
	// }

	private configColumnForModifierGroupable(): void {
		this.columns = [
			{ key: "nameCn", title: "Name CN" },
			{ key: "nameEn", title: "Name EN" },
			{ key: "nameVi", title: "Name VI" },
			{ key: "parentMenuModifierGroupName", title: "Parent Group" },
			{ key: "restaurantNames", title: "Selling at" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteModifierGroup(modifierGroup: ModifierGroup): void {
		const data = { id: modifierGroup.id };
		this.modifierGroupService
			.deleteModifierGroup(JSON.stringify(data))
			.subscribe(() => {
				this.getModifierGroups();
			});
	}

	delete(modifierGroup: ModifierGroup): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteModifierGroup(modifierGroup);
				}
			});
	}

	openModifierGroupDetail(modifierGroup: ModifierGroup): void {
		if (!modifierGroup) {
			modifierGroup = ModifierGroupFactory.create();
			this.router.navigate(["/product-management/modifier-group/add"]);
		} else {
			this.router.navigate(["/product-management/modifier-group/edit"], {
				queryParams: { id: modifierGroup.id },
			});
		}

		// this.dialog
		// 	.open(EmloModifierGroupDetailComponent, {
		// 		data: modifierGroup,
		// 	})
		// 	.afterClosed()
		// 	.subscribe((isSaved) => {
		// 		if (isSaved) {
		// 			this.dataSource$.next(this.request);
		// 		}
		// 	});
	}

	onFilter() {
		this.criteria.pageNum = 1;
		this.getModifierGroups();
	}

	ngOnDestroy() {}
}
