import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { Campaigns } from "../model/campaigns";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable, of } from "rxjs";
import { CAMPAIGNS } from "@apps/modules/emlo-admin/shared/mock-data/campaigns";

@Injectable()
export class CampaignsService {
	constructor(private apiService: ApiService) {}

	getCampaignsById(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "campaigns/detail",
			params
		);
	}

	createCampaigns(context: Campaigns) {
		return this.apiService.post(
			environment.prefix_api_url + "campaigns/create",
			context
		);
	}

	updateCampaigns(context: Campaigns) {
		return this.apiService.post(
			environment.prefix_api_url + "campaigns/update",
			context
		);
	}

	deleteCampaigns(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "campaigns/delete",
			context
		);
	}

	getCampaigns(): Observable<IApiPagingResponseModel<Campaigns[]>> {
		return of({
			data: CAMPAIGNS,
			pageSize: 2,
			totalItems: 2,
			pageNum: 1,
			totalPages: 1,
		});
		// const params = new HttpParams({ fromObject: request as any });
		// return this.apiService.get(
		// 	environment.prefix_api_url + "account/users",
		// 	params
		// );
	}
}
