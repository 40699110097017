import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared";
import { EmloDashboardRoutingModule } from "./dashboard-routing.module";
import { EmloDashboardComponent } from "./component/dashboard.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		MatIconModule,
		EmloDashboardRoutingModule
	],
	declarations: [EmloDashboardComponent]
})
export class EmloDashboardModule {}
