import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatDialogRef
} from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloRestaurantUserTypeRoutingModule } from "./restaurant-usertypes.routes";
import { EmloRestaurantUserTypeComponent } from "./restaurant-usertypes.component";
import { EmloRestaurantUserTypeListComponent } from "./components/list/restaurant-usertypes.list.component";
import { EmloRestaurantUserTypeDetailComponent } from "./components/detail/restaurant-usertypes.detail.component";
import { RestautantUserTypeService } from "./services/restaurant-usertypes.service";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { UserTypeService } from "../usertypes/services/usertype.service";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
	declarations: [
		EmloRestaurantUserTypeComponent,
		EmloRestaurantUserTypeListComponent,
		EmloRestaurantUserTypeDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatDialogModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		MatTabsModule,
		FormlyModule.forRoot(),
		FormlyBootstrapModule,
		EmloRestaurantUserTypeRoutingModule
	],
	providers: [
		RestautantUserTypeService,
		UserTypeService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloRestaurantUserTypeDetailComponent]
})
export class EmloRestaurantUserTypeModule {}
