/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:21:37
 * @modify date 2020-02-11 00:21:37
 * @desc [Wrapper module]
 */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { ShellComponent } from "./components/shell.component";
import { HeaderComponent } from "./header/header.component";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";

import { ShareDataService } from "@apps/core/services/data.service";
import { ShellService } from "./services/shell.service";
import { ClickOutsideModule } from "@apps/shared/clickoutside";
import { EmloButtonModule } from "@apps/shared/button";
import { RestaurantService } from "./services/restaurant.service";
import { AdminShareDataService } from "../shared/services/share.data";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloWarningModule } from "@apps/shared/warning/warning.module";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		RouterModule,
		MatIconModule,
		MatSidenavModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		MatButtonToggleModule,
		EmloButtonModule,
		EmloConfirmModule,
		EmloWarningModule,
		MatExpansionModule,
		ClickOutsideModule
	],
	declarations: [ShellComponent, HeaderComponent],
	providers: [
		ShareDataService,
		ShellService,
		RestaurantService,
		AdminShareDataService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
	]
})
export class ShellModule {}
