import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { debounceTime, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { Order, OrderSearchModel } from "../../model/order";
import { OrderService } from "../../services/order.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import {
	NgbCalendar,
	NgbDate,
	NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-order-list",
	templateUrl: "./list.html",
	styleUrls: ["./style.scss"],
})
export class OrderListComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	isLoading: boolean = false;
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("createdAtFromTpl", { static: true })
	createdAtFromTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public orders: Order[] = [];
	public pagination = PaginationModelFactory.create();
	criteria: OrderSearchModel = new OrderSearchModel();
	restaurants: IRestaurantItemModel[] = [];

	// Range Date for filter order
	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (
			this.fromDate &&
			!this.toDate &&
			date &&
			date.after(this.fromDate)
		) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
		if (this.fromDate && this.toDate) {
			this.getOrders();
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			date.after(this.fromDate) &&
			date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return (
			this.toDate && date.after(this.fromDate) && date.before(this.toDate)
		);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed))
			? NgbDate.from(parsed)
			: currentValue;
	}

	constructor(
		private router: Router,
		private orderService: OrderService,
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private credentialsService: CredentialsService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", 0);
		this.toDate = this.fromDate;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					if (this.criteria && !this.criteria.restaurantId && this.restaurants[0]) {
						this.criteria.restaurantId = this.restaurants[0].id;
					}
				}
			});
		this.configOrderTable();
		this.getOrders();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria = { ...this.criteria, ...$event.value };
			this.getOrders();
		}
	}

	private getOrders() {
		this.isLoading = true;
		if (this.fromDate && this.toDate) {
			const fromDate = new Date(
				this.fromDate.year,
				this.fromDate.month - 1,
				this.fromDate.day
			);
			const toDate = new Date(
				this.toDate.year,
				this.toDate.month - 1,
				this.toDate.day
			);
			this.criteria.fromDate = fromDate;
			this.criteria.toDate = toDate;
		}
		if (this.restaurants && this.restaurants[0] && !this.criteria.restaurantId) {
			this.criteria.restaurantId = this.restaurants[0].id;
		}
		this.orderService
			.getOrders(this.criteria)
			.pipe(debounceTime(200))
			.pipe(
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe((response) => {
				if (response) {
					this.orders = response.data;
					this.pagination = response;
				}
			});
	}

	private configOrderTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "checkId", title: "Check #", orderEnabled: false },
			{ key: "referenceId", title: "Ref #", orderEnabled: false },
			{ key: "restaurantName", title: "Selling at", orderEnabled: false },
			{
				key: "customerName",
				title: "Customer Name",
				orderEnabled: false,
			},
			{ key: "serverName", title: "Server Name", orderEnabled: false },
			{
				key: "numberOfGuests",
				title: "Guests",
				orderEnabled: false,
				cssClass: { name: "text-right", includeHeader: true },
			},
			{
				key: "totalPrice",
				title: "Total Price",
				orderEnabled: false,
				cssClass: { name: "text-right", includeHeader: true },
			},
			{
				key: "createdAt",
				title: "Created At",
				orderEnabled: false,
				cellTemplate: this.createdAtFromTpl,
				cssClass: { name: "text-right", includeHeader: true },
			},
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	openOrderDetail(order: Order): void {
		if (!order) {
			this.router.navigate(["/customer-service/order/add"]);
		} else {
			this.router.navigate(["/customer-service/order/edit"], {
				queryParams: { id: order.id },
			});
		}
	}

	onFilter() {
		this.getOrders();
	}

	ngOnDestroy() {}
}
