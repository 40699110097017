import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
@Component({
	selector: "formly-field-input",
	template: `
		<div class="form-group">
			<label class="vertical-center"><input type="checkbox" class="mr-2" [formControl]="formControl">{{ field.templateOptions.label }}</label>
		</div>
	`
})
export class EmloCheckboxComponent extends FieldType {}
