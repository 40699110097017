/**
 * @author Nam Nguyen
 * @email nhn12.hoangnam@gmail.com
 * @create date 2020-04-03 13:52:33
 * @modify date 2020-04-03 13:52:33
 * @desc Logical Condition Builder
 */

import { DefaultCondition } from "./default-condition";
import { AbstractCondition } from "./abstract-condition";
import { ConditionOperator } from "../model/condition-operator.enum";

export class LogicalCondition implements AbstractCondition {
	constructor(
		public operator: ConditionOperator,
		public conditions: DefaultCondition[]
	) {}

	public printPretty() {
		if (!this.conditions) return [];
		const results = [];
		this.conditions.forEach(element => {
			const temp = element.printPretty();
			if (Array.isArray(temp)) {
				results.push(...temp);
			} else {
				results.push(temp);
			}
		});
		return {
			statement: this.operator,
			filters: results
		};
	}
}
