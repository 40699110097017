import { Component, OnInit } from "@angular/core";
import { CurrencyService } from "../../services/currency.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { Currency, CurrencyFactory, CurrencyRestaurant } from "../../model/currency";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IconName } from "@apps/common/constants/icon";
import { Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";

@Component({
	selector: "app-currency-detail",
	templateUrl: "detail.html",
})
export class EmloCurrencyDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form = new FormGroup({});
	currencyFormFields: FormlyFieldConfig[] = [];
	public currencyRestaurants: CurrencyRestaurant[] = [];
	currency: Currency = CurrencyFactory.create();
	tabIndex: number = 0;
	constructor(
		private router: Router,
		private credentialsService: CredentialsService,
		private currencyService: CurrencyService,
		private restaurantService: RestaurantService,
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;

		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.currencyRestaurants = restaurants;
					this.currencyRestaurants.map((x) => {
						x.concurrencyStamp = null;
					});
				}
			});

		this.isEdit = this.currency && this.currency.id !== null;
		this.createForm();
	}

	private createForm() {
		this.currencyFormFields = [
			{
				type: "input",
				key: "rate",
				defaultValue: null,
				templateOptions: {
					label: "Rate",
					placeholder: "Rate",
					type: "number",
				},
			},
		];
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const assignRestaurants = this.currencyRestaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateCurrency(data) : this.createCurrency(data);
	}

	private createCurrency(body: Currency): void {
		this.currencyService.createCurrency(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateCurrency(body: Currency): void {
		// Not support V1
	}

	cancel() {
		this.resetFormAndClose();
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.router.navigate(["/product-management/currency"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {}
}
