<fieldset *ngFor="let restaurant of modifierRestaurants; let index = index;"
	[ngClass]="{'un-assign': !restaurant.assigned}">
	<legend>
		<input type="checkbox" [(ngModel)]="restaurant.assigned" [ngModelOptions]="{standalone: true}"
			class="form-check-input" [id]="'restaurant_name_modifier_' + index"
			(ngModelChange)="toggleAssignRestaurant(restaurant)">
		<label [for]="'restaurant_name_modifier_' + index">{{ restaurant.name}}</label>
	</legend>
	<div class="mb-2">
		<ng-select bindLabel="name" bindValue="id" [clearable]="true" [(ngModel)]="restaurant.menuModifierGroupId"
			[placeholder]="'No Modifier Group Assign'" appendTo="body" [disabled]="!restaurant.assigned">
			<ng-option *ngFor="let modifierGroup of restaurant.modifierGroups" [value]="modifierGroup.id">
				{{modifierGroup.name}}
			</ng-option>
		</ng-select>
	</div>
</fieldset>
