import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class ItemModifierGroupService {
	constructor(private apiService: ApiService) {}

	getModifierGroupSelections(restaurantId: string) {
		const request = new HttpParams({fromObject: {restaurantId: restaurantId}})
		return this.apiService.get(environment.prefix_api_url + "modifiergroup/selectionlist", request);
	}

}
