import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloFilterBuilderModule } from "@apps/shared/filter-builder/filter-builder.module";
import { EmloFormModule } from "@apps/shared/form";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ColorPickerModule } from "ngx-color-picker";

import { EmloModifierGroupDetailComponent } from "./components/detail/modifier-group.detail.component";
import { EmloModifierGroupListComponent } from "./components/list/modifier-group.list.component";
import { EmloModifierGroupComponent } from "./modifier-group.component";
import { EmloModifierGroupRoutingModule } from "./modifier-group.routes";
import { ModifierGroupService } from "./services/modifier-group.service";

@NgModule({
	declarations: [
		EmloModifierGroupComponent,
		EmloModifierGroupListComponent,
		EmloModifierGroupDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ColorPickerModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatTabsModule,
		MatDialogModule,
		MatCheckboxModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		EmloFilterBuilderModule,
		EmloFormModule,
		FormlyBootstrapModule,
		EmloModifierGroupRoutingModule
	],
	providers: [
		ModifierGroupService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloModifierGroupDetailComponent]
})
export class EmloModifierGroupModule {}
