import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class TotalSaleReportService {
	constructor(private apiService: ApiService) {}

	getTotalSaleReport(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "totalreport/summaryreport",
			context
		);
	}

	generateTotalSaleReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "totalreport/summaryfile",
			context
		);
	}
}
