<div class="keyword">
	<!-- <input
		type="text"
		class="form-control mb-2"
		name="keyword"
		[(ngModel)]="criteriaCustomerTransaction.searchText"
		(keyup.enter)="onFilter()"
		placeholder="{{ 'Search...' | translate }}"
		autocomplete="off"
		[ngModelOptions]="{ standalone: true }"
	/> -->
</div>
<app-loader [isLoading]="isLoading"></app-loader>
<emlo-table
	[configuration]="configuration"
	[data]="customerTransactions"
	[columns]="columns"
	[pagination]="pagination"
	(event)="onTableEvent($event)"
>
</emlo-table>
