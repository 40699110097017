import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@apps/core/services";
import { LoginContext } from "../model/login.model";

@Injectable()
export class LoginService {
	constructor(private apiService: ApiService) {}

	login(context: LoginContext): Observable<any> {
		return this.apiService.post("/api/auth/login", context);
	}
}
