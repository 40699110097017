import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { CustomerConfigurationRoutingModule } from "./configuration.routes";
import { CustomerConfigurationComponent } from "./configuration.component";
import { CustomerConfigurationDetailComponent } from "./components/configuration.detail.component";
import { CommonModule } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
	declarations: [CustomerConfigurationComponent, CustomerConfigurationDetailComponent],
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		TranslateModule,
		MatTabsModule,
		FormlyBootstrapModule,
		CustomerConfigurationRoutingModule,
	],
	providers: [],
	entryComponents: [CustomerConfigurationDetailComponent],
})
export class CustomerConfigurationModule {}
