import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloModifierGroupComponent } from "./modifier-group.component";
import { EmloModifierGroupListComponent } from "./components/list/modifier-group.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloModifierGroupDetailComponent } from "./components/detail/modifier-group.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloModifierGroupComponent,
		children: [
			{
				path: "",
				component: EmloModifierGroupListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloModifierGroupListComponent,
			},
			{ path: "add", component: EmloModifierGroupDetailComponent },
			{ path: "edit", component: EmloModifierGroupDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloModifierGroupRoutingModule {}
