import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { IconName } from "@apps/common/constants/icon";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
	Input,
	Component,
	OnInit,
	OnChanges,
	SimpleChanges,
	AfterViewInit,
	OnDestroy,
	Output,
	EventEmitter,
	forwardRef
} from "@angular/core";
import { IFilterMetadataItemModel } from "@apps/shared/filter-builder/model/filter.metadata.model";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { LogicalCondition } from "@apps/shared/filter-builder/services/logic-condition";
import { DefaultConditionFactory } from "@apps/shared/filter-builder/services/default-condition";

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => FilterContainerComponent),
	multi: true
};

@Component({
	templateUrl: "filter-container.component.html",
	styleUrls: ["filter-container.component.scss"],
	selector: "emlo-filter-builder",
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FilterContainerComponent
	implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Input() meta: IFilterMetadataItemModel[];
	@Input() isLoading = false;
	@Output("submit") submit = new EventEmitter();
	@Output("clear") clear = new EventEmitter();

	IconName = IconName;

	// Internal field
	conditionFields: FormlyFieldConfig[] = [];
	form = new FormGroup({});
	model: any = {};
	statement = ConditionOperator.AND;
	private _value: LogicalCondition = null;
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	constructor() {}

	ngOnInit() {
		this.conditionFields = [
			{
				key: "filter",
				type: "filter-control",
				templateOptions: {
					required: true,
					conditionMeta: this.meta
				},
				defaultValue: this._value
			}
		];

		this._value = this.value;
	}

	ngAfterViewInit() {
		this.form.controls["filter"].valueChanges.subscribe(value => {
			this.packingOutput();
		});
	}

	ngOnChanges(changes: SimpleChanges) {}

	onChangeStatement() {
		this.packingOutput();
	}

	onResetFilter() {
		this._value = new LogicalCondition(ConditionOperator.AND, [
			DefaultConditionFactory.empty()
		]);
		this.extractInput();
		this.clear.next(new LogicalCondition(ConditionOperator.AND, []));
	}

	get value(): any {
		return this._value;
	}

	set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this.onChangeCallback(v);
		}
	}

	writeValue(value: any) {
		if (value !== this._value) {
			this._value = value;
			this.extractInput();
		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	onChangeInternalValue($event) {
		this.value = this._value;
		this.onChangeCallback(this.value);
	}

	onSubmit($event) {
		this.packingOutput();
		this.submit.emit(this._value);
	}

	private packingOutput() {
		this._value = new LogicalCondition(
			this.statement,
			this.form.controls["filter"].value
		);
		this.onChangeInternalValue(null);
	}

	private extractInput() {
		if (this._value && this._value.conditions && this.form) {
			this.statement = this._value.operator;
			this.form.controls["filter"].setValue(this._value.conditions);
		}
	}

	ngOnDestroy() {}
}
