import { CommonConstant } from '../constants/common.constant';

// tslint:disable-next-line: no-empty-interface
export interface IResponseModel {}

export interface IPagingnationModel {
	totalItems: number;
	totalPages: number;
	pageSize: number;
	pageNum: number;
}

export interface IApiPagingResponseModel<T> extends IResponseModel, IPagingnationModel {
	data: T;
}

export class PaginationModelFactory {
	static create(): IPagingnationModel {
		return {
			totalItems: 0,
			totalPages: 0,
			pageSize: CommonConstant.NUMBER_RECORD_PER_PAGE,
			pageNum: 0,
		}
	}

	static createWithIndex(page: number, limit: number) {
		return {
			pageSize: limit,
			pageNum: page,
		}
	}
}
