import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RewardPointComponent } from "./reward-point.component";
import { RewardPointListComponent } from "./components/list/reward-point.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { RewardPointDetailComponent } from "./components/detail/reward-point.detail.component";

const routes: Routes = [
	{
		path: "",
		component: RewardPointComponent,
		children: [
			{
				path: "",
				component: RewardPointListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: RewardPointListComponent,
			},
			{ path: "add", component: RewardPointDetailComponent },
			{ path: "edit", component: RewardPointDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RewardPointRoutingModule {}
