/**
 * @author Nam Nguyen
 * @email nhn12.hoangnam@gmail.com
 * @create date 2020-04-03 13:43:11
 * @modify date 2020-04-03 13:43:11
 * @desc Condition operator
 */


export enum ConditionOperator {
	// Unit condition
	CONTAIN = "Contains",
	EQUAL = "Equals",
	GREATER_OR_EQUAL = "Greater Than Or Equal",
	LESS_OR_EQUAL = "Less Than Or Equal",
	BETWEEN = "Between",
	LESS_THAN = "Less Than",
	GREAT_THAN = "Greater Than",
	// Logic condition
	AND = "and",
	OR = "or",
	XOR = "xor",
	NOT = "not"
}
