import {
	Component,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { ModifierService } from "../../services/modifier.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { Modifier, ModifierFactory } from "../../model/modifier";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
	IdControl,
	NameEnControl,
	NameViControl,
	NameCnControl,
	ButtonTextColorControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { TaxService } from "../../../item/services/tax.service";
import { ModifierGroupService } from "../../../modifier-group/services/modifier-group.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-modifier-detail",
	templateUrl: "detail.html",
})
export class EmloModifierDetailComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	form = new FormGroup({});
	modifierFormFields: FormlyFieldConfig[] = [];
	restaurant$: Subject<string> = new Subject();
	taxes$: Subject<any[]> = new Subject();
	modifierGroup$: Subject<any[]> = new Subject();
	tabIndex: number = 0;
	modifier: Modifier = ModifierFactory.create();
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private modifierService: ModifierService,
		private taxService: TaxService,
		private modifierGroupService: ModifierGroupService,
		private restaurantService: RestaurantService,
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.createForm();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const modifierId = params.get("id");
			this.isEdit = modifierId && modifierId !== null;
			this.restaurantService
				.getRestaurants()
				.subscribe((restaurants: IRestaurantItemModel[]) => {
					if (restaurants && restaurants.length > 0) {
						this.restaurants = restaurants;
						this.restaurants.map((x) => {
							x.concurrencyStamp = null;
							x.parentMenuModifierGroupId = null;
							x.taxId = null;
							x.menuModifierGroupId = null;
						});
					}
					if (this.isEdit) {
						this.initEditModifier(modifierId);
					}
				});
		});

		// this.initRefDataSource();
		// if (this.isEdit) {
		// 	this.initEditModifier();
		// }
	}

	ngOnDestroy() {}

	private createForm() {
		this.modifierFormFields = [
			// {
			// 	key: "restaurantIds",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		bindLabel: "name",
			// 		multiple: true,
			// 		bindValue: "id",
			// 		options: this.adminShareDataService.restaurants,
			// 		change: (field, $event) => {
			// 			// field.form.controls["menuModifierGroupId"].setValue(null);
			// 			// field.form.controls["taxId"].setValue(null);
			// 			// this.restaurant$.next(field.model?.restaurantId);
			// 		},
			// 	},
			// 	defaultValue: [this.adminShareDataService.restaurantActive?.id],
			// },
			IdControl,
			NameEnControl,
			NameViControl,
			NameCnControl,
			// {
			// 	key: "menuModifierGroupId",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Modifier Group",
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		options: this.modifierGroup$,
			// 	},
			// },
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "price",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Price",
							placeholder: "Price",
							type: "number",
						},
					},
					{
						type: "input",
						key: "extraPrice",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Extra Price",
							placeholder: "Extra Price",
							type: "number",
						},
					},
					// {
					// 	type: "emlo-select",
					// 	key: "taxId",
					// 	defaultValue: null,
					// 	className: "col-6",
					// 	templateOptions: {
					// 		label: "Tax",
					// 		options: this.taxes$,
					// 	},
					// },
					{
						type: "input",
						key: "sortOrder",
						className: "col-6",
						defaultValue: null,
						templateOptions: {
							label: "Sort Order",
							placeholder: "Sort Order",
							type: "number",
						},
					},
				],
			},
			// {
			// 	type: "emlo-checkbox",
			// 	key: "isPrintToKitchen",
			// 	defaultValue: true,
			// 	templateOptions: {
			// 		label: "Print to kitchen",
			// 	},
			// },
			ButtonTextColorControl,
			{
				type: "emlo-input",
				key: "concurrencyStamp",
				templateOptions: {
					type: "hidden",
				},
			},
		];
	}

	// private initRefDataSource() {
	// 	this.restaurant$
	// 		.pipe(untilDestroyed(this))
	// 		.pipe(
	// 			catchError((x) => {
	// 				this.initRefDataSource();
	// 				// tslint:disable-next-line: deprecation
	// 				throw x;
	// 			})
	// 		)
	// 		.subscribe((x) => {
	// 			this.modifierService
	// 				.getModifierGroupSelection(x)
	// 				.subscribe((data) => {
	// 					if (data) {
	// 						this.modifierGroup$.next(data);
	// 					}
	// 				});
	// 			this.modifierService.getTaxs(x).subscribe((data) => {
	// 				this.taxes$.next(data?.data);
	// 			});
	// 		});
	// 	this.restaurant$.next(this.adminShareDataService.restaurantActive?.id);
	// }

	private initEditModifier(modifierId: string): void {
		this.modifierService
			.getModifier(modifierId)
			.subscribe((modifier: Modifier) => {
				this.modifier = modifier;
				this.modifier.menuModifierGroupId =
					modifier.menuModifierGroup && modifier.menuModifierGroup.id
						? modifier.menuModifierGroup.id
						: null;
				this.modifier.taxId =
					modifier.tax && modifier.tax.id ? modifier.tax.id : null;
				this.mapDataModifierRestaurant();
			});
	}

	private mapDataModifierRestaurant(): void {
		this.restaurants.map((res) => {
			res.assigned = false;
			const modifierRestaurant = this.modifier.restaurants?.find(
				(itemRes) => itemRes.id === res.id
			);
			if (modifierRestaurant) {
				res.assigned = true;
				res.isVisible = modifierRestaurant.isVisible;
				res.isPrintToKitchen = modifierRestaurant.isPrintToKitchen;
				res.price = modifierRestaurant.price;
				res.sortOrder = modifierRestaurant.sortOrder;
				res.taxId = modifierRestaurant.taxId;
				res.parentMenuModifierGroupId = modifierRestaurant.parentMenuModifierGroupId;
				res.menuModifierGroupId = modifierRestaurant.menuModifierGroupId;
				res.extraPrice = modifierRestaurant.extraPrice;
				res.concurrencyStamp = modifierRestaurant.concurrencyStamp || null;
				this.getTaxsByRestaurantId(res);
				this.getModifierGroupsByRestaurantId(res);
			}
		});
	}

	private getTaxsByRestaurantId(itemRestaurant: IRestaurantItemModel) {
		this.taxService.getTaxs(itemRestaurant.id).subscribe((data) => {
			if (data) {
				itemRestaurant.taxs = data;
			}
		});
	}

	private getModifierGroupsByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		this.modifierGroupService.getModifierGroupSelections(itemRestaurant.id).subscribe((data) => {
			if (data) {
				itemRestaurant.modifierGroups = data;
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateModifier(data) : this.createModifier(data);
	}

	private createModifier(body: Modifier): void {
		this.modifierService.createModifier(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateModifier(body: Modifier): void {
		this.modifierService.updateModifier(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	cancel(): void {
		this.resetFormAndClose();
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {
		if (!restaurant.price) {
			restaurant.price = this.modifier.price;
		}
		if (!restaurant.extraPrice) {
			restaurant.extraPrice = this.modifier.extraPrice;
		}
		if (!restaurant.sortOrder) {
			restaurant.sortOrder = this.modifier.sortOrder;
		}
		if (!restaurant.taxs) {
			this.getTaxsByRestaurantId(restaurant);
		}
		if (!restaurant.modifierGroups) {
			this.getModifierGroupsByRestaurantId(restaurant);
		}
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.router.navigate(["/product-management/modifier"]);
	}
}
