import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
	selector: "emlo-loading",
	styleUrls: ["./style.scss"],
	templateUrl: "loading.html"
})
export class CoreLoadingComponent implements OnInit {

	constructor() {}

	ngOnInit(): void {}
}
