import { Component, OnInit } from "@angular/core";
import { CouponService } from "../../services/coupon.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { Coupon, CouponFactory } from "../../model/coupon";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
	IdControl,
	NameControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { DateUtils } from "@apps/core/utils/date";
import { ActivatedRoute, Router } from "@angular/router";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-coupon-detail",
	templateUrl: "detail.html",
})
export class EmloCouponDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form = new FormGroup({});
	couponFormFields: FormlyFieldConfig[] = [];

	tabIndex: number = 0;
	coupon: Coupon = CouponFactory.create();
	public restaurants: IRestaurantItemModel[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private couponService: CouponService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.createForm();
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					this.restaurants.map((x) => {
						x.isVisible = false;
						x.concurrencyStamp = null;
					});
				}
			});

		this.activatedRoute.queryParamMap.subscribe((params) => {
			const couponId = params.get("id");
			this.isEdit = couponId && couponId !== null;
			if (this.isEdit) {
				this.initEditCoupon(couponId);
			}
		});
	}

	private createForm() {
		this.couponFormFields = [
			// {
			// 	key: "restaurantId",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		options: this.adminShareDataService.restaurants
			// 	}
			// },
			IdControl,
			NameControl,
			{
				type: "input",
				key: "value",
				defaultValue: null,
				templateOptions: {
					label: "Coupon Value",
					placeholder: "Coupon Value",
					type: "number",
				},
			},
			{
				key: "expiryDate",
				type: "emlo-datetime",
				templateOptions: {
					required: false,
					type: "text",
					label: "Expiry Date",
				},
			},
			{
				type: "emlo-checkbox",
				key: "isDisabled",
				defaultValue: true,
				templateOptions: {
					label: "Disabled",
				},
			},
			{
				type: "emlo-checkbox",
				key: "isNeverExpire",
				defaultValue: true,
				templateOptions: {
					label: "Never Expire",
				},
			},
		];
	}

	private initEditCoupon(couponId: string): void {
		this.couponService.getCoupon(couponId).subscribe((coupon: Coupon) => {
			if (coupon) {
				this.coupon = coupon;
				if (this.coupon.expiryDate) {
					const date = this.coupon.expiryDate?.split("/");
					this.coupon.expiryDate = new Date(
						date[2],
						date[1] - 1,
						date[0]
					);
				}
				this.mapDataCouponRestaurant();
			}
		});
	}

	private mapDataCouponRestaurant(): void {
		this.restaurants.map((res) => {
			res.assigned = false;
			const couponRestaurant = this.coupon.restaurants?.find(
				(itemRes) => itemRes.id === res.id
			);
			if (couponRestaurant) {
				res.assigned = true;
				res.concurrencyStamp =
					couponRestaurant.concurrencyStamp || null;
				res.expiryDate = couponRestaurant.expiryDate;
				res.isDisabled = couponRestaurant.isDisabled;
				res.isNeverExpire = couponRestaurant.isNeverExpire;
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		if (data.expiryDate) {
			data.expiryDate =
				DateUtils.ConvertTo(data.expiryDate) + " 23:59:59"; // TODO
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		if (assignRestaurants) {
			assignRestaurants.map((r) => {
				r.expiryDate =
					DateUtils.ConvertTo(r.expiryDate) + " 23:59:59"; // TODO
			});
		}
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateCoupon(data) : this.createCoupon(data);
	}

	private createCoupon(body: Coupon): void {
		this.couponService.createCoupon(body).subscribe(() => {
			this.backToCouponList(true);
		});
	}

	private updateCoupon(body: Coupon): void {
		this.couponService.updateCoupon(body).subscribe(() => {
			this.backToCouponList(true);
		});
	}

	cancel() {
		this.backToCouponList();
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	private backToCouponList(isReload: boolean = false): void {
		this.router.navigate(["/customer-service/coupon"]);
	}
}
