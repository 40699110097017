import { IFilterMetadataItemModel } from "@apps/shared/filter-builder/model/filter.metadata.model";
import { LogicalCondition } from "@apps/shared/filter-builder/services/logic-condition";
import { DefaultConditionFactory } from "@apps/shared/filter-builder/services/default-condition";
import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { commonFilterField } from "../shared/utils/common-filter-field";
import { AbstractCondition } from '@apps/shared/filter-builder/services/abstract-condition';
import { IApiPagingRequestModel, ApiRequestModelFactory } from '@apps/common/model/api.request.model';
import { Subject } from 'rxjs';
import { AdminBaseComponent } from "../abstract/admin.base.component";

export abstract class BaseFilterComponent<T extends IApiPagingRequestModel> extends AdminBaseComponent {
	request: T = ApiRequestModelFactory.create();
	dataSource$: Subject<T> = new Subject();
	isLoading = false;
	filterMeta: IFilterMetadataItemModel[] = [];
	advSearch = false;
	searchData: LogicalCondition = new LogicalCondition(ConditionOperator.AND, [
		DefaultConditionFactory.empty()
	]);


	public onFilterSubmit(data: AbstractCondition) {
		this.request = { ...this.request, ...data.printPretty()};
		this.dataSource$.next(this.request);
	}

	public onClear(data: AbstractCondition) {
		this.request = { ...this.request, ...data.printPretty()};
		this.dataSource$.next(this.request);
	}

	protected initFilterMetaData() {
		this.filterMeta = [...commonFilterField()];
	}
}
