import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { EmloBaseTableComponent } from "./components/base/base.component";
import { EmloBaseModule } from "./components/base/base.module";

@NgModule({
	imports: [CommonModule, EmloBaseModule],
	bootstrap: [EmloBaseTableComponent],
	exports: [EmloBaseTableComponent]
})
export class EmloTableModule {}
