import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig } from "@apps/shared/table";
import { Currency, CurrencyFactory, CurrencySearchModel } from "../../model/currency";
import { CurrencyService } from "../../services/currency.service";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { IApiPagingResponseModel, PaginationModelFactory } from "@apps/common/model/api.reponse.model";

@Component({
	selector: "app-currency-list",
	templateUrl: "./list.html"
})
export class EmloCurrencyListComponent extends AdminBaseComponent
	implements OnInit {
	@ViewChild("statusTpl", { static: true }) statusTpl: TemplateRef<any>;
	IconName = IconName;
	public configuration: Config;
	public columns: Columns[] = [];
	public currencys: Currency[] = [];
	restaurants: IRestaurantItemModel[] = [];
	criteria: CurrencySearchModel = new CurrencySearchModel();
	public pagination = PaginationModelFactory.create();

	constructor(
		private router: Router,
		private credentialsService: CredentialsService,
		private currencyService: CurrencyService,
		private restaurantService: RestaurantService,
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.configColumnForCurrencyTable();
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getCurrencys();
	}

	private getCurrencys(): void {
		this.currencyService
			.getCurrencys(this.criteria)
			.subscribe((currencys: IApiPagingResponseModel<Currency[]>) => {
				if (currencys) {
					this.currencys = currencys.data;
					this.pagination = currencys;
				}
			});
	}

	private configColumnForCurrencyTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = false;
		this.columns = [
			{ key: "createDate", title: "Create Date" },
			{ key: "endDate", title: "End Date" },
			{ key: "rate", title: "Rate" },
			{ key: "status", title: "Status", cellTemplate: this.statusTpl},
			{ key: "restaurantNames", title: "Selling at" },
		];
	}

	addCurrency(currency: Currency): void {
		if (!currency) {
			currency = CurrencyFactory.create();
			this.router.navigate(["/product-management/currency/add"]);
		} else {
			this.router.navigate(["/product-management/currency/edit"], {
				queryParams: { id: currency.id },
			});
		}
	}

	onFilter() {
		this.getCurrencys();
	}
}
