import { CommonConstant } from "@apps/common/constants/common.constant";
import { IPermissionModel } from "@apps/modules/emlo-admin/shared/model/permission.model";
import { ClientUserRestaurant } from "../../user/model/user";

export interface ClientUserType {
	id: string;
	name: string;
	restaurantId: string;
	userPermissions: IPermissionModel[];
	restaurants: ClientUserRestaurant[]
}

export class ClientUsertUserTypeSearchModel {
	searchText: string = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
export class ClientUserTypeRestaurant {
	id: string;
	name: string;
	concurrencyStamp: string;
	assigned?: boolean;
	permissions: IPermissionModel[];
}
export class UserTypeFactory {
	public static create(): ClientUserType {
		return {
			id: null,
			name: null,
			restaurantId: null,
			userPermissions: null,
			restaurants: []
		};
	}
}
