<div class="d-flex align-items-center emlo-table-pagination-wrapper"
	*ngIf="config.paginationEnabled"
	[class.emlo-table__table--dark-pagination-wrapper]="config.tableLayout.theme === 'dark'">

	<div class="flex-grow-1 emlo-table-pagination-range" #paginationRange
		[class.emlo-table__table--dark-pagination-range]="config.tableLayout.theme === 'dark'"
		*ngIf="config.paginationRangeEnabled">
		<div class="emlo-table-dropdown emlo-table-pagination-range-dropdown" id="rowAmount">
			<div class="btn-group btn-group-sm" role="group" aria-label="First group">
				<button type="button" class="btn btn-light" [class.active]="limit === selectedLimit"
				(click)="changeLimit(limit, false)" *ngFor="let limit of ranges">{{limit}}</button>
			</div>
		</div>
		<strong>{{selectedLimit}} items per page</strong>
		<strong *ngIf="paginationDirective.getTotalItems() > 1"> - Total {{ paginationDirective.getTotalItems() }} items</strong>
	</div>

	<div class="emlo-table-pagination-steps">
		<pagination-template #paginationDirective="paginationApi" id="pagination-controls" [id]="id"
			[class.emlo-table__table--dark-pagination]="config.tableLayout.theme === 'dark'"
			[maxSize]="config.paginationMaxSize || 5" (pageChange)="onPageChange($event)">
			<ul class="emlo-pagination m-0 p-0" role="navigation" [attr.aria-label]="screenReaderPaginationLabel"
				[class.responsive]="true">
				<li class="pagination-previous" [class.disabled]="paginationDirective.isFirstPage()"
					*ngIf="directionLinks">
					<a tabindex="0" *ngIf="1 < paginationDirective.getCurrent()"
						(keyup.enter)="paginationDirective.previous()" (click)="paginationDirective.previous()"
						[attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel">
						{{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
						<mat-icon svgIcon="icon-double-arrow-left" class="mat-icon-ssm"></mat-icon>
					</a>
					<span *ngIf="paginationDirective.isFirstPage()">
						{{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
						<mat-icon svgIcon="icon-double-arrow-left" class="mat-icon-ssm"></mat-icon>
					</span>
				</li>
				<li class="small-screen">
					{{ paginationDirective.getCurrent() }} / {{ paginationDirective.getLastPage() }}
				</li>
				<li [class.current]="paginationDirective.getCurrent() === page.value"
					[class.ellipsis]="page.label === '...'" *ngFor="let page of paginationDirective.pages">
					<a tabindex="0" (keyup.enter)="paginationDirective.setCurrent(page.value)"
						(click)="paginationDirective.setCurrent(page.value)"
						*ngIf="paginationDirective.getCurrent() !== page.value">
						<span class="show-for-sr">{{ screenReaderPageLabel }} </span>
						<span>{{ page.label }}</span>
					</a>
					<ng-container *ngIf="paginationDirective.getCurrent() === page.value">
						<span class="show-for-sr">{{ screenReaderCurrentLabel }} </span>
						<span>{{ page.label }}</span>
					</ng-container>
				</li>
				<li class="pagination-next" [class.disabled]="paginationDirective.isLastPage()" *ngIf="directionLinks">
					<a tabindex="0" *ngIf="!paginationDirective.isLastPage()" (keyup.enter)="paginationDirective.next()"
						(click)="paginationDirective.next()"
						[attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel">
						{{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
						<mat-icon svgIcon="icon-double-arrow-right" class="mat-icon-ssm"></mat-icon>
					</a>
					<span *ngIf="paginationDirective.isLastPage()">
						{{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
						<mat-icon svgIcon="icon-double-arrow-right" class="mat-icon-ssm"></mat-icon>
					</span>
				</li>
			</ul>
		</pagination-template>
		<div class="text-right pr-3">
			<strong>
				You're on page: {{ paginationDirective.getCurrent() }} / {{ paginationDirective.getLastPage() }}
			</strong>
		</div>
	</div>
</div>
