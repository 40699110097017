import { Component, OnInit } from "@angular/core";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { CredentialsService } from "@apps/core";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";

@Component({
	selector: "app-configuration-detail",
	templateUrl: "detail.html",
})
export class CustomerConfigurationDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	constructor(private credentialsService: CredentialsService) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
	}
}
