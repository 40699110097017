import { BaseDataReport } from "../../shared/model/report.base.mode";

export class SalesSummaryReport extends BaseDataReport {
	paid: number;
	onCredit: number;
	totalBeforeTax: number;
	totalTax: number;
	totalAfterTax: number;
	numberOfTickets: number;
	numberOfGuests: number;
	avgPerTicket: number;
	avgPerGuest: number;
	ticketType: string;
}
