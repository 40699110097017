import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";

import {
	Logger,
	I18nService,
	AuthenticationService,
	untilDestroyed
} from "@apps/core";
import { LoginService } from "../services/login.service";

const log = new Logger("Login");

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
	error: string | undefined;
	loginForm!: FormGroup;
	isLoading = false;
	isShowPassword = false;
	typePassword = "password";

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private i18nService: I18nService,
		private authenticationService: AuthenticationService,
		private loginService: LoginService
	) {
		this.createForm();
	}

	ngOnInit() {}

	ngOnDestroy() {}

	login() {
		this.isLoading = true;
		const login$ = this.loginService.login(this.loginForm.value);
		login$
			.pipe(
				finalize(() => {
					this.loginForm.markAsPristine();
					this.isLoading = false;
				}),
				untilDestroyed(this)
			)
			.subscribe(
				credentials => {
					log.debug(`${credentials.username} successfully logged in`);
					this.authenticationService.login(credentials);
					this.router.navigate(
						[this.route.snapshot.queryParams.redirect || "/"],
						{ replaceUrl: true }
					);
				},
				error => {
					log.debug(`Login error: ${error}`);
					this.error = error;
				}
			);
	}

	setLanguage(language: string) {
		this.i18nService.language = language;
	}

	get currentLanguage(): string {
		return this.i18nService.language;
	}

	get languages(): string[] {
		return this.i18nService.supportedLanguages;
	}

	private createForm() {
		this.loginForm = this.formBuilder.group({
			username: ["", Validators.required],
			password: ["", Validators.required],
			pin: ["", Validators.nullValidator]
		});
	}

	showPassword() {
		this.isShowPassword = !this.isShowPassword;
		this.typePassword = "text";
	}

	hidePassword() {
		this.isShowPassword = !this.isShowPassword;
		this.typePassword = "password";
	}
}
