<div class="p-3">
	<h3>Add Gift for <strong>{{ customer.fullName }}</strong></h3>
	<formly-form
		[model]="addGift"
		[fields]="addGiftFormFields"
		[form]="form"
	></formly-form>
	<div class="text-right">
		<button type="button" class="btn btn-danger" (click)="close()" translate>
			Close
		</button>
		<button type="button" class="btn btn-primary ml-2" (click)="addGiftToCustomer()" translate>
			Add Gift
		</button>
	</div>
</div>
