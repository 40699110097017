<div class="login-container h-screen">
	<div class="login-box d-flex align-items-center">
		<div class="login-content">
			<h4 translate>Login</h4>
			<p translate class="text-muted">Welcome back, please login to your account.</p>
			<form class="mt-4" (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
				<div class="alert alert-danger" [hidden]="!error || isLoading" translate>
					Username or password incorrect.
				</div>
				<input type="hidden" class="form-control" formControlName="pin" autocomplete="pin"
					[placeholder]="'Pin' | translate" />
				<div class="form-group">
					<label translate>Username</label>
					<div class="input-group flex-nowrap">
						<div class="input-group-prepend">
							<span class="input-group-text bg-transparent">
								<mat-icon svgIcon="icon-user"></mat-icon>
							</span>
						</div>
						<input type="text" class="form-control bg-transparent" formControlName="username"
							autocomplete="username" [placeholder]="'Enter username' | translate" />
					</div>
					<small [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
						class="text-danger" translate>
						Username is required
					</small>
				</div>
				<div class="form-group row-password">
					<label translate>Password</label>
					<div class="input-group flex-nowrap">
						<div class="input-group-prepend">
							<span class="input-group-text bg-transparent">
								<mat-icon svgIcon="icon-lock"></mat-icon>
							</span>
						</div>
						<input [type]="typePassword" class="form-control bg-transparent" formControlName="password"
							autocomplete="current-password" [placeholder]="'Enter password' | translate" required />
						<span *ngIf="!isShowPassword" class="show-password" (click)="showPassword()">
							<mat-icon svgIcon="icon-eye-hide"></mat-icon>
						</span>
						<span *ngIf="isShowPassword" class="show-password" (click)="hidePassword()">
							<mat-icon svgIcon="icon-eye"></mat-icon>
						</span>
					</div>
					<small [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
						class="text-danger" translate>
						Password is required
					</small>
				</div>
				<emlo-button [className]="'btn-lg mt-3'" [textButton]="'Login'" [disabled]="loginForm.invalid || isLoading"></emlo-button>
			</form>
		</div>
	</div>
</div>
