import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { UserSettingService } from "../../shared/services/user.feature.service";
import { EmloFormModule } from "./../../../../shared/form/form.module";
import { EmloUserComponent } from "./client.component";
import { EmloConfigurationClientRoutingModule } from "./client.routes";
import { EmloClientDetailComponent } from "./components/detail/client.detail.component";
import { EmloDisplaySecretComponent } from "./components/display-secret/display-secret.component";
import { EmloClientListComponent } from "./components/list/client.list.component";
import { ClientService } from "./services/client.service";

@NgModule({
	declarations: [
		EmloUserComponent,
		EmloClientListComponent,
		EmloClientDetailComponent,
		EmloDisplaySecretComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatDialogModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatFormFieldModule,
		MatInputModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		MatTabsModule,
		NgSelectModule,
		EmloFormModule,
		FormlyBootstrapModule,
		EmloConfigurationClientRoutingModule
	],
	providers: [
		ClientService,
		UserSettingService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloClientDetailComponent, EmloDisplaySecretComponent]
})
export class EmloConfigurationClientModule {}
