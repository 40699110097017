import { UserRoleTypeEnum } from "../enums/role.type";
import { IconName } from "./icon";
import { AppRoutesNames } from "./routes";

export interface IMenu {
	id: number;
	name: string;
	routerLink: string;
	icon?: string;
	subMenus?: IMenu[];
	roles?: UserRoleTypeEnum[];
}

const dashSymbol = "/";
export const Menus: IMenu[] = [
	{
		id: 1,
		name: "Dasboard",
		icon: IconName.icon_dashboard,
		routerLink: AppRoutesNames.DASHBOARD,
		roles: [
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.Accountant,
			UserRoleTypeEnum.CustomerService,
			UserRoleTypeEnum.ProductManager,
		],
		subMenus: [],
	},
	{
		id: 2,
		name: "Products Management",
		icon: IconName.icon_category,
		routerLink: AppRoutesNames.PRORUDCT_MANAGEMENT,
		subMenus: [
			{
				id: 1,
				name: "Menu Items",
				icon: IconName.icon_menu_item,
				routerLink:
					dashSymbol +
					AppRoutesNames.PRORUDCT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.ITEMS,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
					UserRoleTypeEnum.CustomerService
				],
			},
			{
				id: 2,
				name: "Menu Categories",
				icon: IconName.icon_category,
				routerLink: dashSymbol + AppRoutesNames.PRORUDCT_MANAGEMENT,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
				],
			},
			{
				id: 3,
				name: "Menu Groups",
				icon: IconName.icon_menu_group,
				routerLink:
					dashSymbol +
					AppRoutesNames.PRORUDCT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.MENU_GROUP,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
				],
			},
			{
				id: 4,
				name: "Menu Modifier Groups",
				icon: IconName.icon_modifier_group,
				routerLink:
					dashSymbol +
					AppRoutesNames.PRORUDCT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.MODIFIER_GROUP,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
				],
			},
			{
				id: 5,
				name: "Menu Modifier",
				icon: IconName.icon_modifier,
				routerLink:
					dashSymbol +
					AppRoutesNames.PRORUDCT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.MODIFIER,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
				],
			},
		],
	},
	{
		id: 3,
		name: "Discount Tax Currency",
		icon: IconName.icon_coupon,
		routerLink: AppRoutesNames.ADMIN_DASH_BOARD,
		subMenus: [
			{
				id: 2,
				name: "Tax",
				icon: IconName.icon_tax,
				routerLink:
					dashSymbol +
					AppRoutesNames.PRORUDCT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.TAX,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
				],
			},
			{
				id: 3,
				name: "Currency",
				icon: IconName.icon_currency,
				routerLink:
					dashSymbol +
					AppRoutesNames.PRORUDCT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.CURRENCY,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.ProductManager,
				],
			},
		],
	},
	{
		id: 4,
		name: "Account Management",
		icon: IconName.icon_users,
		routerLink: AppRoutesNames.ACCOUNT_MANAGEMENT,
		subMenus: [
			{
				id: 1,
				name: "Users",
				icon: IconName.icon_users,
				routerLink:
					dashSymbol +
					AppRoutesNames.ACCOUNT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.USER,
				roles: [UserRoleTypeEnum.Administrator],
			},
			{
				id: 2,
				name: "User Roles",
				icon: IconName.icon_user_group,
				routerLink:
					dashSymbol +
					AppRoutesNames.ACCOUNT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.USER_TYPE,
				roles: [UserRoleTypeEnum.Administrator],
			},
			{
				id: 3,
				name: "Restaurant Users",
				icon: IconName.icon_users,
				routerLink:
					dashSymbol +
					AppRoutesNames.ACCOUNT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.RESTAURANT_USER,
				roles: [UserRoleTypeEnum.Administrator],
			},
			{
				id: 4,
				name: "Restaurant User Roles",
				icon: IconName.icon_user_group,
				routerLink:
					dashSymbol +
					AppRoutesNames.ACCOUNT_MANAGEMENT +
					dashSymbol +
					AppRoutesNames.RESTAURANT_USER_TYPE,
				roles: [UserRoleTypeEnum.Administrator],
			},
		],
	},
	{
		id: 5,
		name: "Reporting Groups",
		icon: IconName.icon_report,
		routerLink: AppRoutesNames.REPORT,
		subMenus: [
			{
				id: 1,
				name: "Cash Summary Report",
				icon: IconName.icon_report,
				routerLink:
					dashSymbol +
					AppRoutesNames.REPORT +
					dashSymbol +
					AppRoutesNames.CASH_REPORT,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.Accountant,
				],
			},
			{
				id: 2,
				name: "Sales Summary Report",
				icon: IconName.icon_report,
				routerLink:
					dashSymbol +
					AppRoutesNames.REPORT +
					dashSymbol +
					AppRoutesNames.SALE_SUMMARY_REPORT,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.Accountant,
				],
			},
			{
				id: 3,
				name: "Detail Sales Report",
				icon: IconName.icon_report,
				routerLink:
					dashSymbol +
					AppRoutesNames.REPORT +
					dashSymbol +
					AppRoutesNames.DETAIL_SALE_REPORT,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.Accountant,
				],
			},
			{
				id: 4,
				name: "Menu Item Sales Analysis",
				icon: IconName.icon_report,
				routerLink:
					dashSymbol +
					AppRoutesNames.REPORT +
					dashSymbol +
					AppRoutesNames.MENU_ITEM_SALE_ANALYSIS_REPORT,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.Accountant,
				],
			},
			{
				id: 5,
				name: "Total Sale Report",
				icon: IconName.icon_report,
				routerLink:
					dashSymbol +
					AppRoutesNames.REPORT +
					dashSymbol +
					AppRoutesNames.TOTAL_SALE_REPORT,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.Accountant,
				],
			},
		],
	},
	{
		id: 6,
		name: "Customers",
		icon: IconName.icon_user,
		routerLink: AppRoutesNames.ADMIN_DASH_BOARD,
		subMenus: [
			{
				id: 1,
				name: "Overview",
				icon: IconName.icon_dashboard,
				routerLink:
					dashSymbol +
					AppRoutesNames.CUSTOMER_SERVICE +
					dashSymbol +
					AppRoutesNames.OVERVIEW,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.CustomerService,
				],
			},
			{
				id: 2,
				name: "Order",
				icon: IconName.icon_money_coin,
				routerLink:
					dashSymbol +
					AppRoutesNames.CUSTOMER_SERVICE +
					dashSymbol +
					AppRoutesNames.ORDER,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.CustomerService,
				],
			},
			{
				id: 3,
				name: "Customers",
				icon: IconName.icon_employee,
				routerLink:
					dashSymbol +
					AppRoutesNames.CUSTOMER_SERVICE +
					dashSymbol +
					AppRoutesNames.CUSTOMER,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.CustomerService,
				],
			},
			{
				id: 4,
				name: "Restaurant Discount",
				icon: IconName.icon_coupon,
				routerLink:
					dashSymbol +
					AppRoutesNames.CUSTOMER_SERVICE +
					dashSymbol +
					AppRoutesNames.COUPON,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.CustomerService,
				],
			},
			{
				id: 5,
				name: "Configuration",
				icon: IconName.icon_settings,
				routerLink:
					dashSymbol +
					AppRoutesNames.CUSTOMER_SERVICE +
					dashSymbol +
					AppRoutesNames.CONFIGURATION,
				roles: [
					UserRoleTypeEnum.Administrator,
					UserRoleTypeEnum.CustomerService,
				],
			},
		],
	},
	{
		id: 7,
		name: "Configuration",
		icon: IconName.icon_configuration,
		routerLink: AppRoutesNames.ADMIN_DASH_BOARD,
		subMenus: [
			{
				id: 1,
				name: "Restaurants",
				icon: IconName.icon_configuration,
				routerLink:
					dashSymbol +
					AppRoutesNames.CONFIGURATION +
					dashSymbol +
					AppRoutesNames.CLIENT,
				roles: [UserRoleTypeEnum.Administrator],
			},
		],
	},
];
