import { Component, OnInit } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { Customer } from "../../model/customer";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { MatDialog } from "@angular/material/dialog";
import { AddPointCustomerComponent } from "../add-point/add-point.component";
import { AddGiftCustomerComponent } from "../add-gift/add-gift.component";

@Component({
	selector: "app-customer-detail",
	templateUrl: "detail.html",
})
export class CustomerDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	customerFormFields: FormlyFieldConfig[] = [];
	customer: Customer = new Customer();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private customerService: CustomerService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const customerCode = params.get("code");
			if (customerCode) {
				this.isEdit = true;
				this.getCustomerById(customerCode);
			}
			this.createForm();
		});
	}

	private createForm() {
		this.customerFormFields = [
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "customerCode",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Customer Code",
							placeholder: "Customer Code",
							readonly: true,
						},
					},
					{
						type: "input",
						key: "fullName",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Customer Name",
							placeholder: "Customer Name",
							readonly: true,
						},
					},
					{
						type: "input",
						key: "email",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Email",
							placeholder: "Email",
							readonly: true,
						},
					},
					{
						type: "input",
						key: "mobileNumber",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Phone Number",
							placeholder: "Phone Number",
							readonly: true,
						},
					},
					{
						key: "dob",
						type: "emlo-datetime",
						className: "col-6",
						templateOptions: {
							required: false,
							type: "date",
							label: "Date of birth",
							readonly: true,
						},
					},
					{
						key: "lastLoginDate",
						type: "emlo-datetime",
						className: "col-6",
						templateOptions: {
							required: false,
							type: "date",
							label: "Last Login Date",
							readonly: true,
						},
					},
					{
						type: "input",
						key: "profile.currentLevelName",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Current Level",
							readonly: true,
						},
					},
					{
						type: "input",
						key: "profile.currentPoints",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Current Points",
							readonly: true,
						},
					},
					{
						type: "emlo-checkbox",
						key: "isActive",
						defaultValue: true,
						className: "col-6",
						templateOptions: {
							label: "Active",
						},
					},
				],
			},
			IdControl,
		];
	}

	private getCustomerById(customerId: string): void {
		this.customerService
			.getCustomerById(customerId)
			.subscribe((customer: Customer) => {
				if (customer) {
					customer.dob = new Date(customer.dob);
					customer.lastLoginDate = new Date(customer.lastLoginDate);
					this.customer = customer;
				}
			});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		this.isEdit ? this.updateCustomer(data) : this.createCustomer(data);
	}

	private createCustomer(body: Customer): void {
		this.customerService.createCustomer(body).subscribe(() => {
			this.backToCustomerList();
		});
	}

	private updateCustomer(body: Customer): void {
		this.customerService.updateCustomer(body).subscribe(() => {
			this.backToCustomerList();
		});
	}

	cancel() {
		this.backToCustomerList();
	}

	private backToCustomerList(): void {
		this.router.navigate(["/customer-service/customer"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	addPoint() {
		this.dialog
			.open(AddPointCustomerComponent, {
				data: this.customer,
				minWidth: 360,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.tabIndex = 0;
					setTimeout(() => {
						this.tabIndex = 1;
					});
				}
			});
	}

	addGift() {
		this.dialog
			.open(AddGiftCustomerComponent, {
				data: this.customer,
				minWidth: 360,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.tabIndex = 0;
					setTimeout(() => {
						this.tabIndex = 2;
					});
				}
			});
	}
}
