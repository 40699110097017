/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:14:39
 * @modify date 2020-02-11 00:14:39
 */
import { Injectable } from "@angular/core";
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";

import { CredentialsService } from "./credentials.service";
import { Logger } from "../services/logger.service";

const log = new Logger("AuthenticationGuard");

@Injectable({
	providedIn: "root"
})
export class AuthenticationGuard implements CanActivate {
	constructor(
		private router: Router,
		private credentialsService: CredentialsService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (this.credentialsService.isAuthenticated()) {
			return true;
		}

		log.debug("Not authenticated, redirecting and adding redirect url...");
		this.router.navigate(["/login"], {
			queryParams: { redirect: state.url },
			replaceUrl: true
		});
		return false;
	}
}
