import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class MenuItemSalesAnalysisService {
	constructor(private apiService: ApiService) {}

	getitemReport(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "itemreport/getitemReport",
			context
		);
	}

	getExcelReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "itemreport/getexcelreport",
			context
		);
	}

	getReportGroups() {
		return this.apiService.get(
			environment.prefix_api_url + "reportinggroup/list"
		);
	}
}
