import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloCategoryModule } from "./category";
import { EmloItemModule } from "./item";
import { EmloMenuGroupModule } from "./menu-group";
import { EmloModifierGroupModule } from "./modifier-group";
import { EmloModifierModule } from "./modifier";
import { EmloTaxModule } from "./tax";
import { EmloCurrencyModule } from "./currency";

const routes: Routes = [
	{
		path: "",
		redirectTo: AppRoutesNames.CATEGORY
	},
	{
		path: AppRoutesNames.CATEGORY,
		loadChildren: () => EmloCategoryModule
	},
	{
		path: AppRoutesNames.ITEMS,
		loadChildren: () => EmloItemModule
	},
	{
		path: AppRoutesNames.MENU_GROUP,
		loadChildren: () => EmloMenuGroupModule
	},
	{
		path: AppRoutesNames.MODIFIER_GROUP,
		loadChildren: () => EmloModifierGroupModule
	},
	{
		path: AppRoutesNames.MODIFIER,
		loadChildren: () => EmloModifierModule
	},
	{
		path: AppRoutesNames.TAX,
		loadChildren: () => EmloTaxModule
	},
	{
		path: AppRoutesNames.CURRENCY,
		loadChildren: () => EmloCurrencyModule
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloProductManagementRoutingModule {}
