import { CommonConstant } from "@apps/common/constants/common.constant";

export interface MenuGroup {
	id: string;
	parentGroupId: string;
	parentGroupName: string;
	parentGroupNameEn: string;
	parentGroupNameVi: string;
	parentGroupNameCn: string;
	name: string;
	nameEn: string;
	nameVi: string;
	nameCn: string;
	isVisible: boolean;
	sortOrder: number;
	btnColor: string;
	textColor: string;
	categoryName: string;
	categoryNameEn: string;
	categoryNameVi: string;
	categoryNameCn: string;
	menuCategoryId: string;
	concurrencyStamp: string;
	restaurantIds: string | string[];
	restaurants: MenuGroupRestaurant[];
}

export class MenuGroupSearchModel {
	searchText: string = null;
	isVisible: boolean = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export interface MenuGroupRestaurant {
	concurrencyStamp: string;
	id: string;
	isVisible: boolean;
	menuCategoryId: string;
	name: string;
	parentGroupId: string;
	sortOrder: number;
}

export class MenuGroupFactory {
	public static create(): MenuGroup {
		return {
			id: null,
			parentGroupId: null,
			parentGroupName: null,
			parentGroupNameEn: null,
			parentGroupNameVi: null,
			parentGroupNameCn: null,
			name: null,
			nameEn: null,
			nameVi: null,
			nameCn: null,
			isVisible: true,
			sortOrder: null,
			btnColor: null,
			textColor: null,
			concurrencyStamp: null,
			categoryName: null,
			categoryNameEn: null,
			categoryNameVi: null,
			categoryNameCn: null,
			menuCategoryId: null,
			restaurantIds: [],
			restaurants: []
		};
	}
}

export class MenuGroupSelection {
	id: string;
	name: string;
	nameCn: string;
	nameEn: string;
	nameVi: string;
	restaurantId: string;
}
