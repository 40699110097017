<div class="box border-sub-1">
	<emlo-box-header [title]="'Configuration Customers Level'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-2">
			<div class="flex-grow-1">
				<div class="d-flex flex-row align-items-center">
					<div class="keyword">
						<input type="text" class="form-control" name="keyword" [(ngModel)]="criteria.searchText"
							(keyup.enter)="onFilter()" placeholder="{{ 'Search...' | translate }}" autocomplete="off">
					</div>
					<!-- <div class="search-visible ml-3">
						<mat-radio-group class="row" name="isVisible" [(ngModel)]="criteria.isActive"
							(change)="onFilter()">
							<mat-radio-button class="pr-3" *ngFor="let state of states" [value]="state.value">
								{{ state.name | translate }}
							</mat-radio-button>
						</mat-radio-group>
					</div> -->
				</div>
			</div>
		</div>

		<emlo-table [configuration]="configuration" [data]="customerLevels" [columns]="columns" [pagination]="pagination"
			(event)="onTableEvent($event)">
		</emlo-table>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<!-- <ng-template #isStatusTpl let-row>
		<mat-slide-toggle (click)="toggleLockOut($event, row)" [checked]="row.isActive">
		</mat-slide-toggle>
	</ng-template> -->
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openCustomerLevelDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<!-- <a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a> -->
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="openCustomerLevelDetail(null)">Add
			New</button>
	</ng-template>
</div>
