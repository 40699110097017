export const IconName = {
	icon_category: "icon-category",
	icon_menu_item: "icon-menu-item",
	icon_menu_group: "icon-menu-group",
	icon_modifier_group: "icon-modifier-group",
	icon_modifier: "icon-modifier",
	icon_coupon: "icon-coupon",
	icon_tax: "icon-tax",
	icon_currency: "icon-currency",
	icon_users: "icon-users",
	icon_user: "icon-user",
	icon_user_group: "icon-user-group",
	icon_report: "icon-report",
	icon_configuration: "icon-configuration",
	icon_lock: "icon-lock",
	icon_eye: "icon-eye",
	icon_eye_hide: "icon-eye-hide",
	icon_acc: "icon-acc",
	icon_settings: "icon-settings",
	icon_notification: "icon-notification",
	icon_signout: "icon-signout",
	icon_close: "icon-close",
	icon_menu: "icon-menu",
	icon_home: "icon-home",
	icon_double_arrow_left: "icon-double-arrow-left",
	icon_double_arrow_right: "icon-double-arrow-right",
	icon_double_down_arrow: "icon-double-down-arrow",
	icon_double_up_arrow: "icon-double-up-arrow",
	icon_pinned: "icon-pinned",
	icon_more: "icon-more",
	icon_dashboard: "icon-dashboard",
	icon_admin_settings: "icon-admin-settings",
	icon_electronics: "icon-electronics",
	icon_employee: "icon-employee",
	icon_finance: "icon-finance",
	icon_money_coin: "icon-money-coin",
	icon_warning: "icon-warning",
	icon_edit: "icon-edit",
	icon_trash: "icon-trash",
	icon_arrow_up: "icon-arrow-up",
	icon_arrow_down: "icon-arrow-down",
	icon_add: "icon-add",
	icon_add_nocircle: "icon-add-nocircle",
	icon_checkmark: "icon-checkmark",
	icon_search: "icon-search",
	icon_filter: "icon-filter",
	icon_calendar: "icon-calendar",
	icon_download_black_24dp: "icon-download-black-24dp",
	icon_reset: "icon-lock"
};
