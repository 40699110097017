import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable } from "rxjs";
import { Order, OrderSearchModel } from "../model/order";

@Injectable()
export class OrderService {
	constructor(private apiService: ApiService) {}

	getOrders(request: OrderSearchModel): Observable<IApiPagingResponseModel<Order[]>> {
		// const params = new HttpParams({ fromObject: request as any });
		return this.apiService.post(environment.prefix_api_url + "order/list", request);
	}

	getOrderById(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "order/detail", params);
	}

	createOrder(context: Order) {
		return this.apiService.post(environment.prefix_api_url + "order/create", context);
	}

	updateOrder(context: Order) {
		return this.apiService.post(environment.prefix_api_url + "order/update", context);
	}

	deleteOrder(context: any) {
		return this.apiService.post(environment.prefix_api_url + "order/delete", context);
	}
}
