import { Component, OnInit } from "@angular/core";
import { IMenu } from "@apps/common/constants/menu";
import { CredentialsService } from "@apps/core";
import { ApiService } from "@apps/core/services";

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"]
})
export class EmloDashboardComponent implements OnInit {
	mainMenus: IMenu[];
	constructor(private apiService: ApiService, private credentialsService: CredentialsService) {}
	ngOnInit() {
		//check data current user from api
		this.apiService.get("/aapi/v1/account/check-login").subscribe((res) => {
			console.log(res)
		});

		this.mainMenus = this.credentialsService.filterMenuByUserRole();
	}
}
