/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:10:53
 * @modify date 2020-02-11 00:10:53
 */
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [{ path: "**", redirectTo: "", pathMatch: "full" }];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
