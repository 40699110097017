import { CommonConstant } from "@apps/common/constants/common.constant";
import { IModifierGroupModel } from "@apps/modules/emlo-admin/shared/model/modifier-group.model";
import { ITaxModel } from "@apps/modules/emlo-admin/shared/model/tax.model";
import { ItemRestaurant } from "../../item/model/item-detail";

export interface Modifier {
	id: string;
	btnColor: string;
	extraPrice: number;
	menuModifierGroupName: string;
	menuModifierGroupNameEn: string;
	menuModifierGroupNameCn: string;
	menuModifierGroupNameVi: string;
	nameCn: string;
	nameEn: string;
	nameVi: string;
	price: number;
	isPrintToKitchen: boolean;
	sortOrder: number;
	taxName: string;
	textColor: string;
	restaurantIds: string | string[];
	taxId: string;
	tax: ITaxModel;
	menuModifierGroupId: string;
	menuModifierGroup: IModifierGroupModel
	restaurants: ItemRestaurant[];
}

export class ModifierFactory {
	public static create(): Modifier {
		return {
			id: null,
			btnColor: null,
			extraPrice: null,
			menuModifierGroupName: null,
			menuModifierGroupNameEn: null,
			menuModifierGroupNameCn: null,
			menuModifierGroupNameVi: null,
			nameCn: null,
			nameEn: null,
			nameVi: null,
			price: null,
			isPrintToKitchen: true,
			sortOrder: null,
			taxName: null,
			textColor: null,
			restaurantIds: null,
			taxId: null,
			tax: null,
			menuModifierGroupId: null,
			menuModifierGroup: null,
			restaurants: []
		};
	}
}

export class ModifierSearchModel {
	searchText: string = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
