import { CommonConstant } from "@apps/common/constants/common.constant";


export class Order {
	id: string = null;
	clientEntityId: number = null;
	currentPoints: number = null;
	customerName: string = null;
	restaurantName: string = null;
	owner: string = null;
	displayId: string = null;
	createdAt: string = null;
	ticketType: string = null;
	ticketTypeChinese: string = null;
	subTotal: number = null;
	totalDiscount: number = null || 0;
	totalTax: number = null || 0;
	serviceCharge: number = null || 0;
	serviceChargeRate: number = null || 0;
	roundingVn: number = null || 0;
	totalPrice: number = null || 0;
	tableNumber: string = null;
	customerId: string = null;
	items: OrderItem[] = null;
	claimedBenefits: ClaimedBenefit[] = null;
}

export class OrderItem {
	name: string = null || "";
	quantity: number = null || 0;
	price: number = null || 0;
	subTotal: number = null || 0;
}

export class ClaimedBenefit {
	name: string = null || "";
}
export class OrderSearchModel {
	refId: number = null;
	displayId: string = null;
	restaurantId: string = null;
	fromDate: Date = null;
	toDate: Date = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
