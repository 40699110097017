<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Edit User Role' : 'Add User Role'"
		[headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="canAccess; else contactAdmin">
		<mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="swittchTab($event)">
			<mat-tab label="{{ 'General' | translate }}">
				<div class="box-content">
					<div class="form-group">
						<label>{{ 'Role Name' | translate }}</label>
						<input type="hidden" formControlName="id" />
						<input type="text" class="form-control" formControlName="name" autocomplete="off"
							[placeholder]="'Role Name' | translate" />
					</div>
					<div class="form-group">
						<fieldset class="stardard pr-0" *ngIf="usertype?.userPermissions">
							<legend>{{ 'Permissions' | translate }}</legend>
							<div class="content-popup-info w-100 row">
								<div class="col-sm-12 col-md-6 col-lg-4"
									*ngFor="let permission of usertype.userPermissions; let idx = index;">
									<mat-checkbox name="isActive_{{idx}}" [(ngModel)]="permission.isActive"
										[ngModelOptions]="{standalone: true}">
										{{ permission.name }}
									</mat-checkbox>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="{{ 'Assign Restaurant ' | translate }}">
				<div class="box-content item-content">
					<div class="p-3">
						<div class="mb-2" *ngFor="let restaurant of userTypeRestaurants; let index = index;">
							<input type="checkbox" [(ngModel)]="restaurant.assigned"
								[ngModelOptions]="{standalone: true}" class="form-check-input"
								[id]="'restaurant_name_' + index" (ngModelChange)="toggleAssignRestaurant(restaurant)">
							<label [for]="'restaurant_name_' + index">{{ restaurant.name}}</label>
						</div>
					</div>
					<!-- <fieldset *ngFor="let restaurant of userTypeRestaurants; let index = index;"
						[ngClass]="{'un-assign': !restaurant.assigned}">
						<legend>
							<input type="checkbox" [(ngModel)]="restaurant.assigned"
								[ngModelOptions]="{standalone: true}" class="form-check-input"
								[id]="'restaurant_name_' + index" (ngModelChange)="toggleAssignRestaurant(restaurant)">
							<label [for]="'restaurant_name_' + index">{{ restaurant.name}}</label>
						</legend>
						<input type="hidden" [(ngModel)]="restaurant.concurrencyStamp"
							[ngModelOptions]="{standalone: true}" class="form-control">
						<input type="hidden" [(ngModel)]="restaurant.concurrencyStamp"
							[ngModelOptions]="{standalone: true}" class="form-control">

						<div class="form-group">
							<div class="content-popup-info w-100 row">
								<div class="col-sm-12 col-md-6 col-lg-4"
									*ngFor="let permission of restaurant?.permissions; let idx = index;">
									<mat-checkbox [(ngModel)]="permission.isActive"
										[ngModelOptions]="{standalone: true}" [disabled]="!restaurant.assigned">
										{{ permission.name }}
									</mat-checkbox>
								</div>
							</div>
						</div>
					</fieldset> -->
				</div>
			</mat-tab>
		</mat-tab-group>

		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>
			<button *ngIf="tabIndex === 0" type="button" class="btn btn-primary mr-2" (click)="tabIndex = 1"
				translate>Assign Restaurant</button>
			<emlo-button *ngIf="tabIndex === 1" type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid"
				[textButton]="'Submit'" translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
