import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { RewardPointSearchModel, RewardPoint } from "../../model/reward-point";
import { RewardPointService } from "../../services/reward-point.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { CustomerLevelSearchModel } from "../../../customer-level/model/customer-level";

@Component({
	selector: "app-reward-point-list",
	templateUrl: "./list.html",
})
export class RewardPointListComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("startdateTpl", { static: true }) startdateTpl: TemplateRef<any>;
	@ViewChild("enddateTpl", { static: true }) enddateTpl: TemplateRef<any>;
	@ViewChild("isPermanentTpl", { static: true })
	isPermanentTpl: TemplateRef<any>;

	public configuration: Config;
	public columns: Columns[] = [];
	public rewardPoints: RewardPoint[] = [];
	public pagination = PaginationModelFactory.create();
	criteria: RewardPointSearchModel = new RewardPointSearchModel();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private rewardPointService: RewardPointService,
		private credentialsService: CredentialsService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.configRewardPointsTable();
		this.getRewardPoints();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria = { ...this.criteria, ...$event.value };
			this.criteria = { ...this.criteria, ...$event.value };
			this.getRewardPoints();
			//this.getCustomerLevel();
		}
	}

	private getRewardPoints() {
		this.rewardPointService
			.getRewardPoints(this.criteria)
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if (response) {
					this.rewardPoints = response.data;
					this.pagination = response;
				}
			});
	}

	//private getCustomerLevel() {
	//	const criteria: CustomerLevelSearchModel = new CustomerLevelSearchModel();
	//	criteria.pageSize = 10;
	//	this.customerLevelService
	//		.getCustomerLevels(criteria)
	//		.subscribe((response) => {
	//			if (response) {
	//				this.customerLevel = response.data;
	//			}
	//		});
	//}

	private configRewardPointsTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "name", title: "Event Name", orderEnabled: true },
			//{
			//	key: "customerLevelID",
			//	title: "Customer Level ID",
			//	visible: false
			//},
			{ key: "customerLevelName", title: "Customer Level", orderEnabled: true },
			{ key: "pointRatePercent", title: "Point Rate", orderEnabled: true },
			{ key: "beginValidDate", title: "Begin Date", orderEnabled: true, cellTemplate: this.startdateTpl },
			{ key: "endValidDate", title: "End Date", orderEnabled: true, cellTemplate: this.enddateTpl },
			{ key: "priority", title: "Priority", orderEnabled: true },
			{
				key: "isApplyForHolidays",
				title: "Holidays Apply",
				orderEnabled: true,
				cssClass: { name: "text-center", includeHeader: true },
				cellTemplate: this.isPermanentTpl,
			},
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteRewardPoint(rewardPoint: RewardPoint): void {
		const data = { id: rewardPoint.id, concurrencyStamp: rewardPoint.concurrencyStamp };
		this.rewardPointService.deleteRewardPoint(data).subscribe(() => {
			this.getRewardPoints();
		});
	}

	delete(rewardPoint: RewardPoint): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteRewardPoint(rewardPoint);
				}
			});
	}

	openRewardPointDetail(rewardPoint: RewardPoint): void {
		if (!rewardPoint) {
			this.router.navigate(["/customer-service/configuration/reward-point/add"]);
		} else {
			this.router.navigate(
				["/customer-service/configuration/reward-point/edit"],
				{
					queryParams: { id: rewardPoint.id },
				}
			);
		}
	}

	onFilter() {
		this.getRewardPoints();
	}

	ngOnDestroy() {}
}
