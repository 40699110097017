import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Observable, of } from "rxjs";

@Component({
	selector: "emlo-select",
	template: `
		<div class="form-group">
			<ng-select
				[items]="_internalValue | async"
				[multiple]="to.multiple"
				[attr.disabled]="to.disabled"
				[placeholder]="to.placeholder || 'Select'"
				[bindValue]="to.bindValue || 'id'"
				[bindLabel]="to.bindLabel || 'name'"
				[formControl]="formControl"
				(change)="onChangeValue()"
				appendTo="body"
			>
			</ng-select>
		</div>
	`,
})
export class EmloSelectComponent extends FieldType implements OnInit {
	_internalValue;

	ngOnInit() {
		if (this.to.options instanceof Observable) {
			this._internalValue = this.to.options;
		} else {
			this._internalValue = of(this.to.options);
		}
	}

	onChangeValue() {
		if (this.field.templateOptions.change) {
			this.field.templateOptions.change(this.field, null);
		}
	}
}
