import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from "@angular/core";
import { CredentialsService, I18nService } from "@apps/core";
import { MatSidenav } from "@angular/material/sidenav";
import { ShareDataService } from "@apps/core/services/data.service";
import { EventName } from "@apps/common/constants/event";
import { SettingLayout, MenuMode } from "../model/shell.model";
import { ShellService } from "../services/shell.service";
import { IconName } from "@apps/common/constants/icon";
import { EmloStorage } from "@apps/core/storage/storage";
import { StorageKey } from "@apps/core/storage/store-keys";
import { AdminShareDataService } from "../../shared/services/share.data";
import { IRestaurantItemModel } from "../../shared/model/restaurant-item.model";
import { RestaurantService } from "../services/restaurant.service";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	IconName = IconName;
	isOpeningMenuSettings: boolean = false;
	allowSwitchRestaurantGlobal: boolean = false;
	userRoleName: string;
	@ViewChild("sideNavSettings") sideNavSettings: MatSidenav;
	// Custom template
	theme: string = "mode-default";
	menuMode = MenuMode;
	settingLayout: SettingLayout = {
		theme: "mode-default",
		menu: MenuMode.menu_docked,
		fontSize: 1
	};
	public restaurants: IRestaurantItemModel[];
	public restautantActive: IRestaurantItemModel;
	constructor(
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
		private shellService: ShellService,
		private adminShareDataService: AdminShareDataService,
		private credentialsService: CredentialsService,
		private i18nService: I18nService,
		private shareDataService: ShareDataService,
		private restaurantService: RestaurantService
	) {}

	ngOnInit() {
		this.getLayoutSettingOnLocal();
		this.userRoleName = this.credentialsService.getRoleName();
		// this.getRestaurants();
	}

	private getRestaurants(): void {
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					const storageRestaurantActive = EmloStorage.storageService.getItem(StorageKey.RESTAURANT_ACTIVE);
					if(storageRestaurantActive) {
						const temp = this.restaurants.filter(x => x.id === storageRestaurantActive);
						if(temp.length > 0) {
							this.restautantActive = temp[0];
						}
					} else {
						this.restautantActive = this.restaurants[0];
					}
					this.adminShareDataService.restaurantActive = this.restautantActive;
					this.adminShareDataService.restaurants = restaurants;
					this.shareDataService.setObservable(
						EventName.SWITCH_RESTAURANT,
						this.restautantActive
					);
					this.cdr.detectChanges();
				}
			});
	}

	switchRestaurant(restaurant: IRestaurantItemModel) {
		this.restautantActive = restaurant;
		this.adminShareDataService.restaurantActive = this.restautantActive;
		this.shareDataService.setObservable(
			EventName.SWITCH_RESTAURANT,
			this.restautantActive
		);
		EmloStorage.storageService.setItem(StorageKey.RESTAURANT_ACTIVE, restaurant.id);
	}

	setLanguage(language: string) {
		this.i18nService.language = language;
	}

	logout() {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to log out?"
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.shellService.logout();
				}
			});
	}

	get currentLanguage(): string {
		return this.i18nService.language;
	}

	get languages(): string[] {
		return this.i18nService.supportedLanguages;
	}

	get username(): string | null {
		const credentials = this.credentialsService.credentials;
		return credentials
			? credentials.lastName + " " + credentials.firstName
			: null;
	}

	/**
	 * Settings layout
	 */
	toogleMenuSettings() {
		this.sideNavSettings?.toggle();
		this.isOpeningMenuSettings = !this.isOpeningMenuSettings;
	}

	closeMenuSettings() {
		this.sideNavSettings?.close();
		this.isOpeningMenuSettings = false;
	}

	changeMenuMode() {
		this.shareDataService.setObservable(
			EventName.UPDATE_SETTING_LAYOUT,
			this.settingLayout
		);
		EmloStorage.storageService.setItem(
			StorageKey.LAYOUT_SETTINGS,
			JSON.stringify(this.settingLayout)
		);
	}

	changeThemeMode() {
		const body = document.getElementsByTagName("body")[0];
		body.classList.remove(this.theme);
		this.theme = this.settingLayout.theme;
		body.classList.add(this.theme);
		EmloStorage.storageService.setItem(
			StorageKey.LAYOUT_SETTINGS,
			JSON.stringify(this.settingLayout)
		);
	}

	changeFontSize() {
		const body = document.getElementsByTagName("body")[0];
		const fontSize = Number(this.settingLayout.fontSize);
		if (fontSize === 1) {
			body.style.removeProperty("font-size");
		} else {
			body.style.fontSize = this.settingLayout.fontSize + "em";
		}
		this.shareDataService.setObservable(
			EventName.UPDATE_SETTING_LAYOUT,
			this.settingLayout
		);
		EmloStorage.storageService.setItem(
			StorageKey.LAYOUT_SETTINGS,
			JSON.stringify(this.settingLayout)
		);
	}

	private getLayoutSettingOnLocal(): void {
		const layoutSettings = EmloStorage.storageService.getItem(
			StorageKey.LAYOUT_SETTINGS
		);
		if (layoutSettings) {
			this.settingLayout = JSON.parse(layoutSettings) as SettingLayout;
			this.theme = this.settingLayout.theme;
		}
		// Binding default layout settings to view
		this.changeThemeMode();
		this.changeMenuMode();
		this.changeFontSize();
	}

	/**
	 * Handle Main Menu
	 */
	toggleMainMenu() {
		this.shareDataService.setObservable(
			EventName.TOGGlE_MENU_FLYOUT,
			this.settingLayout
		);
	}
}
