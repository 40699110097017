<div class="box border-sub-1">
	<emlo-box-header [title]="'Modifier'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<!-- <div class="box-toolbar horizontal-end">
		<button class="btn btn-sm " [ngClass]="!advSearch ? 'btn-light bg-color-active active' : 'btn-primary'" (click)="advSearch =! advSearch">
			<mat-icon [svgIcon]="IconName.icon_search" class="mat-icon-ssm"></mat-icon>
			Advance Filter
		</button>
	</div> -->
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-3">
			<div class="search-type" >
				<ng-select class="p-0" name="restaurantId" [items]="restaurants" [(ngModel)]="criteria.restaurantId"
					(change)="onFilter()" bindLabel="name" bindValue="id" [placeholder]="'All restaurants'" appendTo="body">
				</ng-select>
			</div>
			<div class="keyword ml-2">
				<input type="text" class="form-control" name="searchText" [(ngModel)]="criteria.searchText"
					(keyup.enter)="onFilter()" placeholder="{{ 'Search...' | translate }}" autocomplete="off">
			</div>
		</div>
		<emlo-table
			[data]="modifiers"
			[columns]="columns"
			[pagination]="pagination"
			(event)="onTableEvent($event)">
		</emlo-table>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openModifierDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<!-- <button class="btn btn-sm mr-2" [ngClass]="!advSearch ? 'btn-light bg-color-active active' : 'btn-primary'" (click)="advSearch =! advSearch">
			<mat-icon [svgIcon]="IconName.icon_search" class="mat-icon-ssm"></mat-icon>
			Advance Filter
		</button> -->
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="openModifierDetail(null)">Add New</button>
	</ng-template>
</div>
