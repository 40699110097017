import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { CoreErrorDialogComponent } from "./errordialog.component";
import { ErrorDialogService } from "./errordialog.service";
import { SharedModule } from "../shared.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
	imports: [MatButtonModule, MatDialogModule, TranslateModule, SharedModule],
	providers: [ErrorDialogService],
	declarations: [CoreErrorDialogComponent],
	entryComponents: [CoreErrorDialogComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ErrorDialogModule {}
