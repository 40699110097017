<div class="dashboard-section" *ngFor="let menu of mainMenus">
	<fieldset *ngIf="menu?.subMenus?.length > 0">
		<legend>{{ menu.name | translate }}</legend>
		<div class="dashboard-menu">
			<a class="btn btn-dashboard-icons" *ngFor="let subMenu of menu.subMenus" [routerLink]="[subMenu.routerLink]" routerLinkActive="router-link-active" >
				<mat-icon class="fill-by-mode" [svgIcon]="subMenu.icon"></mat-icon>
				<span class="d-block py-2">
					{{ subMenu.name | translate }}
				</span>
			</a>
		</div>
	</fieldset>
</div>
