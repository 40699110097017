<header class="bg-color">
	<div class="d-flex align-items-center">
		<div class="flex-grow-1">
			<div class="d-flex align-items-center">
				<div class="px-2 mh-header cursor-pointer icon-header d-flex align-items-center" (click)="toggleMainMenu()" *ngIf="settingLayout.menu === menuMode.menu_flyout">
					<mat-icon class="mat-icon-sm" [svgIcon]="IconName.icon_menu">
					</mat-icon>
				</div>
				<a href="javascript:void(0)" class="text-white pl-3" routerLink="/" routerLinkActive="active">
					{{ 'APP_NAME' | translate }}
				</a>
			</div>
		</div>
		<div class="mh-header cursor-pointer icon-header d-flex align-items-center" *ngIf="allowSwitchRestaurantGlobal">
			<div *ngIf="restautantActive" class="mh-header d-flex align-items-center justify-content-end cursor-pointer" [matMenuTriggerFor]="menuSwithRestaurant">
				<div class="avatarmenu-header">
					<span class="font-weight-bold" translate>Restaurant: </span>
					{{ restautantActive?.name }}
				</div>
				<div class="avatarmenu-tenant-image-container px-2">
					<mat-icon class="mat-icon-ssm" [svgIcon]="IconName.icon_double_down_arrow"></mat-icon>
				</div>
			</div>
			<mat-menu #menuSwithRestaurant="matMenu" xPosition="before" class="menu-account-header">
				<div class="p-2">
					<a class="d-block text-dark py-2" href="javascript:void(0)"  *ngFor="let restaurant of restaurants" (click)="switchRestaurant(restaurant)">{{ restaurant?.name }}</a>
				</div>
			</mat-menu>

		</div>
		<!-- <div class="mh-header cursor-pointer icon-header d-flex align-items-center" (click)="toogleMenuSettings()"
			[ngClass]="{'bg-color-active': isOpeningMenuSettings}">
			<mat-icon class="mat-icon-sm" [svgIcon]="IconName.icon_settings">
			</mat-icon>
		</div> -->
		<div class="pl-3">
			<div class="mh-header d-flex align-items-center justify-content-end cursor-pointer" [matMenuTriggerFor]="menuAccount" (click)="closeMenuSettings()">
				<div class="avatarmenu-tenant-container text-right">
					<div class="avatarmenu-header">{{ username }}</div>
					<div class="avatarmenu-tenant">{{ userRoleName }}</div>
				</div>
				<div class="avatarmenu-tenant-image-container px-2">
					<mat-icon [svgIcon]="IconName.icon_acc"></mat-icon>
				</div>
			</div>

			<mat-menu #menuAccount="matMenu" xPosition="before" class="menu-account-header">
				<div class="d-flex flex-row align-items-center pt-3">
					<div class="pl-3 pr-2">
						<mat-icon class="mat-icon-xxl" [svgIcon]="IconName.icon_acc"></mat-icon>
					</div>
					<div class="pl-2 pr-3">
						<h3 class="m-0">{{ username }}</h3>
						<!-- <span class="d-block text-dark">contact@emlotech.com</span> -->
						<a class="d-block text-dark" href="javascript:void(0)">My account</a>
					</div>
				</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item"></li>
					<li class="list-group-item">
						<a href="javascript:void(0)" (click)="logout()" class="d-flex align-items-center text-muted">
							<mat-icon class="mat-icon-sm" [svgIcon]="IconName.icon_signout"></mat-icon>
							<span class="pl-1">{{ 'Logout' | translate }}</span>
						</a>
					</li>
				</ul>
			</mat-menu>
		</div>
	</div>
</header>

<!-- <mat-sidenav-container class="sidenav-container-settings" (backdropClick)="closeMenuSettings()">
	<mat-sidenav #sideNavSettings (keydown.escape)="closeMenuSettings()" disableClose [fixedInViewport]="true"
		[fixedTopGap]="36" [position]="'end'" [fixedBottomGap]="0">
		<div class="contextpane-content-settings p-3">
			<div class="d-flex blade-title">
				<div class="flex-grow-1 m-0 text-1 " translate>Layout Settings</div>
				<a href="javascript:void(0)" class="p-2 close-layout-settings" (click)="closeMenuSettings()">
					<mat-icon class="mat-icon-ssm" [svgIcon]="IconName.icon_close"></mat-icon>
				</a>
			</div>
			<div class="settingspane-body py-3">
				<label translate>
					Choose your default mode for the portal menu
				</label>
				<div class="picker-item-list mb-3">
					<mat-button-toggle-group class="w-100" #menuModeToggleGroup="matButtonToggleGroup"
						[(ngModel)]="settingLayout.menu" (ngModelChange)="changeMenuMode()">
						<mat-button-toggle class="w-50" value="menu-flyout">Flyout</mat-button-toggle>
						<mat-button-toggle class="w-50" value="menu-docked">Docked</mat-button-toggle>
					</mat-button-toggle-group>
				</div>

				<label translate>
					Choose a theme
				</label>
				<div class="picker-item-list mb-3">
					<mat-button-toggle-group class="w-100" #themeModeToggleGroup="matButtonToggleGroup"
						[(ngModel)]="settingLayout.theme" (ngModelChange)="changeThemeMode()">
						<mat-button-toggle class="w-50" value="mode-default">Default</mat-button-toggle>
						<mat-button-toggle class="w-50" value="mode-light">Light</mat-button-toggle>
						<mat-button-toggle class="w-50" value="mode-dark">Dark</mat-button-toggle>
					</mat-button-toggle-group>
				</div>

				<label translate>
					Set Font Size
				</label>
				<div class="picker-item-list">
					<mat-button-toggle-group class="w-100" #fontSizeToggleGroup="matButtonToggleGroup"
						[(ngModel)]="settingLayout.fontSize" (ngModelChange)="changeFontSize()">
						<mat-button-toggle class="w-25" value="1">1.0</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.01">1.1</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.015">1.2</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.02">1.3</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.05">1.4</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.1">1.5</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.15">1.6</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.2">1.7</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.25">1.8</mat-button-toggle>
						<mat-button-toggle class="w-25" value="1.3">1.9</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
		</div>
	</mat-sidenav>
</mat-sidenav-container> -->
