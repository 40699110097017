/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:14:49
 * @modify date 2020-02-11 00:14:49
 * @desc [
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 * ]
 */
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { Credentials, CredentialsService } from "./credentials.service";

export interface LoginContext {
	access_token: string;
	tokenType: string;
	refreshToken: string;
	expiresIn: number;
	firstName: string;
	lastName: string;
	roleTypes: number[];
}

@Injectable({
	providedIn: "root"
})
export class AuthenticationService {
	constructor(private credentialsService: CredentialsService) {}

	/**
	 * Authenticates the user.
	 * @param context The login parameters.
	 * @return The user credentials.
	 */
	login(context: LoginContext): void {
		this.credentialsService.setCredentials(context, true);
	}

	/**
	 * Logs out the user and clear credentials.
	 * @return True if the user was logged out successfully.
	 */
	logout(): Observable<boolean> {
		// Customize credentials invalidation here
		this.credentialsService.setCredentials();
		return of(true);
	}
}
