import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { OverviewRoutingModule } from "./overview.routes";
import { OverviewComponent } from "./overview.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CustomerOverviewService } from "./services/customer-overview.service";
import { ChartsModule } from 'ng2-charts';
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	declarations: [OverviewComponent],
	imports: [
		FormsModule,
		ChartsModule,
		CommonModule,
		SharedModule,
		MatIconModule,
		NgbDatepickerModule,
		TranslateModule,
		OverviewRoutingModule,
	],
	providers: [CustomerOverviewService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class CustomerOverviewModule {}
