import { CommonConstant } from "@apps/common/constants/common.constant";
import { CustomerLevel } from "../../customer-level/model/customer-level";

export class RewardPoint {
	id?: string = null;
	concurrencyStamp?: string = null;
	name?: string = null;
	customerLevelID: string = null;
	customerLevelName: string = null;
	pointRatePercent: number = null;
	beginValidDate: string = null;
	endValidDate: string = null;
	isApplyForHolidays: boolean = false;
	priority: number = null;
	customerLevel: CustomerLevel = null;
}

export class RewardPointSearchModel {
	searchText?: string = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

