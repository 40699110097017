import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IconName } from "@apps/common/constants/icon";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { AddPointCustomer, Customer } from "../../model/customer";
import { CustomerService } from "../../services/customer.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";

@Component({
	selector: "app-customer-add-point",
	templateUrl: "add-point.html",
})
export class AddPointCustomerComponent implements OnInit {
	IconName = IconName;
	addPointFormFields: FormlyFieldConfig[] = [];
	addPoint: AddPointCustomer = new AddPointCustomer();
	form = new FormGroup({});
	restaurants: IRestaurantItemModel[] = [];

	constructor(
		public dialogRef: MatDialogRef<AddPointCustomerComponent>,
		@Inject(MAT_DIALOG_DATA) public customer: Customer,
		private restaurantService: RestaurantService,
		private customerService: CustomerService
	) {
		this.addPoint.customerId = customer.customerCode;
	}

	ngOnInit(): void {
		this.createForm();
		// Lấy danh sách nhà hàng từ restaurantService
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					// Chuyển danh sách nhà hàng thành tùy chọn cho trường select
					this.addPointFormFields.find(
						(field) => field.key === "restaurantId"
					).templateOptions.options = restaurants.map(
						(restaurant) => ({
							value: restaurant.id,
							label: restaurant.name,
						})
					);
				}
			});
	}

	private createForm() {
		this.addPointFormFields = [
			{
				type: "input",
				key: "points",
				defaultValue: null,
				templateOptions: {
					label: "Point Value",
					placeholder: "Point Value",
					type: "number",
				},
			},
			{
				type: "input",
				key: "claimedDate",
				templateOptions: {
					label: "Date and Time",
					type: "datetime-local",
				},
			},
			{
				type: "input",
				key: "purchaseAmount",
				defaultValue: null,
				templateOptions: {
					label: "Purchase Amount",
					placeholder: "Amount",
					type: "number",
				},
			},
			{
				type: "select",
				key: "restaurantId",
				templateOptions: {
					label: "Branch",
					options: [],
				},
			},
			{
				type: "input",
				key: "restaurantTicketId",
				defaultValue: null,
				templateOptions: {
					label: "Order Reference",
					placeholder: "Order Reference",
					type: "number",
				},
			},
			{
				type: "select",
				key: "ticketType",
				defaultValue: null,
				templateOptions: {
					label: "Ticket Type",
					options: [
						{ value: "DINE_IN", label: "Dine In" },
						{ value: "TAKE_OUT", label: "Take Out" },
					],
					type: "string",
				},
			},
			{
				type: "textarea",
				key: "notes",
				defaultValue: null,
				templateOptions: {
					label: "Notes",
					placeholder: "Notes",
					rows: 4,
					maxLength: 250
				},
			},
			{
				type: "emlo-input",
				key: "customerCode",
				templateOptions: {
					type: "hidden",
				},
			},
		];
	}

	close() {
		this.dialogRef.close();
	}

	updatePoint() {
		this.customerService.updatePoint(this.addPoint).subscribe(() => {
			this.dialogRef.close(true);
		});
	}
}
