import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CoreLoadingComponent } from "./loading.component";
import { SharedModule } from "../shared.module";

@NgModule({
	imports: [SharedModule],
	declarations: [CoreLoadingComponent],
	exports: [CoreLoadingComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmloLoadingModule {}
