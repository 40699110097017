import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { CustomerServiceSetting } from "../model/settings";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class CustomerServiceSettingService {
	constructor(private apiService: ApiService) {}

	getSettings(): Observable<CustomerServiceSetting[]> {
		return this.apiService.get(
			environment.prefix_api_url + "customer/settings"
		);
	}

	updateSettings(context: CustomerServiceSetting[]) {
		return this.apiService.post(
			environment.prefix_api_url + "customer/settings",
			context
		);
	}
}
