import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloRestaurantUserTypeComponent } from "./restaurant-usertypes.component";
import { EmloRestaurantUserTypeListComponent } from "./components/list/restaurant-usertypes.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloRestaurantUserTypeDetailComponent } from "./components/detail/restaurant-usertypes.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloRestaurantUserTypeComponent,
		children: [
			{
				path: "",
				component: EmloRestaurantUserTypeListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloRestaurantUserTypeListComponent,
			},
			{ path: "add", component: EmloRestaurantUserTypeDetailComponent },
			{ path: "edit", component: EmloRestaurantUserTypeDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloRestaurantUserTypeRoutingModule {}
