<formly-form [model]="model" [fields]="conditionFields" [form]="form" *ngIf="meta && meta.length > 0">
    <div class="form-group mt-2" *ngIf="form && form.controls.filter.value && form.controls.filter.value.length > 0">
        <p>Condition<span>*</span></p>
        <!-- <ng-select [items]="people$ | async"
           bindLabel="name"
           autofocus
           bindValue="id"
           [(ngModel)]="selectedPersonId">
        </ng-select> -->
        <mat-radio-group [(ngModel)]="statement" (ngModelChange)="onChangeStatement()">
            <mat-radio-button class="mr-4 mt-1 d-block" [value]="'and'">
                All of the conditions are met (AND)
            </mat-radio-button>
            <mat-radio-button class="mr-4 mt-1 d-block" [value]="'or'">
                Any of the conditions are met (OR)
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div  class="search-section">
        <button class="mt-3 btn-sm btn btn-outline-primary mr-3" (click)="onResetFilter()">Clear</button>
        <emlo-button    [className]="'btn-sm mt-3'"
                        [color]="'primary'" 
                        [textButton]="'Search'"
                        [isLoading]="isLoading"
                        [disabled]="!(form && form.controls.filter.value && form.controls.filter.value.length > 0)" 
                        (submit)="onSubmit($event)">
        </emlo-button>
    </div>
</formly-form>
