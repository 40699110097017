import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
@Component({
	selector: "emlo-input",
	template: ` <input
        class="form-control"
				autocomplete="off"
		[formControl]="formControl"
        [formlyAttributes]="field"
        type="{{field.templateOptions?.type}}"
	/>`,
	styles: [],
})
export class EmloInputComponent extends FieldType {
}
