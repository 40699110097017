import { Injectable } from "@angular/core";
import { IRestaurantItemModel } from "../model/restaurant-item.model";
import { IMenuGroupModel } from "../model/menu-group.model";
import { ITaxModel } from "../model/tax.model";
import { IPrinterGroupModel } from "../model/printer-group.model";
import { IModifierGroupModel } from "../model/modifier-group.model";
import { ICategoryModel } from "../model/category.model";
import { IRoleModel } from "../model/role.model";
import { IPermissionModel } from "../model/permission.model";
import { IGrantTypeModel } from "../model/grantype.mode";
@Injectable()
export class AdminShareDataService {
	private _restaurantActive: IRestaurantItemModel;

	public get restaurantActive(): IRestaurantItemModel {
		return this._restaurantActive;
	}

	public set restaurantActive(value: IRestaurantItemModel) {
		this._restaurantActive = value;
	}

	private _restaurants: IRestaurantItemModel[];

	public get restaurants(): IRestaurantItemModel[] {
		return this._restaurants;
	}

	public set restaurants(value: IRestaurantItemModel[]) {
		this._restaurants = value;
	}

	private _menuGroups: IMenuGroupModel[];

	public get menuGroups(): IMenuGroupModel[] {
		return this._menuGroups;
	}

	public set menuGroups(value: IMenuGroupModel[]) {
		this._menuGroups = value;
	}

	private _taxs: ITaxModel[];

	public get taxs(): ITaxModel[] {
		return this._taxs;
	}

	public set taxs(value: ITaxModel[]) {
		this._taxs = value;
	}

	private _printerGroups: IPrinterGroupModel[];

	public get printerGroups(): IPrinterGroupModel[] {
		return this._printerGroups;
	}

	public set printerGroups(value: IPrinterGroupModel[]) {
		this._printerGroups = value;
	}

	private _reportGroups: Array<string>;

	public get reportGroups(): Array<string> {
		return this._reportGroups;
	}

	public set reportGroups(value: Array<string>) {
		this._reportGroups = value;
	}

	private _modifierGroups: IModifierGroupModel[];

	public get modifierGroups(): IModifierGroupModel[] {
		return this._modifierGroups;
	}

	public set modifierGroups(value: IModifierGroupModel[]) {
		this._modifierGroups = value;
	}

	private _categories: ICategoryModel[];

	public get categories(): ICategoryModel[] {
		return this._categories;
	}

	public set categories(value: ICategoryModel[]) {
		this._categories = value;
	}

	private _roles: IRoleModel[];

	public get roles(): IRoleModel[] {
		return this._roles;
	}

	public set roles(value: IRoleModel[]) {
		this._roles = value;
	}

	private _permissions: IPermissionModel[];

	public get permissions(): IPermissionModel[] {
		return this._permissions;
	}

	public set permissions(value: IPermissionModel[]) {
		this._permissions = value;
	}

	private _clientRoles: IRoleModel[];

	public get clientRoles(): IRoleModel[] {
		return this._clientRoles;
	}

	public set clientRoles(value: IRoleModel[]) {
		this._clientRoles = value;
	}

	private _grantTypes: IGrantTypeModel[];

	public get grantTypes(): IGrantTypeModel[] {
		return this._grantTypes;
	}

	public set grantTypes(value: IGrantTypeModel[]) {
		this._grantTypes = value;
	}
}
