import { IItemRequestSearchModel } from './../model/item-request-search.model';
import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { Item, ItemSearchModel } from "../model/item";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";

@Injectable()
export class ItemService {
	constructor(private apiService: ApiService) {}

	getItems(obj: ItemSearchModel): Observable<IApiPagingResponseModel<any>> {
		return this.apiService.post(
			environment.prefix_api_url + "item/list",
			obj
		);
	}

	getItem(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "item/getbyid",
			params
		);
	}

	createItem(context: Item) {
		return this.apiService.post(
			environment.prefix_api_url + "item/create",
			context
		);
	}

	updateItem(context: Item) {
		return this.apiService.post(
			environment.prefix_api_url + "item/update",
			context
		);
	}

	enableItem(context: Item) {
		return this.apiService.post(
			environment.prefix_api_url + "item/enable",
			{ id: context.id }
		);
	}

	disableItem(context: Item) {
		return this.apiService.post(
			environment.prefix_api_url + "item/disable",
			{ id: context.id }
		);
	}

	deleteItem(context: Item) {
		return this.apiService.post(
			environment.prefix_api_url + "item/delete",
			{ id: context.id }
		);
	}
}
