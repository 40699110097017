import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { EmloBaseTableComponent } from "./base.component";

import { EmloHeaderTableComponent } from "../header/header.component";
import { EmloPaginationTableComponent } from "../pagination/pagination.component";

import { GlobalSearchPipe } from "../../pipes/global-search-pipe";
import { RenderPipe } from "../../pipes/render-pipe";
import { SearchPipe } from "../../pipes/search-pipe";
import { SortPipe } from "../../pipes/sort.pipe";

import { EmloTableTHeadComponent } from "../thead/thead.component";
import { EmloPaginationModule } from "@apps/shared/pagination/pagination.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	declarations: [
		EmloBaseTableComponent,
		EmloHeaderTableComponent,
		EmloPaginationTableComponent,
		EmloTableTHeadComponent,

		// Pipes
		SearchPipe,
		RenderPipe,
		GlobalSearchPipe,
		SortPipe
	],
	imports: [CommonModule, EmloPaginationModule, MatIconModule],
	exports: [EmloBaseTableComponent]
})
export class EmloBaseModule {}
