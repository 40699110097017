import { Campaigns } from "../../customer-service/campaigns/model/campaigns";

export const CAMPAIGNS: Campaigns[] = [
	{
		id: 1,
		name: "New Template",
		description: "TODO",
		image: "",
		levelCode: "ABC",
		lockPeriodId: 123,
		validFrom: "10/10/2021",
		validTo: "20/10/2021",
	},
	{
		id: 2,
		name: "New Template 2",
		description: "TODO 2",
		image: "",
		levelCode: "ABC 2",
		lockPeriodId: 1232,
		validFrom: "12/10/2021",
		validTo: "22/10/2021",
	}
]
