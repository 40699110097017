<div class="box border-sub-1 order-list">
	<emlo-box-header [title]="'Orders'"></emlo-box-header>
	<div class="box-content mh-50" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-2">
			<div class="flex-grow-1">
				<div class="d-flex flex-row align-items-center">
					<form class="form-inline rang-date">
						<div class="form-group hidden">
							<div class="input-group">
								<input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
									[autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
									[dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1" />
								<ng-template #t let-date let-focused="focused">
									<span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
										[class.faded]="
										isHovered(date) || isInside(date)
										" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
										{{ date.day }}
									</span>
								</ng-template>
							</div>
						</div>
						<div class="form-group">
							<div class="input-group">
								<input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate"
									[value]="formatter.format(fromDate)" (input)="
									fromDate = validateInput(
										fromDate,
											dpFromDate.value
										)
									" />
								<div class="input-group-append">
									<button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
										type="button">
										<mat-icon [svgIcon]="IconName.icon_calendar"></mat-icon>
									</button>
								</div>
							</div>
						</div>
						<div class="form-group ml-2">
							<div class="input-group">
								<input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
									[value]="formatter.format(toDate)" (input)="
									toDate = validateInput(toDate, dpToDate.value)
									" />
								<div class="input-group-append">
									<button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
										type="button">
										<mat-icon [svgIcon]="IconName.icon_calendar"></mat-icon>
									</button>
								</div>
							</div>
						</div>
					</form>
					<div class="search-type ml-2">
						<ng-select class="p-0" name="restaurantId" [items]="restaurants"
							[(ngModel)]="criteria.restaurantId" (change)="onFilter()" bindLabel="name" bindValue="id"
							[placeholder]="'All restaurants'" appendTo="body">
						</ng-select>
					</div>
					<div class="keyword ml-2">
						<input type="text" class="form-control" name="displayId" [(ngModel)]="criteria.displayId"
							(keyup.enter)="onFilter()" placeholder="{{ 'Check #...' | translate }}" autocomplete="off">
					</div>
					<div class="keyword ml-2">
						<input type="text" class="form-control" name="refId" [(ngModel)]="criteria.refId"
							(keyup.enter)="onFilter()" placeholder="{{ 'Ref #...' | translate }}" autocomplete="off">
					</div>
				</div>

			</div>
		</div>
		<app-loader [isLoading]="isLoading"></app-loader>

		<emlo-table [configuration]="configuration" [data]="orders" [columns]="columns" [pagination]="pagination"
			(event)="onTableEvent($event)">
		</emlo-table>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openOrderDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<!-- <a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a> -->
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="openOrderDetail(null)">Add
			New</button>
	</ng-template>
	<ng-template #createdAtFromTpl let-row>
		{{ row.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}
	</ng-template>
</div>
