import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloCashReportModule } from "./cash-report";
import { EmloSaleSummaryReportModule } from "./sale-summary-report";
import { EmloDetailSaleReportModule } from "./detail-sale-report";
import { EmloMenuItemSalesAnalysisReportModule } from "./menu-item-sales-analysis";
import { EmloTotalSaleReportModule } from "./total-sale-report";

const routes: Routes = [
	{
		path: "",
		redirectTo: AppRoutesNames.CASH_REPORT
	},
	{
		path: AppRoutesNames.CASH_REPORT,
		loadChildren: () => EmloCashReportModule
	},
	{
		path: AppRoutesNames.SALE_SUMMARY_REPORT,
		loadChildren: () => EmloSaleSummaryReportModule
	},
	{
		path: AppRoutesNames.DETAIL_SALE_REPORT,
		loadChildren: () => EmloDetailSaleReportModule
	},
	{
		path: AppRoutesNames.MENU_ITEM_SALE_ANALYSIS_REPORT,
		loadChildren: () => EmloMenuItemSalesAnalysisReportModule
	},
	{
		path: AppRoutesNames.TOTAL_SALE_REPORT,
		loadChildren: () => EmloTotalSaleReportModule
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloReportRoutingModule {}
