<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Order Information' : 'Order Information'"
		[headerActionsTemplate]="headerActionsTemplate">
	</emlo-box-header>
	<div class="box-content">
		<app-loader [isLoading]="isLoading"></app-loader>
		<div class="form-row" *ngIf="order">
			<div class="form-group col-md-6">
				<table>
					<tr>
						<td><span class="font-weight-bold">Restaurant Name</span></td>
						<td>: {{order.restaurantName}}</td>
					</tr>
					<tr>
						<td class="font-weight-bold">Server Name</td>
						<td>: {{order.owner}}</td>
					</tr>
					<tr>
						<td class="font-weight-bold">Ticket Type</td>
						<td>: {{order.ticketType}} - {{order.ticketTypeChinese}}</td>
					</tr>
				</table>
			</div>
			<div class="form-group col-md-6">
				<table class="float-right text-right">
					<tr>
						<td class="text-bold">Check #:</td>
						<td>{{order.displayId}}</td>
					</tr>
					<tr>
						<td class="text-bold">Ref #:</td>
						<td>{{order.clientEntityId}}</td>
					</tr>
					<tr>
						<td class="text-bold">Created at:</td>
						<td>{{order.createdAt}}</td>
					</tr>
					<tr>
						<td class="text-bold">Table:</td>
						<td>{{order.tableNumber}}</td>
					</tr>
					<tr *ngIf="order.customerId">
						<td class="text-bold">Customer code:</td>
						<td>{{order.customerId}}</td>
					</tr>
					<tr *ngIf="order.customerId">
						<td class="text-bold">Customer name:</td>
						<td>{{order.customerName}}</td>
					</tr>
					<tr *ngIf="order.customerId">
						<td class="text-bold">Point(s):</td>
						<td>{{order.currentPoints}}</td>
					</tr>
					
				</table>
			</div>
			<div class="form-group col-md-6">
				<table>
					
				</table>
			</div>
			<div class="form-group col-md-12">
				<h3 class="mt-2 mb-1 font-weight-bold">Order Detail</h3>
				<table class="table table-striped">
					<thead>
						<tr>
							<th>Item</th>
							<th class="text-right">Quantity</th>
							<th class="text-right">Price (VND)</th>
							<th class="text-right">Sub total (VND)</th>
						</tr>
					</thead>
					<tbody *ngIf="order.items && order.items.length > 0">
						<tr *ngFor="let item of order.items; let i = index;">
							<td>{{item.name}}</td>
							<td class="text-right">{{item.quantity | number}}</td>
							<td class="text-right">{{item.price | number}}</td>
							<td class="text-right">{{item.subTotal | number}}</td>
						</tr>
					</tbody>
					<tbody *ngIf="!order.items">
						<tr>
							<td colspan="4">No items are found</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colspan="3" class="text-right">Sub total</td>
							<td class="text-right">{{order.subTotal | number}}</td>
						</tr>
						<tr>
							<td colspan="3" class="text-right">Discount</td>
							<td class="text-right">{{order.totalDiscount | number}}</td>
						</tr>
						<tr>
							<td colspan="3" class="text-right">Total tax</td>
							<td class="text-right">{{order.totalTax | number}}</td>
						</tr>
						<tr>
							<td colspan="3" class="text-right">Service charge ({{order.serviceChargeRate}} %)</td>
							<td class="text-right">{{order.serviceCharge | number}}</td>
						</tr>
						<tr>
							<td colspan="3" class="text-right">Rounded by</td>
							<td class="text-right">{{order.roundingVn | number}}</td>
						</tr>
						<tr>
							<td colspan="3" class="text-right">Total price</td>
							<td class="text-right">{{order.totalPrice | number}}</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div *ngIf="order.claimedBenefits" class="form-group col-md-12">
				<h3 class="mt-2 mb-1 font-weight-bold">Loyalty information</h3>
				<table class="table table-striped">
					<thead>
						<tr>
							<th>#</th>
							<th>Gift</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let benefit of order.claimedBenefits; let i = index;">
							<td>{{i + 1}}</td>
							<td>{{benefit.name}}</td>
						</tr>
						
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="box-footer"></div>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-primary btn-sm" (click)="cancel()">
			Back
		</button>
	</ng-template>
</div>
