import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomerComponent } from "./customer.component";
import { CustomerListComponent } from "./components/list/customer.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { CustomerDetailComponent } from "./components/detail/customer.detail.component";

const routes: Routes = [
	{
		path: "",
		component: CustomerComponent,
		children: [
			{
				path: "",
				component: CustomerListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: CustomerListComponent,
			},
			{ path: "add", component: CustomerDetailComponent },
			{ path: "edit", component: CustomerDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomerRoutingModule {}
