<div class="p-3">
	<formly-form [model]="sendGift" [fields]="sendGiftFormFields" [form]="form"></formly-form>
	<div class="text-right">
		<button type="button" class="btn btn-danger" (click)="close()" translate>
			Close
		</button>
		<button type="button" class="btn btn-primary ml-2" (click)="onSendGift()" *ngIf="isProcessing != true" translate>
			Send Gift
		</button>
		<span *ngIf="isProcessing == true">Sending...</span>
	</div>
</div>
