import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloUserComponent } from "./user.component";
import { EmloUserListComponent } from "./components/list/user.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloUserDetailComponent } from "./components/detail/user.detail.component";
import { EmloUserChangePassComponent } from "./components/change-pass/change-pass.component";

const routes: Routes = [
	{
		path: "",
		component: EmloUserComponent,
		children: [
			{
				path: "",
				component: EmloUserListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloUserListComponent,
			},
			{ path: "add", component: EmloUserDetailComponent },
			{ path: "edit", component: EmloUserDetailComponent },
			{ path: "changepass", component: EmloUserChangePassComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloUserRoutingModule {}
