<div class="box border-sub-1">
	<emlo-box-header [title]="'Cash Summary Report'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content mh-50" *ngIf="canAccess; else contactAdmin">
		<div class="w-50 form-group">
			<label translate>Restaurant</label>
			<ng-select [items]="restaurants" bindLabel="name" autofocus placeholder="Select Restaurant" bindValue="id" [(ngModel)]="restaurantId">
			</ng-select>
		</div>

		<div class="form-group">
			<mat-radio-group [(ngModel)]="actionReport" (ngModelChange)="switchActionReport()">
				<mat-radio-button class="mr-4" *ngFor="let time of listTimeReports" [value]="time.action">
					{{ time.name }}
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="d-lg-flex d-md-inline justify-content-start align-items-start">
			<div class="pr-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="startDate" (ngModelChange)="changeDateReport()"
							   [matDatepicker]="start" placeholder="{{ 'Start Date' | translate }}" [max]="endDate">
						<mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
						<mat-datepicker #start></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="startTime" [format]="24" [controlOnly]="true"
											  [disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="pl-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="endDate" (ngModelChange)="changeDateReport()" [matDatepicker]="end"
							   placeholder="{{ 'End Date' | translate }}" [min]="startDate">
						<mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
						<mat-datepicker #end></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="endTime" [format]="24" [controlOnly]="true"
											  [disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="d-flex align-items-start">
				<emlo-button class="mx-1 b-small b-full" [textButton]="'View Report'" [color]="'primary'"
							 [textProcessing]="'View Report'" (click)="viewReport()" [disabled]="inprogress">
				</emlo-button>

				<emlo-button class="mx-1 b-small b-full" [textButton]="'Export Excel'" [color]="'success'"
							 [textProcessing]="'Export Excel'" (click)="exportReport()" [disabled]="inprogress">
				</emlo-button>

				<emlo-button class="mx-1 b-small b-full" [textButton]="'Export Pdf'" [color]="'primary'"
							 [textProcessing]="'Export Pdf'" (click)="exportReport()" [disabled]="inprogress">
				</emlo-button>
			</div>
		</div>
		<app-loader [isLoading]="inprogress"></app-loader>

		<div class="content-report" *ngIf="!inprogress">
			<div class="alert alert-info" role="alert" *ngIf="cashSummaryReport">
				<div>{{ 'Generated by' | translate}}: {{ cashSummaryReport.updatedByUser }}</div>
				<div>{{ 'Generated at' | translate}}: {{ cashSummaryReport.updatedAt }}</div>
				<div>{{ 'Report Period' | translate}}: {{ cashSummaryReport.from }} to {{ cashSummaryReport.to }}</div>
			</div>
			<table class="table table-bordered table-striped mb-0"
				   *ngIf="cashSummaryReport && cashSummaryReport.paymentTypeSummary; else noDataReport">
				<thead>
					<tr>
						<th>{{ 'Payment Type' | translate }}</th>
						<th class="text-right">{{ 'No. of Checks' | translate }}</th>
						<th class="text-right">{{ 'Total' | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of cashSummaryReport.paymentTypeSummary">
						<td>{{ item.paymentTypeDisplay }}</td>
						<td class="text-right">{{ item.numberOfChecks | number }}</td>
						<td class="text-right">{{ item.totalAmount | number }}</td>
					</tr>
					<tr class="font-weight-bold">
						<td>{{ 'Total' | translate }}</td>
						<td class="text-right">{{ cashSummaryReport.totalChecks | number }}</td>
						<td class="text-right">{{ cashSummaryReport.total | number }}</td>
					</tr>
				</tbody>
			</table>
			<ng-template #noDataReport>
				<div class="py-3">{{ 'No data' | translate }}</div>
			</ng-template>
		</div>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
</div>
