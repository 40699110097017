<div class="box border-sub-1">
	<emlo-box-header [title]="'Sales Summary Report'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content mh-50" *ngIf="canAccess; else contactAdmin">
		<div class="row">
			<div class="form-group col-sm-12 col-md-6">
				<label translate>Restaurant</label>
				<ng-select [items]="restaurants" bindLabel="name" autofocus bindValue="id" placeholder="Select Restaurant"  [(ngModel)]="restaurantId">
				</ng-select>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label translate>Ticket Type</label>
				<ng-select name="ticketTypeName" [(ngModel)]="ticketType" bindLabel="name" bindValue="ticketType"
					[items]="ticketTypes" placeholder="{{ 'Ticket Type' | translate }}" appendTo="body" [clearable]="false">
				</ng-select>
			</div>
		</div>

		<div class="form-group">
			<mat-radio-group [(ngModel)]="actionReport" (ngModelChange)="switchActionReport()">
				<mat-radio-button class="mr-4" *ngFor="let time of listTimeReports" [value]="time.action">
					{{ time.name }}
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="d-lg-flex d-md-inline justify-content-start align-items-start">
			<div class="pr-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="startDate" (ngModelChange)="changeDateReport()"
							[matDatepicker]="start" placeholder="{{ 'Start Date' | translate }}" [max]="endDate">
						<mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
						<mat-datepicker #start></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="startTime" [format]="24" [controlOnly]="true"
							[disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="pl-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="endDate" (ngModelChange)="changeDateReport()" [matDatepicker]="end"
							placeholder="{{ 'End Date' | translate }}" [min]="startDate">
						<mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
						<mat-datepicker #end></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="endTime" [format]="24" [controlOnly]="true"
							[disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="d-flex align-items-start">
				<emlo-button class="mx-1 b-small b-full" [textButton]="'View Report'" [color]="'primary'"
					[textProcessing]="'View Report'" (click)="viewReport()" [disabled]="inprogress">
				</emlo-button>

				<emlo-button class="mx-1 b-small b-full" [textButton]="'Export Pdf'" [color]="'primary'"
					[textProcessing]="'Export Pdf'" (click)="exportReport()" [disabled]="inprogress">
				</emlo-button>
			</div>
		</div>
		<app-loader [isLoading]="inprogress"></app-loader>

		<div class="content-report" *ngIf="!inprogress">
			<div class="alert alert-info" role="alert" *ngIf="salesSummaryReport">
				<div>{{ 'Ticket Type' | translate }}: {{ salesSummaryReport.ticketType }}</div>
				<div>{{ 'Generated by' | translate}}: {{ salesSummaryReport.updatedByUser }}
				</div>
				<div>{{ 'Generated at' | translate}}: {{ salesSummaryReport.updatedAt }}</div>
				<div>{{ 'Report Period' | translate}}: {{ salesSummaryReport.from }} to {{ salesSummaryReport.to }}
				</div>
			</div>
			<table class="table table-bordered table-striped mb-0">
				<thead>
					<tr>
						<th></th>
						<th class="text-right">{{ 'Total' | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{ 'Paid' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.paid | number }}</td>
					</tr>
					<tr>
						<td>{{ 'On Credit' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.onCredit | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Total Before Tax' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.totalBeforeTax | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Total Tax' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.totalTax | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Total After Tax' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.totalAfterTax | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Number of Orders' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.numberOfTickets | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Number of Guests' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.numberOfGuests | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Average Per Order' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.avgPerTicket | number }}</td>
					</tr>
					<tr>
						<td>{{ 'Average Per Guest' | translate }}</td>
						<td class="text-right">{{ salesSummaryReport.avgPerGuest | number }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
</div>
