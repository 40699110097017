import {
	PaginationModelFactory,
	IApiPagingResponseModel,
} from "@apps/common/model/api.reponse.model";
import { ApiRequestModelFactory } from "@apps/common/model/api.request.model";
import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { CommonComponent } from "@apps/core/common/common-component";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Event } from "@apps/shared/table";
import {
	Modifier,
	ModifierFactory,
	ModifierSearchModel,
} from "../../model/modifier";
import { ModifierService } from "../../services/modifier.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { EmloModifierDetailComponent } from "../detail/modifier.detail.component";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { ShareDataService } from "@apps/core/services/data.service";
import { EventName } from "@apps/common/constants/event";
import { Subscription, Subject, empty } from "rxjs";
import { ITaxModel } from "@apps/modules/emlo-admin/shared/model/tax.model";
import { IModifierGroupModel } from "@apps/modules/emlo-admin/shared/model/modifier-group.model";
import { IModifierSearchRequestModel } from "../../model/modifier-search-request.model";
import { switchMap, debounceTime, catchError } from "rxjs/operators";
import { CredentialsService, untilDestroyed } from "@apps/core";
import { IPagingnationModel } from "@apps/common/model/api.reponse.model";
import { BaseFilterComponent } from "../../../base-filter-component";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { Router } from "@angular/router";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";

@Component({
	selector: "app-modifier-list",
	templateUrl: "./list.html",
})
export class EmloModifierListComponent extends AdminBaseComponent implements OnInit {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public columns: Columns[] = [];
	public modifiers: Modifier[] = [];

	criteria: ModifierSearchModel = new ModifierSearchModel();
	restaurants: IRestaurantItemModel[] = [];
	pagination: IPagingnationModel = PaginationModelFactory.create();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private modifierService: ModifierService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getModifiers();
		// this.initDataSource();
		// this.initFilterMetaData();
		this.configColumnForCategoryTable();
		// this.switchRestaurant$ = this.shareDataService
		// 	.getObservable(EventName.SWITCH_RESTAURANT)
		// 	.pipe(untilDestroyed(this))
		// 	.subscribe(() => {
		// 		this.request.restaurantId = this.adminShareDataService?.restaurantActive?.id;
		// 		if (this.request.restaurantId) {
		// 			this.dataSource$.next(this.request);
		// 			this.getTaxs();
		// 			this.getModifierGroupSelection();
		// 		}
		// 	});
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getModifiers();
		}
	}

	// private initDataSource(): void {
	// 	this.dataSource$
	// 		.pipe(untilDestroyed(this))
	// 		.pipe(debounceTime(200))
	// 		.pipe(
	// 			catchError(() => {
	// 				this.initDataSource();
	// 				return empty();
	// 			})
	// 		)
	// 		.pipe(switchMap(request => this.modifierService.getModifiers(request)))
	// 		.subscribe((categories: IApiPagingResponseModel<Modifier[]>) => {
	// 			if(categories) {
	// 				this.modifiers = categories.data;
	// 				this.pagination = categories;
	// 			}
	// 		});
	// }

	private getModifiers() {
		this.modifierService
			.getModifiers(this.criteria)
			.subscribe((modifiers) => {
				if (modifiers) {
					this.modifiers = modifiers.data;
					this.pagination = modifiers;
				}
			});
	}

	private getTaxs(): void {
		// if (this.adminShareDataService.taxs) return;
		// this.modifierService.getTaxs(null).subscribe((taxs: ITaxModel[]) => {
		// 	this.adminShareDataService.taxs = taxs;
		// });
	}

	private getModifierGroupSelection(): void {
		// this.modifierService
		// 	.getModifierGroupSelection(this.adminShareDataService.restaurantActive.id)
		// 	.subscribe((modifierGroups: IModifierGroupModel[]) => {
		// 		this.adminShareDataService.modifierGroups = modifierGroups;
		// 	});
	}

	private configColumnForCategoryTable(): void {
		this.columns = [
			{ key: "nameCn", title: "Name CN" },
			{ key: "nameEn", title: "Name EN" },
			{ key: "nameVi", title: "Name VI" },
			{ key: "menuModifierGroupName", title: "Modifier Group" },
			{ key: "price", title: "Price" },
			{ key: "restaurantNames", title: "Selling at" },
			// { key: "sortOrder", title: "Sort Order", width: "120px" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteModifier(category: Modifier): void {
		const data = { id: category.id };
		this.modifierService
			.deleteModifier(JSON.stringify(data))
			.subscribe(() => {
				this.getModifiers();
			});
	}

	delete(category: Modifier): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteModifier(category);
				}
			});
	}

	openModifierDetail(modifier: Modifier): void {
		if (!modifier) {
			this.router.navigate(["/product-management/modifier/add"]);
		} else {
			this.router.navigate(["/product-management/modifier/edit"], {
				queryParams: { id: modifier.id },
			});
		}
		// if (!category) {
		// 	category = ModifierFactory.create();
		// 	category.restaurantIds = this.request.restaurantId;
		// }
		// this.dialog
		// 	.open(EmloModifierDetailComponent, {
		// 		data: category
		// 	})
		// 	.afterClosed()
		// 	.subscribe(isSaved => {
		// 		if (isSaved) {
		// 			this.dataSource$.next(this.request);
		// 		}
		// 	});
	}

	onFilter() {
		this.criteria.pageNum = 1;
		this.getModifiers();
	}
}
