import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	Inject
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { IconName } from "@apps/common/constants/icon";
import { ClientService } from "../../services/client.service";
import { FormlyFieldConfig } from "@ngx-formly/core";

@Component({
	selector: "app-display-secret",
	templateUrl: "display-secret.html"
})
export class EmloDisplaySecretComponent extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form = new FormGroup({});
	displaySecretFormFields: FormlyFieldConfig[] = [];
	constructor(
		private cdr: ChangeDetectorRef,
		private clientService: ClientService,
		public dialogRef: MatDialogRef<EmloDisplaySecretComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		super();
		this.createForm();
	}

	ngOnInit(): void {
		this.createForm();
	}

	private createForm(): void {
		this.displaySecretFormFields = [
			{
				type: "input",
				key: "confirmPassword",
				defaultValue: null,
				templateOptions: {
					label: "Please kindly provide your administrator password",
					placeholder: "Your administrator password",
					type: "password"
				}
			}
		];
	}

	submit() {
		this.dialogRef.close(this.form.value.confirmPassword);
	}

	cancel() {
		this.resetFormAndClose();
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.form.reset();
		this.dialogRef.close(isReload);
	}
}
