import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";

import { LoginRoutingModule } from "./login-routing.module";
import { LoginService } from "./services/login.service";
import { LoginComponent } from "./components/login.component";
import { SharedModule } from "@apps/shared";
import { EmloButtonModule } from "@apps/shared/button";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		MatIconModule,
		SharedModule,
		EmloButtonModule,
		LoginRoutingModule
	],
	providers: [LoginService],
	declarations: [LoginComponent]
})
export class LoginModule {}
