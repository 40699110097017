import { CommonConstant } from "@apps/common/constants/common.constant";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

export class DateUtils {
	public static ConvertTo(input: Date, output: string = "DD/MM/YYYY") {
		return moment(input).format(output);
	}

	public static ConvertDateStructToDate(
		ngDateStruct: NgbDateStruct,
		output: string = "DD/MM/YYYY"
	) {
		if (ngDateStruct) {
			const date = new Date(
				ngDateStruct.year,
				ngDateStruct.month - 1,
				ngDateStruct.day
			);
			return moment(date).format(output);
		}
		return null;
	}

	public static parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = moment(
				value,
				CommonConstant.DEFUALT_SERVER_FORMAT
			).toDate();
			return {
				day: date.getDate(),
				month: date.getMonth() + 1,
				year: date.getFullYear(),
			};
		}
		return null;
	}
}
