import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloMenuGroupComponent } from "./menu-group.component";
import { EmloMenuGroupListComponent } from "./components/list/menu-group.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloMenuGroupDetailComponent } from "./components/detail/menu-group.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloMenuGroupComponent,
		children: [
			{
				path: "",
				component: EmloMenuGroupListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloMenuGroupListComponent,
			},
			{ path: "add", component: EmloMenuGroupDetailComponent },
			{ path: "edit", component: EmloMenuGroupDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloMenuGroupRoutingModule {}
