import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloFormModule } from "@apps/shared/form";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { EmloCouponDetailComponent } from "./components/detail/coupon.detail.component";
import { EmloCouponListComponent } from "./components/list/coupon.list.component";
import { EmloCouponComponent } from "./coupon.component";
import { EmloCouponRoutingModule } from "./coupon.routes";
import { CouponService } from "./services/coupon.service";

export const DATE_PICKER_FORMATS = {
	parse: {
		dateInput: "DD/MM/YYYY"
	},
	display: {
		dateInput: "DD/MM/YYYY",
		monthYearLabel: "YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "YYYY"
	}
};
@NgModule({
	declarations: [
		EmloCouponComponent,
		EmloCouponListComponent,
		EmloCouponDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatSlideToggleModule,
		MatDialogModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatRadioModule,
		MatTabsModule,
		NgbDatepickerModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		EmloFormModule,
		FormlyBootstrapModule,
		EmloCouponRoutingModule,
	],
	providers: [
		CouponService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS }
	],
	entryComponents: [EmloCouponDetailComponent]
})
export class EmloCouponModule {}
