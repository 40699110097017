import {
	Component,
	OnInit,
	ViewContainerRef,
	ComponentRef,
	ViewChild,
	AfterViewInit,
	OnDestroy,
} from "@angular/core";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { ItemService } from "../../services/item.service";
import { Item, ItemSearchModel } from "../../model/item";
import {
	IdControl,
	NameEnControl,
	NameViControl,
	NameCnControl,
	ButtonTextColorControl,
	BuyPriceSalePriceControl,
	ItemIdControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { IconName } from "@apps/common/constants/icon";
import {
	ItemDetailModel,
	ItemDetailFactory,
} from "../../model/item-detail";
import { EmloItemModifierGroupComponent } from "../modifier-groups/item.modifier-groups.component";
import { Subject } from "rxjs";
import { CredentialsService } from "@apps/core";
import { TaxService } from "../../services/tax.service";
import { PrinterService } from "../../services/printer.service";
import { ItemMenuGroupService } from "../../services/menu-group.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { ReportGroupService } from "../../services/report-group.service";
import { ModifierGroupService } from "../../../modifier-group/services/modifier-group.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { map } from "rxjs/operators";

@Component({
	selector: "app-productmanagement-item-detail",
	styleUrls: ["./style.scss"],
	templateUrl: "detail.html",
})
export class EmloItemDetailComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy, AfterViewInit {
	IconName = IconName;
	public form = new FormGroup({});
	public itemFormFields: FormlyFieldConfig[] = [];
	public itemDetail: ItemDetailModel;

	@ViewChild("modifierGroupContainer", { read: ViewContainerRef })
	modifierGroupContainer: ViewContainerRef;
	itemModifierGroupComponentRef: ComponentRef<EmloItemModifierGroupComponent>;

	// restaurant$: Subject<string> = new Subject();
	reportGroups$: Subject<any[]> = new Subject();
	// tax$: Subject<any[]> = new Subject();
	// printerGroup$: Subject<any[]> = new Subject();

	restaurants: IRestaurantItemModel[] = [];
	modifierRestaurants: IRestaurantItemModel[] = [];
	tabIndex: number = 0;

	dataSourceFn = this.menuGroupDataSource.bind(this);

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private credentialsService: CredentialsService,
		private adminShareDataService: AdminShareDataService,
		private itemService: ItemService,
		private taxService: TaxService,
		private printerService: PrinterService,
		private menuGroupService: ItemMenuGroupService,
		private restaurantService: RestaurantService,
		private reportGroupService: ReportGroupService,
		private modifierGroupService: ModifierGroupService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
			UserRoleTypeEnum.CustomerService
		]);
		if (!this.canAccess) return;
		this.itemDetail = ItemDetailFactory.create();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const itemId = params.get("id");
			this.isEdit = itemId && itemId !== null;
			this.restaurantService
				.getRestaurants()
				.subscribe((restaurants: IRestaurantItemModel[]) => {
					if (restaurants && restaurants.length > 0) {
						this.restaurants = [...restaurants];
						this.modifierRestaurants = [...restaurants];
					}
					if (this.isEdit) {
						this.initEditItem(itemId);
					}
				});
		});
		// this.itemDetail.restaurantIds = [
		// 	this.adminShareDataService.restaurantActive?.id,
		// ];
		this.createForm();
		this.initRefDataSource();
		this.getVisibleReportgroups();
	}

	private getVisibleReportgroups() {
		this.reportGroupService
			.getVisiblegroup()
			.subscribe((reportGroups: string[]) => {
				if (reportGroups && reportGroups.length > 0) {
					this.reportGroups$.next(reportGroups);
				}
			});
	}

	private createComponentModifierItem() {
		// if (this.modifierGroupContainer) {
		// 	this.modifierGroupContainer.clear();
		// }
		// if (this.itemModifierGroupComponentRef) {
		// 	this.itemModifierGroupComponentRef.destroy();
		// }
		// const componentFactory: ComponentFactory<EmloItemModifierGroupComponent> = this.componentFactoryResolver.resolveComponentFactory(
		// 	EmloItemModifierGroupComponent
		// );
		// this.itemModifierGroupComponentRef = this.modifierGroupContainer.createComponent(
		// 	componentFactory
		// );
		// const modifierGroups = new Observable<ItemModifierGroup[]>(
		// 	(observer) => {
		// 		observer.next(this.itemDetail.modifierGroups);
		// 	}
		// );
		// this.itemModifierGroupComponentRef.instance.itemModifierGroups = this.itemDetail.modifierGroups;
		// this.itemModifierGroupComponentRef.instance.data$ = modifierGroups;
		// this.itemModifierGroupComponentRef.instance.updatedItemModifierGroup.subscribe(
		// 	(newModifierGroups: ItemModifierGroup[]) => {
		// 		this.itemDetail.modifierGroups = newModifierGroups;
		// 	}
		// );
		// this.cdr.detectChanges();
	}

	private createForm() {
		this.itemFormFields = [
			// {
			// 	key: "restaurantIds",
			// 	type: "emlo-select",
			// 	focus: true,
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		multiple: true,
			// 		options: this.adminShareDataService.restaurants,
			// 		change: (field, $event) => {
			// 			// field.form.controls["menuGroupId"].setValue(null);
			// 			// field.form.controls["printerGroupId"].setValue(null);
			// 			// field.form.controls["taxId"].setValue(null);
			// 			// this.restaurant$.next(field.model?.restaurantId);
			// 		}
			// 	},
			// 	defaultValue: this.itemDetail.restaurantIds
			// },
			IdControl,
			ItemIdControl,
			NameEnControl,
			NameViControl,
			NameCnControl,
			BuyPriceSalePriceControl,
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					// {
					// 	type: "emlo-select",
					// 	key: "menuGroupId",
					// 	defaultValue: null,
					// 	className: "col-6",
					// 	templateOptions: {
					// 		label: "Menu Group",
					// 		options: this.menuGroup$,
					// 	},
					// },
					{
						type: "emlo-select",
						key: "reportingGroup",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Report Group",
							options: this.reportGroups$,
						},
					},
					// {
					// 	type: "emlo-select",
					// 	key: "taxId",
					// 	defaultValue: null,
					// 	className: "col-6",
					// 	templateOptions: {
					// 		label: "Tax",
					// 		options: this.tax$,
					// 	},
					// },
					// {
					// 	type: "emlo-select",
					// 	key: "printerGroupId",
					// 	defaultValue: null,
					// 	className: "col-6",
					// 	templateOptions: {
					// 		label: "Printer Group",
					// 		options: this.printerGroup$,
					// 	},
					// },
					{
						type: "input",
						key: "discountRate",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Discount Rate",
							placeholder: "Discount Rate",
							type: "number",
						},
					},
					{
						type: "emlo-checkbox",
						key: "isVisible",
						defaultValue: true,
						className: "col-6 pt-4",
						templateOptions: {
							label: "Visible",
						},
					},
					{
						type: "input",
						key: "sortOrder",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Sort Order",
							placeholder: "Sort Order",
							type: "number",
						},
					},
					// {
					// 	type: "emlo-select",
					// 	key: "parentSetMenuId",
					// 	defaultValue: null,
					// 	className: "col-6",
					// 	templateOptions: {
					// 		label: "Parent menu",
					// 		options: this.printerGroup$,
					// 	},
					// },
					{
						type: "emlo-checkbox",
						key: "isSetMenu",
						defaultValue: true,
						className: "col-6",
						templateOptions: {
							label: "Set Menu",
						},
					},
					{
						type: "emlo-checkbox",
						key: "canDiscount",
						defaultValue: true,
						className: "col-6",
						templateOptions: {
							label: "Can Discount",
						},
					},
					{
						type: "emlo-input",
						key: "concurrencyStamp",
						templateOptions: {
							type: "hidden",
						},
					},
				],
			},
			ButtonTextColorControl,
			{
				key: "concurrencyStamp",
				templateOptions: {
					hidden: true
				}
			},
		];
	}

	private initRefDataSource() {
		// this.restaurant$
		// 	.pipe(untilDestroyed(this))
		// 	.pipe(
		// 		catchError((x) => {
		// 			this.initRefDataSource();
		// 			// tslint:disable-next-line: deprecation
		// 			throw x;
		// 		})
		// 	)
		// 	.subscribe((restaurantId) => {
		// 		var _restaurantId = null;
		// 		this.menuGroupService
		// 			.getVisiblegroup(_restaurantId)
		// 			.subscribe((data) => {
		// 				this.menuGroup$.next(data?.data);
		// 			});
		// 		this.taxService.getTaxs(_restaurantId).subscribe((data) => {
		// 			this.tax$.next(data?.data);
		// 		});
		// 		this.printerService
		// 			.getAvaiablePrinterGroups(_restaurantId)
		// 			.subscribe((data) => {
		// 				if (data) {
		// 					this.printerGroup$.next(data);
		// 				}
		// 			});
		// 	});
		// this.restaurant$.next(this.adminShareDataService.restaurantActive?.id);
	}

	private initEditItem(itemId: string): void {
		this.itemService.getItem(itemId).subscribe((item: ItemDetailModel) => {
			if (item) {
				this.itemDetail = item;
				this.itemDetail.taxId = item.tax ? item.tax.id : null;
				this.itemDetail.printerGroupId = item.printerGroup
					? item.printerGroup.id
					: null;
				this.mapDataItemRestaurant();
				this.createComponentModifierItem();
			}
		});
	}

	private mapDataItemRestaurant(): void {
		this.restaurants.map((res) => {
			const itemRestaurant = this.itemDetail.restaurants.find(
				(itemRes) => itemRes.id === res.id
			);
			res.assigned = false;
			if (itemRestaurant) {
				res.assigned = true;
				res.price = itemRestaurant.price;
				res.sortOrder = itemRestaurant.sortOrder;
				res.menuGroupId = itemRestaurant.menuGroupId;
				res.isVisible = itemRestaurant.isVisible;
				res.isSetMenu = itemRestaurant.isSetMenu;
				res.taxId = itemRestaurant.taxId;
				res.printerGroupId = itemRestaurant.printerGroupId;
				res.parentSetMenuId = itemRestaurant.parentSetMenuId;
				res.menuModifierGroupId = itemRestaurant.menuModifierGroupId;
				res.concurrencyStamp = itemRestaurant.concurrencyStamp || null;
				res.menuGroupDatasource = ((restaurantId, selectedId) => {
					return (termSearch, pageIndex) => {
						return this.menuGroupService
							.getSampleMenuGroup(restaurantId, selectedId, termSearch, pageIndex ?? 1).pipe(map(x => { return x.data; }));
					}
				})(res.id, res.menuGroupId);
				this.getTaxsByRestaurantId(res);
				this.getAvaiablePrinterGroupsByRestaurantId(res);
				this.getParentMenuItemByRestaurantId(res);
				this.getModifierGroupsByRestaurantId(res);
			}
		});
		this.modifierRestaurants = [...this.restaurants];
	}

	private getMenuGroupByRestaurantId(itemRestaurant: IRestaurantItemModel) {
		this.menuGroupService
			.getVisiblegroup(itemRestaurant.id)
			.subscribe((data) => {
				if (data) {
					itemRestaurant.menuGroups = data;
				}
			});
	}

	private getParentMenuItemByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		const request: ItemSearchModel = {
			pageNum: 1,
			pageSize: 10000,
			restaurantId: itemRestaurant.id,
			isVisible: true,
			searchText: null,
			menuGroupId: null,
			isSetMenu: true,
		};
		this.itemService.getItems(request).subscribe((data) => {
			if (data) {
				itemRestaurant.parentItems = data.data;
			}
		});
	}

	private getModifierGroupsByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		this.modifierGroupService
			.getModifierGroupSelections(itemRestaurant.id)
			.subscribe((data) => {
				if (data) {
					itemRestaurant.modifierGroups = data;
				}
			});
	}

	private getTaxsByRestaurantId(itemRestaurant: IRestaurantItemModel) {
		this.taxService.getTaxs(itemRestaurant.id).subscribe((data) => {
			if (data) {
				itemRestaurant.taxs = data;
			}
		});
	}

	private getAvaiablePrinterGroupsByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		this.printerService
			.getAvaiablePrinterGroups(itemRestaurant.id)
			.subscribe((data) => {
				if (data) {
					itemRestaurant.printerGroups = data;
				}
			});
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {
		if (!restaurant.price) {
			restaurant.price = this.itemDetail.price;
		}
		if (!restaurant.sortOrder) {
			restaurant.sortOrder = this.itemDetail.sortOrder;
		}
		if (restaurant.assigned) {
			restaurant.isVisible = true;
		}
		// if (!restaurant.menuGroups) {
		// 	this.getMenuGroupByRestaurantId(restaurant);
		// }
		if (!restaurant.menuGroupDatasource) {
			restaurant.menuGroupDatasource = ((restaurantId, selectedId) => {
				return (termSearch, pageIndex) => {
					return this.menuGroupService
						.getSampleMenuGroup(restaurantId, selectedId, termSearch, pageIndex ?? 1).pipe(map(x => { return x.data; }));
				}
			})(restaurant.id, restaurant.menuGroupId);
		}
		
		if (!restaurant.modifierGroups) {
			this.getModifierGroupsByRestaurantId(restaurant);
		}
		if (!restaurant.parentItems) {
			this.getParentMenuItemByRestaurantId(restaurant);
		}
		if (!restaurant.printerGroups) {
			this.getAvaiablePrinterGroupsByRestaurantId(restaurant);
		}
		if (!restaurant.taxs) {
			this.getTaxsByRestaurantId(restaurant);
		}
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		data.modifierGroups = null;
		if (
			this.itemDetail.modifierGroups &&
			this.itemDetail.modifierGroups.length > 0
		) {
			this.itemDetail.modifierGroups.forEach((x) => {
				if (!x.id) delete x.id;
			});
			data.modifierGroups = this.itemDetail.modifierGroups;
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		if (assignRestaurants && assignRestaurants.length > 0) {
			assignRestaurants.map((a) => {
				delete a.parentItems;
				delete a.menuGroups;
				delete a.modifierGroups;
				delete a.printerGroups;
				delete a.taxs;
			});
		}
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateItem(data) : this.createItem(data);
	}

	private createItem(body: Item): void {
		this.itemService.createItem(body).subscribe(() => {
			this.backToMenuItems(true);
		});
	}

	private updateItem(body: Item): void {
		this.itemService.updateItem(body).subscribe(() => {
			this.backToMenuItems(true);
		});
	}

	cancel() {
		this.backToMenuItems();
	}

	private backToMenuItems(isReload: boolean = false): void {
		// this.form.reset();
		this.router.navigate(["/product-management/items"]);
		// this.dialogRef.close(isReload);
	}

	ngAfterViewInit(): void {
		if (!this.isEdit) {
			setTimeout(() => {
				this.createComponentModifierItem();
			});
		}
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	ngOnDestroy() {
		// this.modifierGroupContainer.clear();
		// this.itemModifierGroupComponentRef.destroy();
		this.reportGroups$?.complete();
		// this.tax$.complete();
		// this.restaurant$?.complete();
	}


	///////// Sample select with pagination
	private menuGroupDataSource(restaurantId: string, selectedId: string, searchTerm: string, pageIndex: number)
	{
		console.log('run here menuGroupDataSource');
		return this.menuGroupService.getSampleMenuGroup(restaurantId, selectedId, searchTerm, pageIndex).pipe(map(x => { return x.data; }));
	}
}
