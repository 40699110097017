<div class="mb-2">
	<button type="button" class="btn btn-outline-secondary btn-sm"
			[routerLink]="'/customer-service/configuration/general'">
		General
	</button>
	<button type="button" class="btn btn-primary btn-sm ml-2"
			[routerLink]="'/customer-service/configuration/customer-level'">
		Customer Level
	</button>
	<button type="button" class="btn btn-outline-secondary btn-sm ml-2"
			[routerLink]="'/customer-service/configuration/gift'">
		Gift
	</button>
	<button type="button" class="btn btn-outline-secondary btn-sm ml-2"
			[routerLink]="'/customer-service/configuration/reward-point'">
		Reward Points
	</button>
	<!-- <button type="button" class="btn btn-outline-secondary btn-sm ml-2"
		[routerLink]="'/customer-service/configuration/settings'">
		Settings
	</button> -->
	<!-- <button type="button" class="btn btn-outline-secondary btn-sm ml-2"
		[routerLink]="'/customer-service/configuration/customer-level'">
		Coupon / Discount
	</button>
	<button type="button" class="btn btn-outline-secondary btn-sm ml-2"
		[routerLink]="'/customer-service/configuration/customer-level'">
		Config Banner
	</button>
	<button type="button" class="btn btn-outline-secondary btn-sm ml-2"
		[routerLink]="'/customer-service/configuration/campaigns'">
		Config Campaign
	</button> -->
</div>
<router-outlet></router-outlet>
