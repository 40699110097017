/**
 * @create date 2020-04-01 13:44:04
 * @modify date 2020-04-01 13:44:04
 * @desc Common constant for all for core module.
 */

export const CommonConstant = {
	NUMBER_RECORD_PER_PAGE: 10,
	DEFUALT_SERVER_FORMAT: "DD/MM/YYYY"
};
