import { Component, OnInit, OnDestroy } from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { CashSummaryReport } from "../model/cash-report";
import { CashReportService } from "../services/cash-report.service";
import { BaseReportComponent } from "../../shared/base.report.component";
import { DatePipe } from "@angular/common";
import { debounceTime, finalize } from "rxjs/operators";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { ExcelServiceClient } from "@apps/modules/emlo-admin/shared/services/excel.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-cash-report",
	templateUrl: "./cash-report.html",
})
export class EmloCashReportComponent
	extends BaseReportComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	cashSummaryReport: CashSummaryReport = new CashSummaryReport();
	headerActionsTemplate: any;
	constructor(
		protected datePipe: DatePipe,
		private credentialsService: CredentialsService,
		private cashReportService: CashReportService,
		private restaurantService: RestaurantService
	) {
		super(datePipe);
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.Accountant,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					this.restaurantId = restaurants[0].id;
					this.viewReport();
				}
			});
	}

	viewReport() {
		this.getDataViewReport(this.prepareDataForGenerate(true));
	}

	exportReport() {
		const data = this.prepareDataForGenerate(true);
		this.generateCashSummaryReport(data);
	}

	private generateCashSummaryReport(data: string): void {
		this.showInprogress();
		this.cashReportService
			.generateCashSummaryReport(data)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.hideInprogress();
				})
			)
			.subscribe((response) => {
				// PrintServiceClient.openPdfAndPrint(response);
				ExcelServiceClient.openExcelFile(response);
			});
	}

	private getDataViewReport(data: string): void {
		this.showInprogress();
		this.cashReportService
			.getCashSummaryReport(data)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.hideInprogress();
				})
			)
			.subscribe((cashSummaryReport: any) => {
				this.cashSummaryReport = cashSummaryReport;
			});
	}

	ngOnDestroy(): void {}
}
