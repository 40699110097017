import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { IconName } from "@apps/common/constants/icon";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Subject } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import { CustomerLevelSearchModel } from "../../../customer-level/model/customer-level";
import { CustomerLevelService } from "../../../customer-level/services/customer-level.service";
import { GiftSearchModel } from "../../../gift/model/gift";
import { GiftService } from "../../../gift/services/gift.service";
import { SendGiftCustomerLevel } from "../../model/customer";
import { CustomerService } from "../../services/customer.service";

@Component({
	selector: "app-customer-send-gift",
	templateUrl: "send-gift.html",
})
export class SendGiftCustomerComponent implements OnInit {
	isProcessing = false;
	IconName = IconName;
	sendGiftFormFields: FormlyFieldConfig[] = [];
	sendGift: SendGiftCustomerLevel = new SendGiftCustomerLevel();
	form = new FormGroup({});
	customerLevels$: Subject<any[]> = new Subject();
	gift$: Subject<any[]> = new Subject();

	constructor(
		public dialogRef: MatDialogRef<SendGiftCustomerComponent>,
		private customerService: CustomerService,
		private customerLevelService: CustomerLevelService,
		private giftService: GiftService
	) {}

	ngOnInit(): void {
		this.getCustomerLevel();
		this.getGifts();
		this.createForm();
	}

	private getCustomerLevel() {
		const criteria: CustomerLevelSearchModel =
			new CustomerLevelSearchModel();
		criteria.pageSize = 10;
		this.customerLevelService
			.getCustomerLevels(criteria)
			.pipe(debounceTime(200))
			.subscribe((data) => {
				if (data) {
					this.customerLevels$.next(data.data);
				}
			});
	}

	private getGifts(): void {
		const criteria: GiftSearchModel = new GiftSearchModel();
		criteria.pageSize = 10000;
		this.giftService.getGifts(criteria).subscribe((data) => {
			if (data) {
				this.gift$.next(data.data);
			}
		});
	}

	private createForm() {
		this.sendGiftFormFields = [
			{
				key: "levelId",
				type: "emlo-select",
				templateOptions: {
					label: "Customer Level",
					placeholder: "Customer Level",
					bindLabel: "name",
					bindValue: "id",
					options: this.customerLevels$,
				},
			},
			{
				key: "giftId",
				type: "emlo-select",
				templateOptions: {
					label: "Select Gift",
					placeholder: "Select Gift",
					bindLabel: "name",
					bindValue: "id",
					options: this.gift$,
				},
			},
		];
	}

	close() {
		this.dialogRef.close();
	}

	onSendGift() {
		this.isProcessing = true;
		this.customerService.sendGift(this.sendGift).pipe(
			finalize(() => {
				this.isProcessing = false;
			}),
		).subscribe(() => {
			this.isProcessing = false;
			this.dialogRef.close(true);
		});
	}
}
