import {
	Component,
	OnInit,
	ViewChild,
	OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ShareDataService } from "@apps/core/services/data.service";
import { EventName } from "@apps/common/constants/event";
import { IMenu, Menus } from "@apps/common/constants/menu";
import { SettingLayout, MenuMode } from "../model/shell.model";
import { ShellService } from "../services/shell.service";
import { IconName } from "@apps/common/constants/icon";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { CredentialsService } from "@apps/core";

@Component({
	selector: "app-shell",
	templateUrl: "./shell.component.html",
	styleUrls: ["./shell.component.scss"],
})
export class ShellComponent implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("sideMainMenu") sideMainMenu: MatSidenav;
	isOpeningMainMenu: boolean = false;
	isExpandMenuDocked: boolean = false;
	menuMode = MenuMode;
	setingLayout: SettingLayout = {
		theme: "mode-default",
		menu: MenuMode.menu_docked,
		fontSize: 1,
	};
	toggleMainMenuFromLayoutSetting$: Subscription;
	toggleMainMenuFromHeader$: Subscription;
	mainMenus: IMenu[];
	menuActive: IMenu;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private shareDataService: ShareDataService,
		private credentialsService: CredentialsService,
		private shellService: ShellService
	) {}

	ngOnInit() {
		this.mainMenus = this.credentialsService.filterMenuByUserRole();
		this.toggleMainMenuFromLayoutSetting$ = this.shareDataService
			.getObservable(EventName.UPDATE_SETTING_LAYOUT)
			.subscribe((setingLayout: SettingLayout) => {
				if (setingLayout) {
					this.setingLayout = setingLayout;
					if (this.setingLayout.menu === MenuMode.menu_flyout) {
						if (this.sideMainMenu) this.sideMainMenu.close();
					}
				}
			});

		this.toggleMainMenuFromHeader$ = this.shareDataService
			.getObservable(EventName.TOGGlE_MENU_FLYOUT)
			.subscribe((setingLayout: SettingLayout) => {
				if (setingLayout) {
					this.setingLayout = setingLayout;
					if (this.setingLayout.menu === MenuMode.menu_flyout) {
						if (this.sideMainMenu) this.sideMainMenu.toggle();
					}
				}
			});
	}

	closeMainMenu() {
		if (this.sideMainMenu) this.sideMainMenu.close();
	}

	clickOnMenu(menu: IMenu): void {
		this.menuActive = menu;
		if (menu.subMenus?.length === 0) {
			this.router.navigateByUrl(menu.routerLink);
		}
	}

	clickOnSubMenu(menu: IMenu): void {
		this.menuActive = null;
		this.router.navigateByUrl(menu.routerLink);
	}

	toggleExpandMenuDocked() {
		this.isExpandMenuDocked = !this.isExpandMenuDocked;
	}

	onClickedOutside(e: Event) {
		this.menuActive = null;
	}

	logout() {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to log out?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.shellService.logout();
				}
			});
	}

	ngOnDestroy(): void {
		if (this.toggleMainMenuFromLayoutSetting$)
			this.toggleMainMenuFromLayoutSetting$.unsubscribe();
		if (this.toggleMainMenuFromHeader$)
			this.toggleMainMenuFromHeader$.unsubscribe();
	}
}
