import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatDialogRef
} from "@angular/material/dialog";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloCurrencyRoutingModule } from "./currency.routes";
import { EmloCurrencyComponent } from "./currency.component";
import { EmloCurrencyListComponent } from "./components/list/currency.list.component";
import { EmloCurrencyDetailComponent } from "./components/detail/currency.detail.component";
import { CurrencyService } from "./services/currency.service";
import { CommonModule } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
	declarations: [
		EmloCurrencyComponent,
		EmloCurrencyListComponent,
		EmloCurrencyDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatDialogModule,
		EmloTableModule,
		NgSelectModule,
		MatTabsModule,
		EmloConfirmModule,
		EmloButtonModule,
		FormlyModule.forRoot(),
		FormlyBootstrapModule,
		EmloCurrencyRoutingModule
	],
	providers: [
		CurrencyService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloCurrencyDetailComponent]
})
export class EmloCurrencyModule {}
