import { StorageService } from "./storage.service";
import { StaticInjectorService } from "../services/static-injector.service";

/**
 * @desc Get core module instance wiith root injector.
 */

export class EmloStorage {
	/**
	 * Get storage service.
	 */
	static get storageService(): StorageService {
		return StaticInjectorService.injector.get(StorageService);
	}
}
