import { CommonConstant } from "@apps/common/constants/common.constant";

export class GeneralConfig {
	amountPerPoint: number = null;
	concurrencyStamp: string = null;
	excludeDayMonths: ExcludeDate[] = [];
}

export class ExcludeDate {
	day: number = null;
	month: number = null;
	isLunar: boolean = false;
	notes: string = null;
	concurrencyStamp: string = null;
}


export class GeneralConfigSearchModel {
	searchText?: string = null;
	isActive?: boolean = null;
	levelId?: string | number = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

