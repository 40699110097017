import { CommonConstant } from "@apps/common/constants/common.constant";

export class Campaigns {
	id?: string | number = null;
	name?: string | number = null;
	description?: string | number = null;
	image?: string | number = null;
	validFrom?: string | number = null;
	validTo?: string | number = null;
	levelCode?: string | number = null;
	lockPeriodId?: string | number = null;
}


export class CampaignsSearchModel {
	searchText?: string = null;
	isActive?: boolean = null;
	levelId?: string | number = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

