<div class="row overview-charts">
	<div class="col-sm-6">
		<div class="chart">
			<span class="label">Khách hàng</span>
			<button [disabled]="isLoadingCustomerRegistrationStatistic" type="button" class="btn btn-sm" (click)="downloadReport(1)">
				<mat-icon
					[svgIcon]="IconName.icon_download_black_24dp"
				></mat-icon>
			</button>
			<app-loader [isLoading]="isLoadingCustomerRegistrationStatistic"></app-loader>
			<div *ngIf="!isLoadingCustomerRegistrationStatistic">
				<form class="form-inline rang-date">
					<div class="form-group hidden">
						<div class="input-group">
							<input
								name="datepicker"
								class="form-control"
								ngbDatepicker
								#datepicker="ngbDatepicker"
								[autoClose]="'outside'"
								(dateSelect)="onRegistrationDateSelection($event)"
								[displayMonths]="2"
								[dayTemplate]="t"
								outsideDays="hidden"
								[startDate]="fromRegistrationDate!"
								tabindex="-1"
							/>
							<ng-template #t let-date let-focused="focused">
								<span
									class="custom-day"
									[class.focused]="focused"
									[class.range]="isRegistrationRange(date)"
									[class.faded]="
										isRegistrationHovered(date) || isRegistrationInside(date)
									"
									(mouseenter)="hoveredRegistrationDate = date"
									(mouseleave)="hoveredRegistrationDate = null"
								>
									{{ date.day }}
								</span>
							</ng-template>
						</div>
					</div>
					<div class="form-group">
						<div class="input-group">
							<input
								#dpFromDate
								class="form-control"
								placeholder="yyyy-mm-dd"
								name="dpFromDate"
								[value]="formatter.format(fromRegistrationDate)"
								(input)="
								fromRegistrationDate = validateRegistrationInput(
									fromRegistrationDate,
										dpFromDate.value
									)
								"
							/>
							<div class="input-group-append">
								<button
									class="btn btn-outline-secondary calendar"
									(click)="datepicker.toggle()"
									type="button"
								>
									<mat-icon
										[svgIcon]="IconName.icon_calendar"
									></mat-icon>
								</button>
							</div>
						</div>
					</div>
					<div class="form-group ml-2">
						<div class="input-group">
							<input
								#dpToDate
								class="form-control"
								placeholder="yyyy-mm-dd"
								name="dpToDate"
								[value]="formatter.format(toRegistrationDate)"
								(input)="
								toRegistrationDate = validateRegistrationInput(toRegistrationDate, dpToDate.value)
								"
							/>
							<div class="input-group-append">
								<button
									class="btn btn-outline-secondary calendar"
									(click)="datepicker.toggle()"
									type="button"
								>
									<mat-icon
										[svgIcon]="IconName.icon_calendar"
									></mat-icon>
								</button>
							</div>
						</div>
					</div>
				</form>
				<canvas
					height="100vh"
					baseChart
					[data]="customerRegistrationData"
					[labels]="customerRegistrationChartLabels"
					[chartType]="pieChartType"
					[options]="pieChartOptions"
					[legend]="pieChartLegend"
					[plugins]="pieChartPlugins"
				>
				</canvas>
			</div>
		</div>
	</div>
	<div class="col-sm-6">
		<div class="chart">
			<span class="label">Mức độ thường xuyên</span>
			<button [disabled]="isLoadingFrequentlyStatistic" type="button" class="btn btn-sm" (click)="downloadReport(2)">
				<mat-icon
					[svgIcon]="IconName.icon_download_black_24dp"
				></mat-icon>
			</button>
			<app-loader [isLoading]="isLoadingFrequentlyStatistic"></app-loader>
			<div *ngIf="!isLoadingFrequentlyStatistic">
				<form class="form-inline rang-date">
					<div class="form-group hidden">
						<div class="input-group">
							<input
								name="datepicker2"
								class="form-control"
								ngbDatepicker
								#datepicker2="ngbDatepicker"
								[autoClose]="'outside'"
								(dateSelect)="onFrequentlyDateSelection($event)"
								[displayMonths]="2"
								[dayTemplate]="t"
								outsideDays="hidden"
								[startDate]="fromFrequentlyDate!"
								tabindex="-1"
							/>
							<ng-template #t let-date let-focused="focused">
								<span
									class="custom-day"
									[class.focused]="focused"
									[class.range]="isFrequentlyRange(date)"
									[class.faded]="
									isFrequentlyHovered(date) || isFrequentlyInside(date)
									"
									(mouseenter)="hoveredFrequentlyDate = date"
									(mouseleave)="hoveredFrequentlyDate = null"
								>
									{{ date.day }}
								</span>
							</ng-template>
						</div>
					</div>
					<div class="form-group">
						<div class="input-group">
							<input
								#dpFromDate
								class="form-control"
								placeholder="yyyy-mm-dd"
								name="dpFromDate"
								[value]="formatter.format(fromFrequentlyDate)"
								(input)="
									fromFrequentlyDate = validateFrequentlyInput(
										fromFrequentlyDate,
										dpFromDate.value
									)
								"
							/>
							<div class="input-group-append">
								<button
									class="btn btn-outline-secondary calendar"
									(click)="datepicker2.toggle()"
									type="button"
								>
									<mat-icon
										[svgIcon]="IconName.icon_calendar"
									></mat-icon>
								</button>
							</div>
						</div>
					</div>
					<div class="form-group ml-2">
						<div class="input-group">
							<input
								#dpToDate
								class="form-control"
								placeholder="yyyy-mm-dd"
								name="dpToDate"
								[value]="formatter.format(toFrequentlyDate)"
								(input)="
									toFrequentlyDate = validateFrequentlyInput(toFrequentlyDate, dpToDate.value)
								"
							/>
							<div class="input-group-append">
								<button
									class="btn btn-outline-secondary calendar"
									(click)="datepicker2.toggle()"
									type="button"
								>
									<mat-icon
										[svgIcon]="IconName.icon_calendar"
									></mat-icon>
								</button>
							</div>
						</div>
					</div>
				</form>
				<canvas
					height="100vh"
					baseChart
					[data]="customerActivityStatisticOverviewData"
					[labels]="customerActivityStatisticOverviewChartLabels"
					[chartType]="pieChartType"
					[options]="pieChartOptions"
					[plugins]="pieChartPlugins"
					[colors]="pieChartColors"
					[legend]="pieChartLegend"
				>
				</canvas>
			</div>
		</div>
	</div>
	<div class="col-sm-6">
		<div class="chart">
			<span class="label">Giới tính</span>
			<button [disabled]="isLoadingGenderStatistic" type="button" class="btn btn-sm" (click)="downloadReport(0)">
				<mat-icon
					[svgIcon]="IconName.icon_download_black_24dp"
				></mat-icon>
			</button>
			<app-loader [isLoading]="isLoadingGenderStatistic"></app-loader>
			<div *ngIf="!isLoadingGenderStatistic">
				<canvas
					height="100vh"
					baseChart
					[data]="customerGenderOverviewData"
					[labels]="customerGenderOverviewChartLabels"
					[chartType]="pieChartType"
					[options]="pieChartOptions"
					[plugins]="pieChartPlugins"
					[colors]="pieChartColors"
					[legend]="pieChartLegend"
				>
				</canvas>
			</div>
		</div>
	</div>
	<div class="col-sm-6">
		<div class="chart">
			<span class="label">Hạng thành viên</span>
			<button [disabled]="isLoadingLevelOverview" type="button" class="btn btn-sm" (click)="downloadReport(3)">
				<mat-icon
					[svgIcon]="IconName.icon_download_black_24dp"
				></mat-icon>
			</button>
			<app-loader [isLoading]="isLoadingLevelOverview"></app-loader>
			<div *ngIf="!isLoadingLevelOverview">
				<canvas
					height="100vh"
					baseChart
					[data]="customerLevelOverviewData"
					[labels]="customerLevelOverviewChartLabels"
					[chartType]="pieChartType"
					[options]="pieChartOptions"
					[plugins]="pieChartPlugins"
					[colors]="pieChartColors"
					[legend]="pieChartLegend"
				>
				</canvas>
			</div>
		</div>
	</div>
</div>
