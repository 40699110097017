/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:14:01
 * @modify date 2020-02-11 00:14:01
 */
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
	HttpClient,
	HttpParams,
	HttpResponse,
	HttpErrorResponse
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, finalize } from "rxjs/operators";
import { EmloButtonService } from "@apps/shared/button/button.service";
import { ErrorDialogService } from "@apps/shared/errordialog";

@Injectable()
export class ApiService {
	constructor(
		private http: HttpClient,
		private buttonService: EmloButtonService,
		private errorDialogService: ErrorDialogService
	) {}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		const observable = this.http.get(`${environment.api_url}${path}`, {
			params,
			observe: "response"
		});
		return this.responseInterceptor(observable);
	}

	put(path: string, body: object = {}): Observable<any> {
		const observable = this.http.put(`${environment.api_url}${path}`, body);
		return this.responseInterceptor(observable);
	}

	post(path: string, body: object = {}): Observable<any> {
		const observable = this.http.post(
			`${environment.api_url}${path}`,
			body,
			{ observe: "response" }
		);
		return this.responseInterceptor(observable);
	}

	printPdf(path: string, body: object = {}): Observable<any> {
		const observable = this.http.post(
			`${environment.api_url}${path}`,
			body,
			{
				observe: "response",
				responseType: "blob"
			}
		);
		return this.responseInterceptor(observable);
	}

	excel(path: string, body: object = {}): Observable<any> {
		const observable = this.http.post(
			`${environment.api_url}${path}`,
			body,
			{
				observe: "response",
				responseType: "blob"
			}
		);
		return this.responseInterceptor(observable, true);
	}

	delete(path: string): Observable<any> {
		const observable = this.http.delete(`${environment.api_url}${path}`);
		return this.responseInterceptor(observable);
	}

	private responseInterceptor(
		observableRes: Observable<any>,
		fileDownload: boolean = false
	): Observable<any> {
		this.showButtonProcessing();
		return observableRes.pipe(
			map(res => this.baseAdapter(res, fileDownload)),
			finalize(() => {
				this.onFinalize();
			}),
			catchError(error => of(this.onCatch(error)))
		);
	}

	private baseAdapter(response: HttpResponse<any>, fileDownload: boolean = false): any {
		if (response.status === 200) {
			this.checkAndStoreResponseHeader(response);
		}
		if (fileDownload) {
			const responeContentType = response.headers.get(
				"Content-Type"
			)
			return {
				blob: response.body,
				contentType: responeContentType,
				contentDisposition: response.headers.get(
					"Content-Disposition"
				)
			}
		}
		return response.body;
	}

	private onFinalize() {
		this.hideButtonProcessing();
	}

	private onCatch(response: HttpErrorResponse) {
		throw response;
	}

	private checkAndStoreResponseHeader(response: HttpResponse<any>): void {}

	private showButtonProcessing(): void {
		this.buttonService.show();
	}

	private hideButtonProcessing(): void {
		this.buttonService.hide();
	}
}
