import { BaseDataReport } from "../../shared/model/report.base.mode";

export class CashSummary {
	paymentType: string;
	paymentTypeDisplay: string;
	numberOfChecks: number;
	totalAmount: number;
}

export class CashSummaryReport extends BaseDataReport {
	paymentTypeSummary: CashSummary[];
	totalChecks: number;
	total: number;
}
