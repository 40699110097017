/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:11:28
 * @modify date 2020-02-11 00:11:28
 * @desc [Support import all module core]
 */
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
	HttpClient,
	HttpClientModule
} from "@angular/common/http";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { RouteReusableStrategy } from "./route-reusable-strategy";
import { HttpService } from "./services/http.service";
import { ApiService } from "./services";
import { StorageService } from "./storage/storage.service";
import { LocalStorageService } from "./storage/local-storage.service";

@NgModule({
	imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
	providers: [
		{
			provide: HttpClient,
			useClass: HttpService
		},
		{
			provide: RouteReuseStrategy,
			useClass: RouteReusableStrategy
		},
		{
			provide: StorageService,
			useClass: LocalStorageService
		},
		ApiService
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(
				`${parentModule} has already been loaded. Import Core module in the AppModule only.`
			);
		}
	}
}
