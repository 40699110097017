import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import {
	CustomerLevel,
	CustomerLevelSearchModel,
} from "../model/customer-level";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable } from "rxjs";

@Injectable()
export class CustomerLevelService {
	constructor(private apiService: ApiService) {}

	getCustomerLevelById(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "leveldef/getbyid",
			params
		);
	}

	createCustomerLevel(context: CustomerLevel) {
		return this.apiService.post(
			environment.prefix_api_url + "leveldef/create",
			context
		);
	}

	updateCustomerLevel(context: CustomerLevel) {
		return this.apiService.post(
			environment.prefix_api_url + "leveldef/update",
			context
		);
	}

	deleteCustomerLevel(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "leveldef/delete",
			context
		);
	}

	getCustomerLevels(
		request: CustomerLevelSearchModel
	): Observable<IApiPagingResponseModel<CustomerLevel[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "leveldef/list",
			request
		);
	}

	getAvailableLevelcode(): Observable<any[]> {
		return this.apiService.get(
			environment.prefix_api_url + "leveldef/availablecode"
		);
	}
}
