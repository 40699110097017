import { Component, OnInit, OnDestroy } from "@angular/core";
import { RestaurantUserService } from "../../services/restaurant-user.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { ClientFactory, ClientRole, ClientUser } from "../../model/client-user";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { IconName } from "@apps/common/constants/icon";
import { UserLanguage } from "@apps/modules/emlo-admin/shared/model/language.model";
import { UserSettingService } from "@apps/modules/emlo-admin/shared/services/user.feature.service";
import { Subject } from "rxjs";
import { CredentialsService } from "@apps/core";
import { catchError } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { ClientUserRestaurant } from "../../../user/model/user";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";

@Component({
	selector: "app-restaurant-user-detail",
	templateUrl: "detail.html",
})
export class EmloRestaurantUserDetailComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	IconName = IconName;
	form = new FormGroup({});
	restaurantUserFormFields: FormlyFieldConfig[] = [];
	userLanguages: UserLanguage[] = [];
	restaurant$: Subject<string> = new Subject();
	clientRole$: Subject<any[]> = new Subject();
	restaurantUser: ClientUser = ClientFactory.create();
	public userRestaurants: ClientUserRestaurant[] = [];
	tabIndex: number = 0;
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private restaurantUserService: RestaurantUserService,
		private adminShareDataService: AdminShareDataService,
		private userSettingService: UserSettingService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;

		this.userLanguages = this.userSettingService.getListLanguageSupport();
		this.isEdit =
			this.restaurantUser &&
			this.restaurantUser.id != null &&
			this.restaurantUser.id !== "00000000-0000-0000-0000-000000000000";
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const userId = params.get("id");
			this.isEdit =
				userId && userId !== "00000000-0000-0000-0000-000000000000";

			this.restaurantService
				.getRestaurants()
				.subscribe((restaurants: any) => {
					if (restaurants && restaurants.length > 0) {
						this.userRestaurants = [...restaurants];
						this.getRoles(userId);
					}
				});
			this.createForm();
		});
	}

	ngOnDestroy() {
		this.restaurant$.complete();
		this.clientRole$.complete();
	}

	private createForm() {
		this.restaurantUserFormFields = [
			// {
			// 	key: "restaurantId",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		disabled: this.isEdit,
			// 		options: this.adminShareDataService.restaurants,
			// 		change: (field, $event) => {
			// 			field.form.controls["roleId"].setValue(null);
			// 			this.restaurant$.next(field.model?.restaurantId);
			// 		},
			// 	},
			// 	defaultValue: this.adminShareDataService.restaurantActive?.id,
			// },
			IdControl,
			{
				type: "input",
				key: "firstName",
				defaultValue: null,
				templateOptions: {
					label: "First name",
					placeholder: "First name",
				},
			},
			{
				type: "input",
				key: "lastName",
				defaultValue: null,
				templateOptions: {
					label: "Last name",
					placeholder: "Last name",
				},
			},
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						key: "phoneNo",
						type: "input",
						className: "col-6",
						templateOptions: {
							label: "Phone",
							placeholder: "Phone",
						},
					},
					{
						key: "ssn",
						type: "input",
						className: "col-6",
						templateOptions: {
							label: "SSN",
							placeholder: "SSN",
						},
					},
				],
			},
			{
				key: "secretKey",
				hideExpression: this.isEdit,
				validators: {
					fieldMatch: {
						expression: (control) => {
							const value = control.value;
							return (
								value.confirmSecretKey === value.secretKey ||
								// avoid displaying the message error when values are empty
								!value.confirmSecretKey ||
								!value.secretKey
							);
						},
						message: "Secret Key Not Matching",
						errorPath: "confirmSecretKey",
					},
				},
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						key: "secretKey",
						type: "input",
						className: "col-6",
						templateOptions: {
							type: "password",
							label: "Secret Key",
							placeholder:
								"Those passwords didn't match. Try again. (At least 4 characters)",
							required: true,
							minLength: 4,
						},
					},
					{
						key: "confirmSecretKey",
						type: "input",
						className: "col-6",
						templateOptions: {
							type: "password",
							label: "Confirm Secret Key",
							placeholder: "Please re-enter your Secret Key",
							required: true,
						},
					},
				],
			},
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						key: "costPerHour",
						type: "input",
						className: "col-6",
						templateOptions: {
							label: "Cost",
							placeholder: "Cost",
						},
					},
					// {
					// 	key: "roleId",
					// 	type: "emlo-select",
					// 	className: "col-6",
					// 	templateOptions: {
					// 		label: "User role",
					// 		bindLabel: "name",
					// 		bindValue: "id",
					// 		options: this.clientRole$,
					// 	},
					// },
				],
			},
			{
				className: "section-label",
				template: "<div class='pb-2'>User settings</div>",
			},
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "emlo-select",
						key: "defaultLanguage",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Default language",
							bindLabel: "name",
							bindValue: "code",
							options: this.userLanguages,
						},
					},
					{
						type: "input",
						key: "autoLogoffInSecond",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Auto loggoff time",
							type: "number",
						},
					},
				],
			},
		];
	}

	private getRoles(userId: string) {
		// this.restaurant$
		// 	.pipe(untilDestroyed(this))
		// 	.pipe(
		// 		catchError((x) => {
		// 			this.initRefDataSource();
		// 			// tslint:disable-next-line: deprecation
		// 			throw x;
		// 		})
		// 	)
		// 	.subscribe((x) => {
		// 		this.restaurantUserService.getRoles(x).subscribe((data) => {
		// 			this.clientRole$.next(data?.data);
		// 		});
		// 		// this.taxService.getTaxs(x).subscribe(data => {
		// 		// 	this.tax$.next(data?.data);
		// 		// });
		// 		// this.printerService.getAvaiablePrinters(x).subscribe(data => {
		// 		// 	if(data) {
		// 		// 		this.printerGroup$.next(data);
		// 		// 	}
		// 		// });
		// 	});
		// this.restaurant$.next(this.adminShareDataService.restaurantActive?.id);

		this.restaurantUserService.getRoles().subscribe((data) => {
			if (data) {
				const clientRoles: ClientRole[] = data;
				this.clientRole$.next(data);
				this.userRestaurants.map((x) => {
					const roles = clientRoles.filter(
						(r) => r.restaurantId === x.id
					);
					if (!x.userRoles) {
						x.userRoles = [];
					}
					if (clientRoles) {
						x.userRoles = roles;
					}
				});
				if (this.isEdit) {
					this.initEditUser(userId);
				}
			}
		});
	}

	private initEditUser(userId: string): void {
		this.restaurantUserService
			.getUser(userId)
			.subscribe((restaurantUser: ClientUser) => {
				if (restaurantUser) {
					this.restaurantUser = restaurantUser;
					this.mapDataRestaurant();
				}
			});
	}

	private mapDataRestaurant(): void {
		this.userRestaurants.map((res) => {
			const clientRestaurant = this.restaurantUser.restaurants.find(
				(itemRes) => itemRes.id === res.id
			);
			res.assigned = false;
			if (clientRestaurant) {
				res.assigned = true;
				res.concurrencyStamp = clientRestaurant.concurrencyStamp;
				// res.firstName = itemRestaurant.firstName;
				// res.lastName = itemRestaurant.lastName;
				// res.phoneNo = itemRestaurant.phoneNo;
				// res.ssn = itemRestaurant.ssn;
				// res.costPerHour = itemRestaurant.costPerHour;
				// res.defaultLanguage = itemRestaurant.defaultLanguage;
				// res.autoLogoffInSecond = itemRestaurant.autoLogoffInSecond;
				res.roleId = clientRestaurant.roleId;
				res.userRoles = res.userRoles;
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		if (data.secretKey) {
			data.secretKey = data.secretKey.secretKey;
		}
		const assignRestaurants = this.userRestaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateUser(data) : this.createUser(data);
	}

	private createUser(body: ClientUser): void {
		this.restaurantUserService.createUser(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateUser(body: ClientUser): void {
		this.restaurantUserService.updateUser(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	cancel() {
		this.resetFormAndClose();
	}

	private resetFormAndClose(isReload: boolean = false): void {
		this.router.navigate(["/account-management/restaurant-user"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: ClientUserRestaurant) {
		if (!restaurant.roleId) {
			restaurant.roleId = this.restaurantUser.roleId;
		}
		if (!restaurant.defaultLanguage) {
			restaurant.defaultLanguage = this.restaurantUser.defaultLanguage;
		}
		if (!restaurant.autoLogoffInSecond) {
			restaurant.autoLogoffInSecond =
				this.restaurantUser.autoLogoffInSecond;
		}
		if (!restaurant.ssn) {
			restaurant.ssn = this.restaurantUser.ssn;
		}
		if (!restaurant.costPerHour) {
			restaurant.costPerHour = this.restaurantUser.costPerHour;
		}
	}
}
