import { ICategoryRequestSearchModel, CategoryRequestSearchModelFactory } from "./../../category/model/category-request-search.model";
import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { MenuGroup, MenuGroupSearchModel } from "../model/menu-group";
import { environment } from "@env/environment";
import { IMenuGroupSearchRequestModel } from "../model/menu-group-search-request.model";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Category } from "../../category/model/category";

@Injectable()
export class MenuGroupService {
	constructor(private apiService: ApiService) {}

	getMenuGroups(
		request: MenuGroupSearchModel
	): Observable<IApiPagingResponseModel<MenuGroup[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "group/list",
			request
		);
	}

	searchMenuGroups(
		request: IMenuGroupSearchRequestModel
	): Observable<IApiPagingResponseModel<MenuGroup[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "group/search",
			request
		);
	}

	getMenuGroup(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "group/getbyid",
			params
		);
	}

	getVisiblegroup() {
		return this.apiService.get(
			environment.prefix_api_url + "group/getvisiblegroup"
		);
	}

	createMenuGroup(context: MenuGroup) {
		return this.apiService.post(
			environment.prefix_api_url + "group/create",
			context
		);
	}

	updateMenuGroup(context: MenuGroup) {
		return this.apiService.post(
			environment.prefix_api_url + "group/update",
			context
		);
	}

	deleteMenuGroup(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "group/delete",
			context
		);
	}

	enableMenuGroup(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "group/enable",
			context
		);
	}

	disableMenuGroup(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "group/disable",
			context
		);
	}

	// Get Categories
	getCategories(restaurantId: string): Observable<IApiPagingResponseModel<Category[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "category/list",
			CategoryRequestSearchModelFactory.createWithTenancy(restaurantId)
		);
	}
}
