import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { Tax, TaxFactory, TaxSearchModel } from "../../model/tax";
import { TaxService } from "../../services/tax.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import {
	PaginationModelFactory,
	IApiPagingResponseModel,
} from "@apps/common/model/api.reponse.model";
import { debounceTime } from "rxjs/operators";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-tax-list",
	templateUrl: "./list.html",
})
export class EmloTaxListComponent extends AdminBaseComponent implements OnInit {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public columns: Columns[] = [];
	public taxs: Tax[] = [];
	public configuration: Config;
	public pagination = PaginationModelFactory.create();

	criteria: TaxSearchModel = new TaxSearchModel();
	restaurants: IRestaurantItemModel[] = [];
	private readonly All = "All";
	private readonly Visible = "Visible";
	private readonly InVisible = "Invisible";
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private taxService: TaxService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});

		this.configColumnForTaxTable();
		this.getTaxs();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getTaxs();
		}
	}

	private getTaxs() {
		this.taxService
			.getTaxs(this.criteria)
			.pipe(debounceTime(200))
			.subscribe((taxs: IApiPagingResponseModel<Tax[]>) => {
				if (taxs) {
					this.taxs = taxs.data;
					this.pagination = taxs;
				}
			});
	}

	private configColumnForTaxTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "name", title: "Name" },
			{ key: "rate", title: "Rate" },
			{ key: "restaurantNames", title: "Selling at" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteTax(tax: Tax): void {
		const data = { id: tax.id };
		this.taxService.deleteTax(JSON.stringify(data)).subscribe(() => {
			this.getTaxs();
		});
	}

	delete(tax: Tax): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteTax(tax);
				}
			});
	}

	openTaxDetail(tax: Tax): void {
		if (!tax) {
			tax = TaxFactory.create();
			this.router.navigate(["/product-management/tax/add"]);
		} else {
			this.router.navigate(["/product-management/tax/edit"], {
				queryParams: { id: tax.id },
			});
		}
	}

	onFilter() {
		this.getTaxs();
	}
}
