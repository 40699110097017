import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloCategoryComponent } from "./category.component";
import { EmloCategoryListComponent } from "./components/list/category.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloCategoryDetailComponent } from "./components/detail/category.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloCategoryComponent,
		children: [
			{
				path: "",
				component: EmloCategoryListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloCategoryListComponent,
			},
			{ path: "add", component: EmloCategoryDetailComponent },
			{ path: "edit", component: EmloCategoryDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloCategoryRoutingModule {}
