import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { UserType } from "../model/usertype";
import { environment } from "@env/environment";
import { IUserTypeSearchRequestModel } from "../model/usertype-search-request.model";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { UserRoleType } from "@apps/shared/enums/user.role-type";
import { UserRoleTypeResponse } from "../model/user-role-type-response.model";

@Injectable()
export class UserTypeService {
	constructor(private apiService: ApiService) {}

	getUserTypes(request: IUserTypeSearchRequestModel): Observable<UserType[]> {
		const params = new HttpParams({ fromObject: request as any });
		return this.apiService.get(environment.prefix_api_url + "role/list", params);
	}

	getPermissions() {
		return this.apiService.get(environment.prefix_api_url + "permission/list");
	}

	getPermissionsByRoleType(userRoleType: any) {
		const params = new HttpParams().set("id", userRoleType);
		return this.apiService.get(environment.prefix_api_url + "permission/getbyroletype", params);
	}
	getUserRoleTypes(): Observable<UserRoleTypeResponse[]> {
		return this.apiService.get(environment.prefix_api_url + "role/userroletypes");
	}

	getUserType(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "role/getbyid", params);
	}

	createUserType(context: any) {
		return this.apiService.post(environment.prefix_api_url + "role/create", context);
	}

	updateUserType(context: any) {
		return this.apiService.post(environment.prefix_api_url + "role/update", context);
	}

	deleteUserType(context: any) {
		return this.apiService.post(environment.prefix_api_url + "role/delete", context);
	}
}
