import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { FilterType } from "@apps/shared/filter-builder/model/condition-type.enum";

export const commonFilterField = () => {
	return [
		{
			name: "Name En",
			key: "nameEn",
			type: FilterType.STRING,
			supportedOperator: [
				ConditionOperator.CONTAIN,
				ConditionOperator.EQUAL
			]
		},
		{
			name: "Name Vi",
			key: "nameVi",
			type: FilterType.STRING,
			supportedOperator: [
				ConditionOperator.CONTAIN,
				ConditionOperator.EQUAL
			]
		},
		{
			name: "Name Cn",
			key: "nameCn",
			type: FilterType.STRING,
			supportedOperator: [
				ConditionOperator.CONTAIN,
				ConditionOperator.EQUAL
			]
		},
		{
			name: "Created At",
			key: "createdAt",
			type: FilterType.DATE,
			supportedOperator: [
				ConditionOperator.BETWEEN,
				ConditionOperator.EQUAL
			]
		}
	];
};
