import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloFilterBuilderModule } from "@apps/shared/filter-builder/filter-builder.module";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { CategoryService } from "../category/services/category.service";

import { EmloFormModule } from "./../../../../shared/form/form.module";
import { EmloMenuGroupDetailComponent } from "./components/detail/menu-group.detail.component";
import { EmloMenuGroupListComponent } from "./components/list/menu-group.list.component";
import { EmloMenuGroupComponent } from "./menu-group.component";
import { EmloMenuGroupRoutingModule } from "./menu-group.routes";
import { MenuGroupService } from "./services/menu-group.service";

@NgModule({
	declarations: [
		EmloMenuGroupComponent,
		EmloMenuGroupListComponent,
		EmloMenuGroupDetailComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatTabsModule,
		MatRadioModule,
		NgSelectModule,
		MatSlideToggleModule,
		MatDialogModule,
		MatCheckboxModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		EmloFormModule,
		EmloFilterBuilderModule,
		FormlyBootstrapModule,
		EmloMenuGroupRoutingModule
	],
	providers: [
		MenuGroupService,
		CategoryService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [EmloMenuGroupDetailComponent]
})
export class EmloMenuGroupModule {}
