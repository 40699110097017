import { Injectable } from "@angular/core";
import { AuthenticationService } from "@apps/core/authentication/authentication.service";
import { Router } from "@angular/router";
@Injectable()
export class ShellService {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService
	) {}

	logout() {
		this.authenticationService
			.logout()
			.subscribe(() =>
				this.router.navigate(["/login"], { replaceUrl: true })
			);
	}
}
