export class ExcelServiceClient {
	public static openExcelFile(data: any): void {
		const blob = new Blob([data.blob], {
			type:
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		});
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = this.parseFileName(data.contentDisposition);
		link.click();
	}

	private static parseFileName(content: string) {
		let filename = "report";
		if (content) {
			const arrSplit = content.split(" ");
			if (arrSplit) {
				arrSplit.some(x => {
					if (x.indexOf("filename=") > -1) {
						filename = x.replace("filename=", "");
						filename = filename.replace(";", "");
						return true;
					}
				});
			}
		}
		return filename;
	}
}
