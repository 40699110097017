import { Component, OnInit, OnDestroy } from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import {
	DetailSaleSummaryReport,
	DetailSaleSummary,
	TotalDetailSaleSummary,
} from "../model/detail-sale-report";
import { DetailSaleReportService } from "../services/detail-sale-report.service";
import { BaseReportComponent } from "../../shared/base.report.component";
import { DatePipe } from "@angular/common";
import { debounceTime, finalize } from "rxjs/operators";
import { NumberUtils } from "@apps/core/utils/number";
import { ExcelServiceClient } from "@apps/modules/emlo-admin/shared/services/excel.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-detail-sale-report",
	templateUrl: "./detail-sale-report.html",
})
export class EmloDetailSaleReportComponent
	extends BaseReportComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	detailSaleSummaryReport: DetailSaleSummaryReport = new DetailSaleSummaryReport();
	detailSaleReports: DetailSaleSummary[];
	summary: TotalDetailSaleSummary;
	headerActionsTemplate: any;
	constructor(
		protected datePipe: DatePipe,
		private credentialsService: CredentialsService,
		private detailSaleReportService: DetailSaleReportService,
		private restaurantService: RestaurantService
	) {
		super(datePipe);
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.Accountant,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					this.restaurantId = restaurants[0].id;
					this.viewReport();
				}
			});
	}

	viewReport() {
		this.getDataViewReport(this.prepareDataForGenerate(true));
	}

	exportReport() {
		const data = this.prepareDataForGenerate(true);
		this.generateDetailSaleReport(data);
	}

	private generateDetailSaleReport(data: string): void {
		this.showInprogress();
		this.detailSaleReportService
			.getExcelReport(data)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.hideInprogress();
				})
			)
			.subscribe((response) => {
				ExcelServiceClient.openExcelFile(response);
			});
	}

	private getDataViewReport(body: string): void {
		this.showInprogress();
		this.detailSaleReportService
			.getSaleDetailReport(body)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.hideInprogress();
				})
			)
			.subscribe((data: DetailSaleSummaryReport) => {
				if (data && data.reportKey) {
					this.detailSaleSummaryReport = data;
					if (data.data) {
						this.convertDataBeforeView(data);
					}
				}
			});
	}

	private convertDataBeforeView(data: DetailSaleSummaryReport) {
		this.detailSaleReports = data.data.data;
		this.totalItems = data.data.totalItems;
		this.summary = data.summary;
		if (this.detailSaleReports) {
			this.detailSaleReports.forEach((x) => {
				x.kitchen = NumberUtils.convertCommaToNumber(x.kitchen);
				x.bbq = NumberUtils.convertCommaToNumber(x.bbq);
				x.dimsum = NumberUtils.convertCommaToNumber(x.dimsum);
				x.bar = NumberUtils.convertCommaToNumber(x.bar);
				x.pickle = NumberUtils.convertCommaToNumber(x.pickle);
				x.cigarette = NumberUtils.convertCommaToNumber(x.cigarette);
				x.serviceFee = NumberUtils.convertCommaToNumber(x.serviceFee);
				x.totalBeforeTax = NumberUtils.convertCommaToNumber(
					x.totalBeforeTax
				);
				x.discount = NumberUtils.convertCommaToNumber(x.discount);
				x.tax = NumberUtils.convertCommaToNumber(x.tax);
				x.roundAdjusted = NumberUtils.convertCommaToNumber(
					x.roundAdjusted
				);
				x.receiptTotal = NumberUtils.convertCommaToNumber(
					x.receiptTotal
				);
			});
		}
	}

	ngOnDestroy(): void {}
}
