<div class="p-5">
	<div class="emlo-modal-body text-center" mat-dialog-content>
		<mat-icon class="mat-icon-xxl mb-2"
			[svgIcon]="IconName.icon_warning">
		</mat-icon>
		<p translate>{{ warningModel?.message }}</p>
	</div>
	<div class="emlo-modal-footer justify-content-center" mat-dialog-actions>
		<button type="button" class="btn-lg btn btn-primary" (click)="confirm()" translate>{{ confirmTextButton }}</button>
	</div>
</div>
