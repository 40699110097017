export class ClientItemList {
	clientDetail: ClientDetail = ClientDetailFactory.create();
	restaurantDetail: RestaurantDetail = RestaurantDetailFactory.create();
}

export interface ClientDetail {
	id: string;
	name: string;
	// localUrl: string;
	// grantTypeName: string;
	// grantType: string;
	clientScope: string;
	clientSecret: string;
}

export interface RestaurantDetail {
	id: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	capacity: number;
	currencyName: string;
	currencySymbol: string;
	gratuityPercentage: string;
	name: string;
	priceIncludesTax: number;
	serviceChargePercentage: number;
	tables: string;
	telephone: string;
	ticketFooter: string;
	uniqueId: string;
	zipCode: string;
	multiLanguage: false;
	defaultLanguage: boolean;
	deliveryFee: number;
	freeDeliveryApplyFromAmount: number;
	concurrencyStamp: string;
}

export class ClientDetailFactory {
	public static create(): ClientDetail {
		return {
			id: null,
			// clientId: null,
			name: null,
			clientScope: null,
			clientSecret: null
		};
	}
}

export class RestaurantDetailFactory {
	public static create(): RestaurantDetail {
		return {
			id: null,
			addressLine1: null,
			addressLine2: null,
			addressLine3: null,
			capacity: null,
			currencyName: null,
			currencySymbol: null,
			gratuityPercentage: null,
			name: null,
			priceIncludesTax: null,
			serviceChargePercentage: null,
			tables: null,
			telephone: null,
			ticketFooter: null,
			uniqueId: null,
			zipCode: null,
			multiLanguage: false,
			defaultLanguage: null,
			deliveryFee: null,
			freeDeliveryApplyFromAmount: null,
			concurrencyStamp: null
		};
	}
}

export interface ClientDataRequest {
	clientDetailId: number | string;
	clientDetailClientId: string;
	clientDetailClientName: string;
	clientDetailLocalUrl: string;
	clientDetailGrantType: string;
	enabledClientSecret: boolean;
	clientDetailClientSecret: string;
	clientDetailClientScope: string;
	restaurantDetailId: number | string;
	restaurantDetailName: string;
	restaurantDetailAddressLine1: string;
	restaurantDetailAddressLine2: string;
	restaurantDetailAddressLine3: string;
	restaurantDetailZipCode: string;
	restaurantDetailTelephone: string;
	restaurantDetailCapacity: number | string;
	restaurantDetailCurrencyName: string;
	restaurantDetailTables: string;
	restaurantDetailCurrencySymbol: string;
	restaurantDetailServiceChargePercentage: number | string;
	restaurantDetailTicketFooter: string;
}
