import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
	providedIn: "root"
})
export class ErrorService {
	getClientErrorMessage(error: Error): string {
		return error.message ? error.message : error.toString();
	}

	getServerErrorMessage(errorResponse: HttpErrorResponse): string {
		let message = "";
		if (errorResponse) {
			if (errorResponse.error && errorResponse.error.message) {
				message = errorResponse.error.message
			} else {
				message = `Sorry, we can't process your request right now.`;
			}
		}
		return navigator.onLine ? message : "No Internet Connection";
	}
}
