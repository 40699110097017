import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserLanguage, UserTimeLogOff } from "../model/language.model";

@Injectable()
export class UserSettingService {
	constructor(private translate: TranslateService) {}

	public getListLanguageSupport(): UserLanguage[] {
		return [
			{ name: "English", code: "en" },
			{ name: "Chinese", code: "zh" },
			{ name: "Vietnamese", code: "vi" }
		] as UserLanguage[];
	}

	public getListUserTimeLogOff(): UserTimeLogOff[] {
		return [
			{
				name: "10 seconds",
				value: 10
			},
			{
				name: "20 seconds",
				value: 20
			},
			{
				name: "30 seconds",
				value: 30
			},
			{
				name: "1 min",
				value: 60
			},
			{
				name: "5 mins",
				value: 300
			},
			{
				name: "10 mins",
				value: 600
			}
		] as UserTimeLogOff[];
	}
}
