import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "formly-wrapper-default",
  template: `
    <div class="form-group test" [class.has-error]="showError">

		<label class="{{to.classLabel}}" *ngIf="to.label" [attr.for]="id" [ngClass]="to.required ? 'obligatoire' : ''">

			{{ to.label }}

		</label>
 
		<div class="{{to.classDivInput}}">

			<span *ngIf="showError" class="help-block">

				<formly-validation-message [field]="field"></formly-validation-message>

			</span>

			<ng-template #fieldComponent></ng-template>

		</div>

	</div>
  `,
})
export class EmloWrapperComponent extends FieldWrapper {
  @ViewChild("fieldComponent", { read: ViewContainerRef }) fieldComponent: ViewContainerRef;
}