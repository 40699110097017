import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { Observable, of } from "rxjs";
import { DataOverview } from "../model/customer-overview";
import {
	OVERVIEW_CUSTOMER,
	OVERVIEW_CUSTOMER_GENDER,
	OVERVIEW_CUSTOMER_LEVEL,
	OVERVIEW_CUSTOMER_USUALLY,
} from "@apps/modules/emlo-admin/shared/mock-data/overview";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "@env/environment";
import { ChartDataSets } from "chart.js";

@Injectable()
export class CustomerOverviewService {
	constructor(private apiService: ApiService) {}

	getCustomerRegistrationStatistic(
		fromDate: Date,
		toDate: Date
	): Observable<DataOverview[]> {
		// return of(OVERVIEW_CUSTOMER);
		// const params = new HttpParams({});
		const request = {
			fromDate,
			toDate,
		};
		return this.apiService.post(
			environment.prefix_api_url +
				"customerreport/getRegistrationStatistic",
			request
		);
	}

	getCustomerActivityStatisticOverview(
		fromDate: Date,
		toDate: Date
	): Observable<DataOverview[]> {
		const request = {
			fromDate,
			toDate,
		};
		return this.apiService.post(
			environment.prefix_api_url + "customerreport/getActivityStatistic",
			request
		);
	}

	getCustomerGenderStatistic(): Observable<DataOverview[]> {
		//return of(OVERVIEW_CUSTOMER_GENDER);
		// const params = new HttpParams({});
		return this.apiService.get(
			environment.prefix_api_url + "customerreport/getGenderStatistic"
		);
	}

	getCustomerLevelOverview(): Observable<DataOverview[]> {
		//return of(OVERVIEW_CUSTOMER_LEVEL);
		// const params = new HttpParams({});
		return this.apiService.get(
			environment.prefix_api_url + "customerreport/getLevelStatistic"
		);
	}

	formatDate(date: NgbDate) {
		return new Date(date.year, date.month - 1, date.day);
	}

	downloadRegistrationReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "customerreport/downloadReport",
			context
		);
	}
	downloadGenderReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "customerreport/downloadReport",
			context
		);
	}
	downloadActivityReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "customerreport/downloadReport",
			context
		);
	}
	downloadCustomerLevelReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "customerreport/downloadReport",
			context
		);
	}
}
