import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { SettingsRoutingModule } from "./settings.routes";
import { CustomerServiceSettingsComponent } from "./settings.component";
import { CustomerServiceSettingService } from "./services/settings.service";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CustomerServiceSettingsDetailComponent } from "./detail/settings.detail.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
	declarations: [
		CustomerServiceSettingsComponent,
		CustomerServiceSettingsDetailComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		NgbDatepickerModule,
		EmloButtonModule,
		SettingsRoutingModule,
	],
	providers: [CustomerServiceSettingService],
})
export class CustomerServiceSettingsModule {}
