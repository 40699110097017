<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'String'">
        <input type="text" class="form-control condition-value" [(ngModel)]="_value" required (ngModelChange)="onChangeInternalValue()">
    </ng-container>
    <ng-container *ngSwitchCase="'Number'">
        <input type="number" class="form-control condition-value" [(ngModel)]="_value" required (ngModelChange)="onChangeInternalValue()">
    </ng-container>
    <ng-container *ngSwitchCase="'Date'">
        <input type="date" class="form-control condition-value" [(ngModel)]="_value" required (ngModelChange)="onChangeInternalValue()">
    </ng-container>
    <ng-container *ngSwitchCase="'Select'">
        <select class="form-control condition-value" [(ngModel)]="_value" required (ngModelChange)="onChangeInternalValue()">
        </select>
    </ng-container>
</ng-container>