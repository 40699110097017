export interface Item {
	id: string;
	name: string;
	nameCn: string;
	nameEn: string;
	nameVi: string;
	barCode: string;
	btnColor: string;
	buyPrice: number;
	canDiscount: boolean;
	discountRate: number;
	menuGroupName: string;
	menuGroupNameEn: string;
	menuGroupNameVi: string;
	menuGroupNameCn: string;
	imageId: string;
	isSetMenu: boolean;
	parentSetMenuId: string;
	price: number;
	printerGroupName: string;
	recepieId: string;
	reportingGroup: string;
	showImageOnly: boolean;
	sortOrder: number;
	taxName: string;
	textColor: string;
	isVisible: boolean;
	restaurantId: string | string[];
}

export class ItemFactory {
	public static create(): Item {
		return {
			id: null,
			name: null,
			nameCn: null,
			nameEn: null,
			nameVi: null,
			barCode: null,
			btnColor: null,
			buyPrice: null,
			canDiscount: null,
			discountRate: null,
			menuGroupName: null,
			menuGroupNameEn: null,
			menuGroupNameVi: null,
			menuGroupNameCn: null,
			imageId: null,
			isSetMenu: null,
			parentSetMenuId: null,
			price: null,
			printerGroupName: null,
			recepieId: null,
			reportingGroup: null,
			showImageOnly: null,
			sortOrder: null,
			taxName: null,
			textColor: null,
			isVisible: true,
			restaurantId: null
		};
	}
}

export class ItemSearchModel {
	searchText: string = null;
	isVisible: boolean = null;
	restaurantId: string | string[] = null;
	menuGroupId: string = null;
	pageNum: number = 1;
    pageSize: number = 10;
	isSetMenu: boolean = null;
}
