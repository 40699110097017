import { Component, OnInit } from "@angular/core";
import { CustomerLevelService } from "../../services/customer-level.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CustomerLevel, CustomerTransaction } from "../../model/customer-level";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { Columns, Config, DefaultConfig } from "@apps/shared/table";
import { Subject } from "rxjs";
import { GiftService } from "../../../gift/services/gift.service";
import { Gift, GiftSearchModel } from "../../../gift/model/gift";

@Component({
	selector: "app-customer-level-detail",
	templateUrl: "detail.html",
})
export class CustomerLevelDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	public configuration: Config;
	public columns: Columns[] = [];
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	customerFormFields: FormlyFieldConfig[] = [];
	customerLevel: CustomerLevel = new CustomerLevel();
	customerTransactions: CustomerTransaction[] = [];
	availableLevelcode$: Subject<any[]> = new Subject();
	gift$: Subject<any[]> = new Subject();
	cloneGifts: Gift[] = [];
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private customerService: CustomerLevelService,
		private giftService: GiftService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.getAvailableLevelcode();
		this.getGifts();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const levelDefId = params.get("id");
			if (levelDefId) {
				this.isEdit = true;
				this.getCustomerLevelById(levelDefId);
			}
			this.createForm();
			this.configCustomerTable();
		});
	}

	private getAvailableLevelcode(): void {
		this.customerService.getAvailableLevelcode().subscribe((data) => {
			if (data) {
				data.map((x) => (x.levelCode = x.id));
				this.availableLevelcode$.next(data);
			}
		});
	}

	private getGifts(): void {
		const criteria: GiftSearchModel = new GiftSearchModel();
		criteria.pageSize = 10000;
		this.giftService.getGifts(criteria).subscribe((data) => {
			if (data) {
				this.cloneGifts = data.data;
				this.gift$.next(data.data);
			}
		});
	}

	private createForm() {
		this.customerFormFields = [
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						key: "levelCode",
						type: "emlo-select",
						className: "col-6",
						hideExpression: this.isEdit,
						templateOptions: {
							label: "Level Code",
							placeholder: "Level Code",
							bindLabel: "name",
							bindValue: "levelCode",
							options: this.availableLevelcode$,
						},
					},
					{
						key: "levelCodeName",
						type: "input",
						className: "col-6",
						hideExpression: !this.isEdit,
						templateOptions: {
							label: "Level Code",
							disabled: true,
							placeholder: "Level Code",
						},
					},
					{
						type: "input",
						key: "name",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Level Name",
							placeholder: "Level Name",
						},
					},
					{
						key: "minPoints",
						type: "input",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Minimum Points",
							type: "number",
							placeholder: "Minimum Points to archive",
						},
					},
					{
						key: "defaultDiscountPercentage",
						type: "input",
						className: "col-6",
						templateOptions: {
							required: false,
							type: "number",
							label: "Default Discount (%)",
							placeholder: "Default Discount (%)",
						},
					},
					{
						key: "discountPercentageOnDOB",
						type: "input",
						className: "col-3",
						templateOptions: {
							required: false,
							type: "number",
							label: "Discount on DOB (%)",
							placeholder: "Discount on DOB (%)",
						},
					},
					{
						key: "discountPercentageOnCustomerNumber",
						type: "input",
						className: "col-3",
						templateOptions: {
							required: false,
							type: "number",
							label: "Discount on Customer Number (%)",
							placeholder: "Discount on Customer Number (%)",
						},
					},
					{
						key: "minDayFromDOBForGift",
						type: "input",
						className: "col-6",
						templateOptions: {
							required: false,
							type: "number",
							label: "Day from/to of DOB",
							placeholder:
								"[n] of days to allow member can have benefit based on their birthday",
						},
					},
					{
						key: "giftId",
						type: "emlo-select",
						className: "col-12",
						templateOptions: {
							label: "Gift",
							placeholder: "Gift",
							bindLabel: "name",
							bindValue: "id",
							options: this.gift$,
							change: ($event: any) => {
								const gift = this.cloneGifts.find(
									(x) => x.id === $event.model.giftId
								);
								if (gift) {
									if (!this.customerLevel.gifts) {
										this.customerLevel.gifts = [];
									}
									this.customerLevel.gifts.unshift(gift);
								}
							},
						},
					},
				],
			},
			IdControl,
			{
				type: "emlo-input",
				key: "concurrencyStamp",
				templateOptions: {
					type: "hidden",
				},
			},
		];
	}

	private configCustomerTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = false;
		this.columns = [
			{ key: "date", title: "Date", orderEnabled: false },
			{ key: "bean", title: "Bean", orderEnabled: false },
		];
	}

	private getCustomerLevelById(levelDefId: string): void {
		this.customerService
			.getCustomerLevelById(levelDefId)
			.subscribe((customer: CustomerLevel) => {
				if (customer) {
					this.customerLevel = customer;
				}
			});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const giftIds = this.customerLevel?.gifts?.map((x) => x.id);
		data.giftIds = giftIds;
		this.isEdit
			? this.updateCustomerLevel(data)
			: this.createCustomerLevel(data);
	}

	private createCustomerLevel(body: CustomerLevel): void {
		this.customerService.createCustomerLevel(body).subscribe(() => {
			this.backToCustomerLevelList();
		});
	}

	private updateCustomerLevel(body: CustomerLevel): void {
		this.customerService.updateCustomerLevel(body).subscribe(() => {
			this.backToCustomerLevelList();
		});
	}

	cancel() {
		this.backToCustomerLevelList();
	}

	private backToCustomerLevelList(): void {
		this.router.navigate([
			"/customer-service/configuration/customer-level",
		]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	onRemoveCustomerGift(gift: Gift, index: number) {
		this.customerLevel.gifts.splice(index, 1);
	}
}
