import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { GiftSearchModel, Gift } from "../../model/gift";
import { GiftService } from "../../services/gift.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-gift-list",
	templateUrl: "./list.html",
})
export class GiftListComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy
{
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("isPermanentTpl", { static: true })
	isPermanentTpl: TemplateRef<any>;

	public configuration: Config;
	public columns: Columns[] = [];
	public gifts: Gift[] = [];
	public pagination = PaginationModelFactory.create();
	criteria: GiftSearchModel = new GiftSearchModel();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private giftService: GiftService,
		private credentialsService: CredentialsService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.configGiftsTable();
		this.getGifts();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteria = { ...this.criteria, ...$event.value };
			this.getGifts();
		}
	}

	private getGifts() {
		this.giftService
			.getGifts(this.criteria)
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if (response) {
					this.gifts = response.data;
					this.pagination = response;
				}
			});
	}

	private configGiftsTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "name", title: "Name", orderEnabled: false },
			{ key: "giftTypeName", title: "Type", orderEnabled: false },
			{ key: "amount", title: "Amount", orderEnabled: false },
			{
				key: "amountPercent",
				title: "Amount Percent",
				orderEnabled: false,
			},
			{
				key: "isPermanent",
				title: "Is Permanent",
				orderEnabled: false,
				cssClass: { name: "text-center", includeHeader: true },
				cellTemplate: this.isPermanentTpl,
			},
			{
				key: "numberOfValidDays",
				title: "Day(s) valid to claim",
				orderEnabled: false,
			},
			{
				key: "menuItemName",
				title: "Menu Item (type: Item)",
				orderEnabled: false,
			},
			{
				key: "minExpense",
				title: "Minimum Expense",
				orderEnabled: false,
			},
			{
				key: "validFrom",
				title: "Valid From",
				orderEnabled: false,
			},
			{
				key: "validTo",
				title: "Valid To",
				orderEnabled: false,
			},
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	private deleteGift(gift: Gift): void {
		const data = { id: gift.id, concurrencyStamp: gift.concurrencyStamp };
		this.giftService.deleteGift(data).subscribe(() => {
			this.getGifts();
		});
	}

	delete(gift: Gift): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteGift(gift);
				}
			});
	}

	openGiftDetail(gift: Gift): void {
		if (!gift) {
			this.router.navigate(["/customer-service/configuration/gift/add"]);
		} else {
			this.router.navigate(
				["/customer-service/configuration/gift/edit"],
				{
					queryParams: { id: gift.id },
				}
			);
		}
	}

	onFilter() {
		this.getGifts();
	}

	ngOnDestroy() {}
}
