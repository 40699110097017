import { Component, OnInit } from "@angular/core";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { Order } from "../../model/order";
import { OrderService } from "../../services/order.service";

@Component({
	selector: "app-order-detail",
	templateUrl: "detail.html",
})
export class OrderDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	isLoading: boolean = false;
	tabIndex: number = 0;
	IconName = IconName;
	order: Order = new Order();
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private orderService: OrderService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const orderId = params.get("id");
			if (orderId) {
				this.isEdit = true;
				this.isLoading = true;
				this.getOrderById(orderId);
			}
		});
	}

	private getOrderById(orderId: string): void {
		this.orderService
			.getOrderById(orderId)
			.subscribe((order: Order) => {
				if (order) {
					this.order = order;
				}
				this.isLoading = false;
			});
	}

	cancel() {
		this.backToOrderList();
	}

	private backToOrderList(): void {
		this.router.navigate(["/customer-service/order"]);
	}
}
