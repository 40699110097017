import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import {
	Customer,
	CustomerClaimPointHistory,
	CustomerTransactionSearchModel,
} from "../../model/customer";
import { CustomerService } from "../../services/customer.service";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { debounceTime, finalize } from "rxjs/operators";

@Component({
	selector: "app-customer-point-history",
	templateUrl: "./point-history.component.html",
})
export class PointHistoryCustomerComponent
	extends AdminBaseComponent
	implements OnInit, OnDestroy, OnChanges
{
	@Input() customer: Customer = new Customer();
	@Input() tabIndex: number = 0;
	public configuration: Config;
	isLoading: boolean = false;
	IconName = IconName;
	criteriaCustomerTransaction: CustomerTransactionSearchModel =
		new CustomerTransactionSearchModel();
	customerTransactions: CustomerClaimPointHistory[] = [];
	public pagination = PaginationModelFactory.create();
	public columns: Columns[] = [];

	constructor(private customerService: CustomerService) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.getCustomerPointHistory();
	}

	ngOnInit(): void {
		this.configCustomerPointHistoryTable();
	}

	private configCustomerPointHistoryTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "claimedDate", title: "Date", orderEnabled: false },
			{ key: "claimedPoints", title: "Point(s)", orderEnabled: false },
			{ key: "restaurantName", title: "At branch", orderEnabled: false },
			{
				key: "restaurantTicketId",
				title: "Order ref",
				orderEnabled: false,
			},
			{ key: "purchaseAmount", title: "Amount", orderEnabled: false },
			{ key: "ticketType", title: "Ticket Type", orderEnabled: false },
			{ key: "notes", title: "Notes", orderEnabled: false },
		];
	}

	onFilter() {
		this.getCustomerPointHistory();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.criteriaCustomerTransaction = {
				...this.criteriaCustomerTransaction,
				...$event.value,
			};
			this.getCustomerPointHistory();
		}
	}

	private getCustomerPointHistory() {
		this.isLoading = true;
		this.customerService
		.getCustomerPointHistory(
			this.criteriaCustomerTransaction,
			this.customer.customerCode
			)
			.pipe(
				finalize(() => {
					this.isLoading = false;
				}),
			)
			.pipe(debounceTime(500))
			.subscribe((response) => {
				if (response) {
					this.customerTransactions = response.data;
					this.pagination = response;
				}
			});
	}

	ngOnDestroy(): void {}
}
