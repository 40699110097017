import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { Coupon, CouponSearchModel } from "../model/coupon";
import { environment } from "@env/environment";
import { IDiscountSearchRequestModel } from "../model/discount-search-request.model";
import { IApiPagingResponseModel } from '@apps/common/model/api.reponse.model';
import { Observable } from 'rxjs';

@Injectable()
export class CouponService {
	constructor(private apiService: ApiService) {}

	getCoupons(request: CouponSearchModel): Observable<IApiPagingResponseModel<Coupon[]>> {
		return this.apiService.post(environment.prefix_api_url + "discount/list", request);
	}

	getModifierGroupSelections() {
		return this.apiService.get(environment.prefix_api_url + "discount/selectionlist");
	}

	getCoupon(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "discount/getbyid", params);
	}

	createCoupon(context: Coupon) {
		return this.apiService.post(environment.prefix_api_url + "discount/create", context);
	}

	updateCoupon(context: Coupon) {
		return this.apiService.post(environment.prefix_api_url + "discount/edit", context);
	}

	deleteCoupon(context: any) {
		return this.apiService.post(environment.prefix_api_url + "discount/delete", context);
	}
}
