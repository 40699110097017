import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export type InteralStateType = {
	[key: string]: any;
};

@Injectable()
export class ShareDataService {
	private _observableState: InteralStateType = new Array<
		BehaviorSubject<any>
	>();

	getObservable(key: string): Observable<any> {
		if (!this._observableState[key]) {
			this._observableState[key] = new BehaviorSubject<any>(null);
		}
		return this._observableState[key].asObservable();
	}

	setObservable(key: string, data: any) {
		if (!this._observableState[key]) {
			this._observableState[key] = new BehaviorSubject<any>(null);
		}
		this._observableState[key].next(data);
	}

	unsubscribe(key: string) {
		if (this._observableState[key]) {
			this._observableState[key].unsubscribe();
			this._observableState[key] = null;
		}
	}
}
