import { Injectable } from "@angular/core";

import { StorageService } from "./storage.service";

/**
 * @desc Implementation of storage service. Use local storage
 */
@Injectable()
export class LocalStorageService implements StorageService {
	/**
	 * @inheritdoc
	 */
	getItem(key: string): string {
		return localStorage.getItem(key);
	}

	/**
	 * @inheritdoc
	 */
	setItem(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	/**
	 * @inheritdoc
	 */
	setItemAsync(key: string, value: string): Promise<void> {
		localStorage.setItem(key, value);
		return Promise.resolve();
	}

	/**
	 * @inheritdoc
	 */
	getItemAsync(key: string): Promise<string> {
		return Promise.resolve(localStorage.getItem(key));
	}

	/**
	 * @inheritdoc
	 */
	initialize(): Promise<boolean> {
		// Nothing to implement for local storage
		return Promise.resolve(true);
	}

	public removeItem(key: string): void {
		localStorage.removeItem(key);
	}
}
