<div class="box border-sub-1">
	<emlo-box-header [title]="'Customer Configuration'">
	</emlo-box-header>
	<mat-tab-group *ngIf="canAccess; else contactAdmin">
		<mat-tab label="{{ 'Customer Level' | translate }}">
			<div class="box-content">
				Customer Level
			</div>
		</mat-tab>
		<!-- <mat-tab label="{{ 'Config Bean' | translate }}">
			<div class="box-content">
				Configuration Bean
			</div>
		</mat-tab> -->
		<!-- <mat-tab label="{{ 'Coupon / Discount' | translate }}">
			<div class="box-content">
				Coupon / Discount
			</div>
		</mat-tab>
		<mat-tab label="{{ 'Config Banner' | translate }}">
			<div class="box-content">
				Config Banner
			</div>
		</mat-tab>
		<mat-tab label="{{ 'Config Campaign' | translate }}">
			<div class="box-content">
				Config Campaign
			</div>
		</mat-tab> -->
	</mat-tab-group>
	<ng-template #contactAdmin>
		<div class="box-content">
			{{ "You don't have permission to access this page" | translate }}.
		</div>
	</ng-template>
</div>
