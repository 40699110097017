/**
 * @author Nam Nguyen
 * @email nhn12.hoangnam@gmail.com
 * @create date 2020-04-03 13:53:20
 * @modify date 2020-04-03 13:53:20
 * @desc Default Condition Builder
 */

import { AbstractCondition } from "./abstract-condition";
import { ConditionOperator } from "../model/condition-operator.enum";
import { IConditionModel } from "../model/condition.model";
import { FilterType } from "../model/condition-type.enum";
import * as moment from "moment";
import { CommonConstant } from "@apps/common/constants/common.constant";

export class DefaultCondition implements AbstractCondition {
	private _innerOperator: string;
	private _innerValue1: any;
	private _innerValue2: any;
	public supportedOperator: ConditionOperator[] = [];
	// public type: FilterType;
	constructor(
		public key: string,
		public value: any,
		public operator: ConditionOperator,
		public label?: string,
		public value2?: string,
		public type?: FilterType
	) {}

	public printPretty(): IConditionModel | IConditionModel[] {
		if(this.type === FilterType.DATE) {
			if(this.value) {
				this._innerValue1 = moment(this.value, "YYYY-MM-DD").format(CommonConstant.DEFUALT_SERVER_FORMAT);
			}
			if(this.value2 && this.operator === ConditionOperator.BETWEEN) {
				this._innerValue2 = moment(this.value2, "YYYY-MM-DD").format(CommonConstant.DEFUALT_SERVER_FORMAT);
			}
		} else {
			this._innerValue1 = this.value;
			this._innerValue2 = this.value2;
		}
		switch (this.operator) {
			case ConditionOperator.CONTAIN:
				this._innerOperator = "Contains";
				break;
			case ConditionOperator.EQUAL:
				this._innerOperator = "Equals";
				break;
			case ConditionOperator.GREATER_OR_EQUAL:
				this._innerOperator = "GreaterThanOrEqual";
				break;
			case ConditionOperator.LESS_OR_EQUAL:
				this._innerOperator = "LessThanOrEqual";
				break;
			case ConditionOperator.LESS_THAN:
				this._innerOperator = "LessThan";
				break;
			case ConditionOperator.GREAT_THAN:
				this._innerOperator = "GreaterThan";
				break;
			case ConditionOperator.BETWEEN:
				const _tempCondition = [];
				if(this._innerValue1) {
					_tempCondition.push({
						key: this.key,
						value: this._innerValue1,
						operator: "GreaterThanOrEqual",
						type: this.type
					});
				}
				if(this._innerValue2) {
					_tempCondition.push({
						key: this.key,
						value: this._innerValue2,
						operator: "LessThanOrEqual",
						type: this.type
					})
				}
				return _tempCondition;
			default:
				break;
		}
		return {
			key: this.key,
			value: this._innerValue1,
			operator: this._innerOperator,
			type: this.type
		};
	}

	public printTextSummary() {
		if(this.type === FilterType.DATE) {
			if(this.value) {
				this._innerValue1 = moment(this.value, "YYYY-MM-DD").format(CommonConstant.DEFUALT_SERVER_FORMAT);
			}
			if(this.value2 && this.operator === ConditionOperator.BETWEEN) {
				this._innerValue1 = moment(this.value2, "YYYY-MM-DD").format(CommonConstant.DEFUALT_SERVER_FORMAT);
			}
		}
		switch (this.operator) {
			case ConditionOperator.CONTAIN:
				this._innerOperator = "contains";
				break;
			case ConditionOperator.EQUAL:
				this._innerOperator = "equals";
				break;
			case ConditionOperator.GREATER_OR_EQUAL:
				this._innerOperator = "greater than or equal";
				break;
			case ConditionOperator.LESS_OR_EQUAL:
				this._innerOperator = "less than or equal";
				break;
			case ConditionOperator.LESS_THAN:
				this._innerOperator = "less than";
				break;
			case ConditionOperator.GREAT_THAN:
				this._innerOperator = "greater than";
				break;
			case ConditionOperator.BETWEEN:
				this._innerOperator = "between";
				break;
			default:
				break;
		}
		return `${this.key} ${this._innerOperator} ${this._innerValue1} ${this.operator === ConditionOperator.BETWEEN ? " and " : this._innerValue2}`;
	}
}

export class DefaultConditionFactory {
	static empty(): DefaultCondition {
		return new DefaultCondition(null, null, null);
	}
}
