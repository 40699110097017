<div class="box border-sub-1">
	<emlo-box-header [title]="'Menu Categories'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<!-- <div class="box-toolbar horizontal-end">
		<button class="btn btn-sm " [ngClass]="!advSearch ? 'btn-light bg-color-active active' : 'btn-primary'" (click)="advSearch =! advSearch">
			<mat-icon [svgIcon]="IconName.icon_search" class="mat-icon-ssm"></mat-icon>
			Advance Filter
		</button>
	</div> -->
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-3">
			<div class="flex-grow-1">
				<div class="d-flex flex-row align-items-center">
					<div class="search-type" >
						<ng-select class="p-0" name="restaurantId" [items]="restaurants" [(ngModel)]="criteria.restaurantId"
							(change)="onFilter()" bindLabel="name" bindValue="id" [disabled]="isEditOrder"
							[placeholder]="'All restaurants'" appendTo="body">
						</ng-select>
					</div>
					<div class="keyword ml-2">
						<input type="text" class="form-control" name="keyword" [(ngModel)]="criteria.searchText"
							(keyup.enter)="onFilter()" [disabled]="isEditOrder"
							placeholder="{{ 'Search...' | translate }}" autocomplete="off">
					</div>
					<div class="search-visible ml-3">
						<mat-radio-group class="row" name="isVisible" [(ngModel)]="criteria.isVisible"
							(change)="onFilter()" [disabled]="isEditOrder">
							<mat-radio-button class="pr-3" *ngFor="let state of states" [value]="state.value">
								{{ state.name | translate }}
							</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</div>
			<!-- <div class="edit-order ml-2" *ngIf="enableEditOrder">
				<button *ngIf="!isEditOrder" [disabled]="!allowEditOrder" class="button-small h-100" mat-raised-button
					color="info" (click)="onEditOder()">
					{{ 'Edit Order' | translate }}
				</button>
				<button *ngIf="isEditOrder" class="button-small h-100" mat-raised-button color="info"
					(click)="onUpdateEditOder()">
					{{ 'Save' | translate }}
				</button>
				<button *ngIf="isEditOrder" class="button-small h-100 ml-2" mat-raised-button color="red"
					(click)="onCancelEditOder()">
					{{ 'Cancel' | translate }}
				</button>
			</div> -->

		</div>
		<!-- <div class="mb-3" *ngIf="advSearch">
			<emlo-filter-builder [meta]="filterMeta" [(ngModel)]="searchData" [isLoading]="isLoading"  (submit)="onFilterSubmit($event)" (clear)="onClear($event)"></emlo-filter-builder>
		</div> -->
		<emlo-table
			[data]="categories"
			[columns]="columns"
			[pagination]="pagination"
			[configuration]="configuration"
			(event)="onTableEvent($event)">
		</emlo-table>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #isVisibleTpl let-row>
		<mat-slide-toggle
			(click)="toggleCategory($event, row)"
			[checked]="row.isVisible">
		</mat-slide-toggle>
	</ng-template>
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openCategoryDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<!-- <button class="btn btn-sm mr-2" [ngClass]="!advSearch ? 'btn-light bg-color-active active' : 'btn-primary'" (click)="advSearch =! advSearch">
			<mat-icon [svgIcon]="IconName.icon_search" class="mat-icon-ssm"></mat-icon>
			Advance Filter
		</button> -->
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="openCategoryDetail(null)">Add New</button>
	</ng-template>
</div>
