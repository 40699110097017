<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Edit User' : 'Add User'" [headerActionsTemplate]="headerActionsTemplate">
	</emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">
		<div class="box-content">
			<mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="swittchTab($event)">
				<mat-tab label="{{ 'General' | translate }}">
					<div class="box-content item-content" *ngIf="user">
						<formly-form [model]="user" [fields]="userFormFields" [form]="form"></formly-form>
					</div>
				</mat-tab>
				<mat-tab label="{{ 'Assign Restaurant ' | translate }}">
					<div class="box-content item-content">
						<div class="p-3">
							<div class="mb-2" *ngFor="let restaurant of userRestaurants; let index = index;">
								<input type="checkbox" [(ngModel)]="restaurant.assigned"
									[ngModelOptions]="{standalone: true}" class="form-check-input"
									[id]="'restaurant_name_' + index" (ngModelChange)="toggleAssignRestaurant(restaurant)">
								<label [for]="'restaurant_name_' + index">{{ restaurant.name}}</label>
							</div>
						</div>
						<!-- <fieldset *ngFor="let restaurant of userRestaurants; let index = index;"
							[ngClass]="{'un-assign': !restaurant.assigned}">
							<legend>
								<input type="checkbox" [(ngModel)]="restaurant.assigned"
									[ngModelOptions]="{standalone: true}" class="form-check-input"
									[id]="'restaurant_name_' + index"
									(ngModelChange)="toggleAssignRestaurant(restaurant)">
								<label [for]="'restaurant_name_' + index">{{ restaurant.name}}</label>
							</legend>
							<input type="hidden" [(ngModel)]="restaurant.concurrencyStamp"
								[ngModelOptions]="{standalone: true}" class="form-control">
							<input type="hidden" [(ngModel)]="restaurant.concurrencyStamp"
								[ngModelOptions]="{standalone: true}" class="form-control">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>{{ 'User Role' | translate }}</label>
										<ng-select bindLabel="name" autofocus bindValue="id" [clearable]="true"
											[(ngModel)]="restaurant.roleId"
											[ngModelOptions]="{standalone: true}" [disabled]="!restaurant.assigned"
											[placeholder]="'User Role'" appendTo="body">
											<ng-option *ngFor="let item of restaurant.userRoles" [value]="item.id"
												[disabled]="item.disabled">{{item.name}}
											</ng-option>
										</ng-select>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>{{ 'Default Language' | translate }}</label>
										<ng-select bindLabel="name" autofocus bindValue="code" [clearable]="true"
											[(ngModel)]="restaurant.defaultLanguage"
											[ngModelOptions]="{standalone: true}" [disabled]="!restaurant.assigned"
											[placeholder]="'Default Language'" appendTo="body">
											<ng-option *ngFor="let item of userLanguages" [value]="item.code"
												[disabled]="item.disabled">{{item.name}}
											</ng-option>
										</ng-select>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>{{ 'Auto loggoff time' | translate }}</label>
										<input type="number" [(ngModel)]="restaurant.autoLogoffInSecond"
											[disabled]="!restaurant.assigned" [ngModelOptions]="{standalone: true}"
											class="form-control" [placeholder]="'Auto loggoff time' | translate">
									</div>
								</div>
							</div>
						</fieldset> -->
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
		<div class="box-footer">

			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>

			<button *ngIf="tabIndex === 0" type="button" class="btn btn-primary mr-2" (click)="tabIndex = 1"
				translate>Assign Restaurant</button>

			<emlo-button *ngIf="tabIndex === 1" type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
