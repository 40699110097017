<div class="box border-sub-1">
	<emlo-box-header [title]="'Menu Item Sales Analysis'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content mh-50" *ngIf="canAccess; else contactAdmin">

		<div class="row">
			<div class="form-group col-sm-12 col-md-6">
				<label translate>Restaurant</label>
				<ng-select [items]="restaurants" bindLabel="name" autofocus bindValue="id" placeholder="Select Restaurant"  [(ngModel)]="restaurantId">
				</ng-select>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label translate>Report Group</label>
				<ng-select name="reportGroup" [(ngModel)]="reportingGroup" [items]="reportingGroups"
						[clearable]="false" placeholder="{{ 'Report Group' | translate }}" appendTo="body">
				</ng-select>
			</div>
		</div>


		<div class="d-lg-flex d-md-inline justify-content-start align-items-start">
			<div class="pr-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="startDate" (ngModelChange)="changeDateReport()"
							[matDatepicker]="start" placeholder="{{ 'Start Date' | translate }}" [max]="endDate">
						<mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
						<mat-datepicker #start></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="startTime" [format]="24" [controlOnly]="true"
							[disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="pl-3">
				<div class="d-flex justify-content-start align-items-center">
					<mat-form-field class="align-input">
						<input matInput [(ngModel)]="endDate" (ngModelChange)="changeDateReport()" [matDatepicker]="end"
							placeholder="{{ 'End Date' | translate }}" [min]="startDate">
						<mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
						<mat-datepicker #end></mat-datepicker>
					</mat-form-field>
					<div>
						<ngx-timepicker-field class="timepicker-custom" [(ngModel)]="endTime" [format]="24" [controlOnly]="true"
							[disabled]="!isCustomDate">
						</ngx-timepicker-field>
					</div>
				</div>
			</div>
			<div class="d-flex align-items-start">
				<emlo-button class="mx-1 b-small b-full" [textButton]="'View Report'" [color]="'primary'"
					[textProcessing]="'View Report'" (click)="viewReport()" [disabled]="inprogress">
				</emlo-button>

				<emlo-button class="mx-1 b-small b-full" [textButton]="'Export Excel'" [color]="'primary'"
					[textProcessing]="'Export Excel'" (click)="exportReport()" [disabled]="inprogress">
				</emlo-button>
			</div>
		</div>
		<app-loader [isLoading]="inprogress"></app-loader>
		<div class="content-report" *ngIf="!inprogress">
			<div class="alert alert-warning" role="alert" *ngIf="analysisMenuItemReport">
				{{ 'This is top result, to view full report, please Export to Excel' | translate}}
			</div>
			<div *ngIf="analysisMenuItemReport" class="alert alert-info" role="alert">
				<div *ngIf="analysisMenuItemReport.generatedAt">{{ 'Report Generated On' | translate}}:
					{{ analysisMenuItemReport.generatedAt }}</div>
				<div *ngIf="analysisMenuItemReport.from">{{ 'Report Period' | translate}}:
					{{ analysisMenuItemReport.from }} to
					{{ analysisMenuItemReport.to }}
				</div>
			</div>
			<div class="analysisMenuItemReports" *ngIf="analysisMenuItemReports; else noSaleReport">
				<div class="table-responsive">
					<table class="table table-bordered table-striped">
						<thead>
							<tr>
								<th>{{ 'Item name (VN)' | translate }}</th>
								<th>{{ 'Item name (CN)' | translate }}</th>
								<th>{{ 'Reporting group' | translate }}</th>
								<th class="text-right">{{ 'Quantity' | translate }}</th>
								<th class="text-right">{{ 'Total Sales' | translate }}</th>
								<th class="text-right">{{ 'Average sale price' | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of analysisMenuItemReports">
								<td>{{ item.menuItemNameVn }}</td>
								<td>{{ item.menuItemNameCn }}</td>
								<td>{{ item.reportingGroup }}</td>
								<td class="text-right">{{ item.quantity | number }}</td>
								<td class="text-right">{{ item.totalSales | number }}</td>
								<td class="text-right">{{ item.averagePrice | number }}</td>
							</tr>
							<tr class="font-weight-bold" *ngIf="summary">
								<td colspan="3" class="text-right">{{ 'Total' | translate }}</td>
								<td class="text-right">{{ summary.totalQuantity | number }}</td>
								<td class="text-right">{{ summary.totalTotalSales | number }}</td>
								<td class="text-right">{{ summary.totalAverage | number }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<ng-template #noSaleReport>
				<div class="py-3" *ngIf="analysisMenuItemReport">{{ 'No data' | translate }}</div>
			</ng-template>
		</div>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
</div>
