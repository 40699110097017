import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorDialogService } from "@apps/shared/errordialog";

@Injectable({
	providedIn: "root"
})
export class NotificationService {
	constructor(
		private snackBar: MatSnackBar,
		private zone: NgZone,
		private errorDialogService: ErrorDialogService
	) {}

	showSuccess(message: string): void {
		this.zone.run(() => {
			this.snackBar.open(message);
		});
	}

	showError(message: string): void {
		this.zone.run(() => {
			this.snackBar.open(message, null, {
				panelClass: ["bg-danger"],
				duration: 3000
			});
		});
	}

	showDialogError(httpResponse: HttpErrorResponse) {
		this.zone.run(() => {
			this.errorDialogService.openDialog(httpResponse);
		});
	}
}
