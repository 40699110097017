import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IconName } from "@apps/common/constants/icon";
import { FormlyFieldConfig } from "@ngx-formly/core";
import * as moment from 'moment';
import { Subject } from "rxjs";
import { GiftSearchModel } from "../../../gift/model/gift";
import { GiftService } from "../../../gift/services/gift.service";
import { UpdateGiftCustomer, CustomerGift } from "../../model/customer";
import { CustomerService } from "../../services/customer.service";

@Component({
	selector: "app-customer-update-gift",
	templateUrl: "update-gift.html",
})
export class UpdateGiftCustomerComponent implements OnInit {
	IconName = IconName;
	updateGiftFormFields: FormlyFieldConfig[] = [];
	updateGift: UpdateGiftCustomer = new UpdateGiftCustomer();
	form = new FormGroup({});
	constructor(
		public dialogRef: MatDialogRef<UpdateGiftCustomerComponent>,
		@Inject(MAT_DIALOG_DATA) public customerGift: CustomerGift,
		private customerService: CustomerService,
		private giftService: GiftService,
	) {
		this.updateGift.id = customerGift.id;
		this.updateGift.giftName = customerGift.giftName;
		this.updateGift.customerGiftId = customerGift.customerGiftId;
		this.updateGift.is_claimed = customerGift.isClaimed;
		this.updateGift.ticketId = customerGift.claimedByTicketId;
		this.updateGift.restaurantId = customerGift.claimedAtRestaurantId;
		this.updateGift.claimedDate = customerGift.claimedOnDate;
		this.updateGift.notes = customerGift.notes;
		console.log(this.updateGift);
	}
	ngOnInit(): void {
		this.createForm();
	}

	private createForm() {
		this.updateGiftFormFields = [
			{
				type: "emlo-input",
				key: "giftName",
				templateOptions: {
					type: "text",
					readonly: true
				}
			},
			{
				type: "emlo-checkbox",
				key: "is_claimed",
				templateOptions: {
					label: "Is Claimed",
				},
			},
			{
				type: "input",
				key: "ticketId",
				templateOptions: {
					label: "Ticket ID",
					type: "number"
				},
			},
			{
				type: "emlo-input",
				key: "restaurantId",
				templateOptions: {
					label: "Restaurant ID",
				},
			},
			{
				type: "emlo-input",
				key: "claimedDate",
				templateOptions: {
					label: "Claimed Date",
				},
			},
			{
				type: "textarea",
				key: "notes",
				defaultValue: null,
				templateOptions: {
					label: "Notes",
					placeholder: "Notes",
					rows: 4,
					maxLength: 250
				},
			}
		];
	}

	close() {
		this.dialogRef.close();
	}

	updateGiftOfCustomer() {
		if (this.updateGift.claimedDate != null && this.updateGift.claimedDate != "")
			this.updateGift.claimedDate = moment(this.updateGift.claimedDate).format('YYYY-MM-DD');
		else this.updateGift.claimedDate = null;
		this.customerService.updateGift(this.updateGift).subscribe(() => {
			this.dialogRef.close(true);
		});
	}
}
