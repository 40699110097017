/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-03-24 12:46:01
 * @modify date 2020-03-24 12:46:01
 * @desc [Wrapper Emlo Admin]
 */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { EmloAdminRoutingModule } from "./emlo-admin-routing.module";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		EmloAdminRoutingModule
	],
	providers: []
})
export class EmloAdminModule {}
