import { Component, OnInit } from "@angular/core";
import { RestautantUserTypeService } from "../../services/restaurant-usertypes.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import {
	ClientUserType,
	ClientUserTypeRestaurant,
	UserTypeFactory,
} from "../../model/restaurant-usertypes";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { UserTypeService } from "../../../usertypes/services/usertype.service";
import { IPermissionModel } from "@apps/modules/emlo-admin/shared/model/permission.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";

@Component({
	selector: "app-restaurant-usertype-detail",
	templateUrl: "detail.html",
})
export class EmloRestaurantUserTypeDetailComponent
	extends AdminBaseComponent
	implements OnInit
{
	IconName = IconName;
	form: FormGroup;
	usertype: ClientUserType = UserTypeFactory.create();
	public userTypeRestaurants: ClientUserTypeRestaurant[] = [];
	tabIndex: number = 0;
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private credentialsService: CredentialsService,
		private userService: RestautantUserTypeService,
		private userTypeService: UserTypeService,
		private formBuilder: FormBuilder,
		private adminShareDataService: AdminShareDataService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;

		this.createForm();
		this.isEdit =
			this.usertype &&
			this.usertype.id !== "00000000-0000-0000-0000-000000000000";
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const userTypeId = params.get("id");
			this.isEdit =
				userTypeId &&
				userTypeId !== "00000000-0000-0000-0000-000000000000";

			this.restaurantService
				.getRestaurants()
				.subscribe((restaurants: any) => {
					if (restaurants && restaurants.length > 0) {
						this.getPermissions(restaurants, userTypeId);
					}
				});
		});
	}

	private createForm(): void {
		this.form = this.formBuilder.group({
			id: [null, Validators.nullValidator],
			name: [null, Validators.required],
			permissions: [null],
		});
	}

	private initEditUserType(userTypeId: string): void {
		this.userService
			.getUserType(userTypeId)
			.subscribe((usertype: ClientUserType) => {
				if (usertype) {
					this.usertype = usertype;
					this.form.setValue({
						name: this.usertype.name,
						id: userTypeId,
						permissions: null,
					});
					this.mapDataRestaurant();
				}
			});
	}

	private mapDataRestaurant(): void {
		this.userTypeRestaurants.map((res) => {
			const clientRestaurant = this.usertype.restaurants?.find(
				(itemRes) => itemRes.id === res.id
			);
			res.assigned = false;
			if (clientRestaurant) {
				res.assigned = true;
				res.concurrencyStamp = clientRestaurant.concurrencyStamp;
			}
		});
	}

	private getPermissions(
		restaurants: ClientUserTypeRestaurant[],
		userTypeId: string
	): void {
		this.userTypeService
			.getPermissions()
			.subscribe((permissions: IPermissionModel[]) => {
				if (permissions) {
					restaurants.map((t) => {
						t.concurrencyStamp = null;
						// t.permissions = JSON.parse(JSON.stringify(permissions));
					});
					this.userTypeRestaurants = restaurants;
					this.usertype.userPermissions = permissions;
					if (this.isEdit) {
						this.initEditUserType(userTypeId);
					}
				}
			});
	}

	submit() {
		const data = this.prepareDataBeforeSubmit();
		this.isEdit ? this.updateUserType(data) : this.createUserType(data);
	}

	private prepareDataBeforeSubmit() {
		const usetType = {
			id: this.form.value.id,
			name: this.form.value.name,
			permissions: this.filterPermissionCode(),
			restaurants: [],
		};
		const assignRestaurants = this.userTypeRestaurants.filter(
			(r) => r.assigned === true
		);
		usetType.restaurants = assignRestaurants;
		return usetType;
	}

	private filterPermissionCode(): string[] {
		return this.usertype?.userPermissions
			?.filter((userType) => userType.isActive)
			.map((userType) => userType.code);
	}

	private createUserType(body: any): void {
		this.userService.createUserType(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	private updateUserType(body: any): void {
		this.userService.updateUserType(body).subscribe(() => {
			this.resetFormAndClose(true);
		});
	}

	cancel() {
		this.resetFormAndClose();
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: ClientUserTypeRestaurant) {}

	private resetFormAndClose(isReload: boolean = false): void {
		this.router.navigate(["/account-management/restaurant-user-type"]);
	}
}
