/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:09:26
 * @modify date 2020-02-11 00:09:26
 * @desc [Support reuse all modules]
 */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { LoaderComponent } from "./loader/loader.component";
import { EmloBoxHeaderComponent } from "./boxheader/boxheader.component";

@NgModule({
	imports: [CommonModule, TranslateModule],
	declarations: [LoaderComponent, EmloBoxHeaderComponent],
	providers: [],
	exports: [LoaderComponent, EmloBoxHeaderComponent]
})
export class SharedModule {}
