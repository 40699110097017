<div class="box border-sub-1">
	<emlo-box-header [title]="'Customers'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<div class="d-flex flex-row align-items-center w-100 mb-2">
			<div class="flex-grow-1">
				<div class="d-flex flex-row align-items-center">
					<div class="keyword">
						<input type="text" class="form-control" name="keyword" [(ngModel)]="criteria.searchText"
							(keyup.enter)="onFilter()" placeholder="{{ 'Type a name...' | translate }}" autocomplete="off">
					</div>
					<div class="keyword ml-3">
						<input type="text" class="form-control" name="email" [(ngModel)]="criteria.email"
							(keyup.enter)="onFilter()" placeholder="{{ 'Type an email...' | translate }}" autocomplete="off">
					</div>
					<div class="keyword ml-3">
						<input type="text" class="form-control" name="phoneNumber" [(ngModel)]="criteria.phoneNumber"
							(keyup.enter)="onFilter()" placeholder="{{ 'Type a phone...' | translate }}" autocomplete="off">
					</div>
					<div class="keyword ml-3">
						<input type="text" class="form-control" name="loyaltyCode" [(ngModel)]="criteria.loyaltyCodes"
							(keyup.enter)="onFilter()" placeholder="{{ 'Type a code...' | translate }}" autocomplete="off">
					</div>
					<div class="search-type ml-3">
						<ng-select class="p-0" name="levelId" [items]="customerLevels" [(ngModel)]="criteria.levelId"
							(change)="onFilter()" bindLabel="name" bindValue="id" [placeholder]="'Customer Level'"
							appendTo="body">
						</ng-select>
					</div>
					<div class="search-type ml-3">
						<ng-select class="p-0" name="birthdayMonth" [items]="months" [(ngModel)]="criteria.birthdayMonth"
							(change)="onFilter()" bindLabel="text" bindValue="id" [placeholder]="'Birthday in month'"
							appendTo="body">
						</ng-select>
					</div>
					<!-- <div class="search-visible ml-3">
						<mat-radio-group class="row" name="isVisible" [(ngModel)]="criteria.isActive"
							(change)="onFilter()">
							<mat-radio-button class="pr-3" *ngFor="let state of states" [value]="state.value">
								{{ state.name | translate }}
							</mat-radio-button>
						</mat-radio-group>
					</div> -->
				</div>
			</div>
		</div>
		<app-loader [isLoading]="isLoading"></app-loader>
		<emlo-table [configuration]="configuration" [data]="customers" [columns]="columns" [pagination]="pagination"
			(event)="onTableEvent($event)">
		</emlo-table>
	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #isStatusTpl let-row>
		<mat-slide-toggle disabled="disabled" (click)="toggleLockOut($event, row)" [checked]="row.isActive">
		</mat-slide-toggle>
	</ng-template>
	<ng-template #lastLoginDateTpl let-row>
		{{ row.lastLoginDate | date : 'dd/MM/yyyy HH:mm:ss' }}
	</ng-template>
	<ng-template #dobTpl let-row>
		{{ row.dob | date : 'dd/MM/yyyy' }}
	</ng-template>
	<ng-template #createdDateTpl let-row>
		{{ row.createdDate | date : 'dd/MM/yyyy HH:mm:ss' }}
	</ng-template>
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openCustomerDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<a *ngIf="canAccess" class="btn btn-primary btn-sm text-white" target="_blank" href="https://demo-admin.dimtutac.com">Go Dim Tu Tac Website (Demo Admin)</a>
		<a *ngIf="canAccess" class="ml-2 btn btn-primary btn-sm text-white" (click)="sendGift()">Send Gift by level</a>
	</ng-template>
</div>
