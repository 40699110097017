import { Component, Input, OnInit, forwardRef } from "@angular/core";
import { ConditionOperator } from "@apps/shared/filter-builder/model/condition-operator.enum";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => FilterValueComponent),
	multi: true
};

@Component({
	templateUrl: "filter-value.component.html",
	styleUrls: ["filter-value.component.scss"],
	selector: "filter-value",
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FilterValueComponent implements OnInit {
	@Input() type: string;
	// @Input() value: string;

	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	public _value = null;

	constructor() {}

	ngOnInit() {
		this._value = this.value;
	}

	// Get accessor
	get value(): any {
		return this._value;
	}

	// Set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this.onChangeCallback(v);
		}
	}
	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this._value) {
			this._value = value;
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	onChangeInternalValue() {
		this.value = this._value;
		this.onChangeCallback(this.value);
	}
}
