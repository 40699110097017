import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloRestaurantUserComponent } from "./restaurant-user.component";
import { EmloRestaurantUserListComponent } from "./components/list/restaurant-user.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloRestaurantUserDetailComponent } from "./components/detail/restaurant-user.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloRestaurantUserComponent,
		children: [
			{
				path: "",
				component: EmloRestaurantUserListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloRestaurantUserListComponent,
			},
			{ path: "add", component: EmloRestaurantUserDetailComponent },
			{ path: "edit", component: EmloRestaurantUserDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloUserRoutingModule {}
