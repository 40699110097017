import { Component, OnInit, Input } from "@angular/core";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { ModifierGroupService } from "../../../modifier-group/services/modifier-group.service";

@Component({
	selector: "app-item-modifier-groups",
	templateUrl: "./modifier-groups.html",
})
export class EmloItemModifierGroupComponent implements OnInit {
	@Input() modifierRestaurants: IRestaurantItemModel[] = [];

	constructor(private modifierGroupService: ModifierGroupService) {}

	public ngOnInit() {}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {
		if (!restaurant.modifierGroups) {
			this.getModifierGroupsByRestaurantId(restaurant);
		}
	}

	private getModifierGroupsByRestaurantId(
		itemRestaurant: IRestaurantItemModel
	) {
		this.modifierGroupService
			.getModifierGroupSelections(itemRestaurant.id)
			.subscribe((data) => {
				if (data) {
					itemRestaurant.modifierGroups = data;
				}
			});
	}
}
