import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { EmloButtonModule } from "@apps/shared/button";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { ColorPickerModule } from "ngx-color-picker";

import { EmloButtonComponent } from "./components/emlo-button/emlo-button.component";
import { EmloCheckboxComponent } from "./components/emlo-checkbox/emlo-checkbox.component";
import { EmloColorPickerComponent } from "./components/emlo-colorpicker/emlo-colorpicker.component";
import { EmloDatetimeComponent } from "./components/emlo-datetime/emlo-datetime.conponent";
import { EmloInputComponent } from "./components/emlo-input/emnlo-input.component";
import { EmloSelectComponent } from "./components/emlo-select/emlo-select.component";
import { EmloTextAreaComponent } from "./components/emlo-textarea/emlo-textarea.component";
import { EmloWrapperComponent } from "./components/emlo-wrapper/emlo-wrapper.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserModule } from "@angular/platform-browser";

// import { EmloFormComponent } from "./components/form/form.component";
@NgModule({
	declarations: [
		EmloInputComponent,
		EmloSelectComponent,
		EmloDatetimeComponent,
		EmloCheckboxComponent,
		EmloColorPickerComponent,
		EmloTextAreaComponent,
		EmloButtonComponent,

		EmloWrapperComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		EmloButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,

		// Third party
		NgSelectModule,
		ColorPickerModule,
		NgbModule,

		FormlyModule.forRoot({
			wrappers: [{
				name: "emlo-default-field",
				component: EmloWrapperComponent,
			}],
			types: [
				{ name: "emlo-input", component: EmloInputComponent, wrappers: ["emlo-default-field"] },
				{ name: "emlo-colorpicker", component: EmloColorPickerComponent, wrappers: ["emlo-default-field"] },
				{ name: "emlo-checkbox", component: EmloCheckboxComponent },
				{ name: "emlo-button", component: EmloButtonComponent, wrappers: ["emlo-default-field"] },
				{ name: "emlo-textarea", component: EmloTextAreaComponent, wrappers: ["emlo-default-field"] },
				{ name: "emlo-datetime", component: EmloDatetimeComponent, wrappers: ["emlo-default-field"] },
				{
					name: "emlo-select",
					component: EmloSelectComponent,
					wrappers: ["emlo-default-field"]
				}
			],
			validationMessages: [
				{ name: "required", message: "This field is required" },
			],
		}),
		FormlyBootstrapModule,
	],
	exports: [
		// EmloFormComponent,
		FormlyModule
	],
	providers: [],
	entryComponents: [
		EmloInputComponent,
		EmloSelectComponent,
		EmloDatetimeComponent,
		EmloCheckboxComponent,
		EmloColorPickerComponent,
		EmloTextAreaComponent,
		EmloButtonComponent,
		EmloWrapperComponent
	]
})
export class EmloFormModule {}
