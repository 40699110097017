<div *ngIf="isLoading" class="content-loader">
    <div class="loader">
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
		<div><div></div></div>
	  </div>
</div>
