import { Injector } from "@angular/core";

/**
 * @desc Static injector service.
 */
export class StaticInjectorService {
	private static _injector: Injector;

	/**
	 * Set main module injector.
	 * This is module injector.
	 * Injector must be setted from starting app (Injector of AppModule).
	 * param injector
	 */
	static set injector(injector: Injector) {
		this._injector = injector;
	}

	/**
	 * Get main module injector.
	 * @returns injector
	 */
	static get injector(): Injector {
		return this._injector;
	}
}
