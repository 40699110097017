import { DatePipe } from "@angular/common";
import { TicketType } from "@apps/common/enums/ticket-type";
import { DateConfig } from "@apps/common/constants/datetime";
import { IRestaurantItemModel } from "../../shared/model/restaurant-item.model";
import { ActionReport } from "./enums/report.action";
import { AdminBaseComponent } from "../../abstract/admin.base.component";

export abstract class BaseReportComponent extends AdminBaseComponent {
	defaultTimer: number = 5000;
	inprogress: boolean = false;
	restaurantId: string = null;
	restaurants: IRestaurantItemModel[];
	// MatPaginator Inputs
	totalItems = 0;
	pageSize = 20;
	pageIndex = 0;
	pageSizeOptions: number[] = [20, 50, 100];

	isCustomDate: boolean = false;
	allowReGenerate: boolean = false;
	actionReport: ActionReport = ActionReport.Day;
	listTimeReports = [
		{
			name: "Custom Date",
			action: ActionReport.Custom
		},
		{
			name: "Lunch",
			action: ActionReport.Lunch
		},
		{
			name: "Dinner",
			action: ActionReport.Dinner
		},
		{
			name: "Day",
			action: ActionReport.Day
		}
	];

	ticketTypes = [
		{
			name: "All",
			ticketType: TicketType.ALL
		},
		{
			name: "Dine-In",
			ticketType: TicketType.DINE_IN
		},
		{
			name: "Vip",
			ticketType: TicketType.VIP
		},
		{
			name: "Take Out",
			ticketType: TicketType.TAKE_OUT
		}
	];

	startDate = new Date();
	startTime: string = "00:00";

	endDate = new Date();
	endTime: string = "23:59";

	constructor(
		protected datePipe: DatePipe,
	) {
		super();
	}

	switchActionReport() {
		this.allowReGenerate = false;
		this.isCustomDate = this.actionReport === ActionReport.Custom;
		this.startDate = new Date();
		this.endDate = new Date();
		this.startTime = "00:00";
		this.endTime = "23:59";
		if (this.actionReport === ActionReport.Lunch) {
			this.endTime = "14:59";
		} else if (this.actionReport === ActionReport.Dinner) {
			this.startTime = "15:00";
		}
	}

	changeDateReport() {
		this.allowReGenerate = false;
	}

	public prepareDataForGenerate(isRegenerate: boolean = false): string {
		const data = {
			startDate: this.getStartDate(),
			endDate: this.getEndDate(),
			startTime: this.startTime,
			endTime: this.endTime,
			regenerate: isRegenerate,
			restaurantId: this.restaurantId
		};
		return JSON.stringify(data);
	}

	public getStartDate(): string {
		return this.formatOutputDate(this.startDate);
	}

	public getEndDate(): string {
		return this.formatOutputDate(this.endDate);
	}

	public showInprogress(): void {
		if (!this.inprogress) {
			this.inprogress = true;
		}
	}

	public hideInprogress() {
		setTimeout(() => {
			this.inprogress = false;
		}, 300);
	}

	private formatOutputDate(date: Date): string {
		return this.datePipe.transform(date, DateConfig.output);
	}
}
