import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { EmloFilterBuilderModule } from "@apps/shared/filter-builder/filter-builder.module";
import { NgSelectPaginationModule } from "@apps/shared/ng-select-pagination/ng-select-pagination.module";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FORMLY_CONFIG } from "@ngx-formly/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ColorPickerModule } from "ngx-color-picker";

import { registerTranslateExtension } from "../../shared/services/translate.extension";
import { ModifierGroupService } from "../modifier-group/services/modifier-group.service";
import { EmloFormModule } from "./../../../../shared/form/form.module";
import { EmloItemDetailComponent } from "./components/detail/item.detail.component";
import { EmloItemListComponent } from "./components/list/item.list.component";
import { EmloItemModifierGroupComponent } from "./components/modifier-groups/item.modifier-groups.component";
import { EmloItemComponent } from "./item.component";
import { EmloItemRoutingModule } from "./item.routes";
import { ItemService } from "./services/item.service";
import { ItemMenuGroupService } from "./services/menu-group.service";
import { ItemModifierGroupService } from "./services/modifier-group.service";
import { PrinterService } from "./services/printer.service";
import { ReportGroupService } from "./services/report-group.service";
import { TaxService } from "./services/tax.service";

@NgModule({
	declarations: [
		EmloItemComponent,
		EmloItemListComponent,
		EmloItemDetailComponent,
		EmloItemModifierGroupComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ColorPickerModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatSlideToggleModule,
		MatDialogModule,
		MatTabsModule,
		MatCheckboxModule,
		MatRadioModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		EmloFilterBuilderModule,
		EmloFormModule,
		FormlyBootstrapModule,
		EmloItemRoutingModule,
		NgSelectPaginationModule
	],
	providers: [
		ItemService,
		TaxService,
		PrinterService,
		ItemMenuGroupService,
		ReportGroupService,
		ItemModifierGroupService,
		ModifierGroupService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: false,
				panelClass: "no-padding"
			}
		},
		{
			provide: FORMLY_CONFIG,
			multi: true,
			useFactory: registerTranslateExtension,
			deps: [TranslateService]
		},
		{ provide: MatDialogRef, useValue: {} }
	],
})
export class EmloItemModule {}
