import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared";
import { EmloProductManagementRoutingModule } from "./product-management-routing.module";
import { EmloProductManagementComponent } from "./product-management.component";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		EmloProductManagementRoutingModule
	],
	declarations: [EmloProductManagementComponent]
})
export class EmloProductManagementModule {}
