import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared";
import { EmloConfigurationRoutingModule } from "./configuration-routing.module";
import { EmloConfigurationComponent } from "./configuration.component";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		EmloConfigurationRoutingModule
	],
	declarations: [EmloConfigurationComponent]
})
export class EmloConfigurationModule {}
