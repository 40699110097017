/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-12 00:03:15
 * @modify date 2020-02-12 00:03:15
 * @desc [Share buttons]
 */
import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	HostListener,
	ElementRef,
	OnDestroy
} from "@angular/core";
import { Subscription } from "rxjs";

import { ButtonState } from "./button";
import { EmloButtonService } from "./button.service";

@Component({
	selector: "emlo-button",
	templateUrl: "button.component.html",
	styleUrls: ["button.component.css"]
})
export class EmloButtonComponent implements OnInit, OnDestroy {
	@Output() submit: EventEmitter<boolean> = new EventEmitter();

	@Input() disabled: boolean;

	@Input() textButton: string = "OK";

	@Input() textProcessing: string = "Processing";

	@Input() color: string = "primary";

	@Input() className: string = "";

	@Input() isLoading = false;

	isProcessing: boolean = false;

	private subscription: Subscription;

	isClickOn: boolean = false;

	constructor(
		private el: ElementRef,
		private cdr: ChangeDetectorRef,
		private loaderService: EmloButtonService
	) {}

	@HostListener("document:click", ["$event"])
	handleClick(event: Event) {
		if (this.el.nativeElement.contains(event.target)) {
			this.isClickOn = true;
		} else {
			this.isClickOn = false;
		}
	}

	ngOnInit() {
		this.subscription = this.loaderService.loaderState.subscribe(
			(state: ButtonState) => {
				this.isProcessing = state.isProcessing;
				this.cdr.detectChanges();
			}
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	onSubmit() {
		this.submit.emit(true);
	}
}
