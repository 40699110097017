import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class DetailSaleReportService {
	constructor(private apiService: ApiService) {}

	getSaleDetailReport(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "salereport/getSaleDetailReport",
			context
		);
	}

	getExcelReport(context: any) {
		return this.apiService.excel(
			environment.prefix_api_url + "salereport/getexcelreport",
			context
		);
	}
}
