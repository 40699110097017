<div class="keyword">
	<!-- <input
		type="text"
		class="form-control mb-2"
		name="keyword"
		[(ngModel)]="criteriaGift.searchText"
		(keyup.enter)="onFilter()"
		placeholder="{{ 'Search...' | translate }}"
		autocomplete="off"
		[ngModelOptions]="{ standalone: true }"
	/> -->
</div>
<emlo-table
	[configuration]="configuration"
	[data]="customerGifts"
	[columns]="columns"
	[pagination]="pagination"
	(event)="onTableEvent($event)"
>
</emlo-table>
<ng-template #isPermanentTpl let-row>
	<mat-checkbox [checked]="row.isPermanent" [disabled]="true"></mat-checkbox>
</ng-template>
<ng-template #isDOBGiftTpl let-row>
	<mat-checkbox [checked]="row.isDOBGift" [disabled]="true"></mat-checkbox>
</ng-template>
<ng-template #isByLevelUpTpl let-row>
	<mat-checkbox [checked]="row.isByLevelUp" [disabled]="true"></mat-checkbox>
</ng-template>
<ng-template #isClaimedTpl let-row>
	<mat-checkbox [checked]="row.isClaimed" [disabled]="true"></mat-checkbox>
</ng-template>
<ng-template #validFromTpl let-row>
	{{ row.validFrom | date : 'dd/MM/yyyy' }}
</ng-template>
<ng-template #validToTpl let-row>
	{{ row.validTo | date : 'dd/MM/yyyy' }}
</ng-template>
<ng-template #actionTpl let-row>
	<a *ngIf="!row.deletedAt && row.isClaimed !== true" class="p-1 mr-2" (click)="updateGiftFromCustomer(row)">
		<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
	</a>
	<a *ngIf="!row.deletedAt && row.isClaimed !== true" class="p-1 mr-2" (click)="removeGiftFromCustomer(row)">
		<mat-icon [svgIcon]="IconName.icon_trash" class="mat-icon-ssm"></mat-icon>
	</a>
</ng-template>
