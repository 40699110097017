import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomerServiceSettingsComponent } from "./settings.component";
import { CustomerServiceSettingsDetailComponent } from "./detail/settings.detail.component";

const routes: Routes = [
	{
		path: "",
		component: CustomerServiceSettingsComponent,
		children: [
			{
				path: "",
				component: CustomerServiceSettingsDetailComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SettingsRoutingModule {}
