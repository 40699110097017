import {
	Component,
	Inject,
	OnInit,
	ChangeDetectionStrategy
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmModel } from "./confirm";
import { IconName } from "@apps/common/constants/icon";

@Component({
	selector: "emlo-confirm",
	styleUrls: ["./style.scss"],
	templateUrl: "confirm.html"
})
export class EmloConfirmComponent implements OnInit {
	IconName = IconName;
	cancelTextButton: string = "Cancel";
	confirmTextButton: string = "OK";
	constructor(
		public dialogRef: MatDialogRef<EmloConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public confirmModel: ConfirmModel
	) {
		if (confirmModel && confirmModel.textCancel)
			this.cancelTextButton = confirmModel.textCancel;
		if (confirmModel && confirmModel.textConfirm)
			this.confirmTextButton = confirmModel.textConfirm;
	}

	ngOnInit(): void {}

	cancel(): void {
		this.dialogRef.close();
	}

	confirm() {
		this.dialogRef.close(true);
	}
}
