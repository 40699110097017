<label class="m-0" for="search_{{ unifyKey(column.key) }}">
  <input type="text"
        id="search_{{ unifyKey(column.key) }}"
        aria-label="Search"
		placeholder="{{ column.placeholder ? column.placeholder : column.title }}"
		[ngClass]="{'form-control': true}"
		[class.form-control-sm]="config.tableLayout.style === 'small'"
		[class.form-control]="config.tableLayout.style === 'normal'"
		[class.form-control-lg]="config.tableLayout.style === 'large'"
         #input
         (input)="onSearch(input)"
  >
</label>
