<div class="p-3">
    <h3>Update Gift for <strong>{{ updateGift.giftName }}</strong></h3>
    <formly-form [model]="updateGift"
                 [fields]="updateGiftFormFields"
                 [form]="form"></formly-form>
    <div class="text-right">
        <button type="button" class="btn btn-danger" (click)="close()" translate>
            Close
        </button>
        <button type="button" class="btn btn-primary ml-2" (click)="updateGiftOfCustomer()" translate>
            Update
        </button>
    </div>
</div>