import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { User } from "../model/user";
import { environment } from "@env/environment";
import { IUserSearchRequestModel } from "../model/user-search-request.model";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable } from "rxjs";

@Injectable()
export class UserService {
	constructor(private apiService: ApiService) {}

	getUsers(request: IUserSearchRequestModel): Observable<IApiPagingResponseModel<User[]>> {
		const params = new HttpParams({ fromObject: request as any });
		return this.apiService.get(environment.prefix_api_url + "account/users", params);
	}

	getUser(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "account/user", params);
	}

	getUserRole() {
		return this.apiService.get(environment.prefix_api_url + "role/selection");
	}

	createUser(context: User) {
		return this.apiService.post(environment.prefix_api_url + "account/create", context);
	}

	updateUser(context: User) {
		return this.apiService.post(environment.prefix_api_url + "account/update", context);
	}

	deleteUser(context: any) {
		return this.apiService.post(environment.prefix_api_url + "account/delete", context);
	}

	unLockUser(context: any) {
		return this.apiService.post(environment.prefix_api_url + "account/unlock", context);
	}

	lockUser(context: any) {
		return this.apiService.post(environment.prefix_api_url + "account/lock", context);
	}

	getRoles(restaurantId: string) {
		const params = new HttpParams().set("restaurantId", restaurantId);
		return this.apiService.get(environment.prefix_api_url + "role/list", params);
	}

	updateUserPassword(context: User) {
		const _param = {
			userId: context.id,
			newPassword: context.secretKey,
			confirmPassword: context.confirmSecretKey
		};
		return this.apiService.post(environment.prefix_api_url + "account/changepassword", _param);
	}
}
