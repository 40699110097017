import {
	IApiTenancyRequestModel,
	IApiPagingRequestModel,
	ApiRequestModelFactory
} from "@apps/common/model/api.request.model";

export interface ICategoryRequestSearchModel
	extends IApiTenancyRequestModel,
		IApiPagingRequestModel {}
export class CategoryRequestSearchModelFactory {
	static createWithTenancy(
		restaurantId: string
	): ICategoryRequestSearchModel {
		return {
			...ApiRequestModelFactory.createPagingMaxSize(),
			restaurantId
		};
	}
}
