/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-12 10:32:21
 * @modify date 2020-02-12 10:32:21
 */
import { NgModule } from "@angular/core";
import { EmloButtonService } from "./button.service";
import { EmloButtonComponent } from "./button.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";

@NgModule({
	imports: [CommonModule, TranslateModule],
	exports: [EmloButtonComponent],
	declarations: [EmloButtonComponent],
	providers: [EmloButtonService]
})
export class EmloButtonModule {}
