import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy
} from "@angular/core";
import { CommonComponent } from "@apps/core/common/common-component";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig } from "@apps/shared/table";
import {
	ClientDetail,
	ClientDetailFactory,
	ClientItemList,
	RestaurantDetailFactory,
	RestaurantDetail
} from "../../model/client";
import { ClientService } from "../../services/client.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { EmloClientDetailComponent } from "../detail/client.detail.component";
import { IGrantTypeModel } from "@apps/modules/emlo-admin/shared/model/grantype.mode";
import { AdminShareDataService } from "@apps/modules/emlo-admin/shared/services/share.data";
import { Router } from "@angular/router";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { CredentialsService } from "@apps/core";

@Component({
	selector: "app-client-list",
	templateUrl: "./list.html"
})
export class EmloClientListComponent extends AdminBaseComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public clientItemList: ClientItemList[] = [];
	public clientDetails: RestaurantDetail[] = [];
	searchObj: { restaurantId?: string } = {};
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private adminShareDataService: AdminShareDataService,
		private clientService: ClientService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;
		this.configColumnForClientTable();
		this.getClients();
		this.getGrantTypes();
	}

	private getClients(): void {
		this.clientService
			.getClients()
			.subscribe((clientDetails: RestaurantDetail[]) => {
				if (clientDetails) {
					this.clientDetails = clientDetails;
				}
			});
	}

	private configColumnForClientTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = false;
		this.columns = [
			//{ key: "id", title: "Restaurant/Branch Id" },
			{ key: "name", title: "Restaurant/Branch Name" },
			//{ key: "localUrl", title: "Local Url" },
			//{ key: "grantTypeName", title: "Grant Type" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false
			}
		];
	}

	private deleteClient(clientDetail: ClientDetail): void {
		const data = { clientId: clientDetail.id };
		this.clientService.deleteClient(data).subscribe(() => {
			this.getClients();
		});
	}

	delete(clientDetail: ClientDetail): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?"
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteClient(clientDetail);
				}
			});
	}

	openClientDetail(restaurantDetail: RestaurantDetail): void {
		if (!restaurantDetail) {
			restaurantDetail = RestaurantDetailFactory.create();
			this.router.navigate(["/configuration/client/add"]);
		} else {
			this.router.navigate(["/configuration/client/edit"], {
				queryParams: { id: restaurantDetail.id },
			});
		}
		// const restaurantDetail: RestaurantDetail = RestaurantDetailFactory.create();
		// const clientItemList: ClientItemList = {
		// 	clientDetail: restaurantDetail,
		// 	restaurantDetail
		// };
		// this.dialog
		// 	.open(EmloClientDetailComponent, {
		// 		width: "80%",
		// 		data: clientItemList
		// 	})
		// 	.afterClosed()
		// 	.subscribe(isSaved => {
		// 		if (isSaved) {
		// 			this.getClients();
		// 		}
		// 	});
	}

	private getGrantTypes(): void {
		this.clientService
			.getGrantTypes()
			.subscribe((grantTypes: IGrantTypeModel[]) => {
				if (grantTypes) {
					this.adminShareDataService.grantTypes = grantTypes;
				}
			});
	}

	ngOnDestroy() {}
}
