/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-05 09:43:32
 * @modify date 2020-02-05 09:43:32
 * @desc [
 *  Provides storage for authentication credentials
 * ]
 */
import { Injectable } from "@angular/core";
import { IMenu, Menus } from "@apps/common/constants/menu";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

export interface Credentials {
	// Customize received credentials here
	access_token: string;
	tokenType: string;
	refreshToken: string;
	expiresIn: number;
	firstName: string;
	lastName: string;
	roleTypes: number[];
}

const credentialsKey = "credentials";

/**
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
	providedIn: "root"
})
export class CredentialsService {
	private credents: Credentials | null = null;

	constructor() {
		const savedCredentials =
			sessionStorage.getItem(credentialsKey) ||
			localStorage.getItem(credentialsKey);
		if (savedCredentials) {
			this.credents = JSON.parse(savedCredentials);
		}
	}

	/**
	 * Checks is the user is authenticated.
	 * @return True if the user is authenticated.
	 */
	isAuthenticated(): boolean {
		return !!this.credentials;
	}

	/**
	 * Gets the user credentials.
	 * @return The user credentials or null if the user is not authenticated.
	 */
	get credentials(): Credentials | null {
		return this.credents;
	}

	/**
	 * Sets the user credentials.
	 * The credentials may be persisted across sessions by setting the `remember` parameter to true.
	 * Otherwise, the credentials are only persisted for the current session.
	 * @param credentials The user credentials.
	 * @param remember True to remember credentials across sessions.
	 */
	setCredentials(credentials?: Credentials, remember?: boolean) {
		this.credents = credentials || null;
		if (credentials) {
			const storage = remember ? localStorage : sessionStorage;
			storage.setItem(credentialsKey, JSON.stringify(credentials));
		} else {
			sessionStorage.removeItem(credentialsKey);
			localStorage.removeItem(credentialsKey);
		}
	}

	canAccess(roles: UserRoleTypeEnum[]): boolean {
		const credents = this.credents;
		if (credents && credents.roleTypes && credents.roleTypes.length > 0) {
			const roleType = credents.roleTypes[0];
			const found = roles.includes(roleType);
			if (found) {
				return true;
			}
			return false;
		}
		return false;
	}

	public filterMenuByUserRole(): IMenu[] {
		const mainMenus = Menus;
		const newNavs: IMenu[] = [];
		if (mainMenus) {
			mainMenus.forEach((nav) => {
				if (nav.subMenus && nav.subMenus.length > 0) {
					const newSubNavs: IMenu[] = [];
					nav.subMenus.forEach((subNav) => {
						if (subNav.roles) {
							const canAccess = this.canAccess(
								subNav.roles
							);
							if (canAccess) {
								newSubNavs.push(subNav);
							}
						}
					});
					if (newSubNavs && newSubNavs.length > 0) {
						nav.subMenus = newSubNavs;
						newNavs.push(nav);
					}
				} else {
					if (nav.roles) {
						const canAccess = this.canAccess(
							nav.roles
						);
						if (canAccess) {
							newNavs.push(nav);
						}
					}
				}
			});
		}
		return newNavs;
	}

	getRoleName() {
		const credents = this.credents;
		let roleName = '';
		if (credents && credents.roleTypes && credents.roleTypes.length > 0) {
			const roleType = credents.roleTypes[0];
			switch (roleType) {
				case UserRoleTypeEnum.Unknown:
					roleName = 'Unknown';
					break;
				case UserRoleTypeEnum.Administrator:
					roleName = 'Administrator';
					break;
				case UserRoleTypeEnum.Accountant:
					roleName = 'Accountant';
					break;
				case UserRoleTypeEnum.ProductManager:
					roleName = 'Product Manager';
					break;
				case UserRoleTypeEnum.CustomerService:
					roleName = 'Customer Service';
					break;
				default:
					roleName = 'Unknown';
					break;
			}
		}
		return roleName;
	}
}
