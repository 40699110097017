import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatDialogRef
} from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared/shared.module";
import { EmloButtonModule } from "@apps/shared/button/button.module";
import { EmloTableModule } from "@apps/shared/table/table.module";
import { EmloConfirmModule } from "@apps/shared/confirm/confirm.module";
import { CustomerRoutingModule } from "./customer.routes";
import { CustomerComponent } from "./customer.component";
import { CustomerListComponent } from "./components/list/customer.list.component";
import { CustomerDetailComponent } from "./components/detail/customer.detail.component";
import { CustomerService } from "./services/customer.service";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { EmloFormModule } from "@apps/shared/form";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { CustomerLevelService } from "../customer-level/services/customer-level.service";
import { PointHistoryCustomerComponent } from "./components/point-history/point-history.component";
import { GiftCustomerComponent } from "./components/gift/gift.component";
import { AddPointCustomerComponent } from "./components/add-point/add-point.component";
import { AddGiftCustomerComponent } from "./components/add-gift/add-gift.component";
import { UpdateGiftCustomerComponent } from "./components/update-gift/update-gift.component";
import { GiftService } from "../gift/services/gift.service";
import { SendGiftCustomerComponent } from "./components/send-gift/send-gift.component";

@NgModule({
	declarations: [
		CustomerComponent,
		CustomerListComponent,
		CustomerDetailComponent,
		PointHistoryCustomerComponent,
		GiftCustomerComponent,
		AddPointCustomerComponent,
		AddGiftCustomerComponent,
		UpdateGiftCustomerComponent,
		SendGiftCustomerComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		TranslateModule,
		MatDialogModule,
		MatIconModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatTabsModule,
		MatRadioModule,
		EmloTableModule,
		EmloConfirmModule,
		EmloButtonModule,
		NgSelectModule,
		FormlyBootstrapModule,
		CustomerRoutingModule,
		EmloFormModule
	],
	providers: [
		CustomerService,
		CustomerLevelService,
		GiftService,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				autoFocus: true,
				restoreFocus: true,
				disableClose: true,
				panelClass: "no-padding"
			}
		},
		{ provide: MatDialogRef, useValue: {} }
	],
	entryComponents: [CustomerDetailComponent]
})
export class CustomerModule {}
