import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy,
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Event } from "@apps/shared/table";
import {
	MenuGroup,
	MenuGroupFactory,
	MenuGroupSearchModel,
} from "../../model/menu-group";
import { MenuGroupService } from "../../services/menu-group.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { IMenuGroupSearchRequestModel } from "../../model/menu-group-search-request.model";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { BaseFilterComponent } from "../../../base-filter-component";
import { Router } from "@angular/router";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-menu-group-list",
	templateUrl: "./list.html",
})
export class EmloMenuGroupListComponent
	extends BaseFilterComponent<IMenuGroupSearchRequestModel>
	implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("isVisibleTpl", { static: true }) isVisibleTpl: TemplateRef<any>;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public columns: Columns[] = [];
	public menuGroups: MenuGroup[] = [];
	private readonly All = "All";
	private readonly Visible = "Visible";
	private readonly InVisible = "Invisible";

	criteria: MenuGroupSearchModel = new MenuGroupSearchModel();
	states: { name: string; value: boolean }[] = [
		{
			name: this.All,
			value: null,
		},
		{
			name: this.Visible,
			value: true,
		},
		{
			name: this.InVisible,
			value: false,
		},
	];
	restaurants: IRestaurantItemModel[] = [];
	// Emlo table config
	pagination = PaginationModelFactory.create();
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private menuGroupService: MenuGroupService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getMenuGroups();
		this.initFilterMetaData();
		this.configColumnFoMenuGroupable();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.request = { ...this.request, ...$event.value };
			this.criteria.pageNum = $event.value.pageNum;
			this.criteria.pageSize = $event.value.pageSize;
			this.getMenuGroups();
		}
	}

	private getMenuGroups() {
		this.menuGroupService.getMenuGroups(this.criteria).subscribe((data) => {
			this.menuGroups = [];
			if (data) {
				this.menuGroups = data.data;
				this.pagination = data;
			}
		});
	}

	private configColumnFoMenuGroupable(): void {
		this.columns = [
			{ key: "nameEn", title: "Name EN" },
			{ key: "nameVi", title: "Name VI" },
			{ key: "nameCn", title: "Name CN" },
			//{ key: "parentGroupName", title: "Parent Group" },
			//{ key: "categoryName", title: "Menu Category" },
			{ key: "restaurantNames", title: "Selling at" },
			{
				key: "isVisible",
				title: "Visible",
				cellTemplate: this.isVisibleTpl,
				width: "50px",
				cssClass: { name: "text-center", includeHeader: true },
				orderEnabled: false,
			},
			{ key: "sortOrder", title: "Sort Order", width: "120px" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false,
			},
		];
	}

	toggleMenuGroup(event, menuGroup: MenuGroup) {
		event.preventDefault();
		if (!menuGroup.isVisible) {
			this.enableMenuGroup(menuGroup);
		} else {
			const confirmModel: ConfirmModel = {
				id: null,
				message: "Are you sure want to disable this menu group?",
			};
			this.dialog
				.open(EmloConfirmComponent, {
					data: confirmModel,
				})
				.afterClosed()
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.disableMenuGroup(menuGroup);
					}
				});
		}
	}

	private enableMenuGroup(menuGroup: MenuGroup): void {
		const data = { id: menuGroup.id };
		this.menuGroupService
			.enableMenuGroup(JSON.stringify(data))
			.subscribe(() => {
				menuGroup.isVisible = true;
			});
	}

	private disableMenuGroup(menuGroup: MenuGroup): void {
		const data = { id: menuGroup.id };
		this.menuGroupService
			.disableMenuGroup(JSON.stringify(data))
			.subscribe(() => {
				menuGroup.isVisible = false;
			});
	}

	private deleteMenuGroup(menuGroup: MenuGroup): void {
		const data = { id: menuGroup.id };
		this.menuGroupService
			.deleteMenuGroup(JSON.stringify(data))
			.subscribe(() => {
				this.getMenuGroups();
			});
	}

	delete(menuGroup: MenuGroup): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?",
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel,
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteMenuGroup(menuGroup);
				}
			});
	}

	openMenuGroupDetail(menuGroup: MenuGroup): void {
		if (!menuGroup) {
			menuGroup = MenuGroupFactory.create();
			menuGroup.restaurantIds = this.request.restaurantId;
			this.router.navigate(["/product-management/menu-group/add"]);
		} else {
			this.router.navigate(["/product-management/menu-group/edit"], {
				queryParams: { id: menuGroup.id },
			});
		}
		// if (!menuGroup) {
		// 	menuGroup = MenuGroupFactory.create();
		// 	menuGroup.restaurantIds = this.request.restaurantId;
		// }
		// this.dialog
		// 	.open(EmloMenuGroupDetailComponent, {
		// 		data: menuGroup
		// 	})
		// 	.afterClosed()
		// 	.subscribe(isSaved => {
		// 		if (isSaved) {
		// 			this.dataSource$.next(this.request);
		// 		}
		// 	});
	}

	onFilter() {
		this.criteria.pageNum = 1;
		this.getMenuGroups();
	}

	ngOnDestroy() {}
}
