import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";

@Injectable()
export class ReportGroupService {
	constructor(private apiService: ApiService) {}

	getVisiblegroup() {
		return this.apiService.get(environment.prefix_api_url+ "reportinggroup/list");
	}
}
