import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { IClientUserTypeRequestModel } from "../model/client-usertype-request-model";
import { ClientUsertUserTypeSearchModel, ClientUserType } from "../model/restaurant-usertypes";

@Injectable()
export class RestautantUserTypeService {
	constructor(private apiService: ApiService) {}

	getUserTypes(request: ClientUsertUserTypeSearchModel): Observable<IApiPagingResponseModel<ClientUserType[]>> {
		// const params = new HttpParams({ fromObject: request as any });
		return this.apiService.post(environment.prefix_api_url + "ClientRole/List", request);
	}

	getPermissions() {
		return this.apiService.get(environment.prefix_api_url + "permission/list");
	}

	getUserType(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(environment.prefix_api_url + "ClientRole/Getbyid", params);
	}

	createUserType(context: any) {
		return this.apiService.post(environment.prefix_api_url + "ClientRole/Create", context);
	}

	updateUserType(context: any) {
		return this.apiService.post(environment.prefix_api_url + "ClientRole/Update", context);
	}

	deleteUserType(context: any) {
		return this.apiService.post(environment.prefix_api_url + "ClientRole/Delete", context);
	}
}
