import { CommonConstant } from "@apps/common/constants/common.constant";
import { Gift } from "../../gift/model/gift";

export class Customer {
	id?: string | number = null;
	code?: string = null;
	password?: string = null;
	title?: string = null;
	email?: string = null;
	dob?: string = null;
	isActive?: boolean = null;
	gender?: string | number = null;
	phoneNumber?: string = null;
	address?: string = null;
	lastLoggedInAt?: string = null;
	company?: string = null;
	registeredAt?: string = null;
	levelId?: string | number = null;
	currentPoints?: number = null;
	onHoldPoints?: number = null;
	currentOnHoldPointReason?: string = null;
	fullName?: string = null;
	levelName?: string = null;
	totalOrder?: number = null;
}

export class CustomerSearchModel {
	searchText?: string = null;
	isActive?: boolean = null;
	levelId?: string | number = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export class CustomerLevelSearchModel {
	searchText?: string = null;
	pageNum?: number = 1;
	pageSize?: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export class CustomerLevel {
	concurrencyStamp: string | number = null;
	defaultDiscountPercentage: number = null;
	discountPercentageOnDOB: number = null;
	giftIds: string | string[] = null;
	id: string | number = null;
	levelCode: number = null;
	minDayFromDOBForGift: number = null;
	minPoints: number = null;
	name: string = null;
	gifts: Gift[] = null;
	discountPercentageOnCustomerNumber: number = null;
}

export class CustomerTransaction {
	id?: string | number = null;
	date: string = null;
	bean?: number = null;
}
