import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { EmloConfirmComponent } from "./confirm.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
	imports: [MatDialogModule, TranslateModule, MatIconModule],
	declarations: [EmloConfirmComponent],
	exports: [EmloConfirmComponent],
	entryComponents: [EmloConfirmComponent],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { autoFocus: true, disableClose: true, hasBackdrop: true }
		}
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmloConfirmModule {}
