import { environment } from "./../../environments/environment";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from "./services/error/error.service";
import { NotificationService } from "./services/error/notification.service";
import { Logger } from "./services/logger.service";
const logger = new Logger("GlobalErrorHandler");

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {}

	handleError(error: Error | HttpErrorResponse) {
		const errorService = this.injector.get(ErrorService);
		const notifier = this.injector.get(NotificationService);

		let message: any;
		if (error instanceof HttpErrorResponse) {
			// Server error
			if (error.status === 401) {
				notifier.showDialogError(error);
			} else {
				message = errorService.getServerErrorMessage(error);
				notifier.showError(message);
			}
		} else {
			// Client Error
			message = errorService.getClientErrorMessage(error);
			notifier.showError(message);
		}
		// Always log errors
		if(!environment.production) {
			console.error(error);
		} else {
			logger.error(error);
		}
	}
}
