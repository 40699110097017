import { ITaxModel } from "@apps/modules/emlo-admin/shared/model/tax.model";
import { IPrinterGroupModel } from "@apps/modules/emlo-admin/shared/model/printer-group.model";
import { ItemModifierGroup } from "./modifier-groups";
import { MenuGroupSelection } from "../../menu-group/model/menu-group";

export interface ItemDetailModel {
	id: string;
	itemId: string;
	name: string;
	nameCn: string;
	nameEn: string;
	nameVi: string;
	barCode: string;
	btnColor: string;
	buyPrice: number;
	canDiscount: boolean;
	discountRate: number;
	menuGroupId: string;
	imageId: string;
	isSetMenu: boolean;
	parentSetMenuId: string;
	price: number;
	printerGroup: IPrinterGroupModel;
	printerGroupId: string;
	recepieId: string;
	reportingGroup: string;
	showImageOnly: boolean;
	sortOrder: number;
	tax: ITaxModel;
	taxId: string;
	textColor: string;
	isVisible: boolean;
	restaurantIds: string[];
	modifierGroups: ItemModifierGroup[];
	restaurants: ItemRestaurant[];
	concurrencyStamp: string;
}

export interface ItemRestaurant {
	id: string;
	isVisible: boolean;
	isPrintToKitchen: boolean;
	isSetMenu: boolean;
	name: string;
	price: number;
	extraPrice: number;
	sortOrder: number;
	menuGroupId: string;
	menuGroups: MenuGroupSelection[];
	menuModifierGroupId: string;
	parentMenuModifierGroupId: string;
	parentSetMenuId: string;
	printerGroupId: string;
	taxId: string;
	concurrencyStamp: string;
}

export class ItemDetailFactory {
	public static create(): ItemDetailModel {
		return {
			id: null,
			itemId: null,
			name: null,
			nameCn: null,
			nameEn: null,
			nameVi: null,
			barCode: null,
			btnColor: null,
			buyPrice: null,
			canDiscount: false,
			discountRate: null,
			menuGroupId: null,
			imageId: null,
			isSetMenu: false,
			parentSetMenuId: null,
			price: null,
			printerGroup: null,
			printerGroupId: null,
			recepieId: null,
			reportingGroup: null,
			showImageOnly: false,
			sortOrder: null,
			tax: null,
			taxId: null,
			textColor: null,
			isVisible: true,
			restaurantIds: [],
			modifierGroups: [],
			restaurants: [],
			concurrencyStamp: null
		};
	}
}
