import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgSelectPaginationComponent } from "./ng-select-pagination.component";

@NgModule({
    imports: [ CommonModule, NgSelectModule, FormsModule ],
    declarations: [ NgSelectPaginationComponent ],
    exports: [ NgSelectPaginationComponent ]
})
export class NgSelectPaginationModule { }