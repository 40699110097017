import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EmloUserComponent } from "./client.component";
import { EmloClientListComponent } from "./components/list/client.list.component";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloClientDetailComponent } from "./components/detail/client.detail.component";

const routes: Routes = [
	{
		path: "",
		component: EmloUserComponent,
		children: [
			{
				path: "",
				component: EmloClientListComponent,
			},
			{
				path: AppRoutesNames.LIST,
				component: EmloClientListComponent,
			},
			{ path: "add", component: EmloClientDetailComponent },
			{ path: "edit", component: EmloClientDetailComponent },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloConfigurationClientRoutingModule {}
