import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
	selector: "emlo-input",
	template: ` <textarea
    [formControl]="formControl"
    [formlyAttributes]="field"
    >
    </textarea>`,
	host: { "[class]": "field.className || 'col-sm-12'" },
	styles: [],
})
export class EmloTextAreaComponent extends FieldType {}
