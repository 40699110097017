import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared";
import { CustomerServiceRoutingModule } from "./customer-service-routing.module";
import { CustomerServiceComponent } from "./customer-service.component";
import { EmloFormModule } from "@apps/shared/form";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		EmloFormModule,
		CustomerServiceRoutingModule,
	],
	declarations: [CustomerServiceComponent]
})
export class CustomerServiceModule {}
