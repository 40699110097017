<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Edit Currency' : 'Add Currency'"
		[headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" *ngIf="canAccess; else contactAdmin">

		<mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="swittchTab($event)">
			<mat-tab label="{{ 'General' | translate }}">
				<div class="box-content item-content" *ngIf="currency">
					<formly-form [model]="currency" [fields]="currencyFormFields" [form]="form"></formly-form>
				</div>
			</mat-tab>
			<mat-tab label="{{ 'Assign Restaurant ' | translate }}">
				<div class="box-content item-content">
					<div class="p-3">
						<div class="mb-2" *ngFor="let restaurant of currencyRestaurants; let index = index;">
							<input type="checkbox" [(ngModel)]="restaurant.assigned"
								[ngModelOptions]="{standalone: true}" class="form-check-input"
								[id]="'restaurant_name_' + index" (ngModelChange)="toggleAssignRestaurant(restaurant)">
							<label [for]="'restaurant_name_' + index">{{ restaurant.name}}</label>
						</div>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>
			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
