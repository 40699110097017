<div class="box border-sub-1">
	<emlo-box-header [title]="'Restaurant Clients'" [headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<div class="box-content" *ngIf="canAccess; else contactAdmin">
		<emlo-table
			[configuration]="configuration"
			[data]="clientDetails"
			[columns]="columns">
		</emlo-table>

	</div>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #actionTpl let-row>
		<a class="p-1 mr-2" (click)="openClientDetail(row)">
			<mat-icon [svgIcon]="IconName.icon_edit" class="mat-icon-ssm"></mat-icon>
		</a>
		<a class="p-1" (click)="delete(row)">
			<mat-icon [svgIcon]="IconName.icon_trash" class="text-danger mat-icon-ssm"></mat-icon>
		</a>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button *ngIf="canAccess" type="button" class="btn btn-primary btn-sm" (click)="openClientDetail(null)">Add New</button>
	</ng-template>
</div>
