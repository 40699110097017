<app-header></app-header>

<mat-sidenav-container (backdropClick)="closeMainMenu()"
	class="h-100 sidenav-container-mainmenu {{setingLayout.menu}} {{ menuActive && menuActive?.subMenus.length > 0 ? ' submenu-open' : ' submenu-close'}} {{ isExpandMenuDocked ? ' menu-docked-expand' : 'menu-docked-collapse' }}">
	<mat-sidenav class="sidenav-mainmenu box-shadow bg-sub-1" #sideMainMenu (keydown.escape)="closeMainMenu()" disableClose
		[fixedInViewport]="true" [fixedTopGap]="36" [fixedBottomGap]="0">
		<ul class="list-group list-group-flush h-100 bg-transparent" *ngIf="setingLayout.menu !== menuMode.menu_docked">
			<mat-accordion>
				<li class="list-group-item px-0 border-0 bg-transparent" *ngFor="let menu of mainMenus">
					<mat-expansion-panel class="bg-transparent" [disabled]="menu.subMenus && menu.subMenus.length === 0">
						<mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
							<mat-panel-title>
								<a href="javascript:void(0)" class="d-flex align-items-center">
									<mat-icon class="mat-icon-sm color-main" [svgIcon]="menu.icon"></mat-icon>
									<span class="pl-1 pt-1 color-main" translate>{{ menu.name }}</span>
								</a>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							<mat-list-item *ngFor="let subMenu of menu.subMenus">
								<a href="javascript:void(0)" (click)="clickOnSubMenu(subMenu)" routerLinkActive="active">
									<span class="color-main" translate>{{ subMenu.name }}</span>
								</a>
							</mat-list-item>
						</mat-list>
					</mat-expansion-panel>
				</li>
			</mat-accordion>
		</ul>
		<div class="d-flex flex-column h-100" *ngIf="setingLayout.menu === menuMode.menu_docked" (clickOutside)="onClickedOutside($event)">
			<div class="d-flex h-100">
				<div class="d-flex flex-column h-100 sidebar-bar-left b-right bg-sub-1">
					<div class="toggle-sub-menu bg-sub-2">
						<a href="javascript:void(0)" (click)="toggleExpandMenuDocked()">
							<mat-icon *ngIf="isExpandMenuDocked" class="mat-icon-ssm" [svgIcon]="IconName.icon_double_arrow_left">
							</mat-icon>
							<mat-icon *ngIf="!isExpandMenuDocked" class="mat-icon-ssm"
								[svgIcon]="IconName.icon_double_arrow_right">
							</mat-icon>
						</a>
					</div>
					<div class="content-menu h-100">
						<mat-list class="p-0">
							<mat-list-item *ngFor="let menu of mainMenus" (click)="clickOnMenu(menu)">
								<mat-icon class="mat-icon-sm color-main" [svgIcon]="menu.icon"></mat-icon>
								<span *ngIf="isExpandMenuDocked" class="pl-1 pt-1 color-main" translate>{{ menu.name }}</span>
							</mat-list-item>
						</mat-list>
					</div>
					<div class="mt-auto icon-signout">
						<a href="javascript:void(0)" (click)="logout()" class="text-muted">
							<mat-icon class="mat-icon-sm" [svgIcon]="IconName.icon_signout"></mat-icon>
						</a>
					</div>
				</div>
				<div class="flex-grow-1 sidebar-bar-right bg-sub-3" *ngIf="menuActive && menuActive?.subMenus.length > 0">
					<div class="toolbar-line p-2">{{ menuActive?.name | translate }}</div>
					<mat-list class="p-0">
						<mat-list-item *ngFor="let subMenu of menuActive?.subMenus" (click)="clickOnSubMenu(subMenu)"
							routerLinkActive="active">
							<span class="color-main">
								{{ subMenu.name | translate }}
							</span>
						</mat-list-item>
					</mat-list>
				</div>
			</div>
		</div>
	</mat-sidenav>

	<mat-sidenav-content>
		<section class="p-075 h-100">
			<router-outlet></router-outlet>
		</section>
	</mat-sidenav-content>
</mat-sidenav-container>
