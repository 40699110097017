import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IconName } from "@apps/common/constants/icon";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { FormlyFieldConfig } from "@ngx-formly/core/lib/components/formly.field.config";
import { User, UserFactory } from "../../model/user";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-change-pass",
  templateUrl: "./change-pass.component.html",
  styleUrls: ["./change-pass.component.scss"]
})
export class EmloUserChangePassComponent extends AdminBaseComponent
  implements OnInit {
  IconName = IconName;
  form = new FormGroup({});
  title = "";
  userFormFields: FormlyFieldConfig[] = [];
  public user: User = UserFactory.create();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
    if (!this.canAccess) return;
    this.activatedRoute.queryParamMap.subscribe((params) => {
			const userId = params.get("id");
			this.isEdit =
        userId && userId !== "00000000-0000-0000-0000-000000000000";
      if (this.isEdit) {
        this.initEditUser(userId);
      }
			this.createForm();
		});
  }
  private initEditUser(userId: string): void {
		this.userService.getUser(userId).subscribe((user: User) => {
			if (user) {
        this.user = user;
        this.title = "Reset password for user " + this.user.firstName + " " + this.user.lastName;
			}
		});
  }
  
  private createForm() {
		this.userFormFields = [
			IdControl,
			{
				key: "secretKey",
				validators: {
					fieldMatch: {
						expression: (control) => {
							const value = control.value;
							return (
								value.confirmSecretKey === value.secretKey ||
								// avoid displaying the message error when values are empty
								!value.confirmSecretKey ||
								!value.secretKey
							);
						},
						message: "Password does not match",
						errorPath: "confirmSecretKey",
					},
				},
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						key: "secretKey",
						type: "input",
						className: "col-6",
						templateOptions: {
							type: "password",
							label: "Password",
							placeholder:
								"Enter your new password here, at least 4 characters",
							minLength: 4,
						},
					},
					{
						key: "confirmSecretKey",
						type: "input",
						className: "col-6",
						templateOptions: {
							type: "password",
							label: "Confirm Password",
							placeholder: "Confirm your password",
						},
					},
				],
			},
		];
  }
  
  cancel(): void {
    this.resetFormAndClose();
  }
  private resetFormAndClose() {
    this.form.reset();
		this.router.navigate(["/account-management/user"]);
  }

  submit() {
    const data = this.form.value;
    const _newPass = data.secretKey.secretKey;
    const _newConfirmPass = data.secretKey.confirmSecretKey;
    
    this.updateUserPassword({
      id: data.id,
      secretKey: _newPass,
      confirmSecretKey: _newConfirmPass
    });
  }

  private updateUserPassword(body: any): void {
		this.userService.updateUserPassword(body).subscribe(() => {
			this.resetFormAndClose();
		});
	}
}
