import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { GeneralConfig } from "../model/general-config";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable, of } from "rxjs";
import { CAMPAIGNS } from "@apps/modules/emlo-admin/shared/mock-data/campaigns";

@Injectable()
export class GeneralConfigService {
	constructor(private apiService: ApiService) {}

	getGeneralConfig(): Observable<GeneralConfig> {
		return this.apiService.get(
			environment.prefix_api_url + "customergeneralconfig/current"
		);
	}

	updateGeneralConfig(context: GeneralConfig): Observable<GeneralConfig> {
		return this.apiService.post(
			environment.prefix_api_url + "customergeneralconfig/update",
			context
		);
	}

	initGeneralConfig(): Observable<GeneralConfig> {
		return this.apiService.post(
			environment.prefix_api_url + "customergeneralconfig/reInitGeneralConfig"
		);
	}

	getConfigs(): Observable<IApiPagingResponseModel<GeneralConfig[]>> {
		return null;
		// return of({
		// 	data: CAMPAIGNS,
		// 	pageSize: 2,
		// 	totalItems: 2,
		// 	pageNum: 1,
		// 	totalPages: 1,
		// });
		// const params = new HttpParams({ fromObject: request as any });
		// return this.apiService.get(
		// 	environment.prefix_api_url + "account/users",
		// 	params
		// );
	}
}
