import { CommonConstant } from "@apps/common/constants/common.constant";

export interface Currency {
	id: string;
	createDate: string;
	endDate: string;
	rate: number;
	status: string;
}
export interface CurrencyRestaurant {
	id: string;
	name: string;
	concurrencyStamp: string;
	assigned?: boolean;
}

export class CurrencyFactory {
	public static create(): Currency {
		return {
			id: null,
			createDate: null,
			endDate: null,
			rate: null,
			status: null
		};
	}
}

export class CurrencySearchModel {
	searchText: string = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
