/**
 * @author Thuyet Le Van
 * @email thuyetlv.qn@gmail.com
 * @create date 2020-02-11 00:10:53
 * @modify date 2020-02-11 00:10:53
 */
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { Shell } from "@apps/modules/emlo-admin/shell";
import { EmloDashboardModule } from "./dashboard/dashboard.module";
import { EmloProductManagementModule } from "./product-management/product-management.module";
import { EmloAccountManagementModule } from "./account-management/account-management.module";
import { EmloConfigurationModule } from "./configuration/configuration.module";
import { EmloReportModule } from "./report/report.module";
import { ComingSoonModule } from "./coming-soon/coming-soon.module";
import { CustomerServiceModule } from "./customer-service/customer-service.module";

const routes: Routes = [
	Shell.childRoutes([
		{ path: "", redirectTo: "/admin", pathMatch: "full" },
		{
			path: AppRoutesNames.ADMIN_DASH_BOARD,
			loadChildren: () => EmloDashboardModule
		},
		{
			path: AppRoutesNames.PRORUDCT_MANAGEMENT,
			loadChildren: () => EmloProductManagementModule
		},
		{
			path: AppRoutesNames.ACCOUNT_MANAGEMENT,
			loadChildren: () => EmloAccountManagementModule
		},
		{
			path: AppRoutesNames.CONFIGURATION,
			loadChildren: () => EmloConfigurationModule
		},
		{
			path: AppRoutesNames.REPORT,
			loadChildren: () => EmloReportModule
		},
		{
			path: AppRoutesNames.CUSTOMER_SERVICE,
			loadChildren: () => CustomerServiceModule
		},
		{
			path: AppRoutesNames.COMING_SOON,
			loadChildren: () => ComingSoonModule
		}
	])
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class EmloAdminRoutingModule {}
