import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppRoutesNames } from "@apps/common/constants/routes";
import { EmloConfigurationClientModule } from "./client";

const routes: Routes = [
	{
		path: "",
		redirectTo: AppRoutesNames.CLIENT
	},
	{
		path: AppRoutesNames.CLIENT,
		loadChildren: () => EmloConfigurationClientModule
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EmloConfigurationRoutingModule {}
