import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
	selector: "formly-field-button",
	template: `
		<div>
			<button
				[type]="to.type || 'button'"
				class="btn btn-{{ to.btnType}}"
				(click)="onClick($event)"
			>
				{{ to.text }}
			</button>
		</div>
	`
})
export class EmloButtonComponent extends FieldType {
	onClick($event) {
		if (this.to.onClick) {
			this.to.onClick($event);
		}
	}
}
