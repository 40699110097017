import { Component, OnInit } from "@angular/core";
import { RewardPointService } from "../../services/reward-point.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { RewardPoint } from "../../model/reward-point";
import { concat, Observable, of, Subject } from "rxjs";
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	switchMap,
	tap,
} from "rxjs/operators";
//import { InputValueType } from "@apps/common/enums/input-value-type";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateUtils } from "@apps/core/utils/date";
import { CustomerLevelSearchModel } from "../../../customer-level/model/customer-level";

@Component({
	selector: "app-reward-point-detail",
	templateUrl: "detail.html",
})
export class RewardPointDetailComponent extends AdminBaseComponent implements OnInit {
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	rewardPointDetailFormFields: FormlyFieldConfig[] = [];
	rewardPointDetail: RewardPoint = new RewardPoint();
	customerLevel$: Subject<any[]> = new Subject();
	items$: Observable<any>;
	itemsLoading = false;
	itemsInput$ = new Subject<string>();
	minLengthTerm = 3;
	menuItem: { id: string; name: string } = {
		id: null,
		name: null,
	};
	beginValidDate: NgbDateStruct;
	endValidDate: NgbDateStruct;
	date: any = {};

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private rewardPointService: RewardPointService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.getCustomerLevels();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const rewardPointId = params.get("id");
			if (rewardPointId) {
				this.isEdit = true;
				this.getRewardPointById(rewardPointId);
			}
			this.createForm();
		});
	}

	private getCustomerLevels(): void {
		this.rewardPointService.getCustomerLevels(new CustomerLevelSearchModel())
			.pipe(debounceTime(200))
			.subscribe((data) => {
			if (data) {
				this.customerLevel$.next(data.data);
			}
		});
	}

	private createForm() {
		this.rewardPointDetailFormFields = [
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "name",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Event Name",
							placeholder: "Event Name",
						},
					},
					{
						key: "customerLevelID",
						type: "emlo-select",
						className: "col-6",
						templateOptions: {
							label: "Customer Level",
							placeholder: "Customer Level",
							bindLabel: "name",
							bindValue: "id",
							options: this.customerLevel$,
						},
					},
					{
						key: "pointRatePercent",
						type: "input",
						className: "col-6",
						templateOptions: {
							label: "Point Rate",
							placeholder: "Point Rate Percent (1 is default)",
							type: "number",
						},
					},
					{
						type: "input",
						key: "priority",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Priority",
							placeholder: "Priority (larger numbers is higher priority)",
							type: "number",
						},
					},
					{
						type: "emlo-checkbox",
						key: "isApplyForHolidays",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Is Apply for Holidays",
							placeholder: "Is Apply for Holidays",
						},
					},
					IdControl,
					{
						type: "emlo-input",
						key: "concurrencyStamp",
						templateOptions: {
							type: "hidden",
						},
					},
				],
			},
		];
	}

	private getRewardPointById(rewardPointId: string): void {
		this.rewardPointService.getRewardPointById(rewardPointId).subscribe((rewardPoint: RewardPoint) => {
			if (rewardPoint) {
				this.menuItem = {
					id: rewardPoint.id,
					name: rewardPoint.name,
				};
				this.rewardPointDetail = rewardPoint;
				//this.beginValidDate = DateUtils.parse(rewardPoint.beginValidDate);
				//this.endValidDate = DateUtils.parse(rewardPoint.endValidDate);

				let date1 = new Date(rewardPoint.beginValidDate);
				let date2 = new Date(rewardPoint.endValidDate);
				this.date = {
					beginValidDate: {
						year: date1.getFullYear(),
						month: date1.getMonth() + 1,
						day: date1.getDate()
					},
					endValidDate: {
						year: date2.getFullYear(),
						month: date2.getMonth() + 1,
						day: date2.getDate()
					}
				};

			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		data.beginValidDateString = DateUtils.ConvertDateStructToDate(this.date.beginValidDate);
		data.endValidDateString = DateUtils.ConvertDateStructToDate(this.date.endValidDate);
		this.isEdit ? this.updateRewardPoint(data) : this.createRewardPoint(data);
	}

	private createRewardPoint(body: RewardPoint): void {
		this.rewardPointService.createRewardPoint(body).subscribe(() => {
			this.backToRewardPointList();
		});
	}

	private updateRewardPoint(body: RewardPoint): void {
		this.rewardPointService.updateRewardPoint(body).subscribe(() => {
			this.backToRewardPointList();
		});
	}

	cancel() {
		this.backToRewardPointList();
	}

	private backToRewardPointList(): void {
		this.router.navigate(["/customer-service/configuration/reward-point"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	onChangeMenuItem(item: { id: string; name: string }) {
		if (item) {
			this.menuItem = item;
		} else {
			this.menuItem = {
				id: null,
				name: null,
			};
		}
	}
}
