import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy
} from "@angular/core";
import { CommonComponent } from "@apps/core/common/common-component";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { User, UserFactory } from "../../model/user";
import { UserService } from "../../services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { IUserSearchRequestModel } from '../../model/user-search-request.model';
import { ApiRequestModelFactory } from '@apps/common/model/api.request.model';
import { PaginationModelFactory } from '@apps/common/model/api.reponse.model';
import { debounceTime } from 'rxjs/operators';
import { Router } from "@angular/router";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { Subject } from "rxjs";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-user-list",
	templateUrl: "./list.html"
})
export class EmloUserListComponent extends AdminBaseComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	@ViewChild("isLockedTpl", { static: true }) isLockedTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public users: User[] = [];
	request: IUserSearchRequestModel = ApiRequestModelFactory.create();
	public pagination = PaginationModelFactory.create();
	dataSource$: Subject<IUserSearchRequestModel> = new Subject();

	restaurants: IRestaurantItemModel[] = [];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private userService: UserService,
		private credentialsService: CredentialsService,
		private restaurantService: RestaurantService,
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;
		this.configColumnForUserTable();
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getUsers();
	}

	public onTableEvent($event: { event: string; value: any }) {
		if (
			$event.event === Event.onPagination ||
			$event.event === Event.onOrder
		) {
			this.request.pageNum = $event.value.pageNum;
			this.request.pageSize = $event.value.pageSize;
			this.getUsers();
		}
	}

	private getUsers() {
		this.userService.getUsers(this.request)
			.pipe(debounceTime(200))
			.subscribe((response) => {
				if(response) {
					this.users = response.data;
					this.pagination = response;
				}
			});
	}

	private configColumnForUserTable(): void {
		this.configuration = { ...DefaultConfig };
		this.configuration.paginationEnabled = true;
		this.columns = [
			{ key: "userName", title: "User name", orderEnabled: false },
			{ key: "firstName", title: "First name" },
			{ key: "lastName", title: "Last name" },
			{ key: "roleName", title: "User Role", orderEnabled: false },
			{
				key: "isLockedOut",
				title: "Locked",
				cellTemplate: this.isLockedTpl,
				width: "100px",
				cssClass: { name: "text-center", includeHeader: true },
				orderEnabled: false
			},
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false
			}
		];
	}

	toggleLockOut(event, user: User) {
		event.preventDefault();
		if (user.isLockedOut) {
			this.unLockUser(user);
		} else {
			const confirmModel: ConfirmModel = {
				id: null,
				message: "Are you sure want to lock this user?"
			};
			this.dialog
				.open(EmloConfirmComponent, {
					data: confirmModel
				})
				.afterClosed()
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.lockUser(user);
					}
				});
		}
	}

	private unLockUser(user: User): void {
		const data = { id: user.id };
		this.userService.unLockUser(data).subscribe(() => {
			this.dataSource$.next(this.request);
		});
	}

	private lockUser(user: User): void {
		const data = { id: user.id };
		this.userService.lockUser(JSON.stringify(data)).subscribe(() => {
			this.dataSource$.next(this.request);
		});
	}

	private deleteUser(user: User): void {
		const data = { id: user.id };
		this.userService.deleteUser(JSON.stringify(data)).subscribe(() => {
			this.dataSource$.next(this.request);
		});
	}

	delete(user: User): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?"
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteUser(user);
				}
			});
	}

	openUserDetail(user: User): void {
		if (!user) {
			user = UserFactory.create();
			this.router.navigate(["/account-management/user/add"]);
		} else {
			this.router.navigate(["/account-management/user/edit"], {
				queryParams: { id: user.id },
			});
		}
	}

	changePass(user: User): void {
		if (user) {
			this.router.navigate(["/account-management/user/changepass"], {
				queryParams: { id: user.id },
			});
		}
	}

	ngOnDestroy() {}
}
