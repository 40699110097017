import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { Tax, TaxSearchModel } from "../model/tax";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";

@Injectable()
export class TaxService {
	constructor(private apiService: ApiService) {}

	getTaxs(
		request: TaxSearchModel
	): Observable<IApiPagingResponseModel<Tax[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "tax/list",
			request
		);
	}

	getTax(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "tax/getbyid",
			params
		);
	}

	createTax(context: Tax) {
		return this.apiService.post(
			environment.prefix_api_url + "tax/create",
			context
		);
	}

	updateTax(context: Tax) {
		return this.apiService.post(
			environment.prefix_api_url + "tax/update",
			context
		);
	}

	deleteTax(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "tax/delete",
			context
		);
	}
}
