import { CommonConstant } from "@apps/common/constants/common.constant";
import { ItemRestaurant } from "../../item/model/item-detail";

export interface Category {
	id: string;
	name: string;
	nameEn: string;
	nameVi: string;
	nameCn: string;
	isVisible: boolean;
	isBeverage: boolean;
	sortOrder: number;
	btnColor: string;
	textColor: string;
	restaurantIds: string | string[];
	restaurants: ItemRestaurant[];
	concurrencyStamp: string;
}

export class CategoryFactory {
	public static create(): Category {
		return {
			id: null,
			name: null,
			nameEn: null,
			nameVi: null,
			nameCn: null,
			isVisible: true,
			isBeverage: null,
			sortOrder: null,
			btnColor: null,
			textColor: null,
			restaurantIds: [],
			restaurants: [],
			concurrencyStamp: null,
		};
	}
}

export class CategorySearchModel {
	searchText: string = null;
	isVisible: boolean = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}
