import { Component, OnInit, OnDestroy } from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import {
	AnalysisMenuItemReport,
	AnalysisMenuItem,
	TotalSummaryAnalysisMenuItem,
} from "../model/menu-item-sales-analysis";
import { MenuItemSalesAnalysisService } from "../services/menu-item-sales-analysis.service";
import { BaseReportComponent } from "../../shared/base.report.component";
import { DatePipe } from "@angular/common";
import { debounceTime, finalize } from "rxjs/operators";
import { ExcelServiceClient } from "@apps/modules/emlo-admin/shared/services/excel.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-menu-item-sales-analysis",
	templateUrl: "./menu-item-sales-analysis.html",
})
export class EmloMenuItemSalesAnalysisReportComponent
	extends BaseReportComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	reportingGroup: string = "All";
	reportingGroups: Array<string> = [];
	analysisMenuItemReport: AnalysisMenuItemReport;
	analysisMenuItemReports: AnalysisMenuItem[];
	summary: TotalSummaryAnalysisMenuItem;
	headerActionsTemplate: any;
	constructor(
		protected datePipe: DatePipe,
		private credentialsService: CredentialsService,
		private menuItemSalesService: MenuItemSalesAnalysisService,
		private restaurantService: RestaurantService
	) {
		super(datePipe);
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.Accountant,
		]);
		if (!this.canAccess) return;
		this.getReportGroups();
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
					this.restaurantId = restaurants[0].id;
					this.viewReport();
				}
			});
	}

	viewReport() {
		this.getDataViewReport(this.prepareDataForGetReport(true));
	}

	exportReport() {
		const data = this.prepareDataForGetReport(true);
		this.generateDetailSaleReport(data);
	}

	private generateDetailSaleReport(data: any): void {
		this.showInprogress();
		this.menuItemSalesService
			.getExcelReport(data)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.hideInprogress();
				})
			)
			.subscribe((response) => {
				ExcelServiceClient.openExcelFile(response);
			});
	}

	private getDataViewReport(body: any): void {
		this.showInprogress();
		this.menuItemSalesService
			.getitemReport(body)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.hideInprogress();
				})
			)
			.subscribe((data: AnalysisMenuItemReport) => {
				if (data && data.reportKey) {
					this.analysisMenuItemReport = data;
					if (data.data) {
						this.analysisMenuItemReports = data.data.data;
						this.totalItems = data.data.totalItems;
						this.summary = data.summary;
					}
				}
			});
	}

	private prepareDataForGetReport(isRegenerate: boolean = true) {
		const data = {
			reportingGroup: this.reportingGroup,
			startDate: this.getStartDate(),
			endDate: this.getEndDate(),
			startTime: this.startTime,
			endTime: this.endTime,
			regenerate: isRegenerate,
			pageSize: this.pageSize,
			pageNum: this.pageIndex + 1,
			restaurantId: this.restaurantId,
		};
		return data;
	}

	private getReportGroups(): void {
		this.menuItemSalesService
			.getReportGroups()
			.subscribe((reportGroups: Array<string>) => {
				if (reportGroups && reportGroups.length > 0) {
					reportGroups.forEach((x, i, newGroups) => {
						if (x === "None") newGroups[i] = "All";
					});
					this.reportingGroups = reportGroups;
				}
			});
	}

	ngOnDestroy(): void {}
}
