<div class="box border-sub-1">
	<emlo-box-header [title]="isEdit ? 'Edit User Role' : 'Add User Role'"
		[headerActionsTemplate]="headerActionsTemplate"></emlo-box-header>
	<form class="form-w-480" [formGroup]="form" (ngSubmit)="submit()" novalidate  *ngIf="canAccess; else contactAdmin">
		<div class="box-content">
			<div class="form-group">
				<label>{{ 'Role Name' | translate }}</label>
				<input type="hidden" formControlName="id" />
				<input type="hidden" formControlName="concurrencyStamp" />

				<input type="text" class="form-control" formControlName="name" autocomplete="off"
					[placeholder]="'Role Name' | translate" />
			</div>
			<div class="form-group">
				<label>{{ 'Role Type' | translate }}</label>
				<ng-select class="p-0" [items]="roles" formControlName="roleTypeId"
					bindLabel="name" bindValue="id" [placeholder]="'Role Type'" appendTo="body">
				</ng-select>
			</div>

			<!-- <div class="form-group">
				<fieldset class="stardard pr-0" *ngIf="usertype?.permissions">
					<legend>{{ 'Permissions' | translate }}</legend>
					<div class="content-popup-info w-100 row">
						<div class="col-sm-12 col-md-6 col-lg-4"
							*ngFor="let permission of usertype.permissions; let idx = index;">
							<mat-checkbox name="isActive_{{idx}}" [(ngModel)]="permission.isActive" [ngModelOptions]="{standalone: true}">
								{{ permission.name }}
							</mat-checkbox>
						</div>
					</div>
				</fieldset>
			</div> -->
		</div>
		<div class="box-footer">
			<button type="button" class="btn btn-danger mr-2" (click)="cancel()" translate>Cancel</button>
			<emlo-button type="submit" [className]="'btn btn-primary'" [disabled]="!form.valid" [textButton]="'Submit'"
				translate></emlo-button>
		</div>
	</form>
	<ng-template #contactAdmin>
		<div class="box-content">{{ "You don't have permission to access this page" | translate }}.</div>
	</ng-template>
	<ng-template #headerActionsTemplate>
		<button type="button" tabindex="-1" class="btn btn-link btn-sm" (click)="cancel()">
			<mat-icon [svgIcon]="IconName.icon_close" class="mat-icon-ssm"></mat-icon>
		</button>
	</ng-template>
</div>
