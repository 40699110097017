import {
	Component,
	OnInit,
	ViewChild,
	TemplateRef,
	OnDestroy
} from "@angular/core";
import { IconName } from "@apps/common/constants/icon";
import { Columns, Config, DefaultConfig, Event } from "@apps/shared/table";
import { ClientUsertUserTypeSearchModel, ClientUserType, UserTypeFactory } from "../../model/restaurant-usertypes";
import { RestautantUserTypeService } from "../../services/restaurant-usertypes.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModel } from "@apps/shared/confirm/confirm";
import { EmloConfirmComponent } from "@apps/shared/confirm/confirm.component";
import { PaginationModelFactory } from "@apps/common/model/api.reponse.model";
import { IClientUserTypeRequestModel } from "../../model/client-usertype-request-model";
import { ApiRequestModelFactory } from "@apps/common/model/api.request.model";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";

@Component({
	selector: "app-restaurant-usertype-list",
	templateUrl: "./list.html"
})
export class EmloRestaurantUserTypeListComponent extends AdminBaseComponent
	implements OnInit, OnDestroy {
	IconName = IconName;
	@ViewChild("actionTpl", { static: true }) actionTpl: TemplateRef<any>;
	public configuration: Config;
	public columns: Columns[] = [];
	public usertypes: ClientUserType[] = [];
	request: IClientUserTypeRequestModel = ApiRequestModelFactory.create();
	public pagination = PaginationModelFactory.create();
	dataSource$: Subject<IClientUserTypeRequestModel> = new Subject();
	criteria: ClientUsertUserTypeSearchModel = new ClientUsertUserTypeSearchModel();
	restaurants: IRestaurantItemModel[] = [];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private credentialsService: CredentialsService,
		private userTypeService: RestautantUserTypeService,
		private restaurantService: RestaurantService,
	) {
		super();
	}

	public ngOnInit() {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
		]);
		if (!this.canAccess) return;
		this.restaurantService
			.getRestaurants()
			.subscribe((restaurants: IRestaurantItemModel[]) => {
				if (restaurants && restaurants.length > 0) {
					this.restaurants = restaurants;
				}
			});
		this.getUserTypes();
		this.configColumnForUserTable();
	}

	public onTableEvent($event: {event: string, value: any}) {
		if($event.event === Event.onPagination || $event.event === Event.onOrder) {
			this.request = { ...this.request, ...$event.value };
			this.dataSource$.next(this.request);
		}
	}

	private getUserTypes() {
		this.userTypeService.getUserTypes(this.criteria)
		.subscribe((response) => {
			if(response) {
				this.usertypes = response.data;
				this.pagination = response;
			}
		});
	}

	private configColumnForUserTable(): void {
		this.configuration = { ...DefaultConfig, paginationEnabled: true };
		this.configuration.paginationEnabled = false;
		this.columns = [
			{ key: "name", title: "Type Name" },
			{ key: "restaurantNames", title: "Serving at" },
			{
				key: "action",
				title: "Action",
				cellTemplate: this.actionTpl,
				width: "120px",
				cssClass: { name: "text-right", includeHeader: true },
				orderEnabled: false
			}
		];
	}

	private deleteUserType(usertype: ClientUserType): void {
		const data = { id: usertype.id };
		this.userTypeService
			.deleteUserType(JSON.stringify(data))
			.subscribe(() => {
				this.dataSource$.next(this.request);
			});
	}

	delete(usertype: ClientUserType): void {
		const confirmModel: ConfirmModel = {
			id: null,
			message: "Are you sure want to delete?"
		};
		this.dialog
			.open(EmloConfirmComponent, {
				data: confirmModel
			})
			.afterClosed()
			.subscribe((isConfirmed: boolean) => {
				if (isConfirmed) {
					this.deleteUserType(usertype);
				}
			});
	}

	openUserDetail(usertype: ClientUserType): void {
		if (!usertype) {
			usertype = UserTypeFactory.create();
			this.router.navigate(["/account-management/restaurant-user-type/add"]);
		} else {
			this.router.navigate(["/account-management/restaurant-user-type/edit"], {
				queryParams: { id: usertype.id },
			});
		}
	}

	onFilter() {
		this.getUserTypes();
	}

	ngOnDestroy() {}
}
