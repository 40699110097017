<tr class="emlo-table__header" *ngIf="config.headerEnabled && !config.columnReorder">
	<th *ngIf="config.checkboxes || config.radio" [style.width]="'3%'">
		<ng-container *ngIf="selectAllTemplate && config.checkboxes" [ngTemplateOutlet]="selectAllTemplate"
			[ngTemplateOutletContext]="{ $implicit: onSelectAllBinded }">
		</ng-container>
		<label class="emlo-table-form-checkbox" for="selectAllCheckboxes"
			*ngIf="!selectAllTemplate && config.checkboxes">
			<input type="checkbox" id="selectAllCheckboxes" (change)="onSelectAll()">
			<em class="emlo-table-form-icon" id="selectAllCheckbox"></em>
		</label>
	</th>
	<ng-container *ngFor="let column of columns; let colIndex = index; let last = last">
		<th class="emlo-table__header-cell" [class.pinned-left]="column.pinned"
			[ngClass]="column.cssClass && column.cssClass.includeHeader ? column.cssClass.name : ''"
			[style.left]="styleService.pinnedWidth(column.pinned, colIndex)" #th [style.width]="getColumnWidth(column)"
			(mousedown)="onMouseDown($event, th)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)">
			<div (click)="orderBy(column)" style="display: inline" [class.pointer]="isOrderEnabled(column)">
				<div class="emlo-table__header-title">{{ column.title }}<span>&nbsp;</span>
					<mat-icon *ngIf="column.pinned" svgIcon="icon-pinned" class="mat-icon-sssm"></mat-icon>
					<div [style.display]="config.orderEnabled ? 'inline' : 'none' ">
						<mat-icon *ngIf="sortKey === column.key && this.sortState.get(sortKey) === 'asc'" svgIcon="icon-double-up-arrow" class="mat-icon-sssm"></mat-icon>
						<mat-icon *ngIf="sortKey === column.key && this.sortState.get(sortKey) === 'desc'" svgIcon="icon-double-down-arrow" class="mat-icon-sssm"></mat-icon>
					</div>
				</div>
			</div>
			<div class="emlo-table-dropdown" *ngIf="!!column.headerActionTemplate">
				<a class="emlo-table-btn emlo-table-btn-link" (click)="showHeaderActionTemplateMenu(column)">
					<mat-icon svgIcon="icon-more" class="mat-icon-ssm"></mat-icon>
				</a>
				<div class="emlo-table-menu emlo-table__table-menu"
					*ngIf="column.key === openedHeaderActionTemplate">
					<ng-container [ngTemplateOutlet]="column.headerActionTemplate">
					</ng-container>
				</div>
			</div>
			<div class="emlo-table__column-resizer" *ngIf="config.resizeColumn && !last"></div>
		</th>
	</ng-container>
	<th *ngIf="config.additionalActions || config.detailsTemplate || config.collapseAllRows || config.groupRows"
		class="emlo-table__header-cell-additional-actions">
		<div class="emlo-table-dropdown" #additionalActionMenu *ngIf="config.additionalActions">
			<a class="emlo-table-btn emlo-table-btn-link" (click)="showMenu()">
				<mat-icon svgIcon="icon-menu" class="mat-icon-sm black"></mat-icon>
			</a>
			<div class="emlo-table-menu emlo-table__table-menu" *ngIf="menuActive">
				<ng-container *ngIf="additionalActionsTemplate" [ngTemplateOutlet]="additionalActionsTemplate">
				</ng-container>
			</div>
		</div>
	</th>
</tr>
<tr class="emlo-table__header emlo-table__header--draggable"
	*ngIf="config.headerEnabled && config.columnReorder">
	<th [style.width]="'3%'">
		<ng-container *ngIf="selectAllTemplate && config.checkboxes" [ngTemplateOutlet]="selectAllTemplate"
			[ngTemplateOutletContext]="{ $implicit: onSelectAllBinded }">
		</ng-container>
		<label class="emlo-table-form-checkbox" for="selectAllCheckboxes"
			*ngIf="!selectAllTemplate && config.checkboxes">
			<input type="checkbox" id="selectAllCheckboxesDrag" (change)="onSelectAll()">
			<em class="emlo-table-form-icon" id="selectAllCheckboxDrag"></em>
		</label>
	</th>
	<ng-container *ngFor="let column of columns; let colIndex = index; let last = last">
		<th class="emlo-table__header-cell emlo-table__header-cell--draggable"
			[class.pinned-left]="column.pinned"
			[ngClass]="column.cssClass && column.cssClass.includeHeader ? column.cssClass.name : ''"
			[style.left]="styleService.pinnedWidth(column.pinned, colIndex)" #th [style.width]="getColumnWidth(column)"
			(mousedown)="onMouseDown($event, th)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)">
			<div (click)="orderBy(column)" style="display: inline" [class.pointer]="isOrderEnabled(column)">
				<div class="emlo-table__header-title">{{ column.title }}<span>&nbsp;</span>
					<mat-icon *ngIf="column.pinned" svgIcon="icon-pinned" class="mat-icon-sssm"></mat-icon>
					<div [style.display]="config.orderEnabled ? 'inline' : 'none' ">
						<mat-icon *ngIf="sortKey === column.key && this.sortState.get(sortKey) === 'asc'" svgIcon="icon-double-up-arrow" class="mat-icon-sssm"></mat-icon>
						<mat-icon *ngIf="sortKey === column.key && this.sortState.get(sortKey) === 'desc'" svgIcon="icon-double-down-arrow" class="mat-icon-sssm"></mat-icon>
					</div>
				</div>
			</div>
			<div class="emlo-table-dropdown" *ngIf="!!column.headerActionTemplate">
				<a class="emlo-table-btn emlo-table-btn-link" (click)="showHeaderActionTemplateMenu(column)">
					<mat-icon svgIcon="icon-more" class="mat-icon-sssm"></mat-icon>
				</a>
				<div class="emlo-table-menu emlo-table__table-menu"
					*ngIf="column.key === openedHeaderActionTemplate">
					<ng-container [ngTemplateOutlet]="column.headerActionTemplate">
					</ng-container>
				</div>
			</div>
			<div class="emlo-table__column-resizer" *ngIf="config.resizeColumn && !last"></div>
		</th>
	</ng-container>
	<th *ngIf="config.additionalActions || config.detailsTemplate || config.collapseAllRows || config.groupRows"
		class="emlo-table__header-cell-additional-actions">
		<div class="emlo-table-dropdown" #additionalActionMenu *ngIf="config.additionalActions">
			<a class="emlo-table-btn emlo-table-btn-link" (click)="showMenu()">
				<mat-icon svgIcon="icon-menu" class="mat-icon-sm black"></mat-icon>
			</a>
			<div class="emlo-table-menu emlo-table__table-menu" *ngIf="menuActive">
				<ng-container *ngIf="additionalActionsTemplate" [ngTemplateOutlet]="additionalActionsTemplate">
				</ng-container>
			</div>
		</div>
	</th>
</tr>
<tr *ngIf="config.searchEnabled && !filtersTemplate" class="emlo-table__sort-header">
	<th *ngIf="config.checkboxes || config.radio"></th>
	<ng-container *ngFor="let column of columns; let colIndex = index">
		<th [ngClass]="column.cssClass && column.cssClass.includeHeader ? column.cssClass.name : ''"
			[class.pinned-left]="column.pinned" [style.left]="styleService.pinnedWidth(column.pinned, colIndex)">
			<table-header *ngIf="getColumnDefinition(column)" (update)="onSearch($event)" [column]="column" [config]="config">
			</table-header>
		</th>
	</ng-container>
	<th *ngIf="config.additionalActions || config.detailsTemplate"></th>
</tr>
<ng-container *ngIf="filtersTemplate">
	<tr>
		<ng-container [ngTemplateOutlet]="filtersTemplate">
		</ng-container>
	</tr>
</ng-container>
