import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { environment } from "@env/environment";
import { ApiRequestModelFactory } from '@apps/common/model/api.request.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PrinterService {
	constructor(private apiService: ApiService) {}

	getAvaiablePrinterGroups(restaurantId: string) {
		// const paging: any = ApiRequestModelFactory.createPagingMaxSize();
		// paging.restaurantId = restaurantId;
		// const httpPrams = new HttpParams({ fromObject: paging });
		// return this.apiService.get(environment.prefix_api_url + "configuration/getPrinterGroups", httpPrams);
		let taxRequest = {
			restaurantIds: restaurantId ? [ restaurantId ] : null
		};
		return this.apiService.post(environment.prefix_api_url + "configuration/printerGroupSelection", taxRequest);
	}
}
