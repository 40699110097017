import { Component, OnInit } from "@angular/core";
import { CustomerOverviewService } from "./services/customer-overview.service";
import { ChartType, ChartOptions, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import {
	NgbCalendar,
	NgbDate,
	NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { IconName } from "@apps/common/constants/icon";
import { debounceTime, finalize } from "rxjs/operators";
import { ExcelServiceClient } from "../../shared/services/excel.service";

@Component({
	selector: "app-customer-service-overview",
	templateUrl: "overview.component.html",
	styleUrls: ["overview.component.scss"],
})
export class OverviewComponent implements OnInit {
	isLoadingCustomerRegistrationStatistic: boolean = false;
	isLoadingFrequentlyStatistic: boolean = false;
	isLoadingGenderStatistic: boolean = false;
	isLoadingLevelOverview: boolean = false;
	IconName = IconName;
	public pieChartOptions: ChartOptions = {
		responsive: true,
		legend: {
			position: "right",
		},
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					// const label = ctx.chart.data.labels[ctx.dataIndex] + value;
					return value + "%";
				},
			},
		},
	};
	public customerRegistrationChartLabels: Label[] = [];
	public customerRegistrationData: number[] = [];
	public customerActivityStatisticOverviewChartLabels: Label[] = [];
	public customerActivityStatisticOverviewData: number[] = [];
	public customerGenderOverviewChartLabels: Label[] = [];
	public customerGenderOverviewData: number[] = [];
	public customerLevelOverviewChartLabels: Label[] = [];
	public customerLevelOverviewData: number[] = [];
	public pieChartType: ChartType = "pie";
	public barCustomerRegistrationChartType: ChartType = "bar";
	public pieChartLegend = true;
	public pieChartPlugins = [pluginDataLabels];
	public pieChartColors = [
		{
			backgroundColor: [
				"rgb(255, 0, 0)",		// red
				"rgb(255, 255, 0)",		// yellow
				"rgb(192, 192, 192)",	// gray
				"rgb(128, 128, 0)",
				"rgb(0, 0, 255)",		// blue
			],
		},
	];
	public pieRegistrationChartColors = [
		{
			backgroundColor: [
				"rgb(0, 0, 255)",		// blue
				"rgb(192, 192, 192)",	// gray
			],
		},
	];
	public barChartOptions: ChartOptions = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: { xAxes: [{}], yAxes: [{}] },
		plugins: {
			datalabels: {
			  anchor: 'end',
			  align: 'end',
			}
		  }
	  };

	// Range Date Customer Registration Statistic
	hoveredRegistrationDate: NgbDate | null = null;
	fromRegistrationDate: NgbDate | null;
	toRegistrationDate: NgbDate | null;
	onRegistrationDateSelection(date: NgbDate) {
		if (!this.fromRegistrationDate && !this.toRegistrationDate) {
			this.fromRegistrationDate = date;
		} else if (
			this.fromRegistrationDate &&
			!this.toRegistrationDate &&
			date &&
			date.after(this.fromRegistrationDate)
		) {
			this.toRegistrationDate = date;
		} else {
			this.toRegistrationDate = null;
			this.fromRegistrationDate = date;
		}
		if (this.fromRegistrationDate && this.toRegistrationDate) {
			this.getCustomerRegistrationStatistic();
		}
	}

	isRegistrationHovered(date: NgbDate) {
		return (
			this.fromRegistrationDate &&
			!this.toRegistrationDate &&
			this.hoveredRegistrationDate &&
			date.after(this.fromRegistrationDate) &&
			date.before(this.hoveredRegistrationDate)
		);
	}

	isRegistrationInside(date: NgbDate) {
		return (
			this.toRegistrationDate && date.after(this.fromRegistrationDate) && date.before(this.toRegistrationDate)
		);
	}

	isRegistrationRange(date: NgbDate) {
		return (
			date.equals(this.fromRegistrationDate) ||
			(this.toRegistrationDate && date.equals(this.toRegistrationDate)) ||
			this.isRegistrationInside(date) ||
			this.isRegistrationHovered(date)
		);
	}

	validateRegistrationInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed))
			? NgbDate.from(parsed)
			: currentValue;
	}

	// Range Date Customer Frequently Statistic
	hoveredFrequentlyDate: NgbDate | null = null;
	fromFrequentlyDate: NgbDate | null;
	toFrequentlyDate: NgbDate | null;
	onFrequentlyDateSelection(date: NgbDate) {
		if (!this.fromFrequentlyDate && !this.toFrequentlyDate) {
			this.fromFrequentlyDate = date;
		} else if (
			this.fromFrequentlyDate &&
			!this.toFrequentlyDate &&
			date &&
			date.after(this.fromFrequentlyDate)
		) {
			this.toFrequentlyDate = date;
		} else {
			this.toFrequentlyDate = null;
			this.fromFrequentlyDate = date;
		}
		if (this.fromFrequentlyDate && this.toFrequentlyDate) {
			this.getCustomerFrequentlyStatistic();
		}
	}

	isFrequentlyHovered(date: NgbDate) {
		return (
			this.fromFrequentlyDate &&
			!this.toFrequentlyDate &&
			this.hoveredFrequentlyDate &&
			date.after(this.fromFrequentlyDate) &&
			date.before(this.hoveredFrequentlyDate)
		);
	}

	isFrequentlyInside(date: NgbDate) {
		return (
			this.toFrequentlyDate && date.after(this.fromFrequentlyDate) && date.before(this.toFrequentlyDate)
		);
	}

	isFrequentlyRange(date: NgbDate) {
		return (
			date.equals(this.fromFrequentlyDate) ||
			(this.toFrequentlyDate && date.equals(this.toFrequentlyDate)) ||
			this.isRegistrationInside(date) ||
			this.isRegistrationHovered(date)
		);
	}

	validateFrequentlyInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed))
			? NgbDate.from(parsed)
			: currentValue;
	}

	constructor(
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private customerOverviewService: CustomerOverviewService
	) {
		this.fromRegistrationDate = calendar.getNext(calendar.getToday(), "d", -14);
		this.toRegistrationDate = calendar.getToday();
		this.fromFrequentlyDate = calendar.getNext(calendar.getToday(), "d", -14);
		this.toFrequentlyDate = calendar.getToday();
	}

	ngOnInit(): void {
		this.getCustomerRegistrationStatistic();
		this.getCustomerFrequentlyStatistic();
		this.getCustomerGenderStatistic();
		this.getCustomerLevelOverview();
	}

	private getCustomerRegistrationStatistic() {
		const fromDate = this.customerOverviewService.formatDate(this.fromRegistrationDate);
		const toDate = this.customerOverviewService.formatDate(this.toRegistrationDate);
		this.isLoadingCustomerRegistrationStatistic = true;
		this.customerOverviewService
			.getCustomerRegistrationStatistic(fromDate, toDate)
			.pipe(
				finalize(() => {
					this.isLoadingCustomerRegistrationStatistic = false;
				}),
			)
			.pipe(debounceTime(200))
			.subscribe((data) => {
				if (data) {
					// debugger
					this.customerRegistrationChartLabels = [];
					this.customerRegistrationData = [];
					data.forEach((x) => {
						this.customerRegistrationChartLabels.push(x.name);
						this.customerRegistrationData.push(x.value);
					});
				}
			});
	}

	private getCustomerFrequentlyStatistic() {
		this.isLoadingFrequentlyStatistic = true;
		const fromDate = this.customerOverviewService.formatDate(this.fromFrequentlyDate);
		const toDate = this.customerOverviewService.formatDate(this.toFrequentlyDate);
		this.customerOverviewService
			.getCustomerActivityStatisticOverview(fromDate, toDate)
			.pipe(
				finalize(() => {
					this.isLoadingFrequentlyStatistic = false;
				}),
			)
			.pipe(debounceTime(200))
			.subscribe((data) => {
				if (data) {
					this.customerActivityStatisticOverviewChartLabels = [];
					this.customerActivityStatisticOverviewData = [];
					data.forEach((x) => {
						this.customerActivityStatisticOverviewChartLabels.push(x.name);
						this.customerActivityStatisticOverviewData.push(x.value);
					});
				}
			});
	}

	private getCustomerGenderStatistic() {
		this.isLoadingGenderStatistic = true;
		this.customerOverviewService
			.getCustomerGenderStatistic()
			.pipe(
				finalize(() => {
					this.isLoadingGenderStatistic = false;
				}),
			)
			.pipe(debounceTime(200))
			.subscribe((data) => {
				if (data) {
					this.customerGenderOverviewChartLabels = [];
					this.customerGenderOverviewData = [];
					data.forEach((x) => {
						this.customerGenderOverviewChartLabels.push(x.name);
						this.customerGenderOverviewData.push(x.value);
					});
				}
			});
	}

	private getCustomerLevelOverview() {
		this.isLoadingLevelOverview = true;
		this.customerOverviewService
			.getCustomerLevelOverview()
			.pipe(
				finalize(() => {
					this.isLoadingLevelOverview = false;
				}),
			)
			.pipe(debounceTime(200))
			.subscribe((data) => {
				if (data) {
					this.customerLevelOverviewChartLabels = [];
					this.customerLevelOverviewData = [];
					data.forEach((x) => {
						this.customerLevelOverviewChartLabels.push(x.name);
						this.customerLevelOverviewData.push(x.value);
					});
				}
			});
	}
	downloadReport(type: number) {
		switch (type) {
			case 0:
				return this.downloadGenderReport();
			case 1:
				return this.downloadRegistrationReport();
			case 2:
				return this.downloadActivityReport();
			default:
				return this.downloadCustomerLevelReport();
		}
	}
	downloadRegistrationReport() {
		const from = this.customerOverviewService.formatDate(this.fromRegistrationDate);
		const to = this.customerOverviewService.formatDate(this.toRegistrationDate);
		const type = 1;
		const request = {
			from,
			to,
			type
		}
		this.isLoadingCustomerRegistrationStatistic = true;
		this.customerOverviewService
			.downloadRegistrationReport(request)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.isLoadingCustomerRegistrationStatistic = false;
				})
			)
			.subscribe((response) => {
				ExcelServiceClient.openExcelFile(response);
			});
	}
	downloadGenderReport() {
		const from = this.customerOverviewService.formatDate(this.fromRegistrationDate);
		const to = this.customerOverviewService.formatDate(this.toRegistrationDate);
		const type = 0;
		const request = {
			from,
			to,
			type
		}
		this.isLoadingGenderStatistic = true;
		this.customerOverviewService
			.downloadRegistrationReport(request)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.isLoadingGenderStatistic = false;
				})
			)
			.subscribe((response) => {
				ExcelServiceClient.openExcelFile(response);
			});
	}
	downloadActivityReport() {
		const from = this.customerOverviewService.formatDate(this.fromFrequentlyDate);
		const to = this.customerOverviewService.formatDate(this.toFrequentlyDate);
		const type = 2;
		const request = {
			from,
			to,
			type
		}
		this.isLoadingFrequentlyStatistic = true;
		this.customerOverviewService
			.downloadActivityReport(request)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.isLoadingFrequentlyStatistic = false;
				})
			)
			.subscribe((response) => {
				ExcelServiceClient.openExcelFile(response);
			});
	}
	downloadCustomerLevelReport() {
		const from = this.customerOverviewService.formatDate(this.fromFrequentlyDate);
		const to = this.customerOverviewService.formatDate(this.toFrequentlyDate);
		const type = 3;
		const request = {
			from,
			to,
			type
		};
		this.isLoadingLevelOverview = true;
		this.customerOverviewService
			.downloadActivityReport(request)
			.pipe(debounceTime(300))
			.pipe(
				finalize(() => {
					this.isLoadingLevelOverview = false;
				})
			)
			.subscribe((response) => {
				ExcelServiceClient.openExcelFile(response);
			});
	}
}
