import { Component, OnInit } from "@angular/core";
import { CategoryService } from "../../services/category.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { Category, CategoryFactory } from "../../model/category";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
	IdControl,
	NameEnControl,
	NameViControl,
	NameCnControl,
	ButtonTextColorControl,
	VisibleAndSortOrderControl,
} from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { RestaurantService } from "@apps/modules/emlo-admin/shell/services/restaurant.service";
import { IRestaurantItemModel } from "@apps/modules/emlo-admin/shared/model/restaurant-item.model";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";

@Component({
	selector: "app-productmanagement-category-detail",
	templateUrl: "detail.html",
})
export class EmloCategoryDetailComponent
	extends AdminBaseComponent
	implements OnInit {
	IconName = IconName;
	form = new FormGroup({});
	categoryFormFields: FormlyFieldConfig[] = [];

	category: Category = CategoryFactory.create();
	tabIndex: number = 0;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private categoryService: CategoryService,
		private restaurantService: RestaurantService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.ProductManager,
		]);
		if (!this.canAccess) return;
		this.category = CategoryFactory.create();
		this.createForm();

		this.activatedRoute.queryParamMap.subscribe((params) => {
			this.restaurantService
				.getRestaurants()
					.subscribe((restaurants: IRestaurantItemModel[]) => {
						if (restaurants && restaurants.length > 0) {
							this.restaurants = restaurants;
							this.restaurants.map((x) => {
								x.isVisible = false;
								x.concurrencyStamp = null;
							});
							const categoryId = params.get("id");
							this.isEdit = categoryId && categoryId !== null;
							if (this.isEdit) {
								this.initEditCategory(categoryId);
							}
						}
					});
		});
	}

	private createForm() {
		this.categoryFormFields = [
			// {
			// 	key: "restaurantIds",
			// 	type: "emlo-select",
			// 	templateOptions: {
			// 		label: "Restaurant",
			// 		multiple: true,
			// 		bindLabel: "name",
			// 		bindValue: "id",
			// 		options: this.adminShareDataService.restaurants
			// 	}
			// },
			{
				key: "concurrencyStamp",
				templateOptions: {
					hidden: true
				}
			},
			IdControl,
			NameEnControl,
			NameViControl,
			NameCnControl,
			VisibleAndSortOrderControl,
			ButtonTextColorControl,
		];
	}

	private initEditCategory(categoryId: string): void {
		this.categoryService
			.getCategory(categoryId)
			.subscribe((category: Category) => {
				this.category = category;
				this.mapDataCategoryRestaurant();
			});
	}

	private mapDataCategoryRestaurant(): void {
		this.restaurants.map((res) => {
			res.assigned = false;
			const categoryRestaurant = this.category.restaurants?.find(
				(itemRes) => itemRes.id === res.id
			);
			if (categoryRestaurant) {
				res.assigned = true;
				res.isVisible = categoryRestaurant.isVisible;
				res.concurrencyStamp = categoryRestaurant.concurrencyStamp || null;
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		const assignRestaurants = this.restaurants.filter(
			(r) => r.assigned === true
		);
		data.restaurants = assignRestaurants;
		this.isEdit ? this.updateCategory(data) : this.createCategory(data);
	}

	private createCategory(body: Category): void {
		this.categoryService.createCategory(body).subscribe(() => {
			this.backToCategoryList(true);
		});
	}

	private updateCategory(body: Category): void {
		this.categoryService.updateCategory(body).subscribe(() => {
			this.backToCategoryList(true);
		});
	}

	cancel() {
		this.backToCategoryList();
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	toggleAssignRestaurant(restaurant: IRestaurantItemModel) {}

	private backToCategoryList(isReload: boolean = false): void {
		this.router.navigate(["/product-management/category"]);
	}
}
