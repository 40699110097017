import { CommonConstant } from "@apps/common/constants/common.constant";

export interface Tax {
	id: string;
	name: string;
	rate: number;
	isDefault: boolean;
	mustForceChangeIfDefault: boolean;
	restaurantId: string | string[];
	restaurants: TaxRestaurant[];
}

export interface TaxRestaurant {
	concurrencyStamp: string;
	id: string;
	isDefault: boolean;
	name: string;
}

export class TaxSearchModel {
	searchText: string = null;
	isVisible: boolean = null;
	restaurantId: string | string[] = null;
	pageNum: number = 1;
	pageSize: number = CommonConstant.NUMBER_RECORD_PER_PAGE;
}

export class TaxFactory {
	public static create(): Tax {
		return {
			id: null,
			name: null,
			rate: null,
			isDefault: true,
			mustForceChangeIfDefault: true,
			restaurantId: null,
			restaurants: []
		};
	}
}
