import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@apps/shared";
import { EmloReportRoutingModule } from "./report-routing.module";
import { EmloReportComponent } from "./report.component";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		EmloReportRoutingModule
	],
	declarations: [EmloReportComponent]
})
export class EmloReportModule {}
