import { Injectable } from "@angular/core";
import { ApiService } from "@apps/core/services";
import { HttpParams } from "@angular/common/http";
import { RewardPoint, RewardPointSearchModel } from "../model/reward-point";
import { CustomerLevel, CustomerLevelSearchModel } from "../../customer-level/model/customer-level";
import { environment } from "@env/environment";
import { IApiPagingResponseModel } from "@apps/common/model/api.reponse.model";
import { Observable, of } from "rxjs";

@Injectable()
export class RewardPointService {
	constructor(private apiService: ApiService) {}

	getRewardPointById(id: string) {
		const params = new HttpParams().set("id", id);
		return this.apiService.get(
			environment.prefix_api_url + "rewardpointdef/getbyid",
			params
		);
	}

	createRewardPoint(context: RewardPoint) {
		return this.apiService.post(
			environment.prefix_api_url + "rewardpointdef/create",
			context
		);
	}

	updateRewardPoint(context: RewardPoint) {
		return this.apiService.post(
			environment.prefix_api_url + "rewardpointdef/update",
			context
		);
	}

	deleteRewardPoint(context: any) {
		return this.apiService.post(
			environment.prefix_api_url + "rewardpointdef/delete",
			context
		);
	}

	getRewardPoints(
		criteria: RewardPointSearchModel
	): Observable<IApiPagingResponseModel<RewardPoint[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "rewardpointdef/list",
			criteria
		);
	}

	getCustomerLevels(
		request: CustomerLevelSearchModel
	): Observable<IApiPagingResponseModel<CustomerLevel[]>> {
		return this.apiService.post(
			environment.prefix_api_url + "leveldef/list",
			request
		);
	}
}
